import React from "react";
import { Grid } from "@material-ui/core";

const ActionCardList = ({ children }) => (
  <Grid container wrap="nowrap" direction="column" spacing={2}>
    {React.Children.map(
      children,
      card => card && <Grid item>{React.cloneElement(card)}</Grid>,
    )}
  </Grid>
);

export default ActionCardList;
