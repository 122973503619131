import * as React from "react";

import { LinearProgress, Box, makeStyles } from "@material-ui/core";

import { ProgressTrackBarProps } from "../../types";

const useStyles = makeStyles(
  // @ts-ignore
  theme => theme.journey.progressTracker,
);

const ProgressTrackBar: React.FC<ProgressTrackBarProps> = ({
  categoryName,
  progressPercentage,
}) => {
  const classes = useStyles();
  return (
    <div data-test="progress-tracker-bar">
      <Box display="flex" justifyContent="space-between">
        <span //@ts-ignore
          className={classes.categoryName}
        >
          {categoryName}
        </span>
        <span //@ts-ignore
          className={classes.percentComplete}
        >
          {progressPercentage}%
        </span>
      </Box>
      <LinearProgress //@ts-ignore
        classes={{ root: classes.progress }}
        variant="determinate"
        value={progressPercentage}
      />
    </div>
  );
};
export default ProgressTrackBar;
