import * as React from "react";
import { node, shape, bool } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Radio } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    border: "2px solid #E3E2E3",
    boxSizing: "border-box",
    boxShadow: "none",
    borderRadius: "6px",
    padding: "16px 5px",
    fontSize: "1rem",
    flex: "1",
    margin: "0 4px",
    [theme.breakpoints.up("md")]: {
      margin: "0 12px",
      padding: "16px",
    },
  },
  checked: {
    borderColor: theme.palette.primary.main,
    borderWidth: "2px",
    boxShadow: theme.boxShadow,
  },
  disabled: {
    opacity: "0.5",
  },
}));

const RadioCard = ({
  value,
  cardContent,
  cardContentChecked,
  classes = {},
  disabled = false,
  ...restProps
}) => {
  const radioClasses = useStyles({ classes });
  return (
    <Radio
      color="primary"
      classes={radioClasses}
      icon={cardContent}
      checkedIcon={cardContentChecked}
      value={value}
      disabled={disabled}
      inputProps={{ "data-test": `radio-card-${value}` }}
      {...restProps}
    />
  );
};

RadioCard.propTypes = {
  value: node,
  cardContent: node,
  cardContentChecked: node,
  classes: shape({}),
  disabled: bool,
};

export default React.memo(RadioCard);
