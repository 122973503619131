import React from "react";
import { bool, string } from "prop-types";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import Button from "../Button/Button";
import { useBrand } from "../BrandProvider/BrandProvider";

type ContinueButtonProps = {
  href?: string;
  text: string;
  disableVerticalMargin?: boolean;
  disableHorizontalMargin?: boolean;
  [key: string]: any; // restProps
};

const useStyles = ({
  disableHorizontalMargin,
  disableVerticalMargin,
}: Pick<
  ContinueButtonProps,
  "disableHorizontalMargin" | "disableVerticalMargin"
>) =>
  makeStyles((theme: Theme & { journey: { continueButton: any } }) =>
    theme.journey.continueButton({
      disableHorizontalMargin,
      disableVerticalMargin,
    }),
  );

const ContinueButton = React.forwardRef<HTMLButtonElement, ContinueButtonProps>(
  (
    {
      href,
      text,
      disableVerticalMargin = false,
      disableHorizontalMargin = false,
      ...restProps
    },
    ref,
  ) => {
    const { getText } = useBrand();
    const classes = useStyles({
      disableHorizontalMargin,
      disableVerticalMargin,
    })();

    // We want to use assign here to preserve type information
    // eslint-disable-next-line prefer-object-spread
    const mergeProps = Object.assign({}, restProps);
    if (href) {
      mergeProps.href = href;
    }

    return (
      <Button
        text={text || getText("common.continueButton")}
        classes={classes}
        href={href}
        ref={ref}
        {...mergeProps}
      />
    );
  },
);

ContinueButton.propTypes = {
  href: string,
  text: string,
  disableVerticalMargin: bool,
  disableHorizontalMargin: bool,
};

export default ContinueButton;
