import { GraphQLError } from "graphql";
import { useQuery } from "@apollo/react-hooks";

import getUpcomingLessons from "../graphql/getUpcomingLessons.graphql";

const useGetUpcomingLessons = ({
  brand,
  learnerId,
  fetchPolicy = "no-cache",
}) =>
  useQuery(getUpcomingLessons, {
    variables: {
      brand,
      learnerId,
    },
    fetchPolicy,
    onError: error => {
      throw new GraphQLError(error);
    },
  });

export default useGetUpcomingLessons;
