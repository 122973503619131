import React from "react";
import { bool, func, node, string } from "prop-types";
import { ListItem as MuiListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  listItem: {
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: theme.journey.radioItemList.borderColor,
    boxSizing: "border-box",
    borderRadius: theme.journey.radioItemList.borderRadius,
    marginBottom: theme.journey.radioItemList.marginBottom,
    fontSize: "16px",
    lineHeight: "24px",
    opacity: theme.journey.radioItemList.opacity,
    color: theme.palette.grey[400],
    padding: theme.journey.radioItemList.padding,
    "&:hover": {
      borderColor: theme.journey.radioItemList.hoverBorderColor,
      backgroundColor: "transparent",
    },
  },
  selectedListItem: {
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    boxSizing: "border-box",
    borderRadius: theme.journey.radioItemList.borderRadius,
    marginBottom: theme.journey.radioItemList.marginBottom,
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.grey[400],
    padding: theme.journey.radioItemList.padding,
  },
}));

const RadioListItem = ({ value, selected, onClick, testId, children }) => {
  const classes = useStyles();

  return (
    <MuiListItem
      className={selected ? classes.selectedListItem : classes.listItem}
      key={value}
      dense
      button
      onClick={() => onClick(value)}
      selected={selected}
      data-test={testId}
    >
      {children}
    </MuiListItem>
  );
};

RadioListItem.propTypes = {
  value: string,
  selected: bool,
  onClick: func,
  testId: string,
  children: node,
};

export default RadioListItem;
