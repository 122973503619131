const palette = {
  background: {
    default: "#fff",
    dark: "#F6F6F6",
  },
  primary: {
    main: "#F9812A",
    dark: "#E6604F",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#4E1EB5",
    contrastText: "#FFFFFF",
  },
  type: "light",
  text: {
    primary: "#2E2E36",
    secondary: "#F9812A",
  },
  grey: {
    50: "#F8F7F9",
    100: "#F4F3F5",
    200: "#E3E2E3",
    300: "#EFEEF0",
    400: "#807B84",
    500: "#2E2E36",
  },
  common: {
    black: "#2B2D32",
    white: "#FFFFFF",
  },
};

export const gradients = {
  primaryLinear: "linear-gradient(90deg, #F9812A 1%, #FDA147 99%)",
};

export default palette;
