import {
  differenceInDays,
  addHours,
  subHours,
  format,
  parseISO,
  set,
} from "date-fns";
import {
  CLEAR_AVAILABLE_NUJ_INSTRUCTORS,
  CLEAR_NUJ_SELECTED_INSTRUCTOR,
  CLEAR_NUJ_SELECTED_INSTRUCTOR_HISTORY,
  UPDATE_NUJ_HISTORY,
} from "../../../../context/stateContext";
import {
  NEW_USER_JOURNEY_PATH_NAMES,
  LONG_DATE_FORMAT,
} from "../../../../constants";

import { LearnerLessonType } from "@graphql/types";

const queryStringParams = ["postcode", "tT", "lT", "tC"];

export const isCrossSellQueryString = obj => {
  const keys = Object.keys(obj);
  return queryStringParams.every(
    param => keys.includes(param) && obj[param] !== "",
  );
};

export const changeSelectedPackage = ({
  hours,
  selectedInstructorPrices,
  dispatch,
  trackEvent,
}) => {
  const matchingPackage =
    selectedInstructorPrices &&
    selectedInstructorPrices.find(
      lessonPackage => lessonPackage.hours.toString() === hours,
    );

  /* istanbul ignore else */
  if (matchingPackage) {
    trackEvent({
      eventCategory: "Prices",
      eventAction: "Interaction",
      eventLabel: `Lesson Package (${matchingPackage.hours} hours)`,
    });
    dispatch({
      type: UPDATE_NUJ_HISTORY,
      payload: {
        selectedPackage: matchingPackage,
      },
    });
  }
};

export const changeSelectedInstructor = ({ dispatch, selectedInstructor }) =>
  dispatch({
    type: UPDATE_NUJ_HISTORY,
    payload: {
      selectedInstructor,
      selectedPackage: selectedInstructor.prices[2],
    },
  });

export const changeTransmissionType = ({
  dispatch,
  selectedInstructorHistory,
  transmissionType,
  transmissionTypeFromContext,
  selectedInstructor,
}) => {
  dispatch({
    type: CLEAR_AVAILABLE_NUJ_INSTRUCTORS,
  });
  dispatch({
    type: CLEAR_NUJ_SELECTED_INSTRUCTOR,
  });
  const payload = {
    selectedTransmissionType: transmissionType,
    selectedInstructor: selectedInstructorHistory
      ? selectedInstructorHistory[transmissionType.toLowerCase()]
      : undefined,
    selectedInstructorHistory: {
      ...selectedInstructorHistory,
      [transmissionTypeFromContext.toLowerCase()]: selectedInstructor,
    },
  };
  dispatch({
    type: UPDATE_NUJ_HISTORY,
    payload,
  });
};

export const submitPricesForm = ({
  shortNoticeTestDateTime,
  event,
  history,
  dispatch,
}) => {
  event.preventDefault();
  if (shortNoticeTestDateTime) {
    const normalizedDate =
      typeof shortNoticeTestDateTime === "string"
        ? parseISO(shortNoticeTestDateTime)
        : shortNoticeTestDateTime;
    if (differenceInDays(normalizedDate, new Date(Date.now())) < 28) {
      const startTime = set(subHours(normalizedDate, 1), {
        minutes: 15 * Math.floor(normalizedDate.getMinutes() / 15),
      });
      const endTime = addHours(startTime, 2);
      dispatch({
        type: UPDATE_NUJ_HISTORY,
        payload: {
          selectedLessonDate: format(normalizedDate, LONG_DATE_FORMAT),
          selectedLessonTimeslot: {
            startTime: format(startTime, LONG_DATE_FORMAT),
            endTime: format(endTime, LONG_DATE_FORMAT),
          },
        },
      });
      return history.push(NEW_USER_JOURNEY_PATH_NAMES.PERSONAL_DETAILS);
    }
  }
  return history.push(NEW_USER_JOURNEY_PATH_NAMES.TIMESLOTS);
};

export const changeLessonType = ({ dispatch, selectedLessonType }) => {
  dispatch({
    type: UPDATE_NUJ_HISTORY,
    payload: {
      selectedLessonType,
    },
  });
  dispatch({
    type: CLEAR_NUJ_SELECTED_INSTRUCTOR,
  });
  dispatch({
    type: CLEAR_AVAILABLE_NUJ_INSTRUCTORS,
  });
  dispatch({
    type: CLEAR_NUJ_SELECTED_INSTRUCTOR_HISTORY,
  });
};

export const getLowestInstructorRate = ({
  selectedInstructorId,
  availableInstructors,
  brand,
  packageHours,
}) =>
  Math.min(
    ...availableInstructors[brand]
      .filter(({ instructor }) => selectedInstructorId !== instructor.id)
      .reduce((matchingPrices, { prices }) => {
        prices.forEach(price => {
          if (price.hours === packageHours) {
            matchingPrices.push(price.standardHourlyPrice);
          }
        });
        return matchingPrices;
      }, []),
  );

export const mapLessonTypeToLearnerLessonType = selectedLessonType => {
  switch (selectedLessonType) {
    case "Learner":
      return LearnerLessonType.Standard;
    case "Refresher":
      return LearnerLessonType.Qualified;
    case "PassPlus":
      return LearnerLessonType.PassPlus;
    case "Motorway":
      return LearnerLessonType.Motorway;
    case "ShortNoticeTest":
      return LearnerLessonType.Standard;
  }
  return LearnerLessonType.Standard;
};

export default {
  changeSelectedPackage,
  changeSelectedInstructor,
  changeTransmissionType,
  submitPricesForm,
  changeLessonType,
};
