import { ChevronLeft, CheckCircle } from "@material-ui/icons";

import palette from "./palette-aad";
import callusBackground from "../png/aad-call-us.png";

import callusBackgroundDesktop from "../svg/aa-bg-beam-bottom-left.svg";
import qtjConfirmationBackground from "../jpg/aad-qtj-success.jpg";
import existingPupilBackground from "../jpg/aa-existing-pupil.jpg";
import errorImage from "../jpg/aa-error.jpg";
import breakpoints from "./breakpoints";

const journey = {
  exitButton: {
    label: {
      color: palette.text.primary,
    },
    icon: {
      color: palette.text.primary,
      margin: "1px 5px 0 0",
      width: "18px",
      height: "18px",
    },
  },
  backIcon: ChevronLeft,
  appTopbar: {
    backButtonWrapper: {
      width: "60px",
      height: "128px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    logo: {
      marginTop: "6px",
      marginRight: "8px",
    },
  },
  backButton: {
    root: {
      paddingLeft: "9px",
      marginTop: "8px",
      marginRight: "10px",
    },
    icon: {
      color: palette.text.primary,
    },
  },
  continueButton: ({ disableVerticalMargin, disableHorizontalMargin }) => ({
    root: {
      display: "block",
      margin: `${disableVerticalMargin ? "0" : "30px"} ${
        disableHorizontalMargin ? "0" : "auto"
      }`,
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        margin: `${disableVerticalMargin ? "0" : "48px"} ${
          disableHorizontalMargin ? "0" : "auto"
        }`,
      },
    },
  }),
  radioItemList: {
    borderRadius: "4px",
    marginBottom: "8px",
    opacity: "1",
    padding: "8px 24px",
    labelFontSize: "15px",
    labelMargin: "0 0 8px 0",
    borderColor: palette.grey[100],
    hoverBorderColor: palette.grey[200],
    fontWeight: "normal",
    selectedFontWeight: 600,
  },
  prices: {
    transmissionTabs: {
      [`@media (max-width:${breakpoints.values.sm}px)`]: {
        background: palette.background.dark,
      },
    },
    avatar: {
      background: palette.grey[200],
      color: palette.common.white,
    },
    inputField: {
      marginTop: "16px",
      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        marginTop: "24px",
      },
    },
    subHeadingText: {
      padding: "24px 0 4px 0",
      opacity: 0.7,
      fontSize: "14px",
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        padding: "40px 0 0 0",
      },
    },
    infoPanel: {
      flex: "initial",
    },
  },
  infoPanel: {
    body2Variation: {
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        fontSize: "14px",
        lineHeight: "21px",
      },
    },
  },
  lessonPackageText: {
    numLessons: {
      fontWeight: 200,
      "& span": {
        textTransform: "lowercase",
        opacity: 0.5,
      },
    },
    price: {
      "& span": {
        fontWeight: "normal",
      },
    },
    priceSaving: {
      opacity: 0.6,
    },
  },
  datePickerCarousel: {
    dayText: {
      textTransform: "capitalize",
      fontWeight: 400,
    },
    monthText: {
      textTransform: "capitalize",
    },
    dateText: {
      fontWeight: 200,
    },
  },
  callToComplete: {
    headerWrapper: {
      height: "44px",
    },
    container: {
      backgroundImage: `url(${callusBackground})`,
      backgroundSize: "cover",
      height: "100%",
      minHeight: "100vh",
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        backgroundImage: `url(${callusBackgroundDesktop})`,
      },
    },
    bodyText: {
      [`@media (min-width:${breakpoints.values.xs}px)`]: {
        margin: "24px 30px 10px",
      },
      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        margin: "80px 0 0",
      },
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        margin: "120px 0 0",
      },
    },
    callButton: {
      backgroundImage: "linear-gradient(0deg, #3D3D3D, #3D3D3D)",
      color: "#FFFFFF",
    },
  },
  timeSlotPicker: {
    selectedContent: {
      fontWeight: 700,
    },
  },
  marketingPreferences: {
    switchColor: "secondary",
  },
  itemListItem: {
    background: palette.grey[200],
    selectedBackground: palette.grey[200],
    selectedBorder: palette.primary.main,
  },
  lessonDetails: {
    card: {
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.23)",
    },
    primaryAvatar: {
      backgroundColor: palette.primary.main,
      color: palette.common.white,
    },
    secondaryAvatar: {
      backgroundColor: palette.secondary.main,
      color: palette.common.white,
    },
    costAvatar: {
      backgroundColor: "#606367",
      color: palette.common.white,
    },
  },
  loadingScreen: {
    container: {
      backgroundColor: palette.primary.main,
      backgroundSize: "cover",
      height: "100vh",
    },
  },
  circularProgress: {
    root: {
      border: "5px solid #E8E8E8",
    },
    circle: {
      stroke: palette.primary.main,
    },
    percentage: {
      color: palette.common.black,
    },
    step: {
      fontWeight: 700,
      color: palette.common.black,
    },
  },
  circularProgressAlt: {
    root: {
      border: "5px solid #E8E8E8",
    },
    circle: {
      stroke: palette.primary.main,
    },
    percentage: {
      color: palette.common.black,
    },
    step: {
      fontWeight: 700,
      color: palette.common.black,
    },
  },
  commonConfirmation: {
    headerWrapper: {
      backgroundSize: "cover",
      backgroundColor: "white",
      height: "450px",
      backgroundPosition: "left",
      position: "relative",
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        height: "600px",
        backgroundPosition: "bottom",
      },
    },
    detailBanner: {
      position: "absolute",
      top: "calc(100% - 45px)",
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        paddingRight: "15px",
        alignItems: "flex-start",
        top: "auto",
        left: "50%",
        bottom: "-140px",
        transform: "translateX(-50%)",
        maxWidth: "850px",
      },
    },
    titleText: {
      color: palette.grey[400],
    },
    detailsBlock: {
      width: "100%",
      maxWidth: "350px",
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        flex: "0 0 282px",
        alignSelf: "flex-end",
      },
    },
    shareLink: {
      color: palette.secondary.main,
    },
    shareLinkButton: {
      color: palette.secondary.main,
    },
  },
  sntConfirmation: {
    bodyWrapper: {
      padding: "275px 0 100px 0",
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        padding: "180px 0 100px 0",
      },
    },
    titleText: {
      fontWeight: 300,
      color: palette.grey[400],
      marginBottom: "20px",
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "center",
      padding: "48px 0 60px",
      [`@media (max-width:${breakpoints.values.xs}px)`]: {
        padding: "24px 0 48px",
      },
    },
    signOff: {
      fontWeight: "bold",
    },
  },
  nujConfirmation: {
    bodyWrapper: {
      padding: "255px 0 100px 0",
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        padding: "120px 0 100px 0",
      },
    },
  },
  RUJConfirmation: {
    bodyWrapper: {
      padding: "190px 0 100px 0",
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        padding: "55px 0 100px 0",
      },
    },
  },
  RUJTalkToUs: {
    sendMessageButton: {
      color: palette.text.primary,
      border: `2px solid ${palette.text.primary}`,
      margin: "2em",
    },
  },
  qtjConfirmation: {
    bodyWrapper: {
      padding: "190px 0 100px 0",
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        padding: "55px 0 100px 0",
      },
    },
    card: {
      margin: "-75px auto 50px auto",
      width: "95%",
      maxWidth: "340px",
      padding: " 8px 0 24px 0",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.23)",
    },
    headerWrapper: {
      background: `url(${qtjConfirmationBackground})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "600px",
      width: "100%",
      // we don't have access to the breakpoint helpers at this point
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        height: "675px",
        backgroundPosition: "top",
      },
    },
    exitButton: {
      backgroundColor: "transparent",
      border: "2px solid",
      "&:hover": {
        backgroundColor: palette.grey[300],
      },
    },
  },
  tabs: {
    labelWrap: {
      root: { fontSize: "0.9375rem" },
    },
  },
  existingPupil: {
    headerWrapper: {
      background: `url(${existingPupilBackground})`,
      backgroundSize: "cover",
      backgroundPosition: "-100px bottom",
      height: "250px",
      width: "100%",

      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        backgroundPosition: "bottom",
        height: "325px",
      },

      [`@media (min-width:${breakpoints.values.md}px)`]: {
        height: "30vw",
        maxHeight: "800px",
      },
    },
  },
  somethingsGoneWrong: {
    headerWrapper: {
      backgroundImage: `url('${errorImage}')`,
      backgroundSize: "cover",
      backgroundPosition: "top",
      height: "250px",

      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        height: "325px",
      },

      [`@media (min-width:${breakpoints.values.md}px)`]: {
        height: "30vw",
        maxHeight: "800px",
      },
    },
  },
  bookedLessons: {
    avatar: {
      color: palette.common.white,
      background: palette.grey[200],
      fontWeight: 600,
    },
    listTitle: {
      marginBottom: "12px",
      padding: "0 16px",
      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        padding: 0,
      },
    },
  },
  bookedLessonsListIcon: CheckCircle,
  bookedLessonsListIconColor: "secondary",
  keyWorker: {
    infoPanelIcon: {
      width: "36px",
      height: "36px",
      position: "relative",
      top: "16px",
      left: "10px",
    },
  },
  faceCovering: {
    headerWrapper: {
      fontSize: "24px",
    },
    infoPanelIcon: {
      width: "36px",
      height: "36px",
      position: "relative",
      top: "16px",
      left: "10px",
    },
    infoPanelText: {
      fontSize: "14px",
      color: palette.grey[400],
      lineHeight: "24px",
    },
    infoPanelTitle: {
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "21px",
    },
    infoPanelLink: {
      color: "#07828D",
      textDecoration: "none",
      "&:hover, &:active": {
        textDecoration: "underline",
      },
    },
    imageContainer: {
      marginTop: "-60px",
    },
  },
  faceCoveringListItemNumber: {
    root: {
      backgroundColor: palette.common.black,
      width: "17px",
      height: "17px",
      borderRadius: "50%",
      marginRight: "9px",
    },
    number: {
      fontWeight: "bold",
      fontSize: "15px",
      lineHeight: "15px",
    },
  },
  lessonTypes: {
    giftInfo: {
      color: palette.text.secondary,
      marginTop: "16px",
      marginBottom: "6px",
    },
    giftIcon: {
      marginRight: "6px",
    },
  },
  progressTracker: {
    pageTitle: {
      lineHeight: "32px",
      fontSize: "24px",
    },
    overallProgressPercent: {
      fontSize: "32px",
      lineHeight: "32px",
      fontWeight: "normal",
      letterSpacing: "-0.3px",
    },
    updated: {
      marginBottom: "24px",
    },
    progressMeterWrapper: {
      justifyContent: "center",
      display: "flex",
      marginBottom: "24px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        marginBottom: "48px",
      },
    },
    categoryName: {
      marginBottom: "9px",
      fontSize: "16px",
      lineHeight: "16px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        fontSize: "18px",
        lineHeight: "18px",
      },
    },
    categories: {
      marginBottom: "39px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        marginBottom: "63px",
      },
    },
    percentComplete: {
      fontSize: "16px",
      lineHeight: "16px",
    },
    progress: {
      width: "100%",
    },
    infoText: {
      marginBottom: "12px",
      fontSize: "16px",
      lineHeight: "24px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        fontSize: "18px",
        lineHeight: "27px",
      },
    },
    skillName: {
      fontSize: "14px",
      lineHeight: "14px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        fontSize: "16px",
        lineHeight: "16px",
      },
    },
    score: {
      fontSize: "20px",
      lineHeight: "20px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        fontSize: "22px",
        lineHeight: "22px",
      },
    },
    skillWrapper: {
      display: "flex",
      justifyContent: "space-between",
      padding: "12px 41px 12px 16px",
      alignItems: "center",
    },
  },
  noProgress: {
    image: {
      alignSelf: "center",
      marginBottom: "48px",
    },
    text: {
      fontSize: "16px",
      marginBottom: "20px",
      width: "285px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        fontSize: "18px",
        width: "485px",
      },
    },
    para2: {
      fontWeight: "bold",
    },
  },
};

export default journey;
