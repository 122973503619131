import React, { FC } from "react";
import { withStyles } from "@material-ui/styles";
import {
  Typography,
  Box,
  Button,
  Theme,
  StyleRules,
  Container,
} from "@material-ui/core";

import { useBrand } from "../../common/BrandProvider/BrandProvider";
import { ReactComponent as BSMNudgeBackImage } from "../../../svg/bsm-waving-elvis.svg";
import { Brand } from "@graphql/types";

const styles = (theme: Theme): StyleRules<any, Record<string, any>> => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    height: "100%",
    maxWidth: "486px",
    paddingBottom: "100px",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      paddingTop: "60px",
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  AAtitle: {
    fontWeight: 700,
    lineHeight: "28px",
    fontSize: "24px",
    marginBottom: "24px",
    letterSpacing: "0px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
      marginTop: "24px",
    },
  },
  BSMtitle: {
    fontWeight: 600,
    lineHeight: "36px",
    fontSize: "30px",
    marginBottom: "30px",
    letterSpacing: "0px",
    textAlign: "center",
    color: "#807B84",
  },
  AAsubTitle: {
    lineHeight: "27px",
    fontSize: "18px",
    marginBottom: "30px",
    letterSpacing: "0px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  BSMsubTitle: {
    marginTop: "30px",
    lineHeight: "27px",
    fontSize: "18px",
    marginBottom: "30px",
    letterSpacing: "0px",
    textAlign: "center",
    color: "#2E2E36",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  AAphoneNumber: {
    fontWeight: 700,
    lineHeight: "24px",
    fontSize: "20px",
    letterSpacing: "0px",
    textAlign: "center",
    color: "#07828D",
    cursor: "pointer",
    marginBottom: "4px",
  },
  BSMphoneNumber: {
    fontWeight: 700,
    lineHeight: "24px",
    fontSize: "20px",
    textAlign: "center",
    color: "#3933B7",
    cursor: "pointer",
    marginBottom: "4px",
  },
  AAsubText: {
    fontWeight: 500,
    lineHeight: "18.75px",
    fontSize: "12.5px",
    marginBottom: "40px",
    letterSpacing: "0px",
    textAlign: "center",
    color: "rgba(43, 45, 50, 0.8)",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "32px",
    },
  },
  BSMsubText: {
    lineHeight: "24px",
    fontSize: "12px",
    marginBottom: "40px",
    letterSpacing: "0px",
    textAlign: "center",
    color: "rgba(43, 45, 50, 0.8)",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "32px",
    },
  },
});

interface Props {
  classes: any;
  pupilName: string;
  onLogoutClick: () => void;
}

export const Account: FC<Props> = ({
  classes,
  pupilName,
  onLogoutClick = () => {},
}) => {
  const { getText, brand } = useBrand();

  return (
    <Container className={classes.container}>
      <Typography
        className={brand === Brand.Aads ? classes.AAtitle : classes.BSMtitle}
      >
        {getText("account.motabilityWelcome", { name: pupilName })}
      </Typography>
      {brand === Brand.Bsm && <BSMNudgeBackImage />}
      <Typography
        className={
          brand === Brand.Aads ? classes.AAsubTitle : classes.BSMsubTitle
        }
      >
        {getText("account.motabilitySubHeading")}
      </Typography>
      <Typography
        onClick={() => {
          window.location.href = `tel:${getText(
            "account.motabilitySubHeading",
          )}`;
        }}
        className={
          brand === Brand.Aads ? classes.AAphoneNumber : classes.BSMphoneNumber
        }
      >
        {getText("account.motabilityPhoneNumber")}
      </Typography>
      <Typography
        className={
          brand === Brand.Aads ? classes.AAsubText : classes.BSMsubText
        }
      >
        {getText("account.motabilityContactHours")}
      </Typography>

      <Box>
        <Button
          color="primary"
          fullWidth
          className={classes.logoutButton}
          onClick={onLogoutClick}
        >
          Logout
        </Button>
      </Box>
    </Container>
  );
};

export default withStyles(styles)(Account);
