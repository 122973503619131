import React, { ReactElement, useState } from "react";
import { FormLabel, TextField, Box, makeStyles } from "@material-ui/core";
import {
  SentimentVeryDissatisfied,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVerySatisfied,
} from "@material-ui/icons";
import Rating from "@material-ui/lab/Rating";

import { useLocation } from "react-router-dom";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import Titlebar from "../../common/Titlebar/Titlebar";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import Button from "../../common/Button/Button";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";

import { RateInstructorProps } from "./types";

const ratingIcons: {
  [key: number]: {
    icon: ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfied />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfied />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfied />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAlt />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfied />,
    label: "Very Satisfied",
  },
};

const IconContainer = ({ value, ...rest }: { value: number }) => (
  <span {...rest}>{ratingIcons[value].icon}</span>
);

const useStyles = makeStyles({
  formLabel: {
    marginBottom: "10px",
    display: "inline-block",
  },
});

const RateInstructor: React.FC<RateInstructorProps> = ({
  rating,
  onRatingChanged,
  review,
  onReviewChanged,
  submitDisabled,
  onSubmit,
  onBackClick,
}) => {
  const { getText } = useBrand();
  const classes = useStyles();
  const { trackEvent, updateDataLayer } = useTealium();
  const [fieldTouched, setFieldTouched] = useState(false);
  const { pathname } = useLocation();

  return (
    <ContentWrapper>
      <Titlebar
        showBackButton
        onBackClick={onBackClick}
        headingProps={{ "data-test": "rate-instructor-title-bar" }}
      >
        {getText("returning/rate-instructor.pageTitle")}
      </Titlebar>

      <form onSubmit={onSubmit}>
        <Box component="fieldset" mb={3}>
          <FormLabel
            component="legend"
            htmlFor="instructor-rating"
            className={classes.formLabel}
          >
            {getText("returning/rate-instructor.ratingLabel")}
          </FormLabel>
          <Rating
            name="instructor-rating"
            data-test="instructor-rating-input"
            value={rating}
            getLabelText={value => ratingIcons[value].label}
            IconContainerComponent={IconContainer}
            onChange={(_, value) => {
              if (value) {
                trackEvent({
                  eventCategory: capitalizeEachWordOfPathname(pathname),
                  eventAction: "Interaction",
                  eventLabel: value.toString(),
                });
                onRatingChanged(value);
                updateDataLayer({
                  instructor_rating: value.toString(),
                });
              }
            }}
          />
        </Box>

        <FormLabel htmlFor="instructor-review" className={classes.formLabel}>
          {getText("returning/rate-instructor.reviewLabel")}
        </FormLabel>
        <TextField
          id="instructor-review"
          fullWidth
          multiline
          rows="4"
          value={review}
          onChange={e => onReviewChanged(e.target.value)}
          data-test="instructor-review-input"
          onClick={() => {
            if (!fieldTouched) {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(pathname),
                eventAction: "Interaction",
                eventLabel: "Your Review",
              });
              setFieldTouched(true);
            }
          }}
        />

        <Box mt={4} display="flex" justifyContent="center">
          <Button
            text={getText("returning/rate-instructor.submitButton")}
            data-test="instructor-rating-submit"
            type="submit"
            disabled={submitDisabled}
            onClick={() => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(pathname),
                eventAction: "Button Click",
                eventLabel: "Submit Rating",
              });
            }}
          />
        </Box>
      </form>
    </ContentWrapper>
  );
};

export default RateInstructor;
