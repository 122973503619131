import React from "react";
import { Avatar } from "@material-ui/core";

const BSM = () => (
  <Avatar>
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="#F9812A"
      />
      <path
        d="M8.72476 27C11.4068 27 13.1888 25.47 13.1888 23.238C13.1888 21.744 12.3248 20.682 11.0468 20.214C12.0008 19.764 12.6308 18.684 12.6308 17.694C12.6308 15.786 10.9388 14.4 8.68876 14.4H3.23476V27H8.72476ZM9.98476 18.036C9.98476 18.9 9.39076 19.494 8.29276 19.494H5.80876V16.596H8.29276C9.40876 16.596 9.98476 17.19 9.98476 18.036ZM10.5248 23.202C10.5248 24.21 9.78676 24.822 8.68876 24.822H5.80876V21.564H8.67076C9.76876 21.564 10.5248 22.176 10.5248 23.202ZM23.3955 23.094C23.3955 21.294 22.1535 20.034 19.9395 19.62L17.8155 19.242C16.4475 19.008 16.0335 18.54 16.0335 17.838C16.0335 17.046 16.8975 16.47 18.1575 16.47C19.3455 16.47 20.4255 16.884 21.1455 17.622L23.1795 16.11C22.1535 14.94 20.2635 14.22 18.2295 14.22C15.3135 14.22 13.2975 15.858 13.2975 18.018C13.2975 19.962 14.5395 21.222 16.7535 21.618L18.9495 22.014C20.1015 22.23 20.6055 22.644 20.6055 23.418C20.6055 24.3 19.6875 24.948 18.3375 24.948C16.9875 24.948 15.6195 24.3 14.7915 23.202L12.7935 24.804C13.9275 26.352 16.0155 27.18 18.3915 27.18C21.2715 27.18 23.3955 25.506 23.3955 23.094ZM30.407 23.292L34.151 18.234H34.187V27H36.761V14.4H34.007L30.407 19.44L26.771 14.4H23.927V27H26.501V18.234H26.537L30.281 23.292H30.407Z"
        fill="white"
      />
    </svg>
  </Avatar>
);

export default BSM;
