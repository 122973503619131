import React from "react";

const AaWarning: React.FC = () => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M32.4 46.8H39.6V54H32.4V46.8ZM32.4 18H39.6V39.6H32.4V18ZM35.964 0C16.092 0 0 16.128 0 36C0 55.872 16.092 72 35.964 72C55.872 72 72 55.872 72 36C72 16.128 55.872 0 35.964 0ZM36 64.8C20.088 64.8 7.2 51.912 7.2 36C7.2 20.088 20.088 7.2 36 7.2C51.912 7.2 64.8 20.088 64.8 36C64.8 51.912 51.912 64.8 36 64.8Z"
      fill="#07828D"
    />
  </svg>
);

export default AaWarning;
