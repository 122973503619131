import React, { useEffect, Fragment, useState, FC, FormEvent } from "react";
import { ClassNameMap, withStyles } from "@material-ui/styles";
import PostcodeValidator from "../../../common/Form/PostcodeValidator/PostcodeValidator";
import ContinueButton from "../../../common/ContinueButton/ContinueButton";
import ContentWrapper from "../../../common/ContentWrapper/ContentWrapper";
import Titlebar from "../../../common/Titlebar/Titlebar";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import ReferralLandingBanner from "./ReferralLandingBanner";
import { useTealium } from "../../../common/TealiumProvider/TealiumProvider";
import { OnPostcodeChange } from "../../../common/Form/PostcodeValidator/types";

const styles = {
  title: {
    marginBottom: "24px",
  },
  map: {
    height: "150px",
    width: "100%",
  },
};

interface Props {
  classes: ClassNameMap<"title" | "map">;
  handleSubmit: (_event: FormEvent<HTMLFormElement>) => void | undefined;
  handleChange: (_status: OnPostcodeChange) => void;
  postcodeValue: string;
  isPostcodeInvalid: boolean;
  referral: string;
}

export const NewUserJourneyStepsPickupLocation: FC<Props> = ({
  classes,
  handleSubmit,
  handleChange,
  postcodeValue,
  isPostcodeInvalid,
  referral,
}) => {
  const { getText } = useBrand();
  const postcodeInput = React.createRef<HTMLInputElement>();
  const { trackEvent, updateDataLayer, ga4TrackEvent } = useTealium();
  const [fieldTouched, setFieldTouched] = useState(false);

  useEffect(() => {
    postcodeInput.current?.focus();
  }, []);

  const handleContinueButtonClick = () => {
    trackEvent({
      eventCategory: "Pickup Location",
      eventAction: "Interaction",
      eventLabel: `Postcode - ${postcodeValue.slice(0, -3).trim()}`,
    });
    ga4TrackEvent({
      label: `Postcode - ${postcodeValue.slice(0, -3).trim()}`,
      type: "button",
      category: "NUJ",
    });
    trackEvent({
      eventCategory: "Pickup Location",
      eventAction: "Button Click",
      eventLabel: "Continue",
    });
    updateDataLayer({
      postcode: postcodeValue.slice(0, -3).trim(),
    });
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit} data-test="postcode-form">
        <ContentWrapper fullWidth>
          <ContentWrapper fullHeight>
            <Titlebar
              className={classes.title}
              headingProps={{ "data-test": "pickup-location-title-bar" }}
            >
              {getText("pickup-location.pageTitle")}
            </Titlebar>
          </ContentWrapper>

          {referral && <ReferralLandingBanner referralCode={referral} />}

          <ContentWrapper fullHeight>
            <PostcodeValidator
              initialValue={postcodeValue}
              onChange={handleChange}
              label={getText("pickup-location.postCodeLabel")}
              placeholder={getText("common.postcodePlaceholder")}
              inputRef={postcodeInput}
              onClick={() => {
                if (!fieldTouched) {
                  trackEvent({
                    eventCategory: "Pickup Location",
                    eventAction: "Interaction",
                    eventLabel: "Postcode",
                  });
                  setFieldTouched(true);
                }
              }}
            />

            <ContinueButton
              data-test="continue-button"
              disabled={isPostcodeInvalid}
              type="submit"
              onClick={handleContinueButtonClick}
            />
          </ContentWrapper>
        </ContentWrapper>
      </form>
    </Fragment>
  );
};

export default withStyles(styles)(NewUserJourneyStepsPickupLocation);
