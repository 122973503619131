import React, { Fragment } from "react";

import {
  Typography,
  makeStyles,
  useMediaQuery,
  Theme,
} from "@material-ui/core";
import { format } from "date-fns";
import mergeDeep from "lodash.merge";
import RadioCardGroupCarousel from "../RadioCardGroup/Carousel/RadioCardGroupCarousel";
import RadioCard from "../RadioCard/RadioCard";
import { useBrand } from "../../BrandProvider/BrandProvider";

const useStyles = makeStyles(
  (theme: Theme & { journey: { datePickerCarousel: Record<string, any> } }) =>
    mergeDeep(theme.journey.datePickerCarousel, {
      dateCard: {
        width: "30px",
        "@media(min-width:375px)": {
          width: "40px",
        },
        "@media(min-width:435px)": {
          width: "60px",
        },
        "@media(min-width:500px)": {
          width: "80px",
        },
      },
      dayText: {
        color: theme.palette.grey[500],
        opacity: 0.5,
        fontSize: "0.875rem",
        letterSpacing: "0.05em",
        lineHeight: "1em",
      },
      dateText: {
        fontSize: "2rem",
        margin: "3px 0 0 -4px",
        color: theme.palette.grey[400],
        lineHeight: "1em",
        letterSpacing: "-0.05em",
        "@media(min-width:375px)": {
          fontSize: "2.7rem",
        },
      },
      monthText: {
        color: theme.palette.grey[500],
        opacity: 0.4,
        fontSize: "0.75rem",
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: "5px",
        letterSpacing: "0.05em",
        lineHeight: "1em",
      },
    }),
);

export const DateCard = React.memo(
  ({ date, checked = false }: { date: Date; checked?: boolean }) => {
    const classes = useStyles();
    const useShortMonths = useMediaQuery("(max-width:435px)");
    return (
      <div className={classes.dateCard}>
        <Typography className={classes.dayText}>{format(date, "E")}</Typography>
        <Typography
          className={
            checked ? `${classes.dateText} --checked` : classes.dateText
          }
        >
          {format(date, "d")}
        </Typography>
        <Typography className={classes.monthText}>
          {format(date, useShortMonths ? "LLL" : "LLLL")}
        </Typography>
      </div>
    );
  },
);

const FormDatePickerCarousel = ({
  value = undefined,
  dates = [],
  onChange = () => {},
  onPreviousClick = () => {},
  onNextClick = () => {},
  radioGroupProps,
  slideIndex,
  disableAnimation = false,
}: {
  value?: Date;
  dates: Date[];
  onChange: (_val: string) => void;
  onPreviousClick: () => void;
  onNextClick: () => void;
  radioGroupProps: any;
  slideIndex: number;
  disableAnimation?: boolean;
}) => {
  const { getText } = useBrand();
  return (
    <Fragment>
      {dates.length ? (
        <RadioCardGroupCarousel
          onChange={onChange}
          value={value?.toString()}
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
          radioGroupProps={radioGroupProps}
          slideIndex={slideIndex}
          disableAnimation={disableAnimation}
        >
          {dates.map(date => (
            <RadioCard
              key={date.toString()}
              value={date.toString()}
              cardContent={<DateCard date={date} />}
              cardContentChecked={<DateCard date={date} checked />}
            />
          ))}
        </RadioCardGroupCarousel>
      ) : (
        <Typography>{getText("common.datePickerCarouselNoDates")}</Typography>
      )}
    </Fragment>
  );
};

export default FormDatePickerCarousel;
