import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  FormLabel,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

import { useBrand } from "../../common/BrandProvider/BrandProvider";
import Button from "../../common/Button/Button";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import Select from "../../common/Form/Select/Select";
import YesNoPicker from "../../common/Form/YesNoPicker/YesNoPicker";
import Titlebar from "../../common/Titlebar/Titlebar";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";

const COMPLAINT_REASONS = {
  InstructorBehaviour: "Instructor behaviour",
  InstructorLate: "Instructor was late",
  InstructorNonAttendance: "The instructor didn’t attend my lesson",
  RefundDispute: "Refund dispute",
  ShortNoticeCancellation: "Lesson cancelled at short notice",
  TuitionStandard: "Tuition standard",
  UnhappyWithService: "Unhappy with service",
  UnhappyWithVehicle: "Unhappy with vehicle",
};

const useStyles = makeStyles({
  formLabel: {
    margin: "10px 0",
    display: "inline-block",
  },
  formField: {
    marginBottom: "25px",
  },
  explanatoryText: {
    marginBottom: "25px",
    fontSize: "16px",
    lineHeight: "24px",
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  submitButton: {
    margin: "3em",
  },
});

type ComplaintsProps = {
  submitDisabled: boolean;
  reason: string;
  onReasonChanged: (_value: string) => void;
  details: string;
  onDetailsChanged: (_value: string) => void;
  resolution: string;
  onResolutionChanged: (_value: string) => void;
  responseRequired: boolean;
  onResponseRequiredChanged: (_value: boolean) => void;
  onBackClick: () => void;
  onSubmit: () => void;
};

const Complaints: React.FC<ComplaintsProps> = ({
  submitDisabled = false,
  reason = "",
  onReasonChanged,
  details = "",
  onDetailsChanged,
  resolution = "",
  onResolutionChanged,
  responseRequired,
  onResponseRequiredChanged,
  onBackClick,
  onSubmit,
}) => {
  const { getText } = useBrand();
  const classes = useStyles();
  const { trackEvent, updateDataLayer } = useTealium();
  const { pathname } = useLocation();
  const [fieldTouched, setFieldTouched] = useState(false);
  const [fieldTouchedTwo, setFieldTouchedTwo] = useState(false);

  const handleYesNoPicker = (value: boolean) => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Interaction",
      eventLabel: `Respond To You - ${value ? "Yes" : "No"}`,
    });
    updateDataLayer({
      complaint_response: value ? "Yes" : "No",
    });
    onResponseRequiredChanged(value);
  };

  return (
    <ContentWrapper>
      <Titlebar
        showBackButton
        onBackClick={onBackClick}
        headingProps={{ "data-test": "complaints-title-bar" }}
      >
        {getText("returning/complaints.pageTitle")}
      </Titlebar>
      <div>
        <Typography
          className={classes.explanatoryText}
          data-test="complaints-explanatory-text"
        >
          {getText("returning/complaints.explanatoryText")}
        </Typography>

        <form>
          <Select
            label={getText("returning/complaints.reasonLabel")}
            value={reason}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(pathname),
                eventAction: "Interaction",
                eventLabel: e.target.value,
              });
              updateDataLayer({
                complaint_reason: e.target.value,
              });
              e.preventDefault();
              onReasonChanged(e.target.value);
            }}
            inputProps={{
              "data-test": "complaints-reason-select",
            }}
            name="complaints-reason-select"
            id="complaints-reason-select"
            data-test="complaints-reason-select"
            className={classes.formField}
            displayEmpty
          >
            {Object.entries(COMPLAINT_REASONS).map(([key, value], index) => (
              <MenuItem
                key={key}
                value={key}
                data-test={`complaints-reason-${index}`}
              >
                {value}
              </MenuItem>
            ))}
          </Select>

          <FormLabel
            component="legend"
            className={classes.formLabel}
            data-test="complaints-details-label"
          >
            {getText("returning/complaints.details")}
          </FormLabel>
          <TextField
            id="complaint-details"
            fullWidth
            multiline
            rows="4"
            className={classes.formField}
            value={details}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (!fieldTouched) {
                trackEvent({
                  eventCategory: capitalizeEachWordOfPathname(pathname),
                  eventAction: "Interaction",
                  eventLabel: "Enter Your Complaint",
                });
                setFieldTouched(true);
              }
              e.preventDefault();
              onDetailsChanged(e.target.value);
            }}
            data-test="complaints-details-input"
          />

          <FormLabel
            component="legend"
            className={classes.formLabel}
            data-test="complaints-resolution-label"
          >
            {getText("returning/complaints.resolution")}
          </FormLabel>
          <TextField
            id="complaint-resolution"
            fullWidth
            multiline
            rows="4"
            className={classes.formField}
            value={resolution}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (!fieldTouchedTwo) {
                trackEvent({
                  eventCategory: capitalizeEachWordOfPathname(pathname),
                  eventAction: "Interaction",
                  eventLabel: "Resolve The Issue",
                });
                setFieldTouchedTwo(true);
              }
              e.preventDefault();
              onResolutionChanged(e.target.value);
            }}
            data-test="complaints-resolution-input"
          />

          <YesNoPicker
            labelKey="returning/complaints.requiresResponse"
            selected={responseRequired}
            onChange={handleYesNoPicker}
          />

          <div className={classes.buttonWrapper}>
            <Button
              className={classes.submitButton}
              text={getText("returning/complaints.sendButton")}
              data-test="complaints-submit"
              type="submit"
              disabled={submitDisabled}
              onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                trackEvent({
                  eventCategory: capitalizeEachWordOfPathname(pathname),
                  eventAction: "Button Click",
                  eventLabel: "Send Complaint",
                });
                updateDataLayer({
                  complaint_sent: "true",
                });
                e.preventDefault();
                onSubmit();
              }}
            />
          </div>
        </form>
      </div>
    </ContentWrapper>
  );
};

export default Complaints;
