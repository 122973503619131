import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { Theme, withStyles } from "@material-ui/core";
import { format } from "date-fns";
import mergeDeep from "lodash.merge";

import Button from "../../common/Button/Button";
import LessonDetailSection from "../../common/LessonDetails/LessonDetailSection";
import Modal from "../../common/Modal";
import { formatLessonDateTime } from "../../../utils/formatLessonDateTime";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";

interface CustomTheme extends Theme {
  journey: {
    lessonDetails: any;
  };
}

const styles = (theme: CustomTheme) =>
  mergeDeep(theme.journey.lessonDetails, {
    dialogTitle: {
      maxWidth: "360px",
      paddingTop: "30px",
    },
    title: {
      lineHeight: "1.3em",
    },
    dialogActions: {
      padding: "50px 20px 30px 0",
    },
  });

interface Props {
  showModal: boolean;
  toggleModal: () => void;
  duration: number;
  startDateTime: Date;
  pupilBalance: number;
  onConfirmation: () => void;
  classes: any;
}

const BookingConfirmationModal = ({
  showModal,
  toggleModal,
  duration = 2,
  startDateTime,
  pupilBalance,
  onConfirmation,
  classes,
}: Props) => {
  const { getText } = useBrand();
  const { trackEvent } = useTealium();
  const { pathname } = useLocation();
  const cancelModal = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Button Click",
      eventLabel: "Cancel",
    });
    toggleModal();
  };

  const confirmModal = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Button Click",
      eventLabel: "Got It",
    });
    onConfirmation();
  };

  return (
    <Modal
      showModal={showModal}
      toggleModal={toggleModal}
      testId="booking-modal"
      title={getText("returning/timeslots.confirmationModalTitle", {
        duration,
      })}
      content={
        <Fragment>
          {startDateTime && (
            <LessonDetailSection
              avatarText={format(startDateTime, "dd")}
              title={formatLessonDateTime(startDateTime)}
              subtitle={`${duration} ${getText("confirmation.hourLesson")}`}
              avatarColor={classes.primaryAvatar}
              data-test="booking-modal-time"
            />
          )}
          {pupilBalance > 0 && (
            <LessonDetailSection
              avatarText={pupilBalance - duration}
              title={getText(
                "returning/timeslots.confirmationModalBalanceTitle",
              )}
              subtitle={getText(
                "returning/timeslots.confirmationModalBalanceSubtitle",
              )}
              avatarColor={classes.secondaryAvatar}
              data-test="booking-modal-balance"
            />
          )}
        </Fragment>
      }
      actions={
        <Fragment>
          <Button
            onClick={cancelModal}
            text={getText("returning/timeslots.confirmationModalCancelButton")}
            secondary
            data-test="booking-modal-cancel-button"
          />
          <Button
            text={getText(
              "returning/timeslots.confirmationModalConfirmationButton",
            )}
            onClick={confirmModal}
            data-test="booking-modal-confirm-button"
          />
        </Fragment>
      }
    />
  );
};

export default withStyles(styles)(BookingConfirmationModal);
