import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid } from "@material-ui/core";

import { useBrand } from "../../BrandProvider/BrandProvider";

const useStyles = makeStyles(theme => ({
  summary: {
    color: theme.palette.grey[400],
  },
  summaryTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "20px",
    marginBottom: "15px",
    color: theme.palette.grey[500],
  },
  summaryBlock: {
    color: theme.palette.grey[500],
    marginBottom: "40px",
  },
}));

const Summary = ({ children }) => {
  const classes = useStyles();
  const { getText } = useBrand();
  return (
    <Typography
      component="div"
      data-test="payment-content-block"
      className={classes.summary}
    >
      <h3 className={classes.summaryTitle}>
        {getText("payment.summaryTitle")}
      </h3>

      <Grid
        container
        spacing={0}
        className={classes.summaryBlock}
        data-test="payment-summary"
      >
        {children}
      </Grid>
      <p>{getText("payment.refreshWarning")}</p>
    </Typography>
  );
};

export default Summary;
