import { useState } from "react";

import { useQuery } from "@apollo/react-hooks";

import getCountryDetailsQuery from "../graphql/getCountryDetails.graphql";

import { CountryAccessStatus } from "@lessons/models";
import { isValidPostcodeFormat } from "../utils/validation";

const usePostcodeCountryDetails = ({ postcode, ...restProps }) => {
  const [accessStatus, setAccessStatus] = useState();
  const [isOpenToKeyWorkers, setIsOpenToKeyWorkers] = useState();
  const [countries, setCountries] = useState();

  if (
    !isValidPostcodeFormat(postcode) &&
    accessStatus !== CountryAccessStatus.Invalid
  ) {
    setAccessStatus(CountryAccessStatus.Invalid);
  }

  const { loading } = useQuery(getCountryDetailsQuery, {
    skip: !postcode || !isValidPostcodeFormat(postcode),
    variables: {
      postcode: postcode,
    },
    onCompleted: data => {
      // No data happens when there is a caching issue with apollo
      if (!data) {
        return;
      }
      const countryDetails = data?.countryDetails;

      if (!countryDetails) {
        throw new Error("Something went wrong when fetching country details.");
      }

      setCountries(countryDetails);

      // we didn't get any countries for postcode, so we consider it invalid
      if (!countryDetails.length) {
        setAccessStatus(CountryAccessStatus.Invalid);
        return;
      }

      // postcode is on the border of 2 countries
      if (countryDetails.length === 2) {
        if (countryDetails[0].isOpen && countryDetails[1].isOpen) {
          // Both are open - skip the country selection step
          setAccessStatus(CountryAccessStatus.Open);
          return;
        }
        setAccessStatus(CountryAccessStatus.Undetermined);
        return;
      }

      setAccessStatus(
        countryDetails[0].isOpen
          ? CountryAccessStatus.Open
          : CountryAccessStatus.Closed,
      );

      setIsOpenToKeyWorkers(countryDetails[0].isOpenToKeyWorkers);
    },
    ...restProps,
  });

  return {
    loading,
    accessStatus,
    countries,
    isOpenToKeyWorkers,
  };
};

export default usePostcodeCountryDetails;
