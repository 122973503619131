import React, { useEffect } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import get from "lodash.get";
import mergeDeep from "lodash.merge";

import { useStateContext } from "../../../../context/stateContext";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import LessonPackagePicker from "../../../common/Form/LessonPackagePicker/LessonPackagePicker";
import { useTealium } from "../../../common/TealiumProvider/TealiumProvider";
import ChangeInstructorSelect from "./ChangeInstructorSelect";
import {
  changeSelectedPackage,
  getLowestInstructorRate,
  mapLessonTypeToLearnerLessonType,
} from "./helpers";
import NudgeBack from "./NudgeBack";
import CrossSellInstructors from "./CrossSellInstructors";
import NoInstructors from "./NoInstructors";
import useGetInstructors from "../../../../hooks/useGetInstructors";
import ContentWrapper from "../../../common/ContentWrapper/ContentWrapper";
import palette from "../../../../theme/palette-aad";
import { LessonType, MarketingChannel } from "@graphql/types";
import { StateContext } from "models/state";

const useStyles = makeStyles((theme: any) =>
  mergeDeep(theme.journey.prices, {
    subHeading: {
      [theme.breakpoints.only("xs")]: {
        backgroundColor: palette.background.dark,
      },
    },
    subHeadingText: {
      lineHeight: "24px",
      fontSize: "1rem",
    },
    lessonPackagePicker: {
      marginTop: "16px",
    },
  }),
);

const PricesTransmissionTab = ({
  tabName,
  setContinueButtonVisible,
  crossSellInstructorId,
  nudgeBackEnabled = true,
  ...rest
}: {
  tabName: string;
  setContinueButtonVisible: (_shouldShowContinue: boolean) => void;
  crossSellInstructorId: string;
  nudgeBackEnabled: boolean;
}) => {
  const classes: any = useStyles();
  const { brand, crossSellBrand, getText } = useBrand();
  const { trackEvent } = useTealium();

  const {
    state: {
      nujHistory: {
        pickupPostcode: pickupPostcodeFromContext,
        selectedInstructor: selectedInstructorFromContext,
        selectedLessonType: selectedLessonTypeFromContext,
        selectedPackage: packageFromContext,
        selectedTransmissionType: transmissionTypeFromContext,
        availableInstructors,
        marketingChannel: marketingChannelFromContext,
        marketingChannelId: marketingChannelIdFromContext,
        shortNoticeTestDateTime,
        shortNoticeTestCentre,
      },
      sessionId,
    },
    dispatch,
  }: StateContext = useStateContext();

  const { loading } = useGetInstructors({
    skip: transmissionTypeFromContext !== tabName,
    brand,
    postcode: pickupPostcodeFromContext,
    transmission: transmissionTypeFromContext,
    selectedInstructor: selectedInstructorFromContext,
    lessonType: mapLessonTypeToLearnerLessonType(selectedLessonTypeFromContext),
    lessonTypeDL: selectedLessonTypeFromContext,
    dispatch,
    crossSellInstructorId,
    trackEvent,
    marketingChannel: marketingChannelFromContext as MarketingChannel,
    marketingChannelId: marketingChannelIdFromContext,
    testDateTime: shortNoticeTestDateTime,
    testCentre: shortNoticeTestCentre?.toString(),
    lessonLengthHours: 2,
    offersShortNoticeTest:
      selectedLessonTypeFromContext === LessonType.ShortNoticeTest,
    packageLengthsInHours:
      selectedLessonTypeFromContext === LessonType.ShortNoticeTest
        ? [6]
        : [2, 6, 10],
  });

  const handlePackageChange = (hours: number) => {
    changeSelectedPackage({
      hours,
      dispatch,
      selectedInstructorPrices:
        selectedInstructorFromContext && selectedInstructorFromContext.prices,
      trackEvent,
    });
  };

  const renderLoadingSpinner = () => (
    <Grid container alignItems="center">
      <CircularProgress classes={{ root: classes.loader }} />
    </Grid>
  );

  const isLoading =
    loading ||
    (availableInstructors[brand]?.length &&
      !get(selectedInstructorFromContext, "instructor"));

  const isShowingNudgeBacks =
    !availableInstructors[brand]?.length &&
    !availableInstructors[crossSellBrand]?.length &&
    availableInstructors &&
    (!availableInstructors[brand]?.length ||
      !get(selectedInstructorFromContext, "instructor"));

  const isCrossSelling =
    !availableInstructors[brand]?.length &&
    availableInstructors[crossSellBrand]?.length &&
    availableInstructors;

  const shouldShowContinue =
    !isLoading && !isShowingNudgeBacks && !isCrossSelling;

  useEffect(() => {
    setContinueButtonVisible(shouldShowContinue);
  }, [shouldShowContinue]);

  if (transmissionTypeFromContext !== tabName) {
    return renderLoadingSpinner();
  }

  if (isLoading) {
    return renderLoadingSpinner();
  }

  if (isShowingNudgeBacks) {
    return nudgeBackEnabled ? (
      <NudgeBack />
    ) : (
      <NoInstructors pickupPostcode={pickupPostcodeFromContext} />
    );
  }

  if (isCrossSelling) {
    return (
      <CrossSellInstructors
        postcode={pickupPostcodeFromContext}
        referrer={brand}
        transmissionType={transmissionTypeFromContext}
        instructors={availableInstructors[crossSellBrand]}
        crossSellBrand={crossSellBrand}
        lessonType={selectedLessonTypeFromContext}
        sessionId={sessionId}
        testCentre={shortNoticeTestCentre}
      />
    );
  }

  const instructorName = selectedInstructorFromContext?.instructor.name || "";
  const instructorId = selectedInstructorFromContext?.instructor.id;
  const instructorIsTrainee =
    selectedInstructorFromContext?.instructor.isTrainee;
  const instructorPrices = selectedInstructorFromContext?.prices || [];

  return (
    <div {...rest}>
      <ContentWrapper fullWidth fullHeight>
        <div className={classes.subHeading}>
          <ContentWrapper fullHeight>
            <div className={classes.subHeadingText}>
              {getText("prices.yourInstructor")}
            </div>
          </ContentWrapper>
        </div>
      </ContentWrapper>
      <ContentWrapper fullHeight fullWidth>
        <ContentWrapper fullHeight>
          <ChangeInstructorSelect
            hourlyRate={formatCurrency(
              instructorPrices[0]?.standardHourlyPrice || 0,
            )}
            instructorName={instructorName}
            isTrainee={instructorIsTrainee}
            canChangeInstructor={availableInstructors[brand].length > 1}
            lowestAvailableInstructorRate={getLowestInstructorRate({
              selectedInstructorId: instructorId,
              availableInstructors,
              brand,
              packageHours: packageFromContext && packageFromContext.hours,
            })}
            // onChangeClick={() => {
            //   trackEvent({
            //     eventCategory: `Change Instructor Button`,
            //     eventAction: "click",
            //   });
            // }}
          />
        </ContentWrapper>
      </ContentWrapper>
      <ContentWrapper fullWidth fullHeight>
        <div className={classes.subHeading}>
          <ContentWrapper fullHeight>
            <div className={classes.subHeadingText}>
              {getText("prices.howManyLessons")}
            </div>
          </ContentWrapper>
        </div>
      </ContentWrapper>
      {packageFromContext && (
        <ContentWrapper fullHeight>
          <div className={classes.lessonPackagePicker}>
            <LessonPackagePicker
              packageConfiguration={instructorPrices}
              value={packageFromContext.hours.toString()}
              onChange={handlePackageChange}
              data-test="prices-lesson-picker"
              disabled={undefined}
              label={undefined}
            />
          </div>
        </ContentWrapper>
      )}
    </div>
  );
};

export default PricesTransmissionTab;
