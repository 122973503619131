import React, { Fragment } from "react";
import merge from "lodash.merge";
import { makeStyles, Typography, TextField, Theme } from "@material-ui/core";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import Button from "../../common/Button/Button";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import TextFieldErrorMessage from "../../common/Form/TextField/TextFieldErrorMessage";
import Titlebar from "../../common/Titlebar/Titlebar";
import { TalkToUsProps } from "./types";

interface CustomTheme extends Theme {
  journey: {
    RUJTalkToUs: Record<string, any>;
  };
}

const useStyles = makeStyles<CustomTheme>(theme =>
  merge(theme.journey.RUJTalkToUs, {
    buttonWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    chatButton: {
      margin: "2em 0em 3em",
    },
    textField: {
      marginTop: "0.6em",
    },
    messageField: {
      fontSize: "16px",
    },
    sendMessageButton: {
      margin: "40px",
    },
  }),
);

const TalkToUs: React.FC<TalkToUsProps> = ({
  onOpenChat,
  onBackClick,
  handleSubmit,
  errorMessage,
  onMessageUpdate,
  message,
  children,
}) => {
  const { getText } = useBrand();
  const classes = useStyles();

  return (
    <Fragment>
      <ContentWrapper>
        <Titlebar
          showBackButton
          onBackClick={onBackClick}
          headingProps={{ "data-test": "talk-to-us-title-bar" }}
        >
          {getText("returning/talk-to-us.pageTitle")}
        </Titlebar>
        <div>
          {children}
          <Typography component="p" className={classes.textField}>
            {getText("returning/talk-to-us.chatDescription")}
          </Typography>
          <Typography component="p" className={classes.textField}>
            {getText("returning/talk-to-us.chatOpeningTimes")}
          </Typography>
          <div className={classes.buttonWrapper}>
            <Button
              classes={{ root: classes.chatButton }}
              text={getText("returning/talk-to-us.startChatButtonText")}
              onClick={onOpenChat}
              data-chat="true"
              data-test="talk-to-us-chat"
            />
          </div>
          <form>
            <label htmlFor="talk-to-us-error-message">
              <Typography component="label" className={classes.messageField}>
                {getText("returning/talk-to-us.messageFieldLabel")}
              </Typography>
            </label>
            <TextField
              fullWidth
              id="outlined-textarea"
              placeholder="Enter your message to us"
              name="talk-to-us-error-message"
              multiline
              helperText={
                errorMessage && (
                  <TextFieldErrorMessage
                    spanProps={{ "data-test": "talk-to-us-error-message" }}
                    errorMessage={errorMessage}
                  />
                )
              }
              rows="4"
              margin="normal"
              variant="outlined"
              inputProps={{ "data-test": "talk-to-us-message-text-field" }}
              className={classes.textField}
              onChange={onMessageUpdate}
              value={message}
              error={!!errorMessage && errorMessage !== ""}
            />
            <div className={classes.buttonWrapper}>
              <Button
                classes={{ root: classes.sendMessageButton }}
                text={getText("returning/talk-to-us.sendMessageButtonText")}
                onClick={(e: React.FormEvent<HTMLFormElement>) => {
                  e.preventDefault();
                  handleSubmit(message);
                }}
                variant="text"
                data-test="talk-to-us-submit"
                type="submit"
                disabled={!message || errorMessage !== ""}
              />
            </div>
          </form>
        </div>
      </ContentWrapper>
    </Fragment>
  );
};

export default TalkToUs;
