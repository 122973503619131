import React, { FC } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Typography,
  useMediaQuery,
  useTheme,
  DialogTitle,
  IconButton,
  DialogContent,
  Dialog,
  Theme,
  Box,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PhoneIcon from "@material-ui/icons/Phone";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import { ReactComponent as AALogo } from "../../../svg/aad-logo-mobile.svg";
import AAYellowTriangle from "../../../svg/aa-yellow-triangle.svg";

const useStyles = makeStyles<Theme>(theme => ({
  paperFullWidth: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  logo: {
    backgroundImage: `url(${AAYellowTriangle})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 100%",
    height: "151px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "1rem",
  },
  dialogTitle: {
    padding: "0px 24px 30px",
    display: "flex",
    justifyContent: "center",
  },
  title: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "32px",
    lineHeight: "36px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(2),
    color: theme.palette.common.black,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      "& svg": {
        height: "1.6em",
        width: "1.6em",
      },
    },
  },
  closeText: {
    marginBottom: theme.spacing(0.3),
    marginRight: theme.spacing(0.5),
    color: theme.palette.common.white,
    fontSize: "12px",
    lineHeight: "24px",
  },
  content: {
    backgroundColor: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    padding: "30px 24px 40px",
    alignItems: "center",
  },
  text: {
    color: theme.palette.common.black,
    fontSize: "24px",
    lineHeight: "24px",
    marginLeft: "10px",
    textAlign: "center",
  },
  codeText: {
    color: theme.palette.common.black,
    fontSize: "18px",
    lineHeight: "18px",
    maxWidth: "255px",
    textAlign: "center",
    marginTop: "51px",
  },
  telephone: {
    fontSize: theme.typography.body1.fontSize,
    textAlign: "center",
    margin: "48px 0px 35px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.black,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "200px",
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
}));

interface Props {
  closeModal: () => void;
}

const ReferralModal: FC<Props> = ({ closeModal }) => {
  const classes = useStyles();
  const { getText } = useBrand();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Dialog
      open={true} // component is conditionally rendered
      classes={{
        paperFullWidth: classes.paperFullWidth,
      }}
      onClose={closeModal}
      PaperProps={{ elevation: 6 }}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      data-test="referral-modal"
    >
      <Box className={classes.logo}>
        <AALogo width={44} height={30} />
      </Box>

      <DialogTitle className={classes.dialogTitle}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
        <Typography align="center" className={classes.title}>
          {getText("account.breakdownModalTitle")}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography className={classes.text}>
          {getText("account.breakdownModalText")}
        </Typography>
        <Button className={classes.telephone} href="tel:03300530464" type="tel">
          <PhoneIcon /> 03300530464
        </Button>
        <Typography className={classes.codeText}>
          {getText("account.breakdownModalSubtextIntro")}
          <strong>{"DSC02"}</strong>
          {getText("account.breakdownModalSubtextOutro")}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ReferralModal;
