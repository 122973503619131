import { ORDER_STATUS_POLL_INTERVAL } from "./constants";
import { WatchQueryFetchPolicy } from "@apollo/client";

export const getOrderStatusQueryOpts = (
  orderId: string,
  timeout: any,
  type = "NUJ",
) => ({
  variables: {
    orderId,
  },
  pollInterval: ORDER_STATUS_POLL_INTERVAL,
  fetchPolicy: "no-cache" as WatchQueryFetchPolicy,
  displayName: "OrderStatusQuery",
  onError: () => {
    clearTimeout(timeout);
    throw new Error(`An error ocurred with ${type} order #${orderId}`);
  },
});

export default {
  getOrderStatusQueryOpts,
};
