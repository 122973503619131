import React from "react";

import { Helmet } from "react-helmet";
import get from "lodash.get";
import { Route, Redirect, withRouter } from "react-router-dom";

import BaseLayout from "../BaseLayout/BaseLayout";
import {
  useStateContext,
  UPDATE_SESSION_ID,
  UPDATE_NUJ_HISTORY,
} from "../../../context/stateContext";
import { useBrand } from "../BrandProvider/BrandProvider";
import { useTealium } from "../TealiumProvider/TealiumProvider";
import {
  JourneyType,
  NEW_USER_JOURNEY_PATH_NAMES,
  POSTCODE_URL_PARAM,
  REDIRECT_URL_PARAM,
  RETURNING_USER_JOURNEY_PATH_NAMES,
  SESSION_ID_PARAM,
} from "../../../constants";
import { isValidPostcodeFormat } from "../../../utils/validation";
import { isCrossSellQueryString } from "../../../hooks/useCrossSellHandover";
import useQueryString from "../../../hooks/useQueryString";
import { ValidateRouteProps } from "./types";

import {
  NUJHistoryState,
  RUJHistoryState,
  QTUHistoryState,
} from "@lessons/models";
import { Brand } from "@graphql/types";

export const pathHasValidState = (
  routeDependencies: string[],
  appHistory: NUJHistoryState | RUJHistoryState | QTUHistoryState,
) => {
  const isValid = routeDependencies.every(routeDependency => {
    const journeyDependency = get(appHistory, routeDependency);
    if (!journeyDependency && journeyDependency !== 0) {
      return false;
    }

    // if state dependency is an object, check each property is truthy OR 0
    if (typeof journeyDependency === "object") {
      return Object.values(journeyDependency).every(
        value => value || value === 0,
      );
    }
    return true;
  });
  if (!isValid) {
    console.warn("Route dependencies not met");
  }
  return isValid;
};

const shouldBypassRouteDependencies = ({
  pathname,
  queryString,
}: {
  pathname: string;
  queryString: Record<string, string>;
}) =>
  pathname === NEW_USER_JOURNEY_PATH_NAMES.PRICES &&
  isCrossSellQueryString(queryString);

const ValidateRoute: React.FC<ValidateRouteProps> = ({
  path,
  routeDependencies,
  location: { search, pathname },
  Component,
  browserTitle,
  steps,
  journeyType = JourneyType.NEW_USER_JOURNEY,
  setSessionId,
  shouldRedirectLogin,
  ...restProps
}) => {
  const queryString = useQueryString(search);
  const sessionIdFromURL = queryString[SESSION_ID_PARAM];
  const queryStringPostcode = queryString[POSTCODE_URL_PARAM];
  const pickupPostcodeFromURL = queryStringPostcode
    ? (queryStringPostcode as string).trim()
    : null;

  const { state, dispatch } = useStateContext();
  const { trackPageView, updateDataLayer } = useTealium();
  const { brand } = useBrand();

  const shouldDispatchPostcode =
    pickupPostcodeFromURL &&
    state.nujHistory.pickupPostcode !== pickupPostcodeFromURL;

  React.useEffect(() => {
    updateDataLayer({
      full_url: window.location.href,
      page_title: browserTitle,
      page_url: pathname,
    });

    if (window.ga) {
      trackPageView(pathname);
    }
  }, [pathname]);

  // empty url postcode provided, so redirect to pickup location
  if (queryString[POSTCODE_URL_PARAM] === "") {
    return <Redirect push to={NEW_USER_JOURNEY_PATH_NAMES.PICKUP} />;
  }

  // set postcode state from query param
  if (shouldDispatchPostcode) {
    if (isValidPostcodeFormat(pickupPostcodeFromURL)) {
      dispatch({
        type: UPDATE_NUJ_HISTORY,
        payload: {
          pickupPostcode: pickupPostcodeFromURL,
        },
      });
    } else if (pathname !== NEW_USER_JOURNEY_PATH_NAMES.PICKUP) {
      return (
        <Redirect
          push
          to={`${NEW_USER_JOURNEY_PATH_NAMES.PICKUP}?${POSTCODE_URL_PARAM}=${pickupPostcodeFromURL}`}
        />
      );
    }
  }

  if (sessionIdFromURL && state.sessionId !== sessionIdFromURL) {
    dispatch({
      type: UPDATE_SESSION_ID,
      payload: sessionIdFromURL,
    });

    setSessionId(sessionIdFromURL as string);
  }

  const stateHistoryKey = `${journeyType.toLowerCase()}History` as
    | "nujHistory"
    | "rujHistory"
    | "qtuHistory";
  const appHistory = journeyType && state[stateHistoryKey];

  if (
    appHistory &&
    routeDependencies &&
    !shouldBypassRouteDependencies({ pathname, queryString }) &&
    !pathHasValidState(routeDependencies, appHistory) &&
    !shouldDispatchPostcode
  ) {
    if (journeyType === JourneyType.NEW_USER_JOURNEY) {
      return <Redirect push to={NEW_USER_JOURNEY_PATH_NAMES.PICKUP} />;
    }

    if (shouldRedirectLogin) {
      return (
        <Redirect
          push
          to={`${RETURNING_USER_JOURNEY_PATH_NAMES.LOGIN}?${REDIRECT_URL_PARAM}=${path}`}
        />
      );
    }

    if ((appHistory as RUJHistoryState | QTUHistoryState)["topUpId"]) {
      return (
        <Redirect
          push
          to={`${RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT}/${
            (appHistory as RUJHistoryState | QTUHistoryState)["topUpId"]
          }`}
        />
      );
    }

    return <Redirect push to={RETURNING_USER_JOURNEY_PATH_NAMES.LOGIN} />;
  }
  const isCrossSell =
    path === RETURNING_USER_JOURNEY_PATH_NAMES.RECOMMENDED_DRIVING_PRODUCTS;

  // TODO: remove this once we have the cross sell journey approved for BSM
  const isBsm = brand === Brand.Bsm;
  if (isCrossSell && isBsm) {
    return <Redirect push to={RETURNING_USER_JOURNEY_PATH_NAMES.LOGIN} />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {browserTitle ? `${browserTitle} | ` : ""}
          {brand === Brand.Aads ? "AA Driving Lessons" : "BSM Driving Lessons"}
        </title>
        {isCrossSell && <meta name="robots" content="noindex,nofollow" />}
      </Helmet>
      <Route
        {...restProps}
        render={props => (
          <BaseLayout steps={steps}>
            <Component {...props} />
          </BaseLayout>
        )}
      />
    </React.Fragment>
  );
};

export default withRouter(ValidateRoute);
