import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { addDays, isSameDay, parseISO, format } from "date-fns";

import LoadingScreen from "../LoadingScreen/LoadingScreen";
import Timeslots from "./Timeslots";
import { useBrand } from "../BrandProvider/BrandProvider";
import {
  TIMESLOTS_INITIAL_SELECTED_DATE_OFFSET,
  TIMESLOTS_INITIAL_MIN_DATE_OFFSET,
  TIMESLOTS_MAX_DATE_OFFSET,
  TIME_FORMAT,
} from "../../../constants";
import {
  getTimeSlotsStartAndEndDates,
  getFormattedDate,
  getTimeSlots,
} from "../../../utils/getDiaryHelpers";
import { useTealium } from "../TealiumProvider/TealiumProvider";
import useGetDiary from "../../../hooks/useGetDiary";
import { getInstructorName, parseAvailableDates } from "./helpers";
import { goBack } from "../../../utils/navigation";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";
import { TimeslotsStateProps } from "./types";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const TimeslotState = ({
  instructor,
  lessonDate,
  lessonTimeslot,
  titleText,
  updateContext,
  history,
  location: { pathname },
  continueButtonText,
  onTimeslotSelected = () => {},
  fetchPolicy = "cache-first",
  showBanner = false,
  transmissionType,
  // these props are intended only for use in tests
  todayDate = new Date(),
  disableAnimation = false,
  disableContinueButton = false,
}: TimeslotsStateProps & RouteComponentProps) => {
  /* State Variables */
  const { trackEvent, updateDataLayer } = useTealium();
  const { brand, getText } = useBrand();
  const initialSelectedDate = lessonDate || null;
  const minDisplayableDate = addDays(
    todayDate,
    TIMESLOTS_INITIAL_MIN_DATE_OFFSET,
  );
  const [startDate] = useState(todayDate);
  const maxDisplayableDate = addDays(todayDate, TIMESLOTS_MAX_DATE_OFFSET);

  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
  const [selectedTime, setSelectedTime] = useState(
    lessonTimeslot ? JSON.stringify(lessonTimeslot) : "",
  );
  const [showPicker, setShowPicker] = useState(false);
  const [visibleSlideIndex, setVisibleSlideIndex] = useState(
    TIMESLOTS_INITIAL_SELECTED_DATE_OFFSET -
      TIMESLOTS_INITIAL_MIN_DATE_OFFSET -
      1,
  );
  const [totalDisplayedDates, setTotalDisplayedDates] = useState(0);

  /* Handlers */
  const onNextClick = () => {
    setVisibleSlideIndex(Math.min(visibleSlideIndex + 1, totalDisplayedDates));
  };
  const onPreviousClick = () => {
    setVisibleSlideIndex(Math.max(visibleSlideIndex - 1, 0));
  };

  const handleCarouselDateSelection = (val: string) => {
    setSelectedDate(getFormattedDate(new Date(val)));
    setSelectedTime("");
  };

  const handleContinueClick = (e: Event) => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Button Click",
      eventLabel: "Continue",
    });
    e.preventDefault();
    updateContext({
      selectedLessonTimeslot:
        typeof selectedTime === "string"
          ? JSON.parse(selectedTime)
          : selectedTime,
      selectedLessonDate: selectedDate,
    });
  };

  const handleViewCalenderClick = () => {
    setShowPicker(true);
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Interaction",
      eventLabel: "View Calendar",
    });
  };

  const handleDatePickerClose = () => {
    setShowPicker(false);
  };

  const handleDatePickerChange = (date: MaterialUiPickersDate) => {
    if (date) {
      setShowPicker(false);
      setSelectedDate(getFormattedDate(date));
      setSelectedTime("");
    }
  };

  const startTime = (val: string) => {
    const dateObject = JSON.parse(val);
    return `${format(parseISO(dateObject.startTime), TIME_FORMAT)}`;
  };

  const endTime = (val: string) => {
    const dateObject = JSON.parse(val);
    return `${format(parseISO(dateObject.endTime), TIME_FORMAT)}`;
  };

  const handleTimeslotSelected = (val: string) => {
    setSelectedTime(val);
    onTimeslotSelected(val);

    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Interaction",
      eventLabel: `${startTime(val)} — ${endTime(val)}`,
    });
    updateDataLayer({
      book_lesson_date: selectedDate?.slice(0, -14),
      book_lesson_time: `${startTime(val)} — ${endTime(val)}`,
    });
  };

  const handleBackClick = () => {
    goBack({ history, pathname });
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
  };

  const { endDate } = getTimeSlotsStartAndEndDates(todayDate);

  const instructorName = getInstructorName(instructor, getText);

  if (!transmissionType) return <></>;

  const { data, loading } = useGetDiary({
    brand,
    instructorId: instructor?.id ? instructor.id : "",
    startDate,
    endDate,
    transmissionType,
    fetchPolicy,
  });

  const diary = data?.availabilityNUJ || undefined;

  const carouselDates =
    diary && diary.availableDates
      ? parseAvailableDates(diary.availableDates, todayDate)
      : [];

  const availableTimes =
    diary && getTimeSlots(selectedDate, diary.availableDates);

  /* Effects */
  useEffect(() => {
    if (!carouselDates.length || !selectedDate) return;
    const newSlideIndex = Math.max(
      carouselDates.findIndex((d: Date) =>
        isSameDay(d, new Date(selectedDate)),
      ),
      0,
    );
    setVisibleSlideIndex(newSlideIndex);
  }, [selectedDate]);

  useEffect(() => {
    if (diary && Array.isArray(diary.availableDates)) {
      if (!selectedDate) {
        setSelectedDate(diary.initialDate);
      }
      setTotalDisplayedDates(diary.availableDates.length);
    }
  }, [diary]);

  /* Render */

  return loading ? (
    <LoadingScreen messageText={`Loading ${instructorName}'s calendar`} />
  ) : (
    <Timeslots
      onBackClick={handleBackClick}
      titleText={titleText || ""}
      carouselDates={carouselDates}
      onCarouselDateSelect={handleCarouselDateSelection}
      selectedDate={parseISO(selectedDate || "")}
      onCarouselNextClick={onNextClick}
      onCarouselPreviousClick={onPreviousClick}
      carouselSlideIndex={visibleSlideIndex}
      minDisplayableDate={minDisplayableDate}
      maxDisplayableDate={maxDisplayableDate}
      onViewCalenderClick={handleViewCalenderClick}
      availableTimeslots={availableTimes}
      onTimeslotSelected={handleTimeslotSelected}
      selectedTime={
        typeof selectedTime === "string" && selectedTime.length > 0
          ? JSON.parse(selectedTime)
          : selectedTime
      }
      showDatePicker={showPicker}
      onDatePickerClose={handleDatePickerClose}
      onDatePickerChange={handleDatePickerChange}
      onContinueButtonClick={handleContinueClick}
      disableContinueButton={loading || disableContinueButton}
      continueButtonText={continueButtonText || ""}
      showBanner={showBanner}
      disableAnimation={disableAnimation}
    />
  );
};

export default withRouter(TimeslotState);
