import React from "react";
import AppSidebarAAD from "./brands/AppSidebar.AAD";
import AppSidebarBSM from "./brands/AppSidebar.BSM";
import { useBrand } from "../BrandProvider/BrandProvider";
import { Brand } from "@graphql/types";

export default props => {
  const { brand } = useBrand();
  return brand === Brand.Aads ? (
    <AppSidebarAAD {...props} />
  ) : (
    <AppSidebarBSM {...props} />
  );
};
