import React, { useState, useEffect } from "react";
import { node, bool, func, string, shape } from "prop-types";
import upperfirst from "lodash.upperfirst";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  Switch,
  makeStyles,
} from "@material-ui/core";
import { withTheme } from "@material-ui/styles";
import { useTealium } from "../../TealiumProvider/TealiumProvider";

const useStyles = makeStyles(theme => ({
  legend: {
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "11px",
  },
  helperText: {
    fontSize: "16px",
  },
  label: {
    margin: "6px 0 0 0",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  switch: {
    order: "2",
  },
  group: {
    marginTop: "20px",
  },
  control: {
    width: "100%",
  },
}));

export const isIndeterminate = booleanValues =>
  Object.values(booleanValues).some(booleanValue => booleanValue) &&
  !Object.values(booleanValues).every(booleanValue => booleanValue);

export const isAllTrue = booleanValues =>
  Object.values(booleanValues).every(booleanValue => booleanValue);

const switchLabels = {
  email: "Email",
  sms: "SMS",
  phone: "Telephone",
  post: "Post",
};

const FormMarketingPreferences = ({
  value,
  onChange,
  disabled,
  label,
  helperText,
  theme,
}) => {
  const classes = useStyles();
  const [switchValues, setSwitchValues] = useState(value);
  const { trackEvent, updateDataLayer } = useTealium();

  const handleSwitchChange = name => (event, switchValue) => {
    trackEvent({
      eventCategory: "Personal Details",
      eventAction: "Interaction",
      eventLabel: `${upperfirst(name)} - ${switchValue ? "Yes" : "No"}`,
    });
    const updatedValues = {
      ...switchValues,
      [name]: switchValue,
    };
    updateDataLayer({
      marketingPreferences: updatedValues,
    });
    setSwitchValues(updatedValues);
  };

  useEffect(() => {
    onChange(switchValues);
  }, [switchValues]);

  return (
    <FormControl
      disabled={disabled}
      component="fieldset"
      classes={{ root: classes.control }}
    >
      {label && (
        <FormLabel component="legend" classes={{ root: classes.legend }}>
          {label}
        </FormLabel>
      )}
      {helperText && (
        <FormHelperText classes={{ root: classes.helperText }}>
          {helperText}
        </FormHelperText>
      )}
      <FormGroup classes={{ root: classes.group }}>
        {Object.keys(switchValues).map(key => (
          <FormControlLabel
            classes={{ root: classes.label }}
            key={key}
            label={switchLabels[key]}
            control={
              <Switch
                classes={{ root: classes.switch }}
                checked={switchValues[key]}
                value={switchValues[key]}
                onChange={handleSwitchChange(key)}
                name={`marketingOptIn${
                  key.charAt(0).toUpperCase() + key.slice(1)
                }`}
                color={theme.journey.marketingPreferences.switchColor}
              />
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

FormMarketingPreferences.propTypes = {
  value: shape({
    email: bool,
    sms: bool,
    phone: bool,
    post: bool,
  }),
  onChange: func,
  disabled: bool,
  label: node,
  helperText: node,
  checkboxLabel: string,
};

FormMarketingPreferences.defaultProps = {
  value: {
    email: true,
    sms: true,
    phone: true,
    post: true,
  },
  disabled: false,
  label: "",
  helperText: "",
};

export default withTheme(FormMarketingPreferences);
