import React from "react";
import { bool, func, node, string } from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(5),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[400],
  },
  actions: {
    margin: theme.spacing(2),
  },
}));

const Modal = ({
  showModal = false,
  toggleModal = () => {},
  title,
  content,
  actions,
  testId,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={showModal}
      classes={classes.dialog}
      onClose={toggleModal}
      PaperProps={{ elevation: 6 }}
      maxWidth="xs"
      fullWidth
      data-test={testId}
    >
      <DialogTitle className={classes.title}>
        {title}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={toggleModal}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions className={classes.actions}>{actions}</DialogActions>
    </Dialog>
  );
};

Modal.propTypes = {
  showModal: bool,
  toggleModal: func,
  title: string,
  content: node,
  actions: node,
  testId: string,
};

export default Modal;
