import { useState, useEffect } from "react";
import { GraphQLError } from "graphql";
import * as Sentry from "@sentry/browser";
import GetAddresses from "../graphql/getAddressesByPostcode.graphql";
import { isValidPostcodeFormat } from "../utils/validation";
import { GLOBAL_PATH_NAMES } from "../constants";

const useAddressList = (postcode = "", client, history) => {
  const [addressList, setAddressList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isValidPostcodeFormat(postcode)) {
      return;
    }

    const fetchAddress = async () => {
      setIsLoading(true);
      const {
        data: { searchForAddress: search },
        errors,
      } = await client.query({
        query: GetAddresses,
        variables: {
          term: postcode.trim(),
          isExactMatch: true,
        },
      });

      setIsLoading(false);

      if (errors) {
        errors.forEach(graphQlErr => {
          Sentry.captureException(new GraphQLError(graphQlErr));
        });
        history.push(GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG_500);
      } else if (search && search.length) {
        setAddressList(
          search.map(address => ({
            value: address.id,
            label: address.description,
          })),
        );
      }
    };

    fetchAddress();
  }, [client, history, postcode]);

  return { isLoading, addressList };
};

export default useAddressList;
