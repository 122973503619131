import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import { RETURNING_USER_JOURNEY_PATH_NAMES } from "../../../../constants";
import Success from "../../../common/Success/Success";
import { useTealium } from "../../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../../utils/capitalisePathname";

export default () => {
  const history = useHistory();
  const { getText } = useBrand();
  const { trackEvent } = useTealium();
  const { pathname } = useLocation();

  return (
    <Success
      title={getText("returning/talk-to-us/confirmation.title")}
      buttonText={getText("returning/talk-to-us/confirmation.buttonText")}
      handleButtonClick={() => {
        trackEvent({
          eventCategory: capitalizeEachWordOfPathname(pathname),
          eventAction: "Button Click",
          eventLabel: "Go To My Account",
        });
        history.push(RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT);
      }}
    >
      {getText("returning/talk-to-us/confirmation.messageSent")}
    </Success>
  );
};
