import React, { Fragment, useEffect, useState } from "react";

import { Grid, InputLabel, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { addDays, addHours, isBefore, isValid, format } from "date-fns";
import { useHistory, useLocation } from "react-router-dom";

import {
  NEW_USER_JOURNEY_PATH_NAMES,
  LESSON_TYPES,
} from "../../../../../constants";
import {
  useStateContext,
  UPDATE_NUJ_HISTORY,
} from "../../../../../context/stateContext";
import { capitalizeEachWordOfPathname } from "../../../../../utils/capitalisePathname";
import { goBack } from "../../../../../utils/navigation";
import { useBrand } from "../../../../common/BrandProvider/BrandProvider";
import ContentWrapper from "../../../../common/ContentWrapper/ContentWrapper";
import SubmitButton from "../../../../common/SubmitButton/SubmitButton";
import { useTealium } from "../../../../common/TealiumProvider/TealiumProvider";
import Titlebar from "../../../../common/Titlebar/Titlebar";
import testCentres from "./test-centres.json";
import DateTimePicker from "./DateTimePicker";

const NewUserJourneyStepsLessonTypesTestBooked = () => {
  const { getText } = useBrand();
  const { trackEvent, updateDataLayer } = useTealium();
  const {
    state: {
      nujHistory: {
        shortNoticeTestDateTime,
        shortNoticeTestCentre,
        selectedLessonType,
      },
    },
    dispatch,
  } = useStateContext();

  const history = useHistory();
  const { pathname } = useLocation();

  const [date, setDate] = useState(shortNoticeTestDateTime);
  const [time, setTime] = useState(shortNoticeTestDateTime);
  const [testCentre, setTestCentre] = useState(shortNoticeTestCentre);
  const [showMinimumDateError, setShowMinimumDateError] = useState(false);

  const minimumDate = addHours(new Date(), 72 /* 3 days */);

  const constructTestDate = () =>
    new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      0,
    );

  const checkDateTime = () => {
    /* <KeyboardTimePicker> does not handle the minDate prop correctly, and it
       won't be fixed until v4.
       Therefore we need to manually verify that the entered datetime is at
       least 72 hours away */
    if (date && time) {
      const testDateTime = constructTestDate();

      setShowMinimumDateError(isBefore(testDateTime, minimumDate));
    }
  };

  useEffect(checkDateTime, [date, time]);

  const handleSubmit = () => {
    const testDateTime = constructTestDate();
    const isWithin14Days = isBefore(testDateTime, addDays(new Date(), 14));
    dispatch({
      type: UPDATE_NUJ_HISTORY,
      payload: {
        selectedLessonType: isWithin14Days
          ? selectedLessonType
          : LESSON_TYPES.LEARNER,
        shortNoticeTestDateTime: testDateTime,
        shortNoticeTestCentre: testCentre,
      },
    });

    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Button Click",
      eventLabel: "Continue",
    });

    const urlToNavigateTo = isWithin14Days
      ? NEW_USER_JOURNEY_PATH_NAMES.SNT_PRICES
      : NEW_USER_JOURNEY_PATH_NAMES.PRICES;
    history.push(urlToNavigateTo);
  };

  const handleBackButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    goBack({ history, pathname });
  };

  const handleDateChange = newDate => {
    if (isValid(newDate)) {
      updateDataLayer({
        test_date: format(newDate, "dd/MM/yyyy"),
      });
      trackEvent({
        eventCategory: capitalizeEachWordOfPathname(pathname),
        eventAction: "Interaction",
        eventLabel: "Date",
      });
    }

    setDate(newDate);
  };

  const handleTimeChange = newTime => {
    if (isValid(newTime)) {
      updateDataLayer({
        test_time: format(newTime, "HH:mm"),
      });
      trackEvent({
        eventCategory: capitalizeEachWordOfPathname(pathname),
        eventAction: "Interaction",
        eventLabel: "Time",
      });
    }
    setTime(newTime);
  };

  return (
    <Fragment>
      <ContentWrapper>
        <Titlebar
          showBackButton
          onBackClick={handleBackButtonClick}
          headingProps={{ "data-test": "test-booked-title-bar" }}
        >
          {getText("test-booked.pageTitle")}
        </Titlebar>
      </ContentWrapper>

      <ContentWrapper fullHeight>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <DateTimePicker
              dateValue={date}
              error={showMinimumDateError}
              minimumDate={minimumDate}
              onDateChange={newDate => handleDateChange(newDate)}
              onTimeChange={newTime => handleTimeChange(newTime)}
              timeValue={time}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{getText("test-booked.testCentreLabel")}</InputLabel>
            <Autocomplete
              name="test-centres-autocomplete"
              onChange={(e, value) => {
                setTestCentre(value);
                trackEvent({
                  eventCategory: capitalizeEachWordOfPathname(pathname),
                  eventAction: "Interaction",
                  eventLabel: `Test Centre Booked - ${value}`,
                });
                updateDataLayer({
                  test_centre: `Test Centre Booked - ${value}`,
                });
              }}
              options={testCentres.centres}
              renderInput={props => (
                <TextField
                  {...props}
                  inputProps={{
                    ...props.inputProps,
                    id: "test-centres", // prevent mui generated ID being snapshotted
                  }}
                  name="test-centres"
                />
              )}
              value={testCentre}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>{getText("test-booked.lessonInfo")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <SubmitButton
              data-test="test-booked-submit-button"
              disabled={showMinimumDateError || !(date && time && testCentre)}
              text="Continue"
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      </ContentWrapper>
    </Fragment>
  );
};

export default NewUserJourneyStepsLessonTypesTestBooked;
