import { createMuiTheme } from "@material-ui/core/styles";
import { Brand } from "@graphql/types";
import aadTheme from "./theme-aad";
import bsmTheme from "./theme-bsm";

const getTheme = (brand, overrides = {}) =>
  createMuiTheme(
    brand === Brand.Aads ? aadTheme(overrides) : bsmTheme(overrides),
  );

export default getTheme;
