import React from "react";
import mergeDeep from "lodash.merge";
import { Grid, makeStyles, useMediaQuery } from "@material-ui/core";
import { bool, number, oneOfType, string } from "prop-types";

import Button from "../../../common/Button/Button";
import Actions from "../../../common/Form/InstructorPicker/Actions";
import InitialsSelectedAvatar from "../../../common/Form/InstructorPicker/Avatar/InitialsSelected";
import Item from "../../../common/ItemList/Item/Item";
import TraineeMessage from "../../../common/TraineeMessage/TraineeMessage";
import ChangeInstructorButton from "./ChangeInstructorButton";

const useStyles = makeStyles(theme =>
  mergeDeep(theme.journey.prices, {
    actions: {
      flex: 2,
    },
    changeInstructorItem: {
      padding: "16px 0",
      position: "relative",
      alignItems: "center",
      "&::before": {
        content: "''",
        position: "absolute",
        left: "-16px",
        top: 0,
        height: "100%",
        width: "4px",
        background: theme.palette.primary.main,
      },

      "@media(min-width: 600px)": {
        padding: 0,
        "&::before": {
          display: "none",
        },
      },
    },
    changeInstructorButton: {
      padding: "6px",
      marginTop: "-8px",
      [theme.breakpoints.up("sm")]: {
        marginTop: "0",
      },
    },
  }),
);

const ChangeInstructorSelect = ({
  instructorName,
  canChangeInstructor = false,
  hourlyRate,
  isTrainee = false,
  lowestAvailableInstructorRate,
}) => {
  const classes = useStyles();
  const isSmallDevice = useMediaQuery("(max-width:600px)");

  return (
    <Grid container alignItems="center">
      <Item
        classes={{
          root: classes.changeInstructorItem,
        }}
        text={instructorName}
        avatar={
          !isSmallDevice && <InitialsSelectedAvatar name={instructorName} />
        }
        secondaryText={isTrainee && <TraineeMessage />}
        actions={<Actions hourlyRate={hourlyRate} rateType="standard" />}
        data-test="prices-selected-instructor"
        icon={
          canChangeInstructor && (
            <Button
              to="prices/change-instructor"
              text={
                <ChangeInstructorButton
                  showChangeInstructorText
                  startingPrice={lowestAvailableInstructorRate}
                />
              }
              variant="text"
              color="secondary"
              data-test="prices-change-instructor"
              classes={{
                root: classes.changeInstructorButton,
              }}
              navButton
            />
          )
        }
        selected
        disableGutters
      />
    </Grid>
  );
};

ChangeInstructorSelect.propTypes = {
  instructorName: string.isRequired,
  canChangeInstructor: bool,
  hourlyRate: oneOfType([string, number]).isRequired,
  isTrainee: bool,
  lowestAvailableInstructorRate: number,
};

export default ChangeInstructorSelect;
