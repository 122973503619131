import React from "react";
import { func } from "prop-types";
import mergeDeep from "lodash.merge";
import { IconButton, makeStyles, SvgIcon, Typography } from "@material-ui/core";
import { withTheme } from "@material-ui/styles";
import { useBrand } from "../BrandProvider/BrandProvider";

const useStyles = makeStyles(theme =>
  mergeDeep(theme.journey.backButton, {
    root: {
      padding: 0,
      "&:hover": {
        background: "none",
        "&::before": {
          opacity: "1",
        },
      },
      "&::before": {
        content: "''",
        transition: "all 400ms",
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "60px",
        height: "60px",
        transform: "translate(-50%, -50%)",
        borderRadius: "50%",
        background: "rgba(0,0,0,0.1)",
        opacity: "0",
        zIndex: "-1",
      },
    },
    label: {
      fontSize: "0.75rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "0.9rem",
      },
    },
    ripple: {
      width: "60px",
      height: "60px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    icon: {
      height: "24px",
      width: "24px",
    },
  }),
);

/**
 * Component that renders a 'back' button, with icon and text. It will invoke
 * a callback on click, if supplied
 * @param {BackButtonProps} props Props that the component accepts
 */
const BackButton = ({ onClick = () => {}, theme, ...rest }) => {
  const classes = useStyles();
  const { getText } = useBrand();

  return (
    <IconButton
      onClick={onClick}
      classes={{ root: classes.root }}
      TouchRippleProps={{ classes: { root: classes.ripple } }}
      data-test="back-button"
      {...rest}
    >
      <SvgIcon classes={{ root: classes.icon }}>
        {React.createElement(theme.journey.backIcon)}
      </SvgIcon>
      <Typography
        variant="body2"
        component="p"
        classes={{ root: classes.label }}
      >
        {getText("common.back")}
      </Typography>
    </IconButton>
  );
};

BackButton.propTypes = {
  onClick: func,
};

export default withTheme(BackButton);
