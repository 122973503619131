import React from "react";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import mergeDeep from "lodash.merge";
import { useBrand } from "../BrandProvider/BrandProvider";

const useStyles = makeStyles(theme =>
  // @ts-ignore
  mergeDeep(theme.journey.exitButton, {
    root: {
      padding: 0,
      "&:hover": {
        background: "none",
        "&::before": {
          opacity: "1",
        },
      },
      "&::before": {
        content: "''",
        transition: "all 400ms",
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "50px",
        height: "50px",
        transform: "translate(-50%, -50%)",
        borderRadius: "50%",
        background: "rgba(0,0,0,0.1)",
        opacity: "0",
        zIndex: "-1",
      },
    },
    label: {
      flexDirection: "row",
      fontSize: "0.75rem",
      marginRight: "4px",
      [theme.breakpoints.up("md")]: {
        fontSize: "0.9rem",
      },
    },
    ripple: {
      width: "50px",
      height: "50px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    icon: {
      [theme.breakpoints.up("md")]: {
        marginTop: "2px",
      },
    },
  }),
);

/**
 * Component that renders an exit button, with icon and text. It will invoke
 * a callback on click, if supplied
 * @param {ExitButtonProps} props Props that the component accepts
 */
const ExitButton: React.FC<{
  onClick?: () => void;
}> = ({ onClick = () => {} }) => {
  const classes: Record<string, any> = useStyles();
  const { getText } = useBrand();
  return (
    <IconButton
      onClick={onClick}
      classes={{ root: classes.root }}
      TouchRippleProps={{ classes: { root: classes.ripple } }}
      data-test="exit-button"
    >
      <Typography
        component="p"
        variant="body2"
        classes={{ root: classes.label }}
      >
        {getText("common.exit")}
      </Typography>

      <Close classes={{ root: classes.icon }} />
    </IconButton>
  );
};

export default ExitButton;
