import React, { useState, useEffect, Fragment } from "react";
import {
  arrayOf,
  bool,
  node,
  number,
  oneOfType,
  string,
  shape,
  func,
} from "prop-types";
import get from "lodash.get";
import mergeDeep from "lodash.merge";
import { makeStyles } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import ItemList from "../../ItemList/ItemList";
import Actions from "./Actions";
import TraineeMessage from "../../TraineeMessage/TraineeMessage";

const useStyles = makeStyles(theme =>
  mergeDeep(theme.journey.prices, {
    listItemRoot: {
      color: theme.palette.primary,
    },
    button: {
      margin: "40px auto 0 auto",
    },
  }),
);

const FormInstructorPicker = ({ instructors, onChange, value, ...rest }) => {
  const [selectedValue, setSelectedValue] = useState(value);
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleClick = newValue => {
    const newValueString =
      typeof newValue === "number" ? newValue.toString() : newValue;
    onChange(newValueString);
  };

  const classes = useStyles();

  const renderAvatar = (avatar, selectedAvatar, { selected }) =>
    selected ? selectedAvatar : avatar;

  const renderIcon = (icon, { selected }) =>
    selected ? <ChevronRight aria-disabled="true" color="disabled" /> : icon;

  const items = instructors.map(
    ({
      name,
      id,
      hourlyRate,
      rateType,
      isTrainee,
      avatar,
      selectedAvatar,
    }) => ({
      text: name,
      id,
      actions: {
        hourlyRate,
        rateType,
      },
      isTrainee,
      avatar,
      selectedAvatar,
      icon: <ChevronRight />,
    }),
  );

  return (
    <Fragment>
      <ItemList
        items={items}
        renderSecondaryText={isTrainee => isTrainee && <TraineeMessage />}
        renderActions={actions => (
          <Actions
            hourlyRate={get(actions, "hourlyRate", "")}
            rateType={get(actions, "rateType", "standard")}
            classes={classes}
          />
        )}
        renderAvatar={renderAvatar}
        renderIcon={renderIcon}
        selected={[selectedValue]}
        onClick={handleClick}
        listItemProps={{
          classes: {
            root: classes.listItemRoot,
          },
        }}
        listProps={{ ...rest }}
      />
    </Fragment>
  );
};

FormInstructorPicker.propTypes = {
  instructors: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      hourlyRate: oneOfType([string, number]).isRequired,
      rateType: string,
      isTrainee: bool.isRequired,
      avatar: node.isRequired,
      selectedAvatar: node.isRequired,
    }),
  ),
  value: string,
  onChange: func,
};

export default FormInstructorPicker;
