import { makeStyles } from "@material-ui/core";
import ThumbUp from "@material-ui/icons/ThumbUpOutlined";
import React from "react";

import phoneIcon from "../../../svg/icon-phone.svg";
import Banner from "../Banner";
import { useBrand } from "../BrandProvider/BrandProvider";
import { Brand } from "@graphql/types";

const useStyles = makeStyles({
  bannerContainer: {
    marginBottom: "24px",
  },
  thumbUpIcon: {
    color: "#4E1EB5",
    height: "36px",
    width: "26px",
  },
});

const TimeslotsBanner = () => {
  const { brand, getText } = useBrand();
  const classes = useStyles();

  return (
    <div className={classes.bannerContainer}>
      <Banner
        icon={
          brand === Brand.Aads ? (
            <img src={phoneIcon} alt="Phone" />
          ) : (
            <ThumbUp className={classes.thumbUpIcon} />
          )
        }
        title={getText("returning/timeslots.bannerTitle")}
        text={getText("returning/timeslots.bannerText")}
        data-test="timeslots-banner"
      />
    </div>
  );
};

export default TimeslotsBanner;
