import React, { FC } from "react";
import {
  ListItem,
  ListItemAvatar,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { ReactComponent as AALogo } from "../../../svg/aad-logo-mobile.svg";
import AAYellowTriangle from "../../../svg/aa-yellow-triangle.svg";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import { useToggle } from "../../../hooks/useToggle";
import FreeBreakdownModal from "./FreeBreakdownModal";

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: "0px",
    minWidth: "auto",
    display: "flex",
    alignItems: "center",
  },
  chevron: {
    marginLeft: "auto", // Align flex child right
    [theme.breakpoints.up("sm")]: {
      marginLeft: "0px",
    },
  },
  container: {
    backgroundColor: theme.palette.grey[500],
    backgroundImage: `url(${AAYellowTriangle})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 100%",
    padding: "20px 10px 20px 0px",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.grey[500],
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
      borderRadius: "6px",
    },
  },
  text: {
    color: theme.palette.grey[300],
    lineHeight: "1.3",
    fontSize: "0.875rem",
    maxWidth: "235px",
    marginLeft: "auto",
    paddingLeft: "30px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.125rem",
      marginRight: "auto",
    },
  },
}));

const FreeBreakdownBanner: FC = () => {
  const { getText } = useBrand();
  const classes = useStyles();
  const theme = useTheme();

  const [showModal, toggleModal] = useToggle();

  return (
    <ContentWrapper fullHeight fullWidth>
      {showModal && <FreeBreakdownModal closeModal={toggleModal} />}

      <ListItem
        button
        disableGutters
        onClick={toggleModal}
        className={classes.container}
        data-test="referral-account-banner"
      >
        <ListItemAvatar className={classes.avatar}>
          <AALogo height={12} />
        </ListItemAvatar>

        <Typography color="textPrimary" className={classes.text}>
          {getText("account.freeBreakdownClaimOne")}
          <strong>{getText("account.freeBreakdownYearsFreeAA")}</strong>
          {getText("account.freeBreakdownCover")}
        </Typography>

        <ChevronRight
          className={classes.chevron}
          htmlColor={theme.palette.common.white}
        />
      </ListItem>
    </ContentWrapper>
  );
};

export default FreeBreakdownBanner;
