import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const RefundIcon = props => (
  <SvgIcon height="32" width="32" viewBox="0 0 32 32" {...props}>
    <path d="M23.32 18H28V15.3333H23.32V11.3333L18 16.6667L23.32 22V18Z" />
    <path d="M5.68 21.9999H4V25.3332H17.3067V21.9999H9.46667V17.8666H14.08V15.1466H9.46667V12.8532C9.46667 10.9866 10.5333 9.8399 12.2133 9.8399C13.28 9.8399 14.32 10.3732 14.9067 11.1999L17.7867 8.93324C16.48 7.30657 14.4533 6.3999 12.08 6.3999C8.29333 6.3999 5.68 8.90657 5.68 12.6132V15.1466H4V17.8666H5.68V21.9999Z" />
  </SvgIcon>
);

export default RefundIcon;
