import React from "react";
import { useQuery } from "@apollo/react-hooks";

import GetBookedLessons from "../../../graphql/getBookedLessonsRUJ.graphql";
import { useStateContext } from "../../../context/stateContext";
import LoadingScreen from "../../common/LoadingScreen/LoadingScreen";

import BookedLessons from "./BookedLessons";
import {
  categoriseLessons,
  constructListItems,
  filterAndSortLessons,
} from "./helpers";

const BookedLessonsWithQuery = () => {
  const {
    state: {
      rujHistory: { learnerId },
    },
  } = useStateContext();

  const { loading, error, data } = useQuery(GetBookedLessons, {
    variables: {
      learnerId,
    },
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return <LoadingScreen messageText="Your booked lessons" />;
  }

  if (error) {
    throw new Error(error.toString());
  }

  const sortedFilteredLessons = filterAndSortLessons(data.learner.lessons);
  const categorisedLessons = categoriseLessons(sortedFilteredLessons);
  const { upcoming, past } = constructListItems(categorisedLessons);

  return <BookedLessons upcomingLessons={upcoming} pastLessons={past} />;
};

export default BookedLessonsWithQuery;
