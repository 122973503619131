import * as React from "react";
import { number, string, oneOfType } from "prop-types";
import upperfirst from "lodash.upperfirst";
import { makeStyles, Typography } from "@material-ui/core";
import { formatCurrency } from "../../../../utils/formatCurrency";

const useStyles = makeStyles(theme => ({
  actions: {
    paddingLeft: "12px",
  },
  rateType: {
    color: theme.palette.grey[400],
    opacity: 0.9,
    fontSize: "12px",
    lineHeight: "12px",
    paddingTop: "5px",
  },
  hourlyRateText: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const FormInstructorPickerActions = ({ rateType = "", hourlyRate = 0 }) => {
  const classes = useStyles();
  return (
    <div className={classes.actions}>
      <Typography classes={{ root: classes.hourlyRateText }} align="center">
        {`£${hourlyRate && formatCurrency(hourlyRate)}/hr`}
      </Typography>
      <Typography classes={{ root: classes.rateType }} align="center">
        {`${upperfirst(rateType)} rate`}
      </Typography>
    </div>
  );
};

FormInstructorPickerActions.propTypes = {
  rateType: string,
  hourlyRate: oneOfType([string, number]),
};

export default FormInstructorPickerActions;
