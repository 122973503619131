import React from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Typography,
  CircularProgress,
  MenuItem,
} from "@material-ui/core";

import pluralize from "pluralize";
import { formatCurrency } from "../../../utils/formatCurrency";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import ContinueButton from "../../common/ContinueButton/ContinueButton";
import Select from "../../common/Form/Select/Select";
import TopupHeader from "../../common/TopupHeader/TopupHeader";
import AccountBalance from "../../common/AccountBalance/AccountBalance";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import { pluralizeName } from "../../../utils/pluralizeName";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";
import { RefundRequestProps } from "./types";

const REFUND_REASONS = {
  PassedTest: "I passed my test",
  Complaint: "I have a complaint",
  WorkCommitments: "Work commitments",
  FinancialReasons: "Financial reasons",
  MovingAreas: "I'm moving to a different area",
  Holiday: "Going on holiday",
  Bereavement: "Bereavement",
  MovingToDifferentDrivingSchool: "Moving to a different driving school",
  Childcare: "Childcare issues",
};

const useStyles = makeStyles(() => ({
  totalHours: {
    "& span": {
      display: "block",
    },
  },
  totalCost: {
    "& span": {
      display: "block",
      textAlign: "right",
    },
  },
  label: {
    fontSize: "12px",
    lineHeight: "12px",
    marginBottom: "10px",
    textTransform: "uppercase",
  },
  cost: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  header: {
    paddingBottom: "24px",
    marginBottom: "24px",
    marginTop: "30px",
    alignItems: "center",
  },
  totalContainer: {
    marginBottom: "24px",
  },
  reasonLabel: {
    marginBottom: "10px",
    display: "inline-block",
  },
}));

const RefundRequest: React.FC<RefundRequestProps> = ({
  name,
  onSubmit,
  submitButtonDisabled,
  refundableRateHours,
  refundableBalance,
  refundableTotal,
  reason = "",
  onReasonChange,
  loading,
}) => {
  const { getText } = useBrand();
  const classes = useStyles();
  const { trackEvent, updateDataLayer } = useTealium();
  const { pathname } = useLocation();
  return (
    <ContentWrapper>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Grid container spacing={1}>
          <TopupHeader
            title={getText("requestRefund/request.browserTitle")}
            showBackButton
            showBalance={false}
            balance={refundableBalance || 0}
            headingProps={{
              "data-test": "request-refund-title",
            }}
          />
          <Grid item xs={12} md={12}>
            <Typography>
              {getText("requestRefund/request.description1")}
            </Typography>
            <Typography>
              <strong>{getText("requestRefund/request.emphasis")}</strong>{" "}
              {getText("requestRefund/request.description2")}
            </Typography>
          </Grid>

          {loading && (
            <Grid className={classes.header}>
              <CircularProgress data-test="loading-spinner" />
            </Grid>
          )}

          {!loading && (
            <>
              <Grid
                container
                justify="space-between"
                className={classes.header}
              >
                <Grid item xs={6} md={6}>
                  <Typography
                    className={classes.totalHours}
                    variant="h3"
                    data-test="request-refund-name"
                  >
                    {`${pluralizeName(name)} account`}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <AccountBalance
                    hours={refundableBalance}
                    descriptor={getText(
                      "requestRefund/request.balanceDescriptor",
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                justify="space-between"
                className={classes.totalContainer}
              >
                <Grid item xs={6} md={6}>
                  <Typography className={classes.totalHours}>
                    <span className={classes.label}>
                      {getText("requestRefund/request.totalLabel")}
                    </span>
                    {refundableRateHours.map((rh, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <span key={`rh-${i}`} data-test="topup-hours">
                        {`${rh.hours} ${pluralize(
                          "hour",
                          rh.hours,
                        )} @ £${formatCurrency(rh.rate)}/hr`}
                      </span>
                    ))}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.totalCost}>
                    <span className={classes.label}>
                      {getText("requestRefund/request.priceLabel")}
                    </span>
                    <span className={classes.cost} data-test="refundable-total">
                      £{formatCurrency(refundableTotal)}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <Select
                inputProps={{ ["data-test"]: "refund-reason-select" }}
                label={getText("requestRefund/request.refundReasonLabel")}
                value={reason}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  trackEvent({
                    eventCategory: capitalizeEachWordOfPathname(pathname),
                    eventAction: "Interaction",
                    eventLabel: e.target.value,
                  });
                  updateDataLayer({
                    request_refund_reason: e.target.value,
                  });
                  e.preventDefault();
                  onReasonChange(e.target.value);
                }}
                name="refund-reason-select"
                id="refund-reason-select"
                data-test="refund-reason-select"
                displayEmpty
              >
                {Object.entries(REFUND_REASONS).map(([key, value], index) => (
                  <MenuItem
                    key={key}
                    value={key}
                    data-test={`refund-reason-${index}`}
                  >
                    {value}
                  </MenuItem>
                ))}
              </Select>

              <ContinueButton
                text={
                  loading ? (
                    <CircularProgress size={16} />
                  ) : (
                    getText("requestRefund/request.buttonText", {
                      refundableTotal,
                    })
                  )
                }
                data-test="continue-button"
                navButton={false}
                type="submit"
                disabled={submitButtonDisabled}
                onClick={() => {
                  trackEvent({
                    eventCategory: capitalizeEachWordOfPathname(pathname),
                    eventAction: "Button Click",
                    eventLabel: "Request A Refund",
                  });
                  updateDataLayer({
                    request_refund_sent: "true",
                  });
                }}
              />
            </>
          )}
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default React.memo(RefundRequest);
