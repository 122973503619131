import * as React from "react";
import { useLocation } from "react-router-dom";

import {
  makeStyles,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import mergeDeep from "lodash.merge";

import ProgressTrackBar from "../ProgressTrackerBar";
import Skill from "../Skill";
import { ProgressCategoryProps } from "../../types";
import { capitalizeEachWordOfPathname } from "../../../../../utils/capitalisePathname";
import { useTealium } from "../../../../common/TealiumProvider/TealiumProvider";

const useStyles = makeStyles(theme =>
  // @ts-ignore
  mergeDeep(theme.journey.progressTracker, {
    expandIcon: {
      marginLeft: "20px",
    },
    AccordionSummary: {
      padding: 0,
    },
  }),
);

const ProgressCategory: React.FC<ProgressCategoryProps> = ({
  categoryName,
  progressPercentage,
  skills,
}) => {
  const classes = useStyles();
  const { trackEvent } = useTealium();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  return (
    <div data-test="progress-category">
      <Accordion square elevation={0}>
        <AccordionSummary
          classes={{
            //@ts-ignore
            expandIcon: classes.expandIcon,
            //@ts-ignore
            root: classes.AccordionSummary,
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${categoryName}-content`}
          data-test={`${categoryName}-summary`}
          id={`${categoryName}-header`}
          onClick={() => {
            trackEvent({
              eventCategory: capitalizeEachWordOfPathname(pathname),
              eventAction: "Interaction",
              eventLabel: `${categoryName} - ${
                isOpen === false ? "Open" : "Closed"
              }`,
            });
            setIsOpen(!isOpen);
          }}
        >
          <Box flex={1}>
            <ProgressTrackBar {...{ progressPercentage, categoryName }} />
          </Box>
        </AccordionSummary>
        <AccordionDetails data-test={`${categoryName}-details`}>
          <Box flex={1}>
            {skills.map(skill => (
              <Skill key={skill.skillName} {...skill} />
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ProgressCategory;
