import React, { Fragment, FC } from "react";
import { CircularProgress, Theme } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import { makeStyles, useTheme } from "@material-ui/styles";
import mergeDeep from "lodash.merge";
import ContentWrapper from "../../../../common/ContentWrapper/ContentWrapper";
import InfoPanel from "../../../../common/InfoPanel/InfoPanel";
import TextField from "../../../../common/Form/TextField/TextField";
import SubmitButton from "../../../../common/SubmitButton/SubmitButton";
import { useBrand } from "../../../../common/BrandProvider/BrandProvider";
import { ReactComponent as BSMNudgeBackImage } from "../../../../../svg/bsm-nudge-back.svg";
import { ReactComponent as BSMBell } from "../../../../../svg/bsm-icon-bell.svg";
import { ReactComponent as AABell } from "../../../../../svg/aa-icon-bell.svg";
import {
  BSM_BRAND_NAME,
  GOOGLE_RECAPTCHA_SITE_KEY,
  SHOULD_ENABLE_RECAPTCHA,
} from "../../../../../constants";
import NudgeBackConfirmationModal from "./NudgeBackConfirmationModal";
import { FormNudgeBackProps } from "./types";

type CustomTheme = Theme & {
  journey: { prices: Record<string, any> };
  palette: {
    background: Record<string, any>;
  };
};

const useStyles = makeStyles((theme: CustomTheme) =>
  mergeDeep(theme.journey.prices, {
    alertInfo: {
      [theme.breakpoints.only("xs")]: {
        backgroundColor: theme.palette.background.dark,
      },
    },
    continueButton: {
      [`@media (min-width:${theme.breakpoints.values.xs}px)`]: {
        margin: "30px auto 10px",
      },
      [`@media (min-width:${theme.breakpoints.values.lg}px)`]: {
        margin: "50px auto 24px",
      },
    },
    grecaptchaBadge: {
      margin: "10px auto",
    },
  }),
);

const NudgeBack: FC<FormNudgeBackProps> = ({
  handleRecaptchaCheck,
  handleCreateNudgeBack,
  handleChange,
  handleFocus,
  isSubmitting,
  isValid,
  isDirty,
  postcode,
  transmissionType,
  mobileNumber,
  showSummaryModal,
  isCovidNudgeBack,
}) => {
  const { brand, getText } = useBrand();
  const classes = useStyles();
  const theme = useTheme<CustomTheme>();
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  return (
    <Fragment>
      <div className={classes.alertInfo}>
        <ContentWrapper fullHeight>
          <InfoPanel
            backgroundColor={classes.alert}
            svg={brand === BSM_BRAND_NAME ? <BSMNudgeBackImage /> : undefined}
            svgFlexAlignItems={theme.journey.prices.infoPanel.flex}
            title={
              isCovidNudgeBack
                ? getText("prices.covidAlert")
                : getText("prices.alert")
            }
            svgAlign="end"
          >
            {isCovidNudgeBack
              ? getText("prices.covidAlertBody")
              : getText("prices.alertBody")}
          </InfoPanel>
        </ContentWrapper>
      </div>
      <ContentWrapper fullHeight>
        <div className={classes.inputField}>
          <form
            onSubmit={
              SHOULD_ENABLE_RECAPTCHA
                ? event => handleRecaptchaCheck(event, recaptchaRef)
                : handleCreateNudgeBack
            }
            data-test="nudge-back-form"
          >
            <TextField
              data-test="mobile-number-field"
              label={getText("prices.alertMobileNumberLabel")}
              onChange={handleChange}
              onFocus={handleFocus}
              inputIconLeft="phone_iphone"
              fullWidth
              type="tel"
              disabled={isSubmitting}
              error={!isValid && isDirty}
              helperText={
                !isValid && isDirty ? getText("prices.alertInvalid") : ""
              }
            />
            <SubmitButton
              text={
                isSubmitting ? (
                  <CircularProgress size={16} color="inherit" />
                ) : (
                  getText("prices.createAlert")
                )
              }
              disabled={isSubmitting || !isValid || !isDirty}
              classes={{ root: classes.continueButton }}
              data-test="create-alert-button"
            />
          </form>
        </div>
        <InfoPanel
          svg={brand === BSM_BRAND_NAME ? <BSMBell /> : <AABell />}
          title={
            isCovidNudgeBack
              ? getText("prices.covidNotificationTitle")
              : getText("prices.notificationTitle")
          }
        >
          {isCovidNudgeBack
            ? getText("prices.covidNotificationContent")
            : getText("prices.notificationContent")}{" "}
          <a
            href={getText("personal-details.privacyNoticeUrl")}
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </a>
          .
        </InfoPanel>
        {SHOULD_ENABLE_RECAPTCHA && (
          <ReCAPTCHA
            className={classes.grecaptchaBadge}
            ref={recaptchaRef}
            size="invisible"
            badge="inline"
            sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
            onChange={handleCreateNudgeBack} // Only triggered on successful ReCaptcha result
          />
        )}
      </ContentWrapper>
      <NudgeBackConfirmationModal
        postcode={postcode}
        transmissionType={transmissionType}
        mobileNumber={mobileNumber}
        showModal={showSummaryModal}
      />
    </Fragment>
  );
};

export default NudgeBack;
