import React from "react";

import { string, func, instanceOf, number, bool } from "prop-types";

import { useBrand } from "../../common/BrandProvider/BrandProvider";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import LoginForm from "../../common/LoginForm";
import Titlebar from "../../common/Titlebar/Titlebar";

const Login = props => {
  const { getText } = useBrand();
  return (
    <ContentWrapper>
      <Titlebar headingProps={{ "data-test": "login-title-bar" }}>
        {getText("login.pageTitle")}
      </Titlebar>
      <LoginForm {...props} />
    </ContentWrapper>
  );
};

Login.propTypes = {
  pupilId: string,
  surname: string,
  dateOfBirth: instanceOf(Date),
  handlePupilIdChange: func,
  handleSurnameChange: func,
  handleDobChange: func,
  handleDobError: func,
  handleSubmit: func,
  loading: bool,
  hasInvalidLogin: bool,
  attemptsRemaining: number,
  hasDateOfBirthError: bool,
  getText: func,
};

export default Login;
