import { StepDefinition } from "../../StepDefinitions";
import { matchPath } from "react-router-dom";

export const getActiveStep = (
  steps: Partial<StepDefinition>[][],
  pathname: string,
): StepDefinition | undefined => {
  let activeStep;

  // This will keep looping through all the steps, even if it has already matched and potentially overwriting the previous match.
  // I'm unsure if this is by design, so leaving it as is for now
  steps.forEach(step => {
    const match = step.find(({ path }) => matchPath(pathname, { path }));

    if (match) {
      activeStep = match;
    }
  });

  return activeStep;
};

export const getActiveStepIndex = (
  steps: Partial<StepDefinition>[][],
  activeStep: Partial<StepDefinition> | undefined,
): number | undefined => {
  if (!activeStep) {
    return undefined;
  }

  const matchingStep = steps.find(step => step.includes(activeStep));
  if (!matchingStep) {
    return undefined;
  }

  return steps.indexOf(matchingStep);
};
