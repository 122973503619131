import React, { Fragment } from "react";
import upperFirst from "lodash.upperfirst";
import { makeStyles, Box, Theme } from "@material-ui/core";
import { format } from "date-fns";
import { TransmissionType } from "@graphql/types";

import Button from "../../../../common/Button/Button";
import { getInitials } from "../../../../../utils";
import { formatLessonDateTime } from "../../../../../utils/formatLessonDateTime";
import LessonDetailSection from "../../../../common/LessonDetails/LessonDetailSection";
import Modal from "../../../../common/Modal";
import { useBrand } from "../../../../common/BrandProvider/BrandProvider";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import { useTealium } from "../../../../common/TealiumProvider/TealiumProvider";

interface CustomTheme extends Theme {
  journey: any;
}

type Props = {
  showModal: boolean;
  toggleModal: () => void;
  onAccept: () => void;
  startTime: Date;
  duration: number;
  firstName: string;
  lastName: string;
  totalCost: number;
  hoursBooked: number;
  transmissionType: TransmissionType;
};

const useStyles = makeStyles<CustomTheme>(theme => theme.journey.lessonDetails);

const SummaryModal: React.FC<Props> = ({
  showModal = false,
  toggleModal = () => {},
  onAccept = () => {},
  startTime,
  duration,
  firstName,
  lastName,
  totalCost,
  hoursBooked = 2,
  transmissionType,
}) => {
  const { getText } = useBrand();
  const classes = useStyles();
  const gearbox = transmissionType
    ? upperFirst(transmissionType.toLowerCase())
    : "";
  const { trackEvent } = useTealium();

  const cancelModal = () => {
    trackEvent({
      eventCategory: "Personal Details",
      eventAction: "Button Click",
      eventLabel: "Cancel",
    });
    toggleModal();
  };

  const confirmModal = () => {
    trackEvent({
      eventCategory: "Personal Details",
      eventAction: "Button Click",
      eventLabel: "Got It",
    });
    onAccept();
  };

  return (
    <Modal
      showModal={showModal}
      toggleModal={toggleModal}
      title={getText("personal-details.summaryModalTitle")}
      content={
        <Fragment>
          {startTime && duration && !Number.isNaN(duration) && (
            <LessonDetailSection
              avatarText={format(startTime, "dd")}
              title={formatLessonDateTime(startTime)}
              subtitle={`${duration} ${getText("confirmation.hourLesson")}`}
              avatarColor={classes.primaryAvatar}
            />
          )}
          {firstName && lastName && (
            <LessonDetailSection
              avatarText={getInitials(firstName, lastName)}
              title={`${firstName} ${lastName}`}
              subtitle={`${gearbox} gearbox`}
              avatarColor={classes.secondaryAvatar}
              data-test="summary-modal-section-instructor"
            />
          )}
          {totalCost && (
            <LessonDetailSection
              avatarText="£"
              title={`£${formatCurrency(totalCost)}`}
              avatarColor={classes.costAvatar}
              subtitle={`Cost of initial ${hoursBooked} hours of lessons`}
            />
          )}
        </Fragment>
      }
      actions={
        <Fragment>
          <Box display="flex" flex="1" justifyContent="space-between" width={1}>
            <Button
              data-test="summary-modal-cancel-button"
              onClick={cancelModal}
              component="a"
              autoFocus
              secondary
              text="Cancel"
            />
            <Button
              data-test="summary-modal-accept-button"
              onClick={confirmModal}
              autoFocus
              text="Buy now"
            />
          </Box>
        </Fragment>
      }
    />
  );
};

export default SummaryModal;
