import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { string, func } from "prop-types";
import { BSM_BRAND_NAME } from "../../../../../constants";
import Button from "../../../../common/Button/Button";
import { getBookingUrl } from "../helpers";

const useStyles = makeStyles(theme => theme.journey.nujConfirmation);

const ConfirmationBodyBSM = ({
  topUpId,
  sessionId,
  handleOnClick = () => {},
}) => {
  const classes = useStyles();

  return (
    <div className={classes.bodyWrapper}>
      <Typography
        component="h3"
        variant="h6"
        classes={{ root: classes.titleText }}
        gutterBottom
      >
        Great that’s booked. Now you need to book your assessment lesson.
      </Typography>

      <div className={classes.buttonWrapper}>
        <Button
          text="Book more lessons"
          onClick={handleOnClick}
          data-test="snt-book-more-lessons"
        />
      </div>

      <Typography
        component="h4"
        variant="h6"
        classes={{ root: classes.titleText }}
        gutterBottom
      >
        So what happens next?
      </Typography>

      <Typography variant="body1">
        First off, good luck on your driving test!
      </Typography>

      <Typography variant="body1" gutterBottom>
        Your instructor will contact you before your lesson.
      </Typography>
      <br />

      <Typography variant="body1" gutterBottom>
        <strong>Your first lesson</strong>
      </Typography>

      <Typography
        variant="body1"
        component="div"
        gutterBottom
        classes={{ root: classes.bulletList }}
      >
        <ul>
          <li>Don’t forget your provisional licence</li>
          <li>
            If you need glasses, you must bring them. You will need them to
            drive
          </li>
          <li>Wear comfy, flat soled shoes</li>
          <li>Relax! You got this :-)</li>
        </ul>
      </Typography>
      <br />

      {topUpId && (
        <Fragment>
          <Typography
            variant="h6"
            component="h4"
            classes={{ root: classes.titleText }}
            gutterBottom
          >
            Other lessons
          </Typography>

          <Typography variant="body1" gutterBottom>
            You can{" "}
            <Typography
              variant="body1"
              component="a"
              href={getBookingUrl(BSM_BRAND_NAME, topUpId, sessionId)}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.shareLink}
              data-test="share-link"
            >
              book your other lessons before your test
            </Typography>
            . You will need to view your instructor’s diary and find some time
            to book your remaining lessons in.
          </Typography>
        </Fragment>
      )}

      <Typography
        classes={{ root: classes.titleText }}
        variant="h6"
        component="h4"
      >
        Enjoy your lesson ( •ᴗ•)
      </Typography>
    </div>
  );
};

ConfirmationBodyBSM.propTypes = {
  topUpId: string,
  sessionId: string.isRequired,
  handleOnClick: func,
};

export default ConfirmationBodyBSM;
