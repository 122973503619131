import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useStateContext } from "../../../../context/stateContext";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import {
  changeTransmissionType,
  isCrossSellQueryString,
  submitPricesForm,
} from "./helpers";
import Prices from "./Prices";
import { goBack } from "../../../../utils/navigation";
import { useTealium } from "../../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../../utils/capitalisePathname";
import { TransmissionType } from "@graphql/types";
import { StateContext } from "../../../../models/state";
import useQueryString from "../../../../hooks/useQueryString";
import useCrossSellHandover from "../../../../hooks/useCrossSellHandover";
import { LESSON_TYPES } from "../../../../constants";

const getTransmissionTabs = (getText: (_text: string) => string) => [
  {
    id: TransmissionType.Manual,
    title: getText("prices.manual"),
  },
  {
    id: TransmissionType.Automatic,
    title: getText("prices.automatic"),
  },
];

interface Props {
  nudgeBackEnabled: boolean;
}

const NewUserJourneyPricesStep: FC<Props> = ({ nudgeBackEnabled }) => {
  const {
    state: {
      nujHistory: {
        selectedTransmissionType: transmissionTypeFromContext,
        selectedInstructor,
        selectedPackage,
        selectedInstructorHistory,
        availableInstructorsLoaded,
        shortNoticeTestDateTime,
      },
    },
    dispatch,
  }: StateContext = useStateContext();

  const history = useHistory();
  const { search, pathname } = useLocation();
  const { trackEvent, updateDataLayer } = useTealium();

  const { getText } = useBrand();

  const transmissionTabs = getTransmissionTabs(getText);

  const handleTransmissionChange = (transmissionType: TransmissionType) => {
    changeTransmissionType({
      dispatch,
      selectedInstructorHistory,
      transmissionType,
      transmissionTypeFromContext,
      selectedInstructor,
    });
  };

  const queryString = useQueryString(search);

  const {
    postcode = "",
    tT = "",
    lT = LESSON_TYPES.LEARNER,
    tC = "",
    ins: crossSellInstructorId,
  } = queryString;

  const [isCrossSellModalOpen, setCrossSellModalOpen] = useState(
    isCrossSellQueryString({
      postcode,
      tT,
      lT,
      tC,
    }),
  );

  const closeCrossSellModal = () => {
    setCrossSellModalOpen(false);
  };

  useEffect(() => {
    useCrossSellHandover({
      onTransmissionChange: handleTransmissionChange,
      dispatch,
      queryString,
      selectedTransmissionType: transmissionTypeFromContext,
      isCrossSell: isCrossSellModalOpen,
    });
  }, []);

  const [isContinueButtonVisible, setContinueButtonVisible] = useState(false);

  const handleContinueButtonClicked = (event: Event) => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Button Click",
      eventLabel: "Continue",
    });
    updateDataLayer({
      transmission_type: transmissionTypeFromContext,
      rate: selectedPackage?.standardHourlyPrice?.toString(),
      package_hours: selectedPackage?.hours.toString(),
      selected_instructor_id: selectedInstructor?.instructor.id,
    });
    submitPricesForm({ shortNoticeTestDateTime, event, history, dispatch });
  };

  const handleBackClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    goBack({ history, pathname });
  };

  const handleSwipe = (index: number) => {
    const transmissionType = transmissionTabs[index]
      ? transmissionTabs[index].id
      : null;

    if (transmissionType && transmissionType !== transmissionTypeFromContext) {
      handleTransmissionChange(transmissionType);
    }
  };

  const currentlySelectedTabIndex = transmissionTabs.findIndex(
    tab => tab.id === transmissionTypeFromContext,
  );

  return (
    <Prices
      onBackClick={handleBackClick}
      onSubmit={handleContinueButtonClicked}
      onSwipe={handleSwipe}
      onTransmissionChange={handleTransmissionChange}
      selectedTabIndex={currentlySelectedTabIndex}
      selectedTransmissionType={transmissionTypeFromContext}
      setContinueButtonVisible={setContinueButtonVisible}
      showContinueButton={isContinueButtonVisible}
      transmissionTabItems={transmissionTabs}
      availableInstructorsLoaded={availableInstructorsLoaded}
      isCrossSellModalOpen={isCrossSellModalOpen}
      closeCrossSellModal={closeCrossSellModal}
      crossSellInstructorId={crossSellInstructorId}
      nudgeBackEnabled={nudgeBackEnabled}
    />
  );
};

export default NewUserJourneyPricesStep;
