import React, { FC, ReactNode } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme, useMediaQuery } from "@material-ui/core";
import mergeDeep from "lodash.merge";
import get from "lodash.get";

import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import ContentWrapper from "../../../common/ContentWrapper/ContentWrapper";
import palette from "../../../../theme/palette-aad";
import InstructorPicker from "../../../common/Form/InstructorPicker/InstructorPicker";
import useToQueryString from "../../../../hooks/useToQueryString";
import { useTealium } from "../../../common/TealiumProvider/TealiumProvider";

import BSM from "../../../common/Form/InstructorPicker/Avatar/BSM";
import AA from "../../../common/Form/InstructorPicker/Avatar/AA";
import InfoPanel from "../../../common/InfoPanel/InfoPanel";
import { ReactComponent as BSMPreHandoverImage } from "../../../../svg/bsm-infopanel-pre-handover.svg";
import { ReactComponent as AAPreHandoverImage } from "../../../../svg/icon-search.svg";

import {
  AA_BRAND_NAME,
  BSM_BRAND_NAME,
  getJourneyURL,
  NEW_USER_JOURNEY_PATH_NAMES,
  SESSION_ID_PARAM,
} from "../../../../constants";
import { Instructor } from "models/state";
import { Brand } from "@graphql/types";

interface CustomTheme extends Theme {
  journey: any;
}

const useStyles = makeStyles<CustomTheme>(theme =>
  mergeDeep(theme.journey.prices, {
    crossSellSubHeading: {
      [theme.breakpoints.only("xs")]: {
        backgroundColor: palette.background.dark,
      },
    },
    subHeadingText: {
      lineHeight: "24px",
      [`@media (min-width:${theme.breakpoints.values.md}px)`]: {
        padding: 0,
      },
    },
    svg: {
      margin: "-1em -0.5em 0 -2em",
    },
  }),
);

const getAvatarForBrand = ({ brand }: { brand: Brand }): ReactNode => {
  if (brand === BSM_BRAND_NAME) return <BSM />;
  if (brand === AA_BRAND_NAME) return <AA />;
  return Error("Cannot find avatar for brand");
};

interface Props {
  instructors: Instructor[];
  postcode: string;
  referrer: string;
  transmissionType: string;
  crossSellBrand: Brand;
  sessionId: string;
  lessonType: string;
  testCentre: string | null;
}

const CrossSellInstructors: FC<Props> = ({
  instructors,
  postcode,
  referrer,
  transmissionType,
  crossSellBrand: brand,
  sessionId,
  lessonType,
  testCentre,
}) => {
  const { getText } = useBrand();
  const classes: any = useStyles();
  const theme = useTheme<CustomTheme>();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const avatar = getAvatarForBrand({ brand });
  const { trackEvent } = useTealium();

  return (
    <ContentWrapper fullWidth fullHeight>
      <div
        className={classes.crossSellSubHeading}
        data-test="cross-sell-instructors"
      >
        <ContentWrapper fullHeight>
          <InfoPanel
            svg={
              referrer === BSM_BRAND_NAME ? (
                <BSMPreHandoverImage />
              ) : (
                <AAPreHandoverImage />
              )
            }
            svgClasses={classes.svg}
            svgFlexAlignItems={theme.journey.prices.infoPanel.flex}
            title={getText("prices.crossSellInfoPanelTitle", {
              transmissionType: transmissionType.toLowerCase(),
            })}
            variant
          >
            {getText("prices.crossSellInfoPanelSummary")}
          </InfoPanel>
          <div className={classes.subHeadingText}>
            {getText("prices.crossSellHeading")}
          </div>
        </ContentWrapper>
      </div>
      <ContentWrapper fullHeight fullWidth={isMobile}>
        <InstructorPicker
          instructors={instructors.map(instructor => ({
            ...get(instructor, "instructor"),
            hourlyRate: get(instructor?.prices?.[0], "standardHourlyPrice"),
            avatar,
            selectedAvatar: avatar,
          }))}
          value="" // just to satisfy TS
          onChange={(instructorId: string) => {
            const params = {
              postcode,
              ins: instructorId,
              tT: transmissionType,
              lT: lessonType,
              tC: testCentre,
              [SESSION_ID_PARAM]: sessionId,
            };
            const queryString = useToQueryString(params);

            trackEvent({
              eventCategory: "Prices",
              eventAction: "Link Click",
              eventLabel: `Cross Sell - ${brand}`,
            });

            window.open(
              `${getJourneyURL(brand)}${
                NEW_USER_JOURNEY_PATH_NAMES.PRICES
              }${queryString}`,
              "_blank",
            );
          }}
        />
      </ContentWrapper>
    </ContentWrapper>
  );
};

export default CrossSellInstructors;
