import React, { useEffect } from "react";

import { useBrand } from "../../../../common/BrandProvider/BrandProvider";
import { useTealium } from "../../../../common/TealiumProvider/TealiumProvider";
import { useStateContext } from "../../../../../context/stateContext";
import useGetInstructors from "../../../../../hooks/useGetInstructors";

import CrossSellModal from "./CrossSellModal";
import { CrossSellModalStateProviderProps } from "./types";

import { StateContext } from "@lessons/models";
import { LessonType, MarketingChannel } from "@graphql/types";
import { mapLessonTypeToLearnerLessonType } from "../helpers";
const StateProvider: React.FC<CrossSellModalStateProviderProps> = ({
  currentBrand,
  classes,
  crossSellInstructorId,
  closeCrossSellModal,
}) => {
  const { brand, getText } = useBrand();
  const { trackEvent } = useTealium();
  const {
    state: {
      nujHistory: {
        pickupPostcode: pickupPostcodeFromContext,
        selectedInstructor: selectedInstructorFromContext,
        selectedLessonType: selectedLessonTypeFromContext,
        selectedTransmissionType: transmissionTypeFromContext,
        marketingChannel: marketingChannelFromContext,
        marketingChannelId: marketingChannelIdFromContext,
      },
    },
    dispatch,
  }: StateContext = useStateContext();

  const { loading: instructorsLoading, data } = useGetInstructors({
    brand,
    postcode: pickupPostcodeFromContext,
    transmission: transmissionTypeFromContext,
    selectedInstructor: selectedInstructorFromContext,
    lessonType: mapLessonTypeToLearnerLessonType(selectedLessonTypeFromContext),
    lessonTypeDL: selectedLessonTypeFromContext,
    dispatch,
    crossSellInstructorId,
    trackEvent,
    marketingChannel: marketingChannelFromContext as MarketingChannel,
    marketingChannelId: marketingChannelIdFromContext,
    lessonLengthHours: 2,
    offersShortNoticeTest:
      selectedLessonTypeFromContext === LessonType.ShortNoticeTest,
    packageLengthsInHours:
      selectedLessonTypeFromContext === LessonType.ShortNoticeTest
        ? [6]
        : [2, 6, 10],
  });

  useEffect(() => {
    if (!instructorsLoading && data) {
      closeCrossSellModal();
    }
  }, [instructorsLoading]);

  return (
    <CrossSellModal
      currentBrand={currentBrand}
      classes={classes}
      title={`${getText("prices.crossSellHandoverTitle")}...`}
    />
  );
};

export default StateProvider;
