import React, { useState } from "react";
import { string, func, bool, number, oneOfType, instanceOf } from "prop-types";
import { withStyles, Typography, CircularProgress } from "@material-ui/core";
import DobPicker from "../Form/DobPicker/DobPicker";
import TextField from "../Form/TextField/TextField";
import SubmitButton from "../SubmitButton/SubmitButton";

import { useBrand } from "../BrandProvider/BrandProvider";

import { useTealium } from "../TealiumProvider/TealiumProvider";

const validatePupilIdLength = pupilId =>
  pupilId.length >= 6 && pupilId.length <= 9;

const styles = theme => ({
  loginForm: {
    paddingBottom: "30px",
  },
  fieldWrapper: {
    marginTop: "30px",
    [theme.breakpoints.up("md")]: {
      marginTop: "40px",
    },
  },
  pupilIdFieldWrapper: {
    marginBottom: "-10px",
  },
  errorMessage: {
    marginTop: "30px",
    fontWeight: theme.typography.fontWeightMedium,
  },
});

const LoginForm = ({
  classes,
  pupilId,
  surname,
  dateOfBirth,
  handlePupilIdChange,
  handleSurnameChange,
  handleDobChange,
  handleDobError,
  handleSubmit,
  loading,
  hasInvalidLogin,
  attemptsRemaining,
  hasDateOfBirthError,
  showPupilIdField = true,
  buttonText = "login.buttonText",
}) => {
  const { getText } = useBrand();
  const { trackEvent } = useTealium();
  const [fieldsTouched, setFieldsTouched] = useState({});

  return (
    <form onSubmit={handleSubmit} className={classes.loginForm}>
      {showPupilIdField && (
        <div className={classes.pupilIdFieldWrapper}>
          <TextField
            label={getText("login.pupilIdLabel")}
            helperText={getText("login.pupilIdHelperText")}
            inputIconLeft="face"
            placeholder={getText("login.pupilIdPlaceholder")}
            value={pupilId}
            name="pupilId"
            onChange={e => {
              if (e.target.value === "" || /^[0-9\b]+$/.test(e.target.value)) {
                handlePupilIdChange(e);
              }
            }}
            fullWidth
            error={hasInvalidLogin}
            data-test="pupil-id-input"
            className={classes.pupilIdField}
            inputProps={{
              inputMode: "decimal",
            }}
            onClick={() => {
              if (!fieldsTouched.pupilId) {
                trackEvent({
                  eventCategory: "Login",
                  eventAction: "Interaction",
                  eventLabel: "Pupil ID",
                });
                setFieldsTouched({ ...fieldsTouched, pupilId: true });
              }
            }}
          />
        </div>
      )}

      <div className={classes.fieldWrapper}>
        <TextField
          label={getText("login.surnameLabel")}
          inputIconLeft="face"
          placeholder={getText("login.surnamePlaceholder")}
          value={surname}
          name="surname"
          onChange={handleSurnameChange}
          fullWidth
          error={hasInvalidLogin}
          data-test="surname-input"
          onClick={() => {
            if (!fieldsTouched.surname) {
              trackEvent({
                eventCategory: "Login",
                eventAction: "Interaction",
                eventLabel: "Surname",
              });
              setFieldsTouched({ ...fieldsTouched, surname: true });
            }
          }}
        />
      </div>

      <div className={classes.fieldWrapper}>
        <DobPicker
          onError={handleDobError}
          onChange={handleDobChange}
          minimumAgeRequired={false}
          fullWidth
          disabled={false}
          label={getText("login.dobLabel")}
          value={dateOfBirth}
          name="dateOfBirth"
          error={hasInvalidLogin}
          data-test="pupil-dob-input"
          onClick={() => {
            if (!fieldsTouched.dateOfBirth) {
              trackEvent({
                eventCategory: "Login",
                eventAction: "Interaction",
                eventLabel: "DOB",
              });
              setFieldsTouched({ ...fieldsTouched, dateOfBirth: true });
            }
          }}
        />
      </div>

      {hasInvalidLogin && (
        <Typography
          data-test="attempts-count-text"
          classes={{ root: classes.errorMessage }}
          color="error"
        >
          {attemptsRemaining
            ? getText("login.attemptsRemaining", { attemptsRemaining })
            : getText("login.validationError")}
        </Typography>
      )}

      <SubmitButton
        text={
          loading ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            getText(buttonText)
          )
        }
        disabled={
          loading ||
          hasDateOfBirthError ||
          (showPupilIdField && !pupilId) ||
          !dateOfBirth ||
          (pupilId && !validatePupilIdLength(pupilId))
        }
        data-test="submit-button"
        onClick={() => {
          trackEvent({
            eventCategory: "Login",
            eventAction: "Button Click",
            eventLabel: "Login",
          });
        }}
      />
    </form>
  );
};

LoginForm.propTypes = {
  pupilId: string,
  surname: string,
  dateOfBirth: oneOfType([instanceOf(Date), string]),
  handlePupilIdChange: func,
  handleSurnameChange: func,
  handleDobChange: func,
  handleDobError: func,
  handleSubmit: func,
  loading: bool,
  hasInvalidLogin: bool,
  attemptsRemaining: number,
  hasDateOfBirthError: bool,
  showPupilIdField: bool,
  buttonText: string,
};

export default withStyles(styles)(LoginForm);
