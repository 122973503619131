import { KeyboardArrowDownOutlined } from "@material-ui/icons";
import { OutlinedInput } from "@material-ui/core";
import merge from "lodash.merge";
import journey from "./journey-aad";
import palette from "./palette-aad";
import breakpoints from "./breakpoints";

import aaYellowTriangle from "../svg/aa-yellow-triangle.svg";

const aadFontFamily = ["New Transport AA"];

const aadTheme = {
  palette,
  breakpoints,
  boxShadow: "0",
  typography: {
    fontFamily: `${aadFontFamily}, Arial, sans-serif`,
    fontStyle: "SemiBold",
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    allVariants: {
      color: palette.common.black,
    },
    h1: {
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "28px",
      letterSpacing: "0.3px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        fontSize: "24px",
      },
    },
    h2: {
      fontSize: "26px",
      lineHeight: "30px",
    },
    h3: {
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
    },
    // Label heading in design system
    h4: {
      fontSize: "16px",
      lineHeight: "27px",
    },
    body1: {
      fontSize: "18px",
      lineHeight: "27px",
    },
    body2: {
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
    },
    MuiSelect: {
      IconComponent: KeyboardArrowDownOutlined,
    },
  },
  journey,
  overrides: {
    MuiAppBar: {
      root: {
        height: "120px",
        padding: "0 16px 0 0",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "none",
        overflow: "hidden",
        marginBottom: "-30px;",
        background: `transparent url(${aaYellowTriangle}) no-repeat`,
      },
      colorPrimary: {
        backgroundColor: "transparent",
      },
    },
    MuiButton: {
      root: {
        minWidth: "140px",
        padding: "15px 32px",
        "&$disabled": {
          backgroundColor: "#C7C8CA",
        },
      },
      text: {
        padding: "15px 16px",
        lineHeight: "1em",
        minWidth: "0",
      },
      contained: {
        "&$disabled": {
          color: "#727375",
          backgroundColor: "#C7C8CA",
        },
        "&:active": {
          boxShadow: "none",
        },
        "&:hover": {
          boxShadow: "none",
        },
        "&$focusVisible": {
          boxShadow: "none",
        },
      },
      containedPrimary: {
        boxShadow: "none",
        backgroundColor: "#FFCC00",
        "&:hover": {
          backgroundColor: "#FFD800",
        },
        "&:focus": {
          backgroundColor: "#FFD800",
        },
      },
      label: {
        textTransform: "none",
        fontSize: "1.1286rem",
        lineHeight: "1em",
      },
      textPrimary: {
        color: palette.secondary.main,
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
        opacity: "0.7",
      },
    },
    MuiInput: {
      root: {
        color: "#2E2E36",
        fontWeight: 600,
        fontFamily: aadFontFamily,
      },
    },
    MuiInputBase: {
      input: {
        lineHeight: "1em",
      },
    },
    MuiSelect: {
      icon: {
        right: "10px",
        color: palette.grey[500],
      },
    },
    MuiInputLabel: {
      root: {
        color: "#2E2E36",
        marginBottom: "10px",
      },
      shrink: {
        transform: "none",
      },
      formControl: {
        position: "relative",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#2E2E36",
        position: "relative",
        fontSize: "0.9375rem",
        "&$focused": {
          color: "#2E2E36",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: "3px",
        color: "#2E2E36",
        lineHeight: "1.5em",
        fontSize: "0.875rem",

        "&$error": {
          color: "#DA0076",
        },

        "&$contained": {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: "#EEEEEE",
        "&$checked": {
          color: palette.primary.main,
        },
        "&$indeterminate": {
          color: palette.primary.main,
        },
      },
    },
    MuiRadio: {
      root: {
        fontFamily: aadFontFamily,
      },
      colorPrimary: {
        "&:hover": {
          borderColor: "#ADAEB0",
        },
        "&$checked": {
          "&:hover": {
            backgroundColor: "white",
            borderColor: "#FFCC00",
          },
        },
      },
    },
    MuiInputAdornment: {
      positionStart: {
        display: "none",
      },
    },
    MuiBackdrop: {
      root: {
        background: "linear-gradient(0deg, #FFD300, #FFD300)",
        opacity: "0.9 !important",
        [`@media(min-width:${breakpoints.values.md}px)`]: {
          width: "75%",
          left: "auto",
        },
      },
    },
    MuiDialog: {
      root: {
        [`@media(min-width:${breakpoints.values.md}px)`]: {
          width: "75%",
          left: "auto !important",
        },
      },
      paperWidthXs: {
        maxWidth: "340px",
      },
      paperWidthSm: {
        maxWidth: "486px",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": { backgroundColor: "transparent" },
        "&$selected:hover": {
          backgroundColor: "transparent",
          cursor: "default",
        },
      },
    },
    MuiLink: {
      root: {
        color: palette.secondary.main,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#FFCC00",
      },
    },
    MuiTypography: {
      root: {
        color: "#2E2E36",
      },
      body1: {
        fontSize: "1rem",
        lineHeight: "1.3em",
        [`@media(min-width:${breakpoints.values.md}px)`]: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiAvatar: {
      root: {
        background: "#FFCC00",
      },
      colorDefault: {
        backgroundColor: "transparent",
      },
    },
    MuiOutlinedInput: {
      root: {
        "&$focused": {
          backgroundColor: "#FFFCF2",
        },
        "&$error": {
          backgroundColor: "#FFF7FB",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#ADAEB0",
        },
        "&$error $notchedOutline": {
          borderColor: "#DA0076",
        },
      },
      input: {
        padding: "16px 14px",
      },
    },
    MuiList: {
      root: {
        padding: "0",
      },
    },
    MuiListItemIcon: {
      root: {
        color: "#1D1D1D",
        minWidth: "30px",
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: 0,
      },
      colorPrimary: {
        transition: "all .1s ease-in-out",
        "&:hover": {
          backgroundColor: "white",
        },
        "&.Mui-checked": {
          backgroundColor: "white",
          borderColor: "#FFCC00",
        },
      },
    },
    MuiRating: {
      iconFilled: {
        color: palette.secondary.main,
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
      },
      barColorPrimary: {
        backgroundColor: palette.secondary.main,
      },
    },
  },
  SelectInput: OutlinedInput,
};

export default (overrides = {}) => merge(aadTheme, overrides);
