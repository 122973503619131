import React from "react";
import { number, bool } from "prop-types";
import { makeStyles } from "@material-ui/core";
import mergeDeep from "lodash.merge";
import { formatCurrency } from "../../../../utils/formatCurrency";

const useStyles = makeStyles(theme =>
  mergeDeep(theme.journey.lessonPackageText, {
    contentWrap: {
      position: "relative",
      minWidth: "75px",
      width: "100%",
      "@media(min-width: 360px)": {
        minWidth: "90px",
      },
    },
    numLessons: {
      color: theme.palette.grey[400],
      lineHeight: "1em",
      fontSize: "2.7rem",
      margin: "0",
      "& span": {
        display: "block",
        lineHeight: "1em",
        color: theme.palette.grey[400],
        fontSize: "0.9rem",
        marginTop: "5px",
      },
    },
    price: {
      fontWeight: "600",
      color: theme.palette.grey[400],
      fontSize: "1.3rem",
      margin: "10px 0 25px 0",
      "@media(max-width:360px)": {
        fontSize: "16px",
      },
      "&.--discount": {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
      },
      "& span": {
        color: theme.palette.grey[500],
        fontSize: "0.7em",
        fontWeight: theme.typography.fontWeightRegular,
        textDecoration: "line-through",
        margin: "0 0 -1px 7px",
      },
    },
    priceSaving: {
      position: "absolute",
      bottom: "6px",
      fontSize: "0.9rem",
      textAlign: "center",
      width: "100%",
      color: theme.palette.grey[400],
    },
  }),
);

const FormLessonPackageText = ({
  discountedPackagePrice,
  hours,
  savingOnPackagePrice,
  standardPackagePrice,
  checked,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.contentWrap}>
      <p
        className={
          checked ? `${classes.numLessons} --checked` : classes.numLessons
        }
        data-test="lesson-package-hours-text"
      >
        {hours}
        <span>Hours</span>
      </p>

      {savingOnPackagePrice > 0 ? (
        <>
          <p className={`${classes.price} --discount`}>
            {`£${formatCurrency(discountedPackagePrice)}`}
            <span>{`£${formatCurrency(standardPackagePrice)}`}</span>
          </p>
          <p className={classes.priceSaving}>
            {`Save £${formatCurrency(savingOnPackagePrice)}`}
          </p>
        </>
      ) : (
        <p className={classes.price}>
          {`£${formatCurrency(standardPackagePrice)}`}
        </p>
      )}
    </div>
  );
};

FormLessonPackageText.propTypes = {
  hours: number.isRequired,
  standardPackagePrice: number.isRequired,
  discountedPackagePrice: number,
  savingOnPackagePrice: number.isRequired,
  checked: bool,
};

FormLessonPackageText.defaultProps = {
  discountedPrice: undefined,
  checked: false,
};

export default FormLessonPackageText;
