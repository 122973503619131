import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles, withTheme } from "@material-ui/styles";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import ContentWrapper from "../../../common/ContentWrapper/ContentWrapper";

const useStyles = makeStyles(theme => ({
  noInstructorsImage: {
    alignSelf: "center",
    width: "100%",
    maxWidth: "300px",
    transform: "translateY(25px)",
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  bodyWrapper: {
    width: "calc(100% - 60px)",
    margin: "20px auto 0",
  },
  base: {
    background: "linear-gradient(180deg, #E8E3ED 0%, #FFFFFF 100%)",
    width: "100%",
    height: "100px",
  },
}));

const PricesNoInstructors = ({ theme, pickupPostcode }) => {
  const { getText } = useBrand();
  const classes = useStyles();
  return (
    <Fragment>
      <ContentWrapper fullHeight>
        <div className={classes.bodyWrapper} data-test="no-instructors">
          <Typography gutterBottom className={classes.title}>
            {getText("prices.noInstructorsHeading")}
          </Typography>
          <Typography gutterBottom>
            {getText("prices.noInstructorsLinkPrefix")}
            <a href={getText("prices.noInstructorsLink", { pickupPostcode })}>
              {getText("prices.noInstructorsLinkText")}
            </a>
            {getText("prices.noInstructorLinkSuffix")}
          </Typography>
        </div>
        {theme.journey.noInstructors && theme.journey.noInstructors.image && (
          <img
            src={theme.journey.noInstructors.image}
            className={classes.noInstructorsImage}
            alt=""
            data-test="header-image"
          />
        )}
      </ContentWrapper>
      {theme.journey.noInstructors && theme.journey.noInstructors.image && (
        <div className={classes.base} />
      )}
    </Fragment>
  );
};

export default withTheme(PricesNoInstructors);
