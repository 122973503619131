import React from "react";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import Success from "../../common/Success/Success";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import { RETURNING_USER_JOURNEY_PATH_NAMES } from "../../../constants";

const formatDate = (date: Date) =>
  format(new Date(date), "iiii do MMMM 'at' h:mmaa");

type CancelLessonConfirmationProps = {
  lessonTime: Date;
};

const CancelLessonConfirmation: React.FC<CancelLessonConfirmationProps> = ({
  lessonTime,
}) => {
  const { getText } = useBrand();
  const history = useHistory();

  return (
    <Success
      testId="cancel-lesson-confirmation"
      title={getText("returning/cancel-lesson/confirmation.title")}
      buttonText={getText("returning/cancel-lesson/confirmation.buttonText")}
      handleButtonClick={() =>
        history.push(RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT)
      }
    >
      {getText("returning/cancel-lesson/confirmation.summary", {
        date: formatDate(lessonTime),
      })}
    </Success>
  );
};
export default CancelLessonConfirmation;
