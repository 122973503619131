import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import mergeDeep from "lodash.merge";
import ContentWrapper from "../../../common/ContentWrapper/ContentWrapper";
import ContinueButton from "../../../common/ContinueButton/ContinueButton";
import Titlebar from "../../../common/Titlebar/Titlebar";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import { useTealium } from "../../../common/TealiumProvider/TealiumProvider";
import { goBack } from "../../../../utils/navigation";
import { capitalizeEachWordOfPathname } from "../../../../utils/capitalisePathname";

const useStyles = makeStyles(theme =>
  mergeDeep(theme.journey.callToComplete, {
    bodyText: {
      fontWeight: 300,
      margin: "20px 30px 0",
      fontSize: "1.125rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "1.25rem",
      },
    },
  }),
);

const NewUserJourneyStepsCallToComplete = ({ type }) => {
  const { getText, callToCompleteHref } = useBrand();
  const classes = useStyles();
  const theme = useTheme();
  const { trackEvent } = useTealium();
  const history = useHistory();
  const { pathname } = useLocation();

  const handleBackButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    goBack({ history, pathname });
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerWrapper} data-test="header-wrapper">
        <ContentWrapper>
          <Titlebar
            showBackButton
            onBackClick={handleBackButtonClick}
            data-test="back-button"
          >
            {getText("call-to-complete-booking.pageTitle")}
          </Titlebar>
          {theme.journey.callToComplete[type] && (
            <img
              src={theme.journey.callToComplete[type]}
              className={classes.image}
              alt="Call to complete your booking"
              data-test="header-image"
            />
          )}
        </ContentWrapper>
      </div>

      <ContentWrapper fullHeight>
        <Typography component="p" variant="body2" className={classes.bodyText}>
          {getText(`call-to-complete-booking.${type}`)}
        </Typography>
        <ContinueButton
          href={callToCompleteHref || getText("common.chatPage")}
          text={getText("call-to-complete-booking.callButtonText")}
          className={classes.callButton}
          data-test="call-button"
          onClick={() =>
            trackEvent({
              eventCategory: "Call Button",
              eventAction: "click",
            })
          }
        />
      </ContentWrapper>
    </div>
  );
};

export default NewUserJourneyStepsCallToComplete;
