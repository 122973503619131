import React, { Fragment } from "react";
import { useMediaQuery, Button, Typography, useTheme } from "@material-ui/core";
import { useHistory, withRouter } from "react-router-dom";

import { useTealium } from "../common/TealiumProvider/TealiumProvider";
import {
  getJourneyExitURL,
  RETURNING_USER_JOURNEY_PATH_NAMES,
} from "../../constants";
import { useBrand } from "../common/BrandProvider/BrandProvider";
import AppTopbar from "../common/AppTopbar/AppTopbar";
import AppSidebar from "../common/AppSidebar/AppSidebar";
import AccountButton from "../common/AccountButton/AccountButton";
import ExitButton from "../common/ExitButton/ExitButton";
import { goBack } from "../../utils/navigation";
import Modal from "../common/Modal";
import { capitalizeEachWordOfPathname } from "../../utils/capitalisePathname";
import { capitalize } from "../../utils";
import { getActiveStep, getActiveStepIndex } from "./helpers";
import { AppBarProps } from "./types";
import { useStateContext } from "../../context/stateContext";
import { useQueryParams } from "@utils";

const actionButtons = {
  AccountButton: AccountButton,
  ExitButton: ExitButton,
};

const AppBar: React.FC<AppBarProps> = ({
  steps = [],
  location: { pathname },
  history,
}) => {
  const [open, setOpen] = React.useState(false);
  const { trackEvent } = useTealium();
  const { push } = useHistory();
  const param = useQueryParams();
  const isFromTheoryApp = param?.referrer === "theory-app";
  const isInternalLink = param?.referrer === "ruj";

  const shouldNavigateToNUJ = !isInternalLink || isFromTheoryApp;

  const {
    state: {
      rujHistory: { personId },
      isQTUJourney,
    },
  } = useStateContext();

  let HeaderActionButton: React.FC | undefined = undefined;
  let action = "";

  const toggleConfirmDialog = () => {
    setOpen(!open);
    if (!open) {
      trackEvent({
        eventCategory: capitalizeEachWordOfPathname(pathname),
        eventAction: "Link Click",
        eventLabel: action === "exit" ? "Exit Modal" : "Account Modal",
      });
    }
  };
  const { brand } = useBrand();
  const theme = useTheme();
  // This is counter-intuitive and smells like a bug in MUI. This may "break" if
  // they fix this in the future.
  const isSmallScreen = !useMediaQuery(theme.breakpoints.up("md"));
  const activeStep = getActiveStep(steps, pathname);
  const activeStepIndex = getActiveStepIndex(steps, activeStep);

  if (activeStep?.headerActionButton && !isQTUJourney) {
    HeaderActionButton = actionButtons[activeStep.headerActionButton];
    action =
      activeStep.headerActionButton === "ExitButton" ? "exit" : "account";
  }

  const actionHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    toggleConfirmDialog();
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: capitalize(action),
    });
    if (action === "exit") {
      window.location.assign(getJourneyExitURL(brand));
      return;
    }
    push(
      personId
        ? `${RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT}`
        : RETURNING_USER_JOURNEY_PATH_NAMES.LOGIN,
    );
  };

  const handleBackButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    goBack({ history, pathname });
  };

  return (
    <Fragment>
      {isSmallScreen ? (
        <AppTopbar
          stepsLength={steps.length}
          activeStep={activeStepIndex}
          hideProgressBar={activeStep ? activeStep.hideProgressBar : true}
          onActionClick={toggleConfirmDialog}
          onBackClick={handleBackButtonClick}
          hideBackButton={
            shouldNavigateToNUJ ||
            (activeStep ? activeStep.hideBackButton : false)
          }
          ActionButton={isFromTheoryApp ? undefined : HeaderActionButton}
        />
      ) : (
        <AppSidebar
          stepsLength={steps.length}
          activeStep={activeStepIndex}
          hideProgressBar={activeStep ? activeStep.hideProgressBar : true}
          onActionClick={toggleConfirmDialog}
          ActionButton={isFromTheoryApp ? undefined : HeaderActionButton}
        />
      )}
      <Modal
        showModal={open}
        toggleModal={toggleConfirmDialog}
        title={action === "exit" ? "Exit?" : "Go to the Account page?"}
        content={
          <Typography>
            Are you sure you want to{" "}
            {action === "exit" ? "exit" : "go to the Account page"}? All
            progress will lost.
          </Typography>
        }
        actions={
          <Fragment>
            <Button onClick={toggleConfirmDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={actionHandler}
              color="primary"
              autoFocus
              data-test="appbar-action-confirm-button"
            >
              Ok
            </Button>
          </Fragment>
        }
      />
    </Fragment>
  );
};

export default withRouter(AppBar);
