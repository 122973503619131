import React from "react";
import { MenuItem } from "@material-ui/core";
import Select from "../../common/Form/Select/Select";

const REASONS = {
  ILLNESS: "Illness",
  SELF_ISOLATING: "SelfIsolating",
  WORK: "Work",
  HOLIDAY: "Holiday",
  COMPLAINT: "Complaint",
  COST: "Cost",
  STOPPED_LESSONS: "StoppedLessons",
  BEREAVEMENT: "Bereavement",
  WEATHER: "Weather",
  CHILDCARE: "Childcare",
  TIME_NOT_SUITABLE: "TimeNotSuitable",
};

const MenuItems = [
  { name: "Illness", value: REASONS.ILLNESS },
  { name: "Self-isolating", value: REASONS.SELF_ISOLATING },
  { name: "Work commitments", value: REASONS.WORK },
  { name: "Holiday", value: REASONS.HOLIDAY },
  { name: "Complaint", value: REASONS.COMPLAINT },
  { name: "Cost", value: REASONS.COST },
  { name: "Stopped lessons", value: REASONS.STOPPED_LESSONS },
  { name: "Bereavement", value: REASONS.BEREAVEMENT },
  { name: "Weather", value: REASONS.WEATHER },
  { name: "Childcare issues", value: REASONS.CHILDCARE },
  {
    name: "Lesson booked without pupil knowing/not suitable",
    value: REASONS.TIME_NOT_SUITABLE,
  },
];

type CancelLessonSelectProps = {
  label: string;
  selected: string;
  onChange: (value: string) => void;
};

const CancelLessonSelect: React.FC<CancelLessonSelectProps> = ({
  label,
  selected,
  onChange,
}) => (
  <Select
    variant="outlined"
    label={label}
    value={selected}
    onChange={({ target: { value } }: { target: { value: string } }) =>
      onChange(value)
    }
    name="cancel-lesson-reason-select"
    id="cancel-lesson-reason-select"
    data-test="cancel-lesson-reason-select"
    inputProps=""
    displayEmpty
  >
    {MenuItems.map(({ name, value }) => (
      <MenuItem value={value} key={value}>
        {name}
      </MenuItem>
    ))}
  </Select>
);

export default CancelLessonSelect;
