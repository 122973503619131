import { Typography } from "@material-ui/core";
import React from "react";
import { bulletList } from "./bulletList";
import ShareLink from "../../../../../components/common/ShareLink/ShareLink";
import { useBrand } from "../../../../common/BrandProvider/BrandProvider";
import { GiftType } from "../../../../../models/state";

export const BodyText: React.FC<{
  gift: GiftType;
  pupilFirstName: string;
  setupAccountLink: string;
  ListEl: React.FC;
  ListItemEl: React.FC;
  quickTopUpLink?: string;
  classes: any;
}> = ({
  gift,
  pupilFirstName,
  setupAccountLink,
  ListEl,
  ListItemEl,
  quickTopUpLink,
  classes,
}) => {
  const listItems = bulletList({
    gift,
    setupAccountLink,
    accountLinkClassName: classes.accountLink,
  });

  const { getText } = useBrand();

  return (
    <>
      <Typography
        component="h3"
        variant="h6"
        classes={{ root: classes.titleText }}
        gutterBottom
      >
        So what happens next?
      </Typography>

      {gift === GiftType.NOT_GIFT && (
        <>
          <Typography variant="body1">
            Good luck on your first driving lesson!
          </Typography>

          <Typography variant="body1" gutterBottom>
            Your instructor may contact you before your lesson.
          </Typography>
        </>
      )}

      {gift === GiftType.GIFT && (
        <>
          <Typography variant="body1">
            We’ll send all lesson details and updates directly to the learner so
            they’ll have everything they need.
          </Typography>
          <br />
          <Typography variant="body1">
            If the instructor needs to reach out for any reason, they’ll contact
            the learner directly.
          </Typography>
        </>
      )}

      {gift === GiftType.SURPRISE && (
        <>
          <Typography variant="body1">
            To make sure we don’t spoil the surprise, we’ll send all lesson
            details and updates to you until the learner logs into their account
            for the first time.
          </Typography>
          <br />
          <Typography variant="body1">
            The instructor may reach out to you with any questions in the
            meantime.
          </Typography>
        </>
      )}

      <br />

      <Typography variant="body1" gutterBottom>
        <strong>
          {gift === GiftType.NOT_GIFT
            ? "Preparing for your first lesson"
            : `${pupilFirstName}’s lesson checklist:`}
        </strong>
      </Typography>

      <Typography
        variant="body1"
        component="div"
        gutterBottom
        classes={{ root: classes.bulletList }}
      >
        <ListEl>
          {listItems.map((Item, index) => (
            <ListItemEl key={index}>
              <Item />
            </ListItemEl>
          ))}
        </ListEl>
      </Typography>
      <br />

      {quickTopUpLink && (
        <>
          <Typography
            variant="h6"
            component="h4"
            classes={{ root: classes.titleText }}
            gutterBottom
          >
            Crowdfund {gift === GiftType.NOT_GIFT ? "your" : "more"} lessons
          </Typography>

          {gift === GiftType.NOT_GIFT && (
            <>
              <Typography variant="body1" gutterBottom>
                We’ve made it easy for your family and friends to top up your
                account and keep you learning.
              </Typography>

              <Typography variant="body1" gutterBottom>
                Share your unique link for anyone to add credit to your account:
              </Typography>
            </>
          )}

          {gift !== GiftType.NOT_GIFT && (
            <>
              <Typography variant="body1" gutterBottom>
                We’ve made it easy for family and friends to top up a learner’s
                account and keep them moving.
              </Typography>

              <Typography variant="body1" gutterBottom>
                Save and share this unique link for anyone to add credit to the
                account:
              </Typography>
            </>
          )}

          <ShareLink
            link={quickTopUpLink}
            actionConfirmationText={getText("confirmation.topUpLinkCopied")}
            actionText={getText("confirmation.copyTopUpLink")}
          />
        </>
      )}

      <Typography
        classes={{ root: classes.titleText }}
        variant="h6"
        component="h4"
      >
        {gift === GiftType.NOT_GIFT
          ? "Enjoy your lesson!"
          : "Thanks for choosing us!"}
      </Typography>
    </>
  );
};
