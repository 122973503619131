import { withStyles } from "@material-ui/styles";
import mergeDeep from "lodash.merge";
import React, { Fragment, useEffect, useRef } from "react";
import { string, shape, func, number, arrayOf, bool } from "prop-types";
import SwipeableViews from "react-swipeable-views";

import ContentWrapper from "../../../common/ContentWrapper/ContentWrapper";
import ContinueButton from "../../../common/ContinueButton/ContinueButton";
import RefundGuarantee from "../../../common/RefundGuarantee/RefundGuarantee";

import Tabs from "../../../common/Tabs/Tabs";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import Titlebar from "../../../common/Titlebar/Titlebar";
import TransmissionTab from "./TransmissionTab";
import CrossSellModal from "./CrossSellModal";

const styles = theme =>
  mergeDeep(theme.journey.prices, {
    headingWrapper: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    transmissionTabs: {
      margin: "0 -15px 0 -15px",
      padding: "0 15px",
      [theme.breakpoints.up("sm")]: {
        margin: "20px 0 0",
        padding: 0,
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "-10px",
      },
    },
    packageHelperText: {
      marginBottom: "10px",
    },
    loader: {
      margin: "100px auto 0 auto",
    },
    packageSelectorContainer: {
      "& [data-swipeable]": {
        overflow: "hidden !important",
        flex: "0 0 auto",
      },
    },
    packageSelectorFlexGrow: {
      flex: "1",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > div": {
        flex: "1", // random wrapping div applied by 'SwipeableViews' component
      },
    },
    packageSelectorCenteredContainer: {
      display: "flex",
      alignItems: "center",
    },
    crossSellModal: {
      background: theme.palette.grey[100],
      position: "absolute",
      top: 0,
      left: 0,
      width: "100vw",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    flexGrow: {
      flex: "1 1 auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },

    crossSellTitle: {
      position: "relative",
      marginBottom: 60,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: "30px",
      lineHeight: "30px",
      color: theme.palette.grey[400],
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        margin: "100px 0 2em",
        fontSize: "24px",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "100px 0 0",
        fontSize: "24px",
      },
    },
    crossSellModalWrap: {
      position: "relative",
      width: "300px",
      maxWidth: "100%",
      marginTop: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        width: "500px",
      },
    },
    crossSellSpinner: {
      position: "absolute",
      left: "50%",
      bottom: "40%",
      transform: "translateX(-50%)",
    },
    illustration: {
      width: "100%",
    },
  });

const Prices = ({
  classes,
  onBackClick,
  onSubmit,
  onSwipe,
  onTransmissionChange,
  selectedTabIndex,
  selectedTransmissionType,
  setContinueButtonVisible,
  showContinueButton,
  transmissionTabItems,
  availableInstructorsLoaded,
  isCrossSellModalOpen,
  closeCrossSellModal,
  crossSellInstructorId,
  nudgeBackEnabled,
}) => {
  const continueButtonRef = useRef(null);
  const { brand, getText } = useBrand();

  useEffect(() => {
    if (continueButtonRef.current) {
      continueButtonRef.current.focus();
    }
  });

  return isCrossSellModalOpen ? (
    <CrossSellModal
      currentBrand={brand}
      classes={classes}
      closeCrossSellModal={closeCrossSellModal}
    />
  ) : (
    <Fragment>
      <div className={classes.headingWrapper}>
        <ContentWrapper>
          <Titlebar
            showBackButton
            onBackClick={onBackClick}
            headingProps={{ "data-test": "prices-title-bar" }}
          >
            {getText("prices.pageTitle")}
          </Titlebar>
        </ContentWrapper>
      </div>
      <ContentWrapper fullHeight fullWidth>
        <ContentWrapper fullHeight>
          <div className={classes.transmissionTabs}>
            <Tabs
              selected={selectedTransmissionType}
              onChange={onTransmissionChange}
              label={getText("prices.gearbox")}
              tabItems={transmissionTabItems}
              disabled={!availableInstructorsLoaded}
              data-test="prices-gearbox-picker"
            />
          </div>
        </ContentWrapper>
        <ContentWrapper fullHeight fullWidth>
          <div className={classes.packageSelectorContainer}>
            <div className={classes.packageSelectorFlexGrow}>
              <SwipeableViews
                index={selectedTabIndex}
                onChangeIndex={onSwipe}
                disabled={!availableInstructorsLoaded}
              >
                {transmissionTabItems.map(tab => (
                  <TransmissionTab
                    key={tab.id}
                    tabName={tab.id}
                    setContinueButtonVisible={setContinueButtonVisible}
                    crossSellInstructorId={crossSellInstructorId}
                    nudgeBackEnabled={nudgeBackEnabled}
                    data-test={`transmission-tab-${tab.id}`}
                  />
                ))}
              </SwipeableViews>
            </div>

            {showContinueButton && (
              <Fragment>
                <div className={classes.packageSelectorCenteredContainer}>
                  <ContinueButton
                    onClick={onSubmit}
                    data-test="prices-continue-button"
                    ref={continueButtonRef}
                  />
                </div>
                <ContentWrapper fullHeight>
                  <RefundGuarantee />
                </ContentWrapper>
              </Fragment>
            )}
          </div>
        </ContentWrapper>
      </ContentWrapper>
    </Fragment>
  );
};

Prices.propTypes = {
  classes: shape({
    headingWrapper: string,
    transmissionTabs: string,
    packageSelectorContainer: string,
    packageSelectorFlexGrow: string,
    packageSelectorCenteredContainer: string,
  }),
  onBackClick: func,
  onSubmit: func,
  onSwipe: func,
  onTransmissionChange: func,
  selectedTabIndex: number,
  selectedTransmissionType: string,
  setContinueButtonVisible: func,
  showContinueButton: bool,
  transmissionTabItems: arrayOf(
    shape({
      id: string,
      title: string,
    }),
  ),
  availableInstructorsLoaded: bool,
  isCrossSellModalOpen: bool,
  closeCrossSellModal: func,
  crossSellInstructorId: string,
  nudgeBackEnabled: bool,
};

export default withStyles(styles)(Prices);
