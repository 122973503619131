import React from "react";

import Box from "@material-ui/core/Box";

import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import ContentWrapper from "../../../common/ContentWrapper/ContentWrapper";
import Titlebar from "../../../common/Titlebar/Titlebar";
import Warning from "../../../common/Warning/Warning";
import ContinueButton from "../../../common/ContinueButton/ContinueButton";
import KeyWorkerRadio from "../../../common/KeyWorkerRadio/KeyWorkerRadio";

import { KeyWorkerProps } from "./types";

const KeyWorker: React.FC<KeyWorkerProps> = ({
  onBackClick = () => {},
  onContinueClick = () => {},
  isKeyWorker,
  onIsKeyWorkerChange,
  keyWorkerType,
  onKeyWorkerTypeChange,
  keyWorkerEmployer,
  onKeyWorkerEmployerChange,
}) => {
  const { getText } = useBrand();
  return (
    <ContentWrapper>
      <Titlebar
        showBackButton
        onBackClick={onBackClick}
        headingProps={{ "data-test": "key-worker-title-bar" }}
      >
        {getText("returning/keyWorker.pageTitle")}
      </Titlebar>
      <Box marginBottom={6}>
        <Warning
          title={getText("returning/keyWorker.warning")}
          data-test="key-worker-title"
        />
      </Box>
      <ContentWrapper fullHeight>
        <KeyWorkerRadio
          isKeyWorker={isKeyWorker}
          onIsKeyWorkerChange={onIsKeyWorkerChange}
          keyWorkerType={keyWorkerType}
          onKeyWorkerTypeChange={onKeyWorkerTypeChange}
          keyWorkerEmployer={keyWorkerEmployer}
          onKeyWorkerEmployerChange={onKeyWorkerEmployerChange}
        />
      </ContentWrapper>
      <ContentWrapper fullHeight>
        <ContinueButton
          text={getText("returning/keyWorker.actionButton")}
          data-test="key-worker-continue"
          type="submit"
          onClick={onContinueClick}
          disabled={isKeyWorker && !(keyWorkerType && keyWorkerEmployer)}
        />
      </ContentWrapper>
    </ContentWrapper>
  );
};

export default KeyWorker;
