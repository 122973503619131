import React, { Fragment } from "react";
import {
  TextField as MUITextField,
  Icon,
  InputAdornment,
  InputLabel,
  TextFieldProps,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextFieldErrorMessage from "./TextFieldErrorMessage";

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: "16px",
    color: theme.palette.text.primary,
    opacity: 0.4,
  },
}));

const FormTextField: React.FC<
  TextFieldProps & {
    inputIconLeft?: string;
    helperText?: string;
  }
> = ({ inputIconLeft, helperText, error, disabled, label, ...restProps }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <InputLabel>{label}</InputLabel>
      <MUITextField
        error={error}
        helperText={
          helperText && error ? (
            <TextFieldErrorMessage errorMessage={helperText} />
          ) : (
            helperText
          )
        }
        InputProps={{
          startAdornment: inputIconLeft && (
            <InputAdornment position="start">
              <Icon className={classes.icon}>{inputIconLeft}</Icon>
            </InputAdornment>
          ),
        }}
        disabled={disabled}
        {...restProps}
      />
    </Fragment>
  );
};

export default FormTextField;
