import React from "react";

import { Box, Typography } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import PhoneAndroidSharpIcon from "@material-ui/icons/PhoneAndroidSharp";
import CalendarTodayRoundedIcon from "@material-ui/icons/CalendarTodayRounded";
import DirectionsCarOutlinedIcon from "@material-ui/icons/DirectionsCarOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import { withRouter } from "react-router-dom";

import { RETURNING_USER_JOURNEY_PATH_NAMES } from "@constants";

import ActionCard from "../../common/ActionCard/ActionCard";
import ActionCardList from "../../common/ActionCardList/ActionCardList";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import Titlebar from "../../common/Titlebar/Titlebar";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";
import { goBack } from "../../../utils/navigation";
import { RecommendedDrivingProductsProps } from "./types";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import { useQueryParams } from "@utils";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";

import { ReactComponent as AABreakdown } from "../../../svg/aa-breakdown.svg";
import { ReactComponent as AACarLoans } from "../../../svg/aa-car-loans.svg";
import { ReactComponent as BSMBreakdown } from "../../../svg/bsm-breakdown.svg";
import { ReactComponent as BSMCarLoans } from "../../../svg/bsm-car-loans.svg";

const RecommendedDrivingProducts: React.FC<RecommendedDrivingProductsProps> = ({
  history,
  location: { pathname },
}) => {
  const { getText } = useBrand();
  const { trackEvent } = useTealium();
  const param = useQueryParams();
  const isFromTheoryApp = param?.referrer === "theory-app";
  const isInternalLink = param?.referrer === "ruj";

  const shouldNavigateToNUJ = !isInternalLink || isFromTheoryApp;

  const pageSubtitle = getText("recommendedDrivingProducts.pageSubtitle");

  return (
    <ContentWrapper fullWidth fullHeight>
      <ContentWrapper>
        <Titlebar
          onBackClick={() => goBack({ history, pathname })}
          showBackButton={!shouldNavigateToNUJ}
        >
          {getText("recommendedDrivingProducts.pageTitle")}
        </Titlebar>

        {pageSubtitle && (
          <Box mb={3}>
            <Typography>{pageSubtitle}</Typography>
          </Box>
        )}

        <Box mb={2}>
          <Typography>
            {getText("recommendedDrivingProducts.learningToDrive")}
          </Typography>
        </Box>

        <ActionCardList>
          <ActionCard
            data-test="driving-products-theory-app-button"
            title={getText("recommendedDrivingProducts.theoryApp")}
            subtitle={getText("recommendedDrivingProducts.theoryAppSubtitle")}
            action={() => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(pathname),
                eventAction: "Interaction",
                eventLabel: "Theory App",
              });
            }}
            isExternalLink
            externalLink="https://www.theaa.com/driving-school/driving-lessons/aa-driving-theory-app"
            actionIcon={<OpenInNewIcon fontSize="small" />}
            IconComponent={
              <PhoneAndroidSharpIcon color="secondary" fontSize="large" />
            }
          />
          <ActionCard
            data-test="driving-products-book-a-lesson"
            title={getText("recommendedDrivingProducts.bookALesson")}
            subtitle={getText("recommendedDrivingProducts.bookALessonSubtitle")}
            action={() => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(pathname),
                eventAction: "Interaction",
                eventLabel: "Book a lesson",
              });
              !shouldNavigateToNUJ &&
                history.push(RETURNING_USER_JOURNEY_PATH_NAMES.FACE_COVERING);
            }}
            isExternalLink={shouldNavigateToNUJ}
            externalLink={
              "https://www.theaa.com/driving-school/lessons/pickup-location"
            }
            actionIcon={<OpenInNewIcon fontSize="small" />}
            IconComponent={
              <CalendarTodayRoundedIcon color="secondary" fontSize="large" />
            }
          />
        </ActionCardList>

        <Box mb={2} mt={3}>
          <Typography>
            {getText("recommendedDrivingProducts.gettingYouOnTheRoad")}
          </Typography>
        </Box>

        <ActionCardList>
          <ActionCard
            data-test="driving-products-aa-cars"
            title={getText("recommendedDrivingProducts.aaCars")}
            subtitle={getText("recommendedDrivingProducts.aaCarsSubtitle")}
            action={() => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(pathname),
                eventAction: "Interaction",
                eventLabel: "AA Cars",
              });
            }}
            isExternalLink
            externalLink="https://www.theaa.com/cars/"
            actionIcon={<OpenInNewIcon fontSize="small" />}
            IconComponent={
              <DirectionsCarOutlinedIcon color="secondary" fontSize="large" />
            }
          />
          <ActionCard
            data-test="driving-products-aa-car-loans"
            title={getText("recommendedDrivingProducts.aaCarLoans")}
            subtitle={getText("recommendedDrivingProducts.aaCarLoansSubtitle")}
            action={() => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(pathname),
                eventAction: "Interaction",
                eventLabel: "AA Car Loans",
              });
            }}
            isExternalLink
            externalLink="https://www.theaa.com/loans/car-loans"
            actionIcon={<OpenInNewIcon fontSize="small" />}
            IconComponent={
              pageSubtitle ? <BSMCarLoans /> : <AACarLoans fontSize="medium" />
            }
          />
        </ActionCardList>

        <Box mb={2} mt={3}>
          <Typography>
            {getText("recommendedDrivingProducts.keepingYouOnTheRoad")}
          </Typography>
        </Box>

        <ActionCardList>
          <ActionCard
            data-test="driving-products-aa-breakdown"
            title={getText("recommendedDrivingProducts.aaBreakdown")}
            subtitle={getText("recommendedDrivingProducts.aaBreakdownSubtitle")}
            action={() => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(pathname),
                eventAction: "Interaction",
                eventLabel: "AA Breakdown",
              });
            }}
            isExternalLink
            externalLink="https://www.theaa.com/breakdown-cover/"
            actionIcon={<OpenInNewIcon fontSize="small" />}
            IconComponent={
              pageSubtitle ? (
                <BSMBreakdown />
              ) : (
                <AABreakdown color="secondary" fontSize="large" />
              )
            }
          />
          <ActionCard
            data-test="driving-products-aa-smart-care"
            title={getText("recommendedDrivingProducts.aaSmartCare")}
            subtitle={getText("recommendedDrivingProducts.aaSmartCareSubtitle")}
            action={() => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(pathname),
                eventAction: "Interaction",
                eventLabel: "AA Smart Care",
              });
            }}
            isExternalLink
            externalLink="https://www.theaa.com/car-care/book/start"
            actionIcon={<OpenInNewIcon fontSize="small" />}
            IconComponent={
              <AssignmentOutlinedIcon color="secondary" fontSize="large" />
            }
          />
        </ActionCardList>
        <Box mb={18} />
      </ContentWrapper>
    </ContentWrapper>
  );
};
export default withRouter(RecommendedDrivingProducts);
