import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";

import {
  GLOBAL_PATH_NAMES,
  RETURNING_USER_JOURNEY_PATH_NAMES,
} from "../../../constants";
import createComplaintMutation from "../../../graphql/createComplaint.graphql";
import { goBack } from "../../../utils/navigation";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import LoadingScreen from "../../common/LoadingScreen/LoadingScreen";
import Complaints from "./Complaints";

const ReturningUserJourneyComplaints = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { brand, getText } = useBrand();

  const [reason, setReason] = useState("");
  const [details, setDetails] = useState("");
  const [resolution, setResolution] = useState("");
  const [responseRequired, setResponseRequired] = useState(true);

  const [createComplaint, { loading }] = useMutation(createComplaintMutation, {
    onCompleted: data => {
      if (data && data.sendComplaintEmail) {
        history.push(RETURNING_USER_JOURNEY_PATH_NAMES.COMPLAINTS_CONFIRMATION);
      } else {
        history.push(GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG);
      }
    },
    onError: () => {
      history.push(GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG);
    },
  });

  const handleSubmit = () => {
    createComplaint({
      variables: {
        input: {
          brand,
          reason,
          complaintDetails: details,
          requiresResponse: responseRequired,
          complaintResolution: resolution,
        },
      },
    });
  };

  const handleBackClick = () => {
    goBack({ history, pathname });
  };

  if (loading) {
    return (
      <LoadingScreen messageText={getText("returning/complaints.processing")} />
    );
  }

  return (
    <Complaints
      reason={reason}
      onReasonChanged={setReason}
      details={details}
      onDetailsChanged={setDetails}
      resolution={resolution}
      onResolutionChanged={setResolution}
      responseRequired={responseRequired}
      onResponseRequiredChanged={setResponseRequired}
      onSubmit={handleSubmit}
      onBackClick={handleBackClick}
      submitDisabled={!(reason && details)}
    />
  );
};

export default ReturningUserJourneyComplaints;
