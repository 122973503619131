import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import get from "lodash.get";
import {
  useStateContext,
  CLEAR_QTU_SELECTION_HISTORY,
} from "../../../context/stateContext";
import learnerBalance from "../../../graphql/learnerBalance.graphql";
import Confirmation from "./Confirmation";
import { RETURNING_USER_JOURNEY_PATH_NAMES } from "../../../constants";

const ConfirmationWithQuery = ({ history }: RouteComponentProps) => {
  const {
    state: {
      qtuHistory: {
        confirmation: { pupilBalance, selectedHours, pupilFirstName, topUpId },
      },
    },
    dispatch,
  } = useStateContext();

  useEffect(() => {
    dispatch({
      type: CLEAR_QTU_SELECTION_HISTORY,
    });
  }, []);

  const { data } = useQuery(learnerBalance, {
    variables: {
      topUpId,
    },
    // Force apollo to re-fetch the data from the server, as the hours should be updated
    fetchPolicy: "no-cache",
  });

  const calculatedBalance = (pupilBalance + selectedHours).toFixed(2);

  const balanceFromServer = get(
    data,
    "learnerTopUpDetails.allocations[0].balance",
    null,
  );

  /* A faux optimistic response - we'll calculate the new balance based on the
     information from context, then replace it with the server response once
    it arrives */
  const newBalance = balanceFromServer || calculatedBalance;

  const handleContinueClick = () => {
    history.push(`${RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT}/${topUpId}`);
  };

  return (
    <Confirmation
      name={pupilFirstName}
      hoursAdded={selectedHours}
      balance={newBalance}
      onContinueClick={handleContinueClick}
    />
  );
};

export default withRouter(ConfirmationWithQuery);
