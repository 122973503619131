import { QueryHookOptions, useLazyQuery } from "@apollo/client";

import EmailAddressExistsCheckQuery from "../graphql/emailAddressExistsCheck.graphql";

const useLazyQueryEmailAddressExistsCheck = ({
  onCompleted,
}: QueryHookOptions) =>
  useLazyQuery(EmailAddressExistsCheckQuery, {
    onCompleted,
  });

export default useLazyQueryEmailAddressExistsCheck;
