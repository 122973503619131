import React, { FormEvent, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { useStateContext } from "../../../../context/stateContext";
import { submitPickupLocationForm } from "./helpers";
import PickupLocation from "./PickupLocation";
import useReferral from "../../../../hooks/useReferral";
import { POSTCODE_URL_PARAM } from "../../../../constants";
import useQueryString from "../../../../hooks/useQueryString";
import { OnPostcodeChange } from "../../../common/Form/PostcodeValidator/types";

const PickupLocationWithState = () => {
  const { search } = useLocation();
  const history = useHistory();

  const urlParams = useQueryString(search);
  const urlPostcode = urlParams[POSTCODE_URL_PARAM] || "";
  const {
    state: {
      nujHistory: { pickupPostcode },
    },
    dispatch,
  } = useStateContext();

  const referral = useReferral({ dispatch, search });

  const [postcodeValue, setPostcodeValue] = useState(
    urlPostcode || pickupPostcode || "",
  );

  const [isPostcodeInvalid, setIsPostcodeInValid] = useState(true);

  const handleChange = ({ value, isInvalid }: OnPostcodeChange) => {
    setPostcodeValue(value);
    setIsPostcodeInValid(isInvalid);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement> | undefined) =>
    submitPickupLocationForm({
      event,
      isPostcodeInvalid,
      postcodeValue,
      dispatch,
      history,
    });

  return (
    <PickupLocation
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      postcodeValue={postcodeValue}
      isPostcodeInvalid={isPostcodeInvalid}
      referral={referral}
    />
  );
};

export default PickupLocationWithState;
