import { BaseMutationOptions, useMutation } from "@apollo/client";

import rujLoginQuery from "../graphql/rujLogin.graphql";

const useRujLogin = ({ onCompleted }: BaseMutationOptions) =>
  useMutation(rujLoginQuery, {
    fetchPolicy: "no-cache",
    onCompleted,
  });

export default useRujLogin;
