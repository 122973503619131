import bsmLogoMobile from "../svg/bsm-logo-mobile.svg";
import bsmLogoDesktop from "../svg/bsm-logo-desktop.svg";
import aadLogoMobile from "../svg/aad-logo-mobile.svg";
import aadLogoDesktop from "../svg/aad-logo-desktop.svg";
import { Brand } from "@graphql/types";

export const getMobileAppLogo = brand =>
  brand === Brand.Aads ? aadLogoMobile : bsmLogoMobile;

export const getDesktopAppLogo = brand =>
  brand === Brand.Aads ? aadLogoDesktop : bsmLogoDesktop;

export default {
  getDesktopAppLogo,
  getMobileAppLogo,
};
