import { format } from "date-fns";
import React, {
  useState,
  useEffect,
  Fragment,
  FC,
  ChangeEvent,
  FormEvent,
} from "react";
import { withRouter, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import { History } from "history";

import {
  useStateContext,
  UPDATE_RUJ_HISTORY,
} from "../../../context/stateContext";

import {
  getJourneyURL,
  GLOBAL_PATH_NAMES,
  RETURNING_USER_JOURNEY_PATH_NAMES,
} from "../../../constants";
import { getGAClientId } from "../../../utils/getGAClientId";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import AccountLocked from "../../common/AccountLocked/AccountLocked";
import LoadingScreen from "../../common/LoadingScreen/LoadingScreen";
import useFetchTopUpDetails from "../../../hooks/useFetchTopUpDetails";
import useQueryString from "../../../hooks/useQueryString";
import useRujLogin from "../../../hooks/useRujLogin";

import Login from "./Login";
import { Learner, RujLoginResult } from "@graphql/types";

interface Props {
  history: History;
}

export const LoginWithQuery: FC<Props> = ({ history }) => {
  const {
    dispatch,
    state: {
      rujHistory: { pupilId: pupilIdFromState },
    },
  } = useStateContext();
  const { trackEvent } = useTealium();
  const { brand, getText } = useBrand();

  const [pupilId, setPupilId] = useState(pupilIdFromState || "");
  const [surname, setSurname] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const [hasDateOfBirthError, setHasDateOfBirthError] = useState(false);
  const [hasInvalidLogin, setHasInvalidLogin] = useState(false);
  const [accountLocked, setAccountLocked] = useState(false);
  const [attemptsRemaining, setAttemptsRemaining] = useState<
    number | undefined
  >();
  const [loginData, setLoginData] = useState<RujLoginResult | undefined>();
  const [loginViaURL, setLoginViaURL] = useState(false);
  const { search } = useLocation();
  const queryString = useQueryString(search);

  const [
    fetchTopUpDetails,
    {
      data: topUpDetailsData,
      loading: topUpDetailsLoading,
      error: topUpDetailsError,
    },
  ] = useFetchTopUpDetails({
    onCompleted: (data: { learnerTopUpDetails: Learner }) => {
      let instructorIdTemp;

      if (data.learnerTopUpDetails.allocations?.length) {
        instructorIdTemp =
          data.learnerTopUpDetails.allocations[0].instructor.id;
      }

      const result = {
        name: data.learnerTopUpDetails.name.firstName,
        pupilId: data.learnerTopUpDetails.personId,
        instructorId: instructorIdTemp,
        learnerId: data.learnerTopUpDetails.id,
      };

      const { pupilId, instructorId, name, learnerId } = result;
      if (topUpDetailsData) {
        if (!topUpDetailsData.learnerTopUpDetails) {
          history.push(GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG); // This should never really happen, but go to error page just in case
        }

        dispatch({
          type: UPDATE_RUJ_HISTORY,
          payload: {
            personId: parseInt(pupilId),
            topUpId: loginData?.encryptedPersonId,
            pupilFirstName: name,
            instructorId,
            learnerId,
          },
        });

        if (queryString.redirect) {
          history.push(queryString.redirect);
        } else if (loginData?.encryptedPersonId) {
          history.push(
            `${RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT}/${loginData.encryptedPersonId}`,
          );
        }
      }
    },
  });

  const [rujLogin, { loading: loginLoading, error: loginError }] = useRujLogin({
    onCompleted: (data: { rujLogin: RujLoginResult }) => {
      if (data.rujLogin.accessToken) {
        localStorage.setItem("learnerAuth", data.rujLogin.accessToken);
      }

      setLoginData(data.rujLogin);
    },
  });
  // If the user has already logged in
  const hasUserPreviouslyLoggedIn =
    queryString.pupilId && queryString.surname && queryString.dateOfBirth;

  if (hasUserPreviouslyLoggedIn) {
    if (loginError) {
      history.push(
        `${RETURNING_USER_JOURNEY_PATH_NAMES.LOGIN}?error=${encodeURIComponent(
          loginError.message,
        )}`,
      );
    } else if (!loginLoading && !loginViaURL) {
      setLoginViaURL(true);

      const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY || "";

      const decryptedPupilId = CryptoJS.AES.decrypt(
        queryString.pupilId,
        encryptionKey,
      ).toString(CryptoJS.enc.Utf8);
      setPupilId(decryptedPupilId);

      const decryptedSurname = CryptoJS.AES.decrypt(
        queryString.surname,
        encryptionKey,
      ).toString(CryptoJS.enc.Utf8);
      setSurname(decryptedSurname);

      const decryptedDateOfBirth = CryptoJS.AES.decrypt(
        queryString.dateOfBirth,
        encryptionKey,
      );

      const parsedDateOfBirth = new Date(
        parseInt(decryptedDateOfBirth.toString(CryptoJS.enc.Utf8), 10),
      );
      setDateOfBirth(parsedDateOfBirth);

      const variables = {
        brand,
        pupilId: decryptedPupilId,
        dateOfBirth: format(parsedDateOfBirth, "yyyy-MM-dd"),
        surname: decryptedSurname,
        clientId: getGAClientId(),
      };
      rujLogin({ variables });

      setHasInvalidLogin(false);
    }
  }

  const handleLoginDataUpdate = () => {
    if (!loginData) return;

    const loginURL = `${getJourneyURL(brand)}${
      RETURNING_USER_JOURNEY_PATH_NAMES.LOGIN
    }`;

    if (loginData?.authenticated) {
      let customerType = "Learner";

      if (loginData?.customerTypes) {
        const isMotability = loginData?.customerTypes?.some(
          ct => ct.type === "Motability",
        );
        if (isMotability) {
          customerType = "Motability";
        }
      }

      dispatch({
        type: UPDATE_RUJ_HISTORY,
        payload: {
          personId: parseInt(pupilId),
          pupilSurname: surname,
          pupilDateOfBirth: dateOfBirth?.valueOf(),
          pupilId,
          topUpId: loginData.encryptedPersonId,
          customerType,
        },
      });
      const variables = {
        topUpId: loginData.encryptedPersonId,
      };

      // Fetch top up details
      // This will navigate learner to account screen once completed
      fetchTopUpDetails({ variables });
    } else if (
      loginData?.authenticated === false &&
      loginData?.attemptsRemaining === 0
    ) {
      trackEvent({ eventCategory: "Account Locked", eventAction: "login" });
      setAccountLocked(true);
      setHasInvalidLogin(true);
      if (loginViaURL) {
        window.location.assign(
          `${loginURL}?error=${encodeURIComponent(
            getText("login.lockedAccountTitle"),
          )}`,
        );
      }
    } else {
      setAttemptsRemaining(loginData?.attemptsRemaining ?? 0);
      setHasInvalidLogin(true);

      if (loginViaURL) {
        window.location.assign(
          `${loginURL}?error=${encodeURIComponent(
            getText("login.validationError"),
          )}`,
        );
      }
    }
  };

  useEffect(handleLoginDataUpdate, [loginData]);

  const error = loginError || topUpDetailsError;
  const loading = loginLoading || topUpDetailsLoading;

  if (error) {
    throw new Error(error.message);
  }

  const handlePupilIdChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setPupilId(value);
  };

  const handleSurnameChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSurname(value);
  };

  const handleDobChange = (value: any) => {
    setHasDateOfBirthError(false);
    setDateOfBirth(value);
  };

  const handleDobError = (err: Error | undefined) => {
    if (err) {
      setHasDateOfBirthError(true);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!dateOfBirth || !pupilId || !surname) {
      return;
    }

    rujLogin({
      variables: {
        brand,
        pupilId,
        dateOfBirth: format(dateOfBirth, "yyyy-MM-dd"),
        surname,
        clientId: getGAClientId(),
      },
    });
    setHasInvalidLogin(false);
  };

  if (loginViaURL) {
    return <LoadingScreen messageText="Logging you in..." />;
  }

  return (
    <Fragment>
      {accountLocked ? (
        <AccountLocked />
      ) : (
        <Login
          pupilId={pupilId}
          surname={surname}
          dateOfBirth={dateOfBirth}
          handlePupilIdChange={handlePupilIdChange}
          handleSurnameChange={handleSurnameChange}
          handleDobChange={handleDobChange}
          handleDobError={handleDobError}
          handleSubmit={handleSubmit}
          loading={loading}
          hasInvalidLogin={hasInvalidLogin}
          attemptsRemaining={attemptsRemaining}
          hasDateOfBirthError={hasDateOfBirthError}
        />
      )}
    </Fragment>
  );
};

export default withRouter(LoginWithQuery);
