import React, { Fragment } from "react";
import { string, func } from "prop-types";
import { useTealium } from "../../../common/TealiumProvider/TealiumProvider";
import RadioListItem from "../../../common/RadioItemList/RadioListItem";
import RadioListItemText from "../../../common/RadioItemList/RadioListItemText";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import { NEW_USER_JOURNEY_PATH_NAMES } from "../../../../constants";

export const eventLabel = {
  [NEW_USER_JOURNEY_PATH_NAMES.PRICES]: "Learn to Drive",
  [NEW_USER_JOURNEY_PATH_NAMES.TEST_PASSED]: "Passed My Test",
  [NEW_USER_JOURNEY_PATH_NAMES.TEST_BOOKED]: "Test Booked",
};

const LessonTypesOptionList = ({ selectedLessonType, onClick }) => {
  const { getText } = useBrand();
  const { trackEvent } = useTealium();

  const handleLessonTypeClick = value => {
    trackEvent({
      eventCategory: "Lesson Types",
      eventAction: "Interaction",
      eventLabel: eventLabel[value],
    });
    onClick(value);
  };

  return (
    <Fragment>
      <RadioListItem
        value={NEW_USER_JOURNEY_PATH_NAMES.PRICES}
        selected={selectedLessonType === NEW_USER_JOURNEY_PATH_NAMES.PRICES}
        onClick={handleLessonTypeClick}
        testId="learn-to-drive-option"
      >
        <RadioListItemText
          selected={selectedLessonType === NEW_USER_JOURNEY_PATH_NAMES.PRICES}
          text={getText("lesson-types.learnToDrive")}
        />
      </RadioListItem>
      <RadioListItem
        value={NEW_USER_JOURNEY_PATH_NAMES.TEST_PASSED}
        selected={
          selectedLessonType === NEW_USER_JOURNEY_PATH_NAMES.TEST_PASSED
        }
        onClick={handleLessonTypeClick}
        testId="test-passed-option"
      >
        <RadioListItemText
          selected={
            selectedLessonType === NEW_USER_JOURNEY_PATH_NAMES.TEST_PASSED
          }
          text={getText("lesson-types.testPassed")}
        />
      </RadioListItem>
      <RadioListItem
        value={NEW_USER_JOURNEY_PATH_NAMES.TEST_BOOKED}
        selected={
          selectedLessonType === NEW_USER_JOURNEY_PATH_NAMES.TEST_BOOKED
        }
        onClick={handleLessonTypeClick}
        testId="test-booked-option"
      >
        <RadioListItemText
          selected={
            selectedLessonType === NEW_USER_JOURNEY_PATH_NAMES.TEST_BOOKED
          }
          text={getText("lesson-types.testBooked")}
        />
      </RadioListItem>
    </Fragment>
  );
};

LessonTypesOptionList.propTypes = {
  selectedLessonType: string,
  onClick: func,
};

export default LessonTypesOptionList;
