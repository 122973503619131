import { useMutation } from "@apollo/react-hooks";
import { Box, Theme, Typography } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutline";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  RETURNING_USER_JOURNEY_PATH_NAMES,
  GLOBAL_PATH_NAMES,
} from "../../../constants";
import {
  useStateContext,
  UPDATE_RUJ_HISTORY,
} from "../../../context/stateContext";
import requestNewInstructorMutation from "../../../graphql/requestNewInstructor.graphql";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";
import { goBack } from "../../../utils/navigation";
import ActionCard from "../../common/ActionCard/ActionCard";
import ActionCardList from "../../common/ActionCardList/ActionCardList";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import RefundIcon from "../../common/RefundIcon/RefundIcon";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import Titlebar from "../../common/Titlebar/Titlebar";

import LoadingScreen from "../../common/LoadingScreen/LoadingScreen";
import { HelpCentreProps } from "./types";

interface CustomTheme extends Theme {
  title: Record<string, any>;
  intro: Record<string, any>;
}

const useStyles = makeStyles<CustomTheme>(theme => ({
  title: {
    "& h1": {
      paddingTop: 0,
      margin: 0,
      [theme.breakpoints.up("sm")]: {
        textAlign: "left",
      },
    },
  },
}));

const HelpCentre: React.FC<HelpCentreProps> = ({
  history,
  location: { pathname },
}) => {
  const classes = useStyles();
  const { getText } = useBrand();
  const { trackEvent } = useTealium();
  const {
    dispatch,
    state: {
      rujHistory: { newInstructorRequested, learnerId, instructorId },
    },
  } = useStateContext();

  const [
    requestNewInstructor,
    { loading: requestInstructorClicked },
  ] = useMutation(requestNewInstructorMutation, {
    onCompleted: ({ requestNewInstructor: { successful } }) => {
      if (successful) {
        dispatch({
          type: UPDATE_RUJ_HISTORY,
          payload: {
            newInstructorRequested: true,
          },
        });
        history.push(
          RETURNING_USER_JOURNEY_PATH_NAMES.NEW_INSTRUCTOR_REQUEST_RECEIVED,
        );
      } else {
        history.push(GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG);
      }
    },
    onError: () => {
      history.push(GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG);
    },
  });

  if (requestInstructorClicked) {
    return <LoadingScreen messageText="Processing your request" />;
  }
  return (
    <ContentWrapper fullWidth fullHeight>
      <ContentWrapper>
        <Titlebar
          classes={{ root: classes.title }}
          onBackClick={() => goBack({ history, pathname })}
          showBackButton
        >
          {getText("helpCentre.pageTitle")}
        </Titlebar>

        <Box mb={2}>
          <Typography className={classes.intro}>
            {getText("helpCentre.intro")}
          </Typography>
        </Box>

        <ActionCardList>
          <ActionCard
            data-test="account-talk-to-us-button"
            title={getText("helpCentre.talkButtonTitle")}
            subtitle={getText("helpCentre.talkButtonSubtitle")}
            action={() => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(pathname),
                eventAction: "Interaction",
                eventLabel: "Talk To Us",
              });
              history.push(RETURNING_USER_JOURNEY_PATH_NAMES.TALK_TO_US);
            }}
            IconComponent={
              <QuestionAnswerIcon color="secondary" fontSize="large" />
            }
          />
          <ActionCard
            data-test="account-cancel-lesson-button"
            title={getText("helpCentre.cancelButtonTitle")}
            subtitle={getText("helpCentre.cancelButtonSubtitle")}
            action={() => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(pathname),
                eventAction: "Interaction",
                eventLabel: "Cancel A Lesson",
              });
              history.push(RETURNING_USER_JOURNEY_PATH_NAMES.CANCEL_LESSON);
            }}
            IconComponent={
              <DeleteOutlineIcon color="secondary" fontSize="large" />
            }
          />
          {instructorId ? (
            <ActionCard
              data-test="account-change-instructor-button"
              title={getText("helpCentre.changeInstructorButtonTitle")}
              subtitle={getText("helpCentre.changeInstructorButtonSubtitle")}
              action={() => {
                trackEvent({
                  eventCategory: capitalizeEachWordOfPathname(pathname),
                  eventAction: "Interaction",
                  eventLabel: "Change Your Instructor",
                });
                history.push(
                  RETURNING_USER_JOURNEY_PATH_NAMES.CHANGE_INSTRUCTOR,
                );
              }}
              IconComponent={
                <SwapHorizIcon color="secondary" fontSize="large" />
              }
            />
          ) : (
            <ActionCard
              title={getText("account.requestInstructorButtonTitle")}
              subtitle={getText("account.requestInstructorButtonSubtitle")}
              data-test="request-instructor-button"
              disabled={newInstructorRequested || requestInstructorClicked}
              action={() => {
                trackEvent({
                  eventCategory: "Account",
                  eventAction: "Interaction",
                  eventLabel: "Request Instructor",
                });
                requestNewInstructor({
                  variables: {
                    input: {
                      pupilId: learnerId,
                    },
                  },
                });
              }}
              IconComponent={
                <AddCircleOutlinedIcon color="secondary" fontSize="large" />
              }
            />
          )}
          <ActionCard
            data-test="account-refund-button"
            title={getText("helpCentre.refundButtonTitle")}
            subtitle={getText("helpCentre.refundButtonSubtitle")}
            action={() => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(pathname),
                eventAction: "Interaction",
                eventLabel: "Refund",
              });
              history.push(RETURNING_USER_JOURNEY_PATH_NAMES.REFUND_REQUEST);
            }}
            IconComponent={<RefundIcon color="secondary" fontSize="large" />}
          />
          <ActionCard
            data-test="account-complaint-button"
            title={getText("helpCentre.complaintButtonTitle")}
            subtitle={getText("helpCentre.complaintButtonSubtitle")}
            action={() => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(pathname),
                eventAction: "Interaction",
                eventLabel: "Make A Complaint",
              });
              history.push(RETURNING_USER_JOURNEY_PATH_NAMES.COMPLAINTS);
            }}
            IconComponent={
              <SentimentVeryDissatisfiedIcon
                color="secondary"
                fontSize="large"
              />
            }
          />
        </ActionCardList>
      </ContentWrapper>
    </ContentWrapper>
  );
};
export default withRouter(HelpCentre);
