import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import merge from "lodash.merge";
import {
  makeStyles,
  Typography,
  FormHelperText,
  MenuItem,
  FormControl,
  Theme,
} from "@material-ui/core";
import { goBack } from "../../../utils/navigation";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import Button from "../../common/Button/Button";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import Select from "../../common/Form/Select/Select";
import Titlebar from "../../common/Titlebar/Titlebar";
import { CHANGE_INSTRUCTOR_REASONS } from "../../../constants";
import TextFieldErrorMessage from "../../common/Form/TextField/TextFieldErrorMessage";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";
import { ChangeInstructorProps } from "./types";

interface CustomTheme extends Theme {
  journey: {
    RUJChangeInstructor: Record<string, any>;
  };
}

const useStyles = makeStyles<CustomTheme>(theme =>
  merge(theme.journey.RUJChangeInstructor, {
    subtitleText: {
      fontWeight: "bold",
    },
    bodyText: {
      margin: "2em 0",
    },
    labelText: {
      marginBottom: "0.5em",
    },
    buttonWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    button: {
      margin: "2em 0em 3em",
    },
  }),
);

const formattedReasons = Object.keys(CHANGE_INSTRUCTOR_REASONS).map(key => ({
  value: key,
  label: CHANGE_INSTRUCTOR_REASONS[key],
}));

const ChangeInstructor: React.FC<ChangeInstructorProps> = ({
  handleChange = () => {},
  handleSubmit = () => {},
  errorMessage = "",
  reason = "",
  history,
  location: { pathname },
}) => {
  const { getText } = useBrand();
  const classes = useStyles();
  const { trackEvent, updateDataLayer } = useTealium();

  const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Interaction",
      eventLabel: event.target.value,
    });
    updateDataLayer({
      change_instructor_reason: event.target.value,
    });
    handleChange(event);
  };

  const handleSubmitEvent = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Button Click",
      eventLabel: "Request An Instructor Change",
    });
    updateDataLayer({
      changed_instructor: "true",
    });
    handleSubmit();
  };

  return (
    <Fragment>
      <ContentWrapper>
        <Titlebar
          showBackButton
          onBackClick={() => goBack({ history, pathname })}
          headingProps={{ "data-test": "ruj-change-instructor-title-bar" }}
        >
          {getText("returning/change-instructor.pageTitle")}
        </Titlebar>
        <div>
          <Typography component="p" className={classes.subtitleText}>
            {getText("returning/change-instructor.subTitle")}
          </Typography>

          <Typography component="p" className={classes.bodyText}>
            {getText("returning/change-instructor.bodyText")}
          </Typography>

          <FormControl fullWidth>
            <label htmlFor="ruj-change-instructor-reason-select">
              <Typography component="label" className={classes.labelText}>
                {getText("returning/change-instructor.selectLabel")}
              </Typography>
            </label>
            <Select
              label=""
              inputProps={{
                "data-test": "ruj-change-instructor-reason-select",
              }}
              error={Boolean(errorMessage)}
              value={reason}
              variant="outlined"
              onChange={handleChangeEvent}
              name="ruj-change-instructor-reason-select"
              data-test="ruj-change-instructor-reason-select"
              displayEmpty
            >
              {formattedReasons.map(r => (
                <MenuItem value={r.value} key={`reason-${r.value}`}>
                  {r.label}
                </MenuItem>
              ))}
            </Select>
            {errorMessage && (
              <FormHelperText data-test="ruj-change-instructor-error-message">
                <TextFieldErrorMessage errorMessage={errorMessage} />
              </FormHelperText>
            )}
            <div className={classes.buttonWrapper}>
              <Button
                classes={{ root: classes.button }}
                text={getText("returning/change-instructor.buttonText")}
                onClick={handleSubmitEvent}
                disabled={!reason}
                data-test="ruj-change-instructor-submit"
                type="submit"
              />
            </div>
          </FormControl>
        </div>
      </ContentWrapper>
    </Fragment>
  );
};

export default withRouter(ChangeInstructor);
