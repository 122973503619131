import React, { Fragment } from "react";
import { string, bool, func } from "prop-types";
import { InputAdornment, makeStyles, MenuItem } from "@material-ui/core";
import { WorkOutlineOutlined } from "@material-ui/icons";
import merge from "lodash.merge";
import YesNoPicker from "../Form/YesNoPicker/YesNoPicker";
import Select from "../Form/Select/Select";
import TextField from "../Form/TextField/TextField";
import { useBrand } from "../BrandProvider/BrandProvider";

const useStyles = makeStyles(theme =>
  merge(theme.journey.keyWorker, {
    selectInputAdornment: { opacity: 0.4, fontSize: "16px" },
    wrapper: { marginTop: theme.spacing(3) },
  }),
);

const items = [
  { label: "Education and childcare", value: "EducationChildcare" },
  { label: "Food and other necessary goods", value: "FoodNecessaryGoods" },
  { label: "Health and social care", value: "HealthSocialCare" },
  { label: "Key public services", value: "KeyPublicServices" },
  { label: "Local and national government", value: "LocalNationalGovernment" },
  { label: "Public safety and national security", value: "SafetySecurity" },
  { label: "Transport", value: "Transport" },
  {
    label: "Utilities, communication and financial services",
    value: "UtilitiesCommunicationFinancial",
  },
];

const KeyWorkerRadio = ({
  isKeyWorker,
  onIsKeyWorkerChange,
  keyWorkerType,
  onKeyWorkerTypeChange,
  keyWorkerEmployer,
  onKeyWorkerEmployerChange,
}) => {
  const classes = useStyles();
  const { getText } = useBrand();
  return (
    <Fragment>
      <YesNoPicker
        labelKey="key-worker.yesNoLabel"
        selected={isKeyWorker}
        onChange={onIsKeyWorkerChange}
      />

      {isKeyWorker && (
        <Fragment>
          <div className={classes.wrapper}>
            <Select
              label={getText("key-worker.keyWorkerTypeLabel")}
              value={keyWorkerType}
              data-test="key-worker-type"
              name="key-worker-type"
              onChange={e => {
                e.preventDefault();
                onKeyWorkerTypeChange(e.target.value);
              }}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WorkOutlineOutlined
                      className={classes.selectInputAdornment}
                    />
                  </InputAdornment>
                ),
              }}
            >
              {items.map(({ label, value }) => (
                <MenuItem
                  value={value}
                  data-test={`key-worker-type-${value}`}
                  key={`key-worker-type-${value}`}
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.wrapper}>
            <TextField
              label={getText("key-worker.keyWorkerEmployerLabel")}
              placeholder={getText("key-worker.keyWorkerEmployerPlaceholder")}
              inputIconLeft="work_outline"
              value={keyWorkerEmployer}
              name="key-worker-name"
              data-test="key-worker-name"
              fullWidth
              onChange={e => {
                e.preventDefault();
                onKeyWorkerEmployerChange(e.target.value);
              }}
            />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

KeyWorkerRadio.propTypes = {
  isKeyWorker: bool.isRequired,
  onIsKeyWorkerChange: func.isRequired,
  keyWorkerType: string.isRequired,
  onKeyWorkerTypeChange: func.isRequired,
  keyWorkerEmployer: string.isRequired,
  onKeyWorkerEmployerChange: func.isRequired,
};

export default KeyWorkerRadio;
