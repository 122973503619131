import React from "react";
import { withRouter } from "react-router-dom";
import { useStateContext } from "../../../context/stateContext";
import Confirmation from "./Confirmation";
import { RETURNING_USER_JOURNEY_PATH_NAMES } from "../../../constants";

const ConfirmationWithQuery = ({ history }) => {
  const {
    state: {
      rujHistory: { topUpId },
    },
  } = useStateContext();

  const handleButtonClick = e => {
    e.preventDefault();
    history.push(`${RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT}/${topUpId}`);
  };

  return <Confirmation onButtonClick={handleButtonClick} />;
};

export default withRouter(ConfirmationWithQuery);
