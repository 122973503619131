import React from "react";
import { AppBar, makeStyles } from "@material-ui/core";
import mergeDeep from "lodash.merge";

import ProgressBar from "../ProgressBar/MobileStepper/MobileStepper";
import BackButton from "../BackButton/BackButton";

import { useBrand } from "../BrandProvider/BrandProvider";
import { getMobileAppLogo } from "../../../utils/logos";

type AppTopbarProps = {
  stepsLength?: number;
  activeStep?: number;
  hideProgressBar?: boolean;
  hideBackButton?: boolean;
  onActionClick?: () => void;
  onBackClick?: () => void;
  ActionButton?: React.FC<{
    onClick?: () => void;
  }>;
};

// @ts-ignore
const useStyles = makeStyles(theme => mergeDeep(theme.journey.appTopbar, {}));

/**
 * Component that renders a topbar, intended to be used in the mobile view only
 * @param {AppTopbarProps} props Props that the component accepts
 */
const AppTopbar: React.FC<AppTopbarProps> = ({
  activeStep = -1,
  onBackClick = () => {},
  onActionClick = () => {},
  stepsLength = 0,
  hideProgressBar = false,
  hideBackButton = false,
  ActionButton,
}) => {
  const { brand } = useBrand();
  const classes = useStyles();

  return (
    <AppBar position="relative" data-test="app-topbar">
      {/* @ts-ignore */}
      <div className={classes.backButtonWrapper}>
        {!hideBackButton ? (
          <BackButton
            onClick={onBackClick}
            data-test="mobile-global-back"
            // @ts-ignore
            className={classes.backButton}
          />
        ) : (
          <img
            src={getMobileAppLogo(brand)}
            alt="Logo"
            // @ts-ignore
            className={classes.logo}
          />
        )}
      </div>
      {!hideProgressBar && (
        <ProgressBar steps={stepsLength} activeStep={activeStep} />
      )}

      {ActionButton && <ActionButton onClick={onActionClick} />}
    </AppBar>
  );
};

export default AppTopbar;
