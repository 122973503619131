import React from "react";
import { func } from "prop-types";
import { makeStyles } from "@material-ui/core";

import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";

import { useBrand } from "../../common/BrandProvider/BrandProvider";
import Success from "../../common/Success/Success";

export const ConfirmationProps = {
  onButtonClick: func,
};

const useStyles = makeStyles(() => ({
  confirmation: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
}));

const Confirmation = ({ onButtonClick }) => {
  const { getText } = useBrand();
  const classes = useStyles();

  return (
    <ContentWrapper className={classes.confirmation}>
      <Success
        title={getText("requestRefund/confirmation.pageTitle")}
        buttonText={getText("requestRefund/confirmation.buttonText")}
        handleButtonClick={onButtonClick}
      >
        {getText("requestRefund/confirmation.confirmationText")}
      </Success>
    </ContentWrapper>
  );
};

Confirmation.propTypes = ConfirmationProps;

export default Confirmation;
