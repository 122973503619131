import React, { Fragment } from "react";
import { bool, func } from "prop-types";
import { Button, Typography } from "@material-ui/core";
import { useBrand } from "../../BrandProvider/BrandProvider";
import Modal from "../../Modal";

const FormInstructorPickerTraineeModal = ({
  showModal = false,
  toggleModal = () => {},
}) => {
  const { getText } = useBrand();
  return (
    <Modal
      showModal={showModal}
      toggleModal={toggleModal}
      title="Trainee Instructors"
      content={
        <Fragment>
          <Typography gutterBottom>
            {getText("prices.traineeModalContent")}
          </Typography>
        </Fragment>
      }
      actions={
        <Button
          data-test="trainee-modal-button"
          onClick={toggleModal}
          color="primary"
          autoFocus
        >
          Got it
        </Button>
      }
    />
  );
};

FormInstructorPickerTraineeModal.propTypes = {
  showModal: bool,
  toggleModal: func,
};

export default FormInstructorPickerTraineeModal;
