import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import ContentWrapper from "../ContentWrapper/ContentWrapper";
import cornerImage from "../../../png/corner.png";
import { useTealium } from "../TealiumProvider/TealiumProvider";

const useStyles = makeStyles(theme => ({
  appFooter: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    overflow: "hidden",
    minHeight: "160px",
    display: "flex",
    alignItems: "flex-end",
  },
  appFooterCorner: {
    position: "absolute",
    bottom: 0,
    right: 0,
    border: "none",
    margin: 0,
    padding: 0,
    outline: "none",
  },
  appFooterCornerImage: {
    display: "block",
  },
  appFooterCat: {
    display: "block",
    transform: "translateY(200px)",
    transition: "all 300ms ease-in-out",
    color: theme.palette.primary.main,
    fontSize: "1.1rem",
    "&.--visible": {
      transform: "translateY(0)",
    },
  },
}));

const AppFooter = () => {
  const classes = useStyles();
  const { trackEvent } = useTealium();
  const [mascotVisible, setMascotVisible] = useState(false);

  return (
    <footer className={classes.appFooter}>
      <ContentWrapper fullHeight>
        {/* eslint-disable */}
        <span
          className={
            mascotVisible
              ? `${classes.appFooterCat} --visible`
              : classes.appFooterCat
          }
        >
          /\_/\
          <br />
          (='_' )<br />
          (, (") ("),,,,,,,,
        </span>
        {/* eslint-enable */}
      </ContentWrapper>
      <button
        type="button"
        className={classes.appFooterCorner}
        onClick={() => {
          setMascotVisible(!mascotVisible);
          trackEvent({
            eventCategory: "Mascot Reveal",
            eventAction: "click",
          });
        }}
      >
        <img
          src={cornerImage}
          className={classes.appFooterCornerImage}
          alt=""
        />
      </button>
    </footer>
  );
};
export default AppFooter;
