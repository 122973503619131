import * as React from "react";
import { ApolloClient } from "@apollo/client";

import { Field, FieldInputProps, withFormik } from "formik";
import {
  isValid as isValidDate,
  subYears,
  startOfDay,
  isAfter,
} from "date-fns";
import {
  makeStyles,
  Link,
  CircularProgress,
  Box,
  Typography,
  Button,
  Theme,
} from "@material-ui/core";
import { ReportProblem, ArrowBack } from "@material-ui/icons";

import { useLocation, useHistory } from "react-router-dom";
import merge from "lodash.merge";

import { ReactComponent as GiftIcon } from "../../../../svg/aa-gift-icon.svg";
import AddressPicker from "../../../common/Form/AddressPicker/AddressPicker";
import Checkbox from "../../../common/Form/Checkbox/Checkbox";
import ContentWrapper from "../../../common/ContentWrapper/ContentWrapper";
import DobPicker from "../../../common/Form/DobPicker/DobPicker";
import HonoraryTitlePicker from "../../../common/Form/HonoraryTitlePicker/HonoraryTitlePicker";
import InfoPanel from "../../../common/InfoPanel/InfoPanel";
import MarketingPreferences from "../../../common/Form/MarketingPreferences/MarketingPreferences";
import MarketingPreferencesNotice from "./MarketingPreferencesNotice";
import Postcode from "../../../common/Form/PostCode/PostCode";
import SubmitButton from "../../../common/SubmitButton/SubmitButton";
import SummaryModal from "./SummaryModal";
import TextField from "../../../common/Form/TextField/TextField";
import Titlebar from "../../../common/Titlebar/Titlebar";
import YesNoPicker from "../../../common/Form/YesNoPicker/YesNoPicker";

import {
  BSM_BRAND_NAME,
  NEW_USER_JOURNEY_PATH_NAMES,
  getJourneyURL,
} from "../../../../constants";
import { ReactComponent as GiftSurpriseIconBSM } from "../../../../svg/bsm-gift-surprise-icon.svg";
import {
  UPDATE_NUJ_HISTORY,
  useStateContext,
} from "../../../../context/stateContext";
import { capitalizeEachWordOfPathname } from "../../../../utils/capitalisePathname";
import { getSurpriseIcon, validateForm } from "./utils.js";
import { goBack } from "../../../../utils/navigation";
import {
  isValidPostcodeFormat,
  validateEmail,
  validatePhoneNumber,
  validateText,
} from "../../../../utils/validation";
import { soapTimeToLocalTime } from "../../../../utils/soapTimeToLocalTime";
import { useTealium } from "../../../common/TealiumProvider/TealiumProvider";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import useAddressList from "../../../../hooks/useAddressList";
import useLazyQueryEmailAddressExistsCheck from "../../../../hooks/useLazyQueryEmailAddressExistsCheck";
import useLazyQueryGetFullAddress from "../../../../hooks/useLazyQueryGetFullAddress";

import "./gift-animation.css";
import { PersonalDetailsFormValues } from "./types";
import { GiftType } from "../../../../models/state";

interface CustomTheme extends Theme {
  journey: {
    personalDetails: Record<string, any>;
  };
}

const useStyles = makeStyles<CustomTheme>(theme => ({
  ...merge(theme.journey.personalDetails, {
    wrapper: {
      paddingBottom: "100px",
    },
    fieldWrapper: {
      marginTop: "30px",
      [theme.breakpoints.up("md")]: {
        marginTop: "40px",
      },
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "center",
    },
    editButton: {
      marginLeft: "25px",
    },
    svg: {
      margin: "0px",
      width: "33px",
      height: "29px",
      color: theme.palette.error.main,
    },
    giftSvg: {
      marginTop: "-0.1em",
      marginRight: "0.5em",
    },
    backButton: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: "6px",
      color: theme.palette.grey[500],
      fontSize: "14px",
      height: "44px",
      display: "flex",
      padding: "10px",
      marginTop: "20px",
    },
    arrowIcon: {
      marginRight: "10px",
    },
    infoPanel: {
      paddingTop: "1em",
      paddingBottom: "0px",
    },
  }),
}));

/* Wraps form fields to apply margins */
const FieldWrapper: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.fieldWrapper}>{children}</div>;
};

const NewUserJourneyStepsPersonalDetails = ({
  isValid,
  setFieldValue,
  setFieldTouched,
  values,
  setValues,
  errors,
  touched,
  onSubmit,
  isSubmittingOrder,
  apolloClient,
}: {
  isValid: boolean;
  setFieldValue: (field: string, value: any) => void;
  setFieldTouched: (field: string, touched: boolean) => void;
  values: PersonalDetailsFormValues & Record<string, any>;
  setValues: (values: PersonalDetailsFormValues) => void;
  errors: Record<string, string>;
  touched: Record<string, boolean>;
  onSubmit: (values: PersonalDetailsFormValues) => void;
  isSubmittingOrder: boolean;
  apolloClient: ApolloClient<unknown>;
}) => {
  const { getText, brand } = useBrand();
  const classes = useStyles();
  const { trackEvent, updateDataLayer } = useTealium();
  const { pathname } = useLocation();
  const history = useHistory();

  const {
    billingPostcode,
    billingAddress,
    pickupAddress,
    dateOfBirth,
    emailAddress,
    firstName,
    surname,
    mobileNumber,
    honoraryTitle,
    isGift,
    shouldBeASurprise,
    giftGiversEmail,
    giftGiversMobile,
    marketingPreferences,
  } = values;

  const {
    state: { nujHistory },
    dispatch,
  } = useStateContext();

  const {
    pickupPostcode,
    selectedTransmissionType,
    selectedLessonTimeslot,
    selectedPackage,
    selectedInstructor,
  } = nujHistory;

  const startTime = selectedLessonTimeslot?.startTime || new Date();
  const discountedPackagePrice = selectedPackage?.discountedPackagePrice;
  const hours = selectedPackage?.hours;

  const instructorName = selectedInstructor?.instructor.name || "";
  const instructorFirstName = instructorName.split(" ")[1];
  const instructorLastName = instructorName.split(" ")[2];

  const [sameBillingAddress, setSameBillingAddress] = React.useState(true);
  const [dateOfBirthError, setDateOfBirthError] = React.useState(false);
  const [showSummaryModal, setShowSummaryModal] = React.useState(false);
  const [
    isEmailAddressUnavailable,
    setIsEmailAddressUnavailable,
  ] = React.useState(false);

  const {
    isLoading: isLoadingPickupAddresses,
    addressList: pickupAddresses,
  } = useAddressList(pickupPostcode, apolloClient, history);
  const {
    isLoading: isLoadingBillingAddresses,
    addressList: billingAddresses,
  } = useAddressList(billingPostcode, apolloClient, history);

  const [
    getEmailAddress,
    { loading: emailCheckLoading },
  ] = useLazyQueryEmailAddressExistsCheck({
    onCompleted: data => {
      if (data?.emailAddressExistsCheck.exists) {
        setIsEmailAddressUnavailable(true);
      } else {
        setIsEmailAddressUnavailable(false);
      }
    },
  });

  const [
    getFullPickupAddress,
    { loading: fullPickupAddressLoading },
  ] = useLazyQueryGetFullAddress({
    onCompleted: data => {
      if (data?.getFullAddress) {
        setFieldValue("pickupAddress", data.getFullAddress);
      } else {
        setFieldValue("pickupAddress", "");
      }
    },
  });

  const [
    getFullBillingAddress,
    { loading: fullBillingAddressLoading },
  ] = useLazyQueryGetFullAddress({
    onCompleted: data => {
      if (data?.getFullAddress) {
        setFieldValue("billingAddress", data.getFullAddress);
      } else {
        setFieldValue("billingAddress", "");
      }
    },
  });

  const handleEmailAddressCheck = () => {
    getEmailAddress({
      variables: {
        input: { emailAddress },
      },
    });
  };

  const handleGetFullAddress = (
    addressId: string,
    addressType: "pickupAddress" | "billingAddress",
  ) => {
    if (addressType === "billingAddress") {
      getFullBillingAddress({
        variables: {
          id: addressId,
        },
      });
    }

    if (addressType === "pickupAddress") {
      getFullPickupAddress({
        variables: {
          id: addressId,
        },
      });
    }
  };

  const toggleSummaryModal = (event?: any) => {
    if (event) {
      event.preventDefault();
    }
    setShowSummaryModal(!showSummaryModal);
  };

  const handleSubmit = () => {
    dispatch({
      type: UPDATE_NUJ_HISTORY,
      payload: {
        tempPupilDetailFormValues: undefined,
        pupilDetails: {
          billingAddressId: billingAddress || pickupAddress,
          pickupAddressId: pickupAddress,
          dateOfBirth,
          emailAddress,
          firstName,
          surname,
          mobileNumber,
          honoraryTitle,
          giftGiversEmail,
          giftGiversMobile,
        },
        confirmation: {
          gift: !isGift
            ? GiftType.NOT_GIFT
            : shouldBeASurprise
            ? GiftType.SURPRISE
            : GiftType.GIFT,
        },
      },
    });
    onSubmit({
      ...values,
      ...nujHistory,
    });
  };

  const acceptSummary = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    toggleSummaryModal();
    handleSubmit();
  };

  const resetBillingAddressFields = () => {
    const updatedValues = values;
    delete updatedValues.billingAddress;
    delete updatedValues.billingPostcode;

    setValues(updatedValues);
  };

  const back = () => goBack({ history, pathname });

  const handleBackButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    back();
  };

  const toggleSurpriseAnimClass = () => {
    const surprise = document.getElementById("surprise");
    if (!surprise) return;
    const operation = shouldBeASurprise ? "add" : "remove";
    surprise.classList[operation]("close--lid");
  };
  React.useEffect(toggleSurpriseAnimClass, [toggleSurpriseAnimClass]);

  React.useEffect(() => {
    if (isEmailAddressUnavailable) {
      trackEvent({
        eventCategory: "Personal Details",
        eventAction: "Form Field Error",
        eventLabel: "Email address already in use. Please try another.",
      });
    }
  }, [isEmailAddressUnavailable]);

  /** Store form state and navigate back to lesson selection */
  const reselectLesson = () => {
    dispatch({
      type: UPDATE_NUJ_HISTORY,
      payload: {
        tempPupilDetailFormValues: {
          billingAddress: billingAddress || pickupAddress,
          pickupAddress: pickupAddress,
          dateOfBirth,
          emailAddress,
          firstName,
          surname,
          mobileNumber,
          honoraryTitle,
          giftGiversEmail,
          giftGiversMobile,
          termsAndConditions: false, // Ensure learner has to re-confirm
          isGift,
          shouldBeASurprise,
          marketingPreferences,
        },
      },
    });
    back();
  };

  const seventeenYearsBeforeLesson = startOfDay(
    subYears(new Date(startTime), 17),
  );

  const isUnderAge =
    dateOfBirth &&
    isAfter(startOfDay(new Date(dateOfBirth)), seventeenYearsBeforeLesson);

  return (
    <ContentWrapper classes={{ wrapper: classes.wrapper }}>
      <Titlebar showBackButton onBackClick={handleBackButtonClick}>
        {getText("personal-details.pageTitle")}
      </Titlebar>

      <form onSubmit={toggleSummaryModal} data-test="details-form">
        <Field name="honoraryTitle">
          {({ field }: { field: FieldInputProps<any> }) => (
            <HonoraryTitlePicker
              {...field}
              label={getText("personal-details.honoraryTitle")}
              onChange={(title: string) => {
                setFieldValue(field.name, title);
                trackEvent({
                  eventCategory: "Personal Details",
                  eventAction: "Interaction",
                  eventLabel: title,
                });
                updateDataLayer({
                  title: title,
                });
              }}
              disabled={isSubmittingOrder}
            />
          )}
        </Field>

        <Field name="firstName" validate={validateText}>
          {({
            field,
          }: {
            field: FieldInputProps<PersonalDetailsFormValues>;
          }) => (
            <FieldWrapper>
              <TextField
                {...field}
                label={getText("personal-details.firstName")}
                inputIconLeft="face"
                fullWidth
                disabled={isSubmittingOrder}
                error={!!(touched[field.name] && errors[field.name])}
                helperText={
                  touched[field.name] && errors[field.name]
                    ? getText(errors[field.name], { fieldName: "first name" })
                    : undefined
                }
                onFocus={() => {
                  if (!touched[field.name]) {
                    trackEvent({
                      eventCategory: "Personal Details",
                      eventAction: "Interaction",
                      eventLabel: "First Name",
                    });
                    setFieldTouched(field.name, true);
                  }
                }}
                onBlur={() => {
                  if (
                    values[field.name] &&
                    touched[field.name] &&
                    errors[field.name]
                  ) {
                    trackEvent({
                      eventCategory: "Personal Details",
                      eventAction: "Form Field Error",
                      eventLabel: getText(errors[field.name], {
                        fieldName: "first name",
                      }),
                    });
                  }
                }}
              />
            </FieldWrapper>
          )}
        </Field>

        <Field name="surname" validate={validateText}>
          {({ field }: { field: FieldInputProps<any> }) => (
            <FieldWrapper>
              <TextField
                {...field}
                label={getText("personal-details.surname")}
                inputIconLeft="face"
                fullWidth
                disabled={isSubmittingOrder}
                error={!!(touched[field.name] && errors[field.name])}
                helperText={
                  touched[field.name] && errors[field.name]
                    ? getText(errors[field.name], { fieldName: "surname" })
                    : undefined
                }
                onFocus={() => {
                  if (!touched[field.name]) {
                    trackEvent({
                      eventCategory: "Personal Details",
                      eventAction: "Interaction",
                      eventLabel: "Surname",
                    });
                    setFieldTouched(field.name, true);
                  }
                }}
                onBlur={() => {
                  if (
                    values[field.name] &&
                    touched[field.name] &&
                    errors[field.name]
                  ) {
                    trackEvent({
                      eventCategory: "Personal Details",
                      eventAction: "Form Field Error",
                      eventLabel: getText(errors[field.name], {
                        fieldName: "surname",
                      }),
                    });
                  }
                }}
              />
            </FieldWrapper>
          )}
        </Field>

        <Field name="mobileNumber" validate={validatePhoneNumber}>
          {({ field }: { field: FieldInputProps<any> }) => (
            <FieldWrapper>
              <TextField
                {...field}
                label={getText("personal-details.mobile")}
                inputIconLeft="phone_iphone"
                fullWidth
                type="tel"
                disabled={isSubmittingOrder}
                error={!!(touched[field.name] && errors[field.name])}
                helperText={
                  touched[field.name] && errors[field.name]
                    ? getText(errors[field.name])
                    : undefined
                }
                onFocus={() => {
                  if (!touched[field.name]) {
                    trackEvent({
                      eventCategory: "Personal Details",
                      eventAction: "Interaction",
                      eventLabel: "Mobile",
                    });
                    setFieldTouched(field.name, true);
                  }
                }}
                onBlur={() => {
                  if (
                    values[field.name] &&
                    touched[field.name] &&
                    errors[field.name]
                  ) {
                    trackEvent({
                      eventCategory: "Personal Details",
                      eventAction: "Form Field Error",
                      eventLabel: getText(errors[field.name]),
                    });
                  }
                }}
              />
            </FieldWrapper>
          )}
        </Field>

        <Field name="emailAddress" validate={validateEmail}>
          {({ field }: { field: FieldInputProps<any> }) => (
            <FieldWrapper>
              <TextField
                {...field}
                label={getText("personal-details.email")}
                inputIconLeft="mail_outline"
                type="email"
                fullWidth
                disabled={isSubmittingOrder || emailCheckLoading}
                error={
                  !!(touched[field.name] && errors[field.name]) ||
                  isEmailAddressUnavailable
                }
                helperText={
                  isEmailAddressUnavailable
                    ? "Email address already in use. Please try another."
                    : touched[field.name] && errors[field.name]
                    ? getText(errors[field.name])
                    : undefined
                }
                onFocus={() => {
                  if (!touched[field.name]) {
                    trackEvent({
                      eventCategory: "Personal Details",
                      eventAction: "Interaction",
                      eventLabel: "Email",
                    });
                    setFieldTouched(field.name, true);
                  }
                }}
                onBlur={() => {
                  handleEmailAddressCheck();
                  if (
                    values[field.name] &&
                    touched[field.name] &&
                    errors[field.name]
                  ) {
                    trackEvent({
                      eventCategory: "Personal Details",
                      eventAction: "Form Field Error",
                      eventLabel: getText(errors[field.name]),
                    });
                  }
                }}
              />
            </FieldWrapper>
          )}
        </Field>

        <Field name="dateOfBirth">
          {({ field }: { field: FieldInputProps<any> }) => (
            <FieldWrapper>
              <DobPicker
                {...field}
                error={isUnderAge || dateOfBirthError}
                maxDate={seventeenYearsBeforeLesson}
                // set as blank string for DoB error as it is handled in separate component
                minDateMessage=""
                maxDateMessage=""
                helperText=""
                onError={(error: boolean) => setDateOfBirthError(!!error)}
                onChange={(date: Date) => {
                  if (isValidDate(date)) {
                    const normalizedDate = new Date(
                      date.getTime() - date.getTimezoneOffset() * 60000,
                    ).toISOString();

                    setFieldValue(field.name, normalizedDate);
                    setDateOfBirthError(false);
                  } else {
                    setFieldValue(field.name, "");
                    setDateOfBirthError(true);
                  }
                }}
                fullWidth
                disabled={isSubmittingOrder}
                label={getText("common.dobPickerLabel")}
                onFocus={() => {
                  if (!touched[field.name]) {
                    trackEvent({
                      eventCategory: "Personal Details",
                      eventAction: "Interaction",
                      eventLabel: "DOB",
                    });
                    setFieldTouched(field.name, true);
                  }
                }}
                onBlur={() => {
                  if (touched[field.name] && errors[field.name]) {
                    trackEvent({
                      eventCategory: "Personal Details",
                      eventAction: "Form Field Error",
                      eventLabel: dateOfBirthError ? "Date of Birth Error" : "",
                    });
                  }
                }}
              />
            </FieldWrapper>
          )}
        </Field>

        {isUnderAge && (
          <InfoPanel
            rootClasses={classes.infoPanel}
            svg={<ReportProblem />}
            svgClasses={classes.svg}
            title={getText("personal-details.maxDobMessage")}
          >
            <Typography variant="body2">
              {getText("personal-details.selectAlternateLesson")}
            </Typography>

            <Button onClick={reselectLesson} className={classes.backButton}>
              <ArrowBack className={classes.arrowIcon} />
              Go back to lesson selection
            </Button>
          </InfoPanel>
        )}

        <Field name="pickupAddress">
          {({ field }: { field: FieldInputProps<any> }) => (
            <FieldWrapper>
              <AddressPicker
                {...field}
                isLoading={isLoadingPickupAddresses}
                onChangeAddress={(address: string) => {
                  handleGetFullAddress(address, "pickupAddress");
                  setFieldValue(field.name, address);
                  trackEvent({
                    eventCategory: "Personal Details",
                    eventAction: "Interaction",
                    eventLabel: "Pickup Address",
                  });
                }}
                addresses={pickupAddresses || []}
                label={
                  <p>
                    {getText("personal-details.choosePickup")}
                    {!isSubmittingOrder && (
                      <Link
                        href={`${getJourneyURL(brand)}${
                          NEW_USER_JOURNEY_PATH_NAMES.PICKUP
                        }`}
                        classes={{ root: classes.editButton }}
                        data-test="personal-details-reset-pickup"
                        component="a"
                      >
                        {getText("personal-details.edit")}
                      </Link>
                    )}
                  </p>
                }
                disabled={isSubmittingOrder}
              />
            </FieldWrapper>
          )}
        </Field>

        <FieldWrapper>
          <YesNoPicker
            onChange={(value: boolean) => {
              setSameBillingAddress(value);
              trackEvent({
                eventCategory: "Personal Details",
                eventAction: "Interaction",
                eventLabel: `Billing Address - ${value ? "Yes" : "No"}`,
              });

              // reset billing address if same as billing
              if (value) {
                resetBillingAddressFields();
              }
            }}
            disabled={isSubmittingOrder}
            data-test="is-same-billing-address"
          />
        </FieldWrapper>

        {!sameBillingAddress && (
          <React.Fragment>
            <Field name="billingPostcode">
              {({ field }: { field: FieldInputProps<any> }) => (
                <FieldWrapper>
                  <Postcode
                    {...field}
                    fullWidth
                    onChange={({ target: { value } }) => {
                      setFieldValue(field.name, value.toUpperCase());
                    }}
                    data-test="personal-details-billing-postcode"
                    disabled={isSubmittingOrder}
                    onFocus={() => {
                      if (!touched[field.name]) {
                        trackEvent({
                          eventCategory: "Personal Details",
                          eventAction: "Interaction",
                          eventLabel: "Billing Postcode",
                        });
                        setFieldTouched(field.name, true);
                      }
                    }}
                  />
                </FieldWrapper>
              )}
            </Field>

            {isValidPostcodeFormat(values.billingPostcode) && (
              <Field name="billingAddress">
                {({ field }: { field: FieldInputProps<any> }) => (
                  <FieldWrapper>
                    <AddressPicker
                      {...field}
                      isLoading={isLoadingBillingAddresses}
                      onChangeAddress={(address: string) => {
                        handleGetFullAddress(address, "billingAddress");
                        setFieldValue(field.name, address);
                        trackEvent({
                          eventCategory: "Personal Details",
                          eventAction: "Interaction",
                          eventLabel: "Billing Address",
                        });
                      }}
                      addresses={billingAddresses || []}
                      disabled={isSubmittingOrder}
                      label={getText("personal-details.chooseBilling")}
                    />
                  </FieldWrapper>
                )}
              </Field>
            )}
          </React.Fragment>
        )}

        <Field name="isGift">
          {({ field }: { field: FieldInputProps<any> }) => (
            <FieldWrapper>
              <YesNoPicker
                selected={field.value}
                labelKey="personal-details.yesNoIsGift"
                onChange={value => {
                  trackEvent({
                    eventCategory: "Personal Details",
                    eventAction: "Interaction",
                    eventLabel: `Gift Surprise - ${value ? "Yes" : "No"}`,
                  });
                  setFieldValue(field.name, value);
                }}
                disabled={isSubmittingOrder}
                leftIcon={<GiftIcon className={classes.giftIcon} />}
                data-test="is-a-gift-picker"
              />
            </FieldWrapper>
          )}
        </Field>

        {isGift && (
          <React.Fragment>
            <Field name="shouldBeASurprise">
              {({ field }: { field: FieldInputProps<any> }) => (
                <FieldWrapper>
                  <YesNoPicker
                    selected={field.value}
                    labelKey="personal-details.yesNoShouldBeSurprise"
                    onChange={value => {
                      trackEvent({
                        eventCategory: "Personal Details",
                        eventAction: "Interaction",
                        eventLabel: `Gift Surprise to Learner - ${
                          value ? "Yes" : "No"
                        }`,
                      });
                      setFieldValue(field.name, value);
                    }}
                    disabled={isSubmittingOrder}
                    data-test="should-be-a-surprise-picker"
                  />
                </FieldWrapper>
              )}
            </Field>

            {brand === BSM_BRAND_NAME && (
              <Box display="flex" mt={2}>
                <GiftSurpriseIconBSM />
              </Box>
            )}

            {shouldBeASurprise && (
              <React.Fragment>
                <Field name="giftGiversEmail" validate={validateEmail}>
                  {({ field }: { field: FieldInputProps<any> }) => (
                    <FieldWrapper>
                      <TextField
                        {...field}
                        label={getText("personal-details.giftGiversEmail")}
                        fullWidth
                        disabled={isSubmittingOrder}
                        error={!!(touched[field.name] && errors[field.name])}
                        helperText={
                          touched[field.name] && errors[field.name]
                            ? getText(errors[field.name], {
                                fieldName: "Gift Surprise - Email",
                              })
                            : undefined
                        }
                        onFocus={() => {
                          if (!touched[field.name]) {
                            trackEvent({
                              eventCategory: "Personal Details",
                              eventAction: "Interaction",
                              eventLabel: "Gift Surprise - Email",
                            });
                            setFieldTouched(field.name, true);
                          }
                        }}
                        onBlur={() => {
                          if (
                            values[field.name] &&
                            touched[field.name] &&
                            errors[field.name]
                          ) {
                            trackEvent({
                              eventCategory: "Personal Details",
                              eventAction: "Form Field Error",
                              eventLabel: getText(errors[field.name], {
                                fieldName: "Gift Surprise - Email",
                              }),
                            });
                          }
                        }}
                        data-test="gift-givers-email-input"
                      />
                    </FieldWrapper>
                  )}
                </Field>

                <Field name="giftGiversMobile" validate={validatePhoneNumber}>
                  {({ field }: { field: FieldInputProps<any> }) => (
                    <FieldWrapper>
                      <TextField
                        {...field}
                        label={getText("personal-details.giftGiversMobile")}
                        fullWidth
                        type="tel"
                        disabled={isSubmittingOrder}
                        error={!!(touched[field.name] && errors[field.name])}
                        helperText={
                          touched[field.name] && errors[field.name]
                            ? getText(errors[field.name], {
                                fieldName: "Gift Surprise - Mobile",
                              })
                            : undefined
                        }
                        onFocus={() => {
                          if (!touched[field.name]) {
                            trackEvent({
                              eventCategory: "Personal Details",
                              eventAction: "Interaction",
                              eventLabel: "Gift Surprise - Mobile",
                            });
                            setFieldTouched(field.name, true);
                          }
                        }}
                        onBlur={() => {
                          if (
                            values[field.name] &&
                            touched[field.name] &&
                            errors[field.name]
                          ) {
                            trackEvent({
                              eventCategory: "Personal Details",
                              eventAction: "Form Field Error",
                              eventLabel: getText(errors[field.name], {
                                fieldName: "Gift Surprise - Mobile",
                              }),
                            });
                          }
                        }}
                        data-test="gift-givers-mobile-input"
                      />
                    </FieldWrapper>
                  )}
                </Field>
              </React.Fragment>
            )}

            <InfoPanel
              svg={getSurpriseIcon(brand, shouldBeASurprise, classes)}
              svgClasses={classes.giftSvg}
              svgFlexAlignItems="flex-start"
              title={getText(
                `personal-details.giftInfoPanel${
                  shouldBeASurprise ? "" : "No"
                }SurpriseTitle`,
              )}
              data-test="gift-surprise-info-panel"
            >
              {getText(
                `personal-details.giftInfoPanel${
                  shouldBeASurprise ? "" : "No"
                }SurpriseBody`,
              )}
            </InfoPanel>
          </React.Fragment>
        )}

        {!isGift && (
          <Field name="marketingPreferences">
            {({ field }: { field: FieldInputProps<any> }) => (
              <FieldWrapper>
                <MarketingPreferences
                  {...field}
                  onChange={preferences => {
                    setFieldValue(field.name, preferences);
                  }}
                  label={getText("personal-details.marketingPrefsLabel")}
                  helperText={<MarketingPreferencesNotice />}
                  disabled={isSubmittingOrder}
                />
              </FieldWrapper>
            )}
          </Field>
        )}

        <Field name="termsAndConditions">
          {({ field }: { field: FieldInputProps<any> }) => (
            <FieldWrapper>
              <Checkbox
                {...field}
                indeterminate={false}
                helperText=""
                legend={
                  <span>
                    {getText("personal-details.tsandcsBrand")} &nbsp;
                    <Link
                      href={getText("personal-details.tsandcsLink")}
                      target="_blank"
                    >
                      {getText("personal-details.tsandcs")}
                    </Link>
                  </span>
                }
                label={getText("personal-details.tsandcsConfirmLabel")}
                disabled={isSubmittingOrder}
                onClick={() => {
                  handleEmailAddressCheck();
                  trackEvent({
                    eventCategory: "Personal Details",
                    eventAction: "Interaction",
                    eventLabel: `Terms & Conditions - ${
                      field.value ? "No" : "Yes"
                    }`,
                  });
                }}
              />
            </FieldWrapper>
          )}
        </Field>

        <div className={classes.buttonWrapper}>
          <SubmitButton
            text={
              isSubmittingOrder ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                getText("personal-details.bookNow")
              )
            }
            data-test="book-button"
            disabled={
              !isValid ||
              dateOfBirthError ||
              isUnderAge ||
              isSubmittingOrder ||
              emailCheckLoading ||
              fullPickupAddressLoading ||
              fullBillingAddressLoading ||
              isEmailAddressUnavailable
            }
            onClick={() => {
              trackEvent({
                eventCategory: "Personal Details",
                eventAction: "Button Click",
                eventLabel: "Book Now",
              });
            }}
          />
        </div>
      </form>
      <SummaryModal
        showModal={showSummaryModal}
        onAccept={acceptSummary}
        toggleModal={toggleSummaryModal}
        startTime={soapTimeToLocalTime(startTime)}
        duration={2}
        firstName={instructorFirstName}
        lastName={instructorLastName}
        totalCost={discountedPackagePrice || 0}
        hoursBooked={hours || 0}
        transmissionType={selectedTransmissionType}
      />
    </ContentWrapper>
  );
};

export default withFormik<any, PersonalDetailsFormValues>({
  mapPropsToValues: ({ initialValues }) => initialValues,
  mapPropsToErrors: ({ errors }) => errors,
  mapPropsToTouched: ({ touched }) => touched,
  handleSubmit: () => {},
  validate: validateForm,
  validateOnMount: true,
})(NewUserJourneyStepsPersonalDetails);
