import { GiftType } from "../../../../../models/state";
import React from "react";

export const bulletList = ({
  gift,
  setupAccountLink,
  accountLinkClassName,
}: {
  gift: GiftType;
  setupAccountLink: string;
  accountLinkClassName?: string;
}): React.FC[] => {
  if (gift === GiftType.NOT_GIFT) {
    return [
      () => (
        <>
          Finish setting up{" "}
          <strong>
            <a href={setupAccountLink} className={accountLinkClassName}>
              your learner account
            </a>
          </strong>
        </>
      ),
      () => <>Don’t forget your provisional licence</>,
      () => <>If you need glasses, bring those too</>,
      () => <>Wear comfy, flat soled shoes</>,
      () => <>Relax! You got this :-)</>,
    ];
  }

  return [
    () => <>Log in to their learner account before their first lesson</>,
    () => <>Bring their provisional licence</>,
    () => <>If they need glasses, bring those too</>,
    () => <>Wear comfy, flat soled shoes</>,
  ];
};
