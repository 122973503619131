import React from "react";
import mergeDeep from "lodash.merge";
import { Avatar, makeStyles, Theme, useTheme } from "@material-ui/core";

import ItemList, { ItemListItemConfig } from "../../common/ItemList/ItemList";

interface CustomTheme extends Theme {
  journey: any;
}

const useStyles = makeStyles<CustomTheme>(theme =>
  mergeDeep(theme.journey.bookedLessons, {
    secondaryText: {
      color: theme.palette.common.black,
      fontSize: "0.75rem",
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: 1.2,
      opacity: 0.6,
      marginBottom: "4px",
      display: "block",
    },
  }),
);

type LessonSlotItemListProps = {
  lessonSlots: ItemListItemConfig[];
  isUpcomingLesson?: boolean;
};

const LessonSlotItemList: React.FC<LessonSlotItemListProps> = ({
  lessonSlots,
  isUpcomingLesson,
}) => {
  const classes = useStyles();
  const theme = useTheme<CustomTheme>();

  return (
    <ItemList
      selected={isUpcomingLesson ? lessonSlots.map(item => item.id) : []}
      renderAvatar={avatar => (
        <Avatar classes={{ root: classes.avatar }}>{avatar}</Avatar>
      )}
      renderIcon={() =>
        !isUpcomingLesson &&
        React.createElement(theme.journey.bookedLessonsListIcon, {
          color: theme.journey.bookedLessonsListIconColor,
        })
      }
      listItemProps={{ button: false }}
      renderSecondaryText={(_, text) => (
        <span className={classes.secondaryText}>{text}</span>
      )}
      items={lessonSlots}
    />
  );
};

export default LessonSlotItemList;
