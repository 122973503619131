import React, { ReactNode } from "react";
import get from "lodash.get";
import { Box, List, Theme, makeStyles } from "@material-ui/core";
import Item from "./Item/Item";

export type ItemListItemConfig = {
  id: string;
  text: string;
  secondaryText: string;
  avatar: React.ReactNode;
  selectedAvatar: React.ReactNode;
  actions: React.ReactNode;
  icon: React.ReactNode;
};

const useStyles = makeStyles(
  (theme: Theme & { journey: Record<string, any> }) => ({
    box: {
      paddingLeft: "4px",
    },
    selectedBox: {
      borderLeft: `4px solid ${theme.journey.itemListItem.selectedBorder}`,
    },
  }),
);

type renderOptions = {
  id: string;
  selected: boolean;
  disabled: boolean;
};

type ItemListProps = {
  selected: string[];
  onClick?: (_id: string) => void;
  items: ItemListItemConfig[];
  disabled?: boolean;
  renderSecondaryText: (_: boolean, _text: string) => void;
  renderAvatar: (
    _avatar: ReactNode,
    _selectedAvatar: ReactNode,
    _options: renderOptions,
  ) => void;
  renderActions?: (_actions: ReactNode, _options: renderOptions) => void;
  renderIcon: (_icon: ReactNode, _options: renderOptions) => void;
  listProps?: Record<string, unknown>;
  listItemProps: Record<string, unknown>;
};

const ItemList: React.FC<ItemListProps> = ({
  selected,
  onClick = () => {},
  items = [],
  disabled = false,
  renderSecondaryText,
  renderAvatar,
  renderActions,
  renderIcon,
  listProps = {},
  listItemProps = {},
}) => {
  const classes = useStyles();
  return (
    <List {...listProps}>
      {items &&
        items.map((item, idx) => {
          const id = get(item, "id", `id-${idx}`);
          const isSelected =
            Boolean(selected) && selected.includes(get(item, "id"));
          return (
            <Box
              className={isSelected ? classes.selectedBox : classes.box}
              key={id}
            >
              <Item
                {...listItemProps}
                onClick={() => onClick(id)}
                disabled={disabled}
                text={get(item, "text", "")}
                secondaryText={
                  renderSecondaryText &&
                  renderSecondaryText(
                    get(item, "isTrainee"),
                    get(item, "secondaryText", ""),
                  )
                }
                avatar={
                  renderAvatar &&
                  get(item, "avatar") &&
                  get(item, "selectedAvatar") &&
                  renderAvatar(
                    get(item, "avatar"),
                    get(item, "selectedAvatar"),
                    {
                      id,
                      selected: isSelected,
                      disabled,
                    },
                  )
                }
                actions={
                  renderActions &&
                  get(item, "actions") &&
                  renderActions(get(item, "actions"), {
                    id,
                    selected: isSelected,
                    disabled,
                  })
                }
                icon={
                  renderIcon &&
                  get(item, "icon") &&
                  renderIcon(get(item, "icon"), {
                    id,
                    selected: isSelected,
                    disabled,
                  })
                }
                selected={isSelected}
                data-test={`list-item-${id}`}
              />
            </Box>
          );
        })}
    </List>
  );
};

export default ItemList;
