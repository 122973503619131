import React, { FC, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import upperFirst from "lodash.upperfirst";
import mergeDeep from "lodash.merge";
import Modal from "../../../../common/Modal";
import Button from "../../../../common/Button/Button";
import { useBrand } from "../../../../common/BrandProvider/BrandProvider";

import LessonDetailSection from "../../../../common/LessonDetails/LessonDetailSection";
import mapIcon from "../../../../../svg/map-icon.svg";
import { formatPostcode } from "../../../../../utils";
import { getJourneyURL } from "../../../../../constants";
import { useTealium } from "../../../../common/TealiumProvider/TealiumProvider";
import { NudgeBackConfirmationProps } from "./types";

const useStyles = makeStyles(
  (theme: Theme & { journey: { lessonDetails: Record<string, any> } }) =>
    mergeDeep(theme.journey.lessonDetails, {
      actionButton: {
        margin: "15px auto",
      },
    }),
);

const NudgeBackConfirmationModal: FC<NudgeBackConfirmationProps> = ({
  postcode,
  transmissionType,
  mobileNumber,
  showModal,
}) => {
  const classes = useStyles();
  const { brand, getText } = useBrand();
  const { trackEvent } = useTealium();

  const closeModal = () => {
    trackEvent({
      eventCategory: "Prices",
      eventAction: "Modal - Click",
      eventLabel: "Close",
    });
    window.location.href = getJourneyURL(brand);
  };

  return (
    <Modal
      testId="nudge-back-confirmation-modal"
      showModal={showModal}
      toggleModal={closeModal}
      title={getText("prices.alertModalTitle")}
      content={
        <Fragment>
          <LessonDetailSection
            avatarImage={mapIcon}
            title={formatPostcode(postcode)}
            subtitle={getText("prices.alertModalPostcode")}
          />
          <LessonDetailSection
            avatarText={transmissionType[0]}
            avatarColor={classes.primaryAvatar}
            title={`${upperFirst(transmissionType.toLowerCase())} ${getText(
              "prices.alertModalTransmission",
            )}`}
            subtitle={getText("prices.alertModalTransmissionSubTitle")}
          />
          <LessonDetailSection
            avatarText="T"
            avatarColor={classes.secondaryAvatar}
            title={mobileNumber}
            subtitle={getText("prices.alertModalMobileNumber")}
          />
        </Fragment>
      }
      actions={
        <Button
          data-test="nudge-back-confirmation-modal-cta"
          className={classes.actionButton}
          onClick={closeModal}
          text={getText("prices.alertModalDone")}
          secondary
        />
      }
    />
  );
};

export default NudgeBackConfirmationModal;
