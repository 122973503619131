import React, { useState, Fragment } from "react";
import { useHistory, withRouter, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { useStateContext } from "../../../context/stateContext";
import { RETURNING_USER_JOURNEY_PATH_NAMES } from "../../../constants";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import requestNewInstructorWithReasonMutation from "../../../graphql/requestNewInstructorWithReason.graphql";
import LoadingScreen from "../../common/LoadingScreen/LoadingScreen";
import ChangeInstructor from "./ChangeInstructor";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";

const ReturningUserJourneyChangeInstructor = () => {
  const {
    state: {
      rujHistory: { pupilId },
    },
  } = useStateContext();
  const history = useHistory();
  const { getText } = useBrand();
  const { trackEvent } = useTealium();
  const [errorMessage, setErrorMessage] = useState("");
  const [reason, setReason] = useState("");
  const { pathname } = useLocation();

  const [createInstructorChangeRequest, { error, loading }] = useMutation(
    requestNewInstructorWithReasonMutation,
  );

  if (error) {
    throw error;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
    setErrorMessage("");
  };

  const submitForm = async () => {
    setErrorMessage("");
    const { data } = await createInstructorChangeRequest({
      variables: {
        input: {
          pupilId,
          reason,
        },
      },
    });

    if (!data.requestNewInstructorWithReason.successful) {
      setErrorMessage(data.requestNewInstructorWithReason.errorMessage);
    }

    if (data && data.requestNewInstructorWithReason) {
      const { requestNewInstructorWithReason } = data;
      if (requestNewInstructorWithReason.successful) {
        history.push(
          RETURNING_USER_JOURNEY_PATH_NAMES.CHANGE_INSTRUCTOR_CONFIRMATION,
        );
        trackEvent({
          eventCategory: capitalizeEachWordOfPathname(pathname),
          eventAction: "Form Submit",
          eventLabel: "Change Instructor (RUJ)",
        });
      }
    }
  };

  if (loading) {
    return (
      <LoadingScreen
        messageText={getText("returning/change-instructor.processing")}
      />
    );
  }

  return (
    <Fragment>
      <ChangeInstructor
        handleSubmit={submitForm}
        reason={reason}
        handleChange={handleChange}
        errorMessage={errorMessage}
      />
    </Fragment>
  );
};

export default withRouter(ReturningUserJourneyChangeInstructor);
