import { Input } from "@material-ui/core";
import merge from "lodash.merge";
import journey from "./journey-bsm";
import palette, { gradients } from "./palette-bsm";
import breakpoints from "./breakpoints";

const fontFamily = ["Titillium Web", "sans-serif"];

const bsmTheme = {
  palette,
  breakpoints,
  gradients,
  props: {
    MuiTextField: {
      variant: "standard",
    },
  },
  boxShadow: "0px 10px 20px rgba(130, 83, 49, 0.1)",
  typography: {
    fontFamily,
    fontStyle: "SemiBold",
    fontWeightMedium: 600,
    allVariants: {
      color: palette.grey[400],
    },
    h1: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "28px",
      letterSpacing: "0.3px",
    },
    h2: {
      fontSize: "26px",
      lineHeight: "30px",
    },
    h3: {
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
    },
    // Label heading in design system
    h4: {
      fontSize: "16px",
      lineHeight: "27px",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "27px",
      color: "#2E2E36",
    },
    body2: {
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
  journey,
  overrides: {
    MuiAppBar: {
      root: {
        height: "60px",
        padding: "0 16px",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "0px 4px 12px rgba(231, 222, 214, 0.4)",
      },
      colorPrimary: {
        backgroundColor: "white",
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "black",
        opacity: "0.2",
      },
    },
    MuiRadio: {
      root: {
        fontFamily,
      },
      colorPrimary: {
        border: "2px solid pink",
        "&:hover": {
          borderColor: "#ADAEB0",
          background: "white",
        },
        "&$checked": {
          "&:hover": {
            backgroundColor: "white",
            borderColor: "#F9812A",
          },
        },
      },
    },
    MuiButton: {
      root: {
        display: "block",
        background: "linear-gradient(90deg, #F9812A 1%, #F9812A 99%)",
        boxShadow: "0px 10px 20px rgba(253, 161, 71, 0.2)",
        padding: "14px 20px",
        textAlign: "center",
        letterSpacing: "0.05em",
        fontSize: "1rem",
        lineHeight: "1rem",
        transition: "all 250ms",
        "&:hover": {
          background: "linear-gradient(90deg, #F9812A 1%, #FDA147 99%)",
          boxShadow: "0px 10px 20px rgba(249, 129, 42, 0.4)",
        },
        "&$disabled": {
          background: "#E3E2E3",
        },
        [`@media(min-width:${breakpoints.values.md}px)`]: {
          padding: "18px 32px",
        },
      },
      label: {
        display: "flex",
      },
      contained: {
        boxShadow: "0px 10px 20px rgba(253, 161, 71, 0.2)",
        "&:hover": {
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: "none",
        },
        "&$focusVisible": {
          boxShadow: "none",
        },
      },
      text: {
        color: "#F9812A",
        background: "none",
        boxShadow: "none",
        padding: "14px 20px",
        [`@media(min-width:${breakpoints.values.md}px)`]: {
          padding: "18px 20px",
        },
        "&:hover": {
          background: "none",
          boxShadow: "none",
        },
      },
    },
    MuiInput: {
      root: {
        color: "#2E2E36",
        fontWeight: 600,
        fontFamily,
      },
      underline: {
        "&:after": {
          borderBottom: `1px solid #F9812A`,
        },
        "&:hover:not(.Mui-disabled)": {
          "&:before": {
            borderBottom: `1px solid #F9812A`,
          },
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: "#2E2E36",
        lineHeight: "1.4",
        marginBottom: "10px",
      },
      shrink: {
        transform: "none",
      },
      formControl: {
        position: "relative",
        marginTop: "10px",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#2E2E36",
        position: "relative",
        "@media(min-width: 768px)": {
          fontSize: "1.1rem",
        },
        "&$error": {
          color: "#2E2E36",
        },
        "&$focused": {
          color: "#2E2E36",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: "#2E2E36",
        lineHeight: "1.5em",
        fontSize: "0.875rem",
        "&$error": {
          fontSize: "0.875rem",
          fontWeight: 600,
          marginTop: "0",
          lineHeight: "1.5em",
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: "#EEEEEE",
      },
      indeterminate: {
        color: "#F9812A",
      },
    },
    MuiBackdrop: {
      root: {
        background:
          "linear-gradient(90.83deg, rgba(253, 161, 71, 0.8) 99.46%, rgba(249, 129, 42, 0.8) 0.44%)",
        opacity: 0.94,
        [`@media(min-width:${breakpoints.values.md}px)`]: {
          width: "75%",
          left: "auto",
        },
      },
    },
    MuiDialog: {
      root: {
        [`@media(min-width:${breakpoints.values.md}px)`]: {
          width: "75%",
          left: "auto !important",
        },
      },
      paperWidthXs: {
        maxWidth: "340px",
      },
      paperWidthSm: {
        maxWidth: "486px",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": { backgroundColor: "transparent" },
        "&$selected:hover": {
          backgroundColor: "transparent",
          cursor: "default",
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: palette.text.primary,
        minWidth: "30px",
        fontSize: "0.5rem",
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: "transparent",
      },
    },
  },
  SelectInput: Input,
};

export default (overrides = {}) => merge(bsmTheme, overrides);
