import * as React from "react";

import { makeStyles } from "@material-ui/core";

import { ProgressSkillProps } from "../../types";

const useStyles = makeStyles(
  // @ts-ignore
  theme => theme.journey.progressTracker,
);

const ProgressSkill: React.FC<ProgressSkillProps> = ({ skillName, score }) => {
  const classes = useStyles();
  return (
    <div //@ts-ignore
      className={classes.skillWrapper}
      data-test="progress-skill"
    >
      <span //@ts-ignore
        className={classes.skillName}
      >
        {skillName}
      </span>
      <span //@ts-ignore
        className={classes.score}
      >
        {score}
      </span>
    </div>
  );
};
export default ProgressSkill;
