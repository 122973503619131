/* eslint-disable no-unused-vars */
import { CountryDetails } from "@lessons/models";
import { Brand, KeyWorkerType, TransmissionType } from "@graphql/types";

export type LessonPackage = {
  hours: number;
  standardHourlyPrice: number | null;
  standardPackagePrice: number | null;
  discountedPackagePrice: number | null;
  savingOnPackagePrice: number | null;
  lessonType: string;
};

export type InstructorDetails = {
  id: string;
  name: string;
  isTrainee: boolean;
};

export type Instructor = {
  prices: LessonPackage[];
  instructor: InstructorDetails;
  brand: Brand;
  transmissionType: string;
};

export type AddressFull = {
  id: string;
  country: string;
  county: string;
  line1: string;
  line2: string;
  postcode: string;
  town: string;
  __typename: string;
};

export type NUJHistoryState = {
  pickupPostcode: string;
  selectedCountry: CountryDetails | null;
  marketingChannel: string | null;
  marketingChannelId: string | null;
  availableInstructors: {
    [Brand.Bsm]: Instructor[];
    [Brand.Aads]: Instructor[];
  };
  availableInstructorsLoaded: boolean;
  selectedInstructor: Instructor | null;
  selectedInstructorHistory: {
    manual: string | null;
    automatic: string | null;
  } | null;
  selectedPackage: LessonPackage | null;
  selectedTransmissionType: TransmissionType;
  selectedLessonDate: string | null;
  selectedLessonTimeslot: {
    startTime: string;
    endTime: string;
  } | null;
  selectedLessonType: string;
  tempPupilDetailFormValues: {
    honoraryTitle: string;
    firstName: string;
    surname: string;
    mobileNumber: string;
    emailAddress: string;
    dateOfBirth: Date;
    pickupAddress: AddressFull;
    billingAddress: AddressFull;
    marketingPreferences: {
      email: boolean;
      sms: boolean;
      phone: boolean;
      post: boolean;
    };
    termsAndConditions: boolean;
    isGift: boolean;
    shouldBeASurprise: boolean;
    giftGiversEmail: string;
    giftGiversMobile: string;
  } | null;
  pupilDetails: {
    honoraryTitle: string;
    firstName: string;
    surname: string;
    mobileNumber: string;
    emailAddress: string;
    dateOfBirth: Date | null;
    pickupAddressId: string;
    billingAddressId: string;
  };
  order: {
    orderId: string;
    bookingId: string | null;
    pupilId: string;
    payment: {
      token: string;
      endpoint: string;
    };
    orderCostSummary: {
      hours: number;
      packagePrice: number;
      discount: number;
      bookingFee: number;
      total: number;
    };
  };
  confirmation: {
    topUpId: string;
    pickupPostcode: string;
    lesson: {
      startTime: string;
      endTime: string;
    };
    instructorName: string;
    pupilFirstName: string;
    gift: GiftType;
  };
  isRestrictedForKeyWorker: boolean;
  keyWorkerType: KeyWorkerType | "" | undefined;
  keyWorkerEmployer: string;
  shortNoticeTestDateTime: Date | null;
  shortNoticeTestCentre: string | null;
};

export type RUJHistoryState = {
  newInstructorRequested: boolean;
  learnerId?: string;
  topUpId: string;
  referralId: string;
  personId: number | null;
  pupilId: string;
  pupilFirstName: string;
  pupilSurname: string;
  pupilDateOfBirth: string;
  pupilBalance: number;
  pupilPickupPostcode: string;
  instructorId: string;
  instructorName: string;
  transmissionType: TransmissionType;
  selectedLessonDate: string;
  selectedLessonTimeslot: {
    startTime: string;
    endTime: string;
  };
  keyWorker: {
    type: string;
    employer: string;
  };
  confirmation: {
    topUpId: string;
    lesson: {
      startTime: string;
      endTime: string;
    };
    instructorName: string;
    pupilFirstName: string;
    pupilBalance: number;
  };
  selectedCountry: CountryDetails | null;
  ldrive: boolean;
  customerType: string;
};

export type QTUHistoryState = {
  learnerId: string;
  personId: number | null;
  pupilId: string;
  pupilBalance: number;
  pupilFirstName: string;
  topUpId: string;
  instructorId: string;
  selectedHours: number;
  selectedHoursCost: number;
  hourlyRate: number;
  discountedHourlyRate: number;
  threshold: number;
  paymentSuccess: boolean;
  confirmation: {
    pupilBalance: number;
    selectedHours: number;
    pupilFirstName: string;
    topUpId: string;
  };
};

export type State = {
  sessionId: string;
  isQTUJourney: boolean;
  nujHistory: NUJHistoryState;
  rujHistory: RUJHistoryState;
  qtuHistory: QTUHistoryState;
};

export type Dispatch = (input: {
  type: string;
  payload?: string | Record<string, any>;
}) => void;

export type StateContext = {
  state: State;
  dispatch: Dispatch;
};

export enum GiftType {
  NOT_GIFT = "NOT_GIFT",
  GIFT = "GIFT",
  SURPRISE = "SURPRISE",
}
