import React, { Fragment } from "react";
import { arrayOf, func, shape, number, bool, string } from "prop-types";
import get from "lodash.get";
import {
  makeStyles,
  Typography,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import LessonPackageText from "./LessonPackageText";
import RadioCardGroup from "../RadioCardGroup/RadioCardGroup";
import RadioCard from "../RadioCard/RadioCard";
import { useBrand } from "../../BrandProvider/BrandProvider";

const useStyles = makeStyles({
  formControl: {
    maxWidth: ({ packageCount }) => (packageCount === 1 ? "280px" : "100%"),
    display: "block",
    margin: "0 auto",
  },
  formLabel: {
    marginBottom: "10px",
  },
  packageRadioWrapper: {
    "&:only-child": {
      maxWidth: "280px",
    },
  },
});

export const ErrorMessage = ({ message }) => <Typography>{message}</Typography>;

const FormLessonPackagePicker = ({
  packageConfiguration,
  value,
  onChange,
  disabled,
  label,
  ...rest
}) => {
  const classes = useStyles({
    packageCount: packageConfiguration.length,
  });
  const { getText } = useBrand();
  return (
    <FormControl fullWidth classes={{ root: classes.formControl }}>
      {packageConfiguration && packageConfiguration.length ? (
        <Fragment>
          {label && (
            <FormLabel className={classes.formLabel} component="legend">
              {label}
            </FormLabel>
          )}
          <RadioCardGroup
            value={value}
            name="lesson-package-radio"
            onChange={onChange}
            disabled={disabled}
            {...rest}
          >
            {packageConfiguration.map((lessonPackage, idx) => (
              <RadioCard
                key={get(lessonPackage, "hours", `id-${idx}`)}
                value={lessonPackage.hours.toString()}
                cardContent={<LessonPackageText {...lessonPackage} />}
                cardContentChecked={
                  <LessonPackageText {...lessonPackage} checked />
                }
              />
            ))}
          </RadioCardGroup>
        </Fragment>
      ) : (
        <ErrorMessage message={getText("common.lessonPackagePickerError")} />
      )}
    </FormControl>
  );
};

FormLessonPackagePicker.defaultProps = {
  packageConfiguration: [],
  value: "",
  disabled: false,
  label: "",
  onChange() {},
};

FormLessonPackagePicker.propTypes = {
  packageConfiguration: arrayOf(
    shape({
      hours: number,
      standardHourlyPrice: number,
      standardPackagePrice: number,
      discountedPackagePrice: number,
      savingOnPackagePrice: number,
    }),
  ),
  onChange: func,
  value: string,
  disabled: bool,
  label: string,
};

export default FormLessonPackagePicker;
