import { GraphQLError } from "graphql";
import { useQuery, WatchQueryFetchPolicy } from "@apollo/react-hooks";
import { isEmpty } from "lodash";

import getDiary from "../graphql/getDiary.graphql";

import { AvailabilityNuj, AvailabilityNujInput } from "@graphql/types";

const useGetDiary = ({
  brand,
  instructorId,
  startDate,
  endDate,
  transmissionType,
  fetchPolicy = "cache-first",
}: AvailabilityNujInput & {
  instructorId: string;
  fetchPolicy: WatchQueryFetchPolicy;
}) =>
  useQuery<{ availabilityNUJ: AvailabilityNuj }>(getDiary, {
    skip: isEmpty(instructorId),
    variables: {
      brand,
      instructorId,
      startDate,
      endDate,
      transmissionType,
    },
    fetchPolicy,
    onError: error => {
      throw new GraphQLError(JSON.stringify(error));
    },
  });

export default useGetDiary;
