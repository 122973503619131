import { Brand, TransmissionType } from "@graphql/types";
import get from "lodash.get";
import {
  GiftType,
  NUJHistoryState,
  QTUHistoryState,
  RUJHistoryState,
  State,
} from "./models";

export const USE_AUTH_PORTAL = get(
  window,
  "_env_.REACT_APP_use_auth_portal",
  false,
);

export const APP_VERSION = get(
  window,
  "_env_.REACT_APP_version",
  "development",
);

export const ENVIRONMENT = get(
  window,
  "_env_.REACT_APP_environment",
  "development",
);

export const getJourneyExitURL = (brand: Brand) =>
  get(
    window,
    USE_AUTH_PORTAL
      ? `_env_.REACT_APP_AUTH_PORTAL_URL_${brand}`
      : `_env_.REACT_APP_client_endpoints.${brand}.exitUrl`,
    `/`,
  );

export const getJourneyURL = (brand: Brand) =>
  get(
    window,
    `_env_.REACT_APP_client_endpoints.${brand}.baseUrl`,
    `${brand}-base-url`,
  );

export const LIDL_TERMS = "https://www.lidl.co.uk/aadrivingschool";

export const SHOULD_ENABLE_RECAPTCHA =
  process.env.REACT_APP_ENABLE_RECAPTCHA === "true";

export const TITLES = ["Mr", "Miss", "Mrs", "Ms", "Mx"];

export const POSTCODE_URL_PARAM = "pickupPostcode";
export const REDIRECT_URL_PARAM = "redirect";
export const REFERRAL_ID_PARAM = "referralId";
export const SESSION_ID_PARAM = "sessionId";

export const LIDL_REFERRAL = "LIDL2020";

// long date format using date-fn's tokens
export const LONG_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
export const LONG_DATE_FORMAT_WITH_ZEROED_TIMES = "yyyy-MM-dd'T00:00:00.000Z'";
export const TIME_FORMAT = "hh:mm a";

export const TIMESLOTS_NUMBER_OF_DAYS = 60;
export const TIMESLOTS_INITIAL_MIN_DATE_OFFSET = 3;
export const TIMESLOTS_MAX_DATE_OFFSET =
  TIMESLOTS_NUMBER_OF_DAYS + TIMESLOTS_INITIAL_MIN_DATE_OFFSET;
export const TIMESLOTS_INITIAL_SELECTED_DATE_OFFSET = 7;

export const TODAY = new Date();

export enum JourneyType {
  NEW_USER_JOURNEY = "NUJ", // acronym for new user journey type
  QUICK_TOP_UP_JOURNEY = "QTU", // acronym for quick top up journey type
  RETURNING_USER_JOURNEY = "RUJ", // acronym for returning user journey type
  THEORY_APP_JOURNEY = "TAJ", // acronym for theory app journey type
}

export const GLOBAL_PATH_NAMES = {
  CALL_TO_COMPLETE: "/call-to-complete-booking",
  SOMETHINGS_GONE_WRONG: "/somethings-gone-wrong",
  SOMETHINGS_GONE_WRONG_500: "/somethings-gone-wrong-500",
};

export const NEW_USER_JOURNEY_PATH_NAMES = {
  PICKUP: "/pickup-location",
  SELECT_COUNTRY: "/select-country",
  // SNOW-PLAN
  FACE_COVERING: "/face-covering",
  // SNOW-PLAN
  KEY_WORKER: "/key-worker",
  LESSON_TYPES: "/lesson-types",
  PRICES: "/prices",
  SNT_PRICES: "/snt-prices",
  TIMESLOTS: "/timeslots",
  PERSONAL_DETAILS: "/personal-details",
  PAYMENT: "/payment",
  CONFIRMATION: "/confirmation",
  SNT_CONFIRMATION: "/snt-confirmation",
  CHANGE_INSTRUCTOR: "/prices/change-instructor",
  TEST_PASSED: "/test-passed",
  EXISTING_PUPIL: "/existing-pupil",
  TEST_BOOKED: "/test-booked",
};

export const RETURNING_USER_JOURNEY_PATH_NAMES = {
  LOGIN: "/login",
  TOPUP_AMOUNT: "/topup/amount",
  TOPUP_PAYMENT: "/topup/payment",
  TOPUP_CONFIRMATION: "/topup/confirmation",
  KEY_WORKER: "/returning/key-worker",
  TIMESLOTS: "/returning/timeslots",
  KEY_WORKER_TALK_TO_US: "/returning/key-worker-talk-to-us",
  CONFIRMATION: "/returning/confirmation",
  FACE_COVERING: "/returning/face-covering",
  BOOKED_LESSONS: "/returning/booked-lessons",
  ACCOUNT: "/account",
  HELP_CENTRE: "/returning/help-centre",
  CANCEL_LESSON: "/returning/cancel-lesson",
  COMPLAINTS: "/returning/complaints",
  COMPLAINTS_CONFIRMATION: "/returning/complaints/confirmation",
  CHANGE_INSTRUCTOR: "/returning/change-instructor",
  CHANGE_INSTRUCTOR_CONFIRMATION: "/returning/change-instructor/confirmation",
  TALK_TO_US: "/returning/talk-to-us",
  TALK_TO_US_CONFIRMATION: "/returning/talk-to-us/confirmation",
  REFUND_REQUEST: "/returning/refund",
  REFUND_REQUEST_CONFIRMATION: "/returning/refund/confirmation",
  RATE_INSTRUCTOR: "/returning/rate-instructor",
  RATE_INSTRUCTOR_CONFIRMATION: "/returning/rate-instructor/confirmation",
  SELECT_COUNTRY: "/returning/select-country",
  PROGRESS: "/returning/progress",
  NEW_INSTRUCTOR_REQUEST_RECEIVED: "/new-instructor-request",
  RECOMMENDED_DRIVING_PRODUCTS: "/products",
};

export const THEORY_APP_PATH_NAMES = {
  THEORY_APP_PRIVACY_NOTICE: "/theory-app-privacy-notice",
  THEORY_APP_TERMS_AND_CONDITIONS: "/theory-app-terms-and-conditions",
};

export const BOOK_LESSON_ACCOUNT_ACTION = "BOOK_LESSON"; // radio card name for Account page
export const TOPUP_ACCOUNT_ACTION = "TOPUP"; // radio card name for Account page

export const BSM_BRAND_NAME = Brand.Bsm;
export const AA_BRAND_NAME = Brand.Aads;

export const MANUAL_TRANSMISSION = TransmissionType.Manual;
export const AUTOMATIC_TRANSMISSION = TransmissionType.Automatic;

export const MIN_PHONE_NUMBER_LENGTH = 11;

export const POSTCODE_VALIDATION_DELAY = 250;
export const POSTCODE_VALIDATION_LOCAL_STORAGE_NAME =
  "POSTCODE_CONFIRMATION_ATTEMPTS";
export const POSTCODE_VALIDATION_MAX_ATTEMPTS = 3;

export const ERROR_TYPES = {
  PUPIL_EXISTS: "PupilExists",
  CANCEL_LESSON_NOTICE_TOO_SHORT: "CancelLessonNoticeTooShort",
  LESSON_DOES_NOT_BELONG_TO_LEARNER: "LessonDoesNotBelongToLearner",
};

export const SENTRY_REDACTED_KEYS = [
  "pupilDetails",
  "pupilFirstName",
  "pupilSurname",
  "pupilDateOfBirth",
];

export const LESSON_TYPES = {
  LEARNER: "Learner",
  REFRESHER: "Refresher",
  PASS_PLUS: "PassPlus",
  MOTORWAY: "Motorway",
  SHORT_NOTICE_TEST: "ShortNoticeTest",
};

export const MARKETING_CHANNELS = {
  NUDGE_BACK: "NudgeBack",
  REFERRAL: "Referral",
};

export const NUDGE_BACK_TYPES = {
  WEB: "Web",
  WEB_COVID: "WebCovid",
};

export const NUJ_HISTORY_INITIAL_STATE: NUJHistoryState = {
  pickupPostcode: "",
  selectedCountry: null,
  marketingChannel: null,
  marketingChannelId: null,
  availableInstructors: {
    [BSM_BRAND_NAME]: [],
    [AA_BRAND_NAME]: [],
  },
  availableInstructorsLoaded: false,
  selectedInstructor: null,
  selectedInstructorHistory: {
    manual: null,
    automatic: null,
  },
  selectedPackage: {
    hours: 10,
    standardHourlyPrice: null,
    standardPackagePrice: null,
    discountedPackagePrice: null,
    savingOnPackagePrice: null,
    lessonType: "",
  },
  selectedTransmissionType: TransmissionType.Manual,
  selectedLessonDate: "",
  selectedLessonTimeslot: {
    startTime: "",
    endTime: "",
  },
  selectedLessonType: LESSON_TYPES.LEARNER,
  tempPupilDetailFormValues: null,
  pupilDetails: {
    honoraryTitle: "",
    firstName: "",
    surname: "",
    mobileNumber: "",
    emailAddress: "",
    dateOfBirth: null,
    pickupAddressId: "",
    billingAddressId: "",
  },
  order: {
    orderCostSummary: {
      hours: 0,
      packagePrice: 0,
      discount: 0,
      bookingFee: 0,
      total: 0,
    },
    orderId: "",
    bookingId: null,
    pupilId: "",
    payment: {
      token: "",
      endpoint: "",
    },
  },
  confirmation: {
    topUpId: "",
    pickupPostcode: "",
    lesson: {
      startTime: "",
      endTime: "",
    },
    instructorName: "",
    pupilFirstName: "",
    gift: GiftType.NOT_GIFT,
  },
  isRestrictedForKeyWorker: false,
  keyWorkerType: "",
  keyWorkerEmployer: "",
  shortNoticeTestDateTime: null,
  shortNoticeTestCentre: null,
};

export const RUJ_HISTORY_INITIAL_STATE: RUJHistoryState = {
  topUpId: "",
  referralId: "",
  learnerId: "",
  personId: null,
  pupilId: "",
  pupilFirstName: "",
  pupilSurname: "",
  pupilDateOfBirth: "",
  pupilBalance: 0,
  pupilPickupPostcode: "",
  instructorId: "",
  instructorName: "",
  transmissionType: TransmissionType.Manual,
  selectedLessonDate: "",
  selectedLessonTimeslot: {
    startTime: "",
    endTime: "",
  },
  keyWorker: {
    type: "",
    employer: "",
  },
  confirmation: {
    topUpId: "",
    lesson: {
      startTime: "",
      endTime: "",
    },
    instructorName: "",
    pupilFirstName: "",
    pupilBalance: 0,
  },
  selectedCountry: null,
  ldrive: false,
  newInstructorRequested: false,
  customerType: "Learner",
};

export const QTU_HISTORY_INITIAL_STATE: QTUHistoryState = {
  learnerId: "",
  personId: null,
  pupilId: "",
  pupilBalance: 0,
  pupilFirstName: "",
  topUpId: "",
  instructorId: "",
  selectedHours: 1,
  selectedHoursCost: 0,
  hourlyRate: 0,
  discountedHourlyRate: 0,
  threshold: 0,
  paymentSuccess: false,
  confirmation: {
    pupilBalance: 0,
    selectedHours: 0,
    pupilFirstName: "",
    topUpId: "",
  },
};

export const INITIAL_STATE: State = {
  sessionId: "",
  isQTUJourney: false,
  nujHistory: NUJ_HISTORY_INITIAL_STATE,
  rujHistory: RUJ_HISTORY_INITIAL_STATE,
  qtuHistory: QTU_HISTORY_INITIAL_STATE,
};

export const APPSYNCCLIENT_MAX_RETRIES = 10;

export const GOOGLE_RECAPTCHA_SITE_KEY =
  "6LfiZMgUAAAAAIdCI2aPE3lkvIR_ay4EsvkirONe";

export const CHANGE_INSTRUCTOR_REASONS: { [key: string]: string } = {
  InstructorLeaving: "Instructor is leaving",
  InstructorAvailability: "Instructor availability",
  MovingAddress: "Moving address",
  Complaint: "Complaint",
  UnhappyWithService: "Unhappy with service",
  PracticalTestCover: "Practical Test cover",
};

export const COUNTRY_ACCESS_STATUS = {
  OPEN: "OPEN",
  CLOSED: "CLOSED",
  UNDETERMINED: "UNDETERMINED",
  INVALID: "INVALID",
};

export const LESSON_PURCHASE_TYPES = {
  ThirdParty: "ThirdParty",
  Caller: "Caller",
  Surprise: "Surprise",
};

export default {
  SHOULD_ENABLE_RECAPTCHA,
  TITLES,
  POSTCODE_URL_PARAM,
  REDIRECT_URL_PARAM,
  SESSION_ID_PARAM,
  REFERRAL_ID_PARAM,
  APP_VERSION,
  JourneyType,
  GLOBAL_PATH_NAMES,
  NEW_USER_JOURNEY_PATH_NAMES,
  RETURNING_USER_JOURNEY_PATH_NAMES,
  BOOK_LESSON_ACCOUNT_ACTION,
  TOPUP_ACCOUNT_ACTION,
  MIN_PHONE_NUMBER_LENGTH,
  POSTCODE_VALIDATION_MAX_ATTEMPTS,
  POSTCODE_VALIDATION_LOCAL_STORAGE_NAME,
  POSTCODE_VALIDATION_DELAY,
  ERROR_TYPES,
  MARKETING_CHANNELS,
  NUJ_HISTORY_INITIAL_STATE,
  RUJ_HISTORY_INITIAL_STATE,
  QTU_HISTORY_INITIAL_STATE,
  INITIAL_STATE,
  APPSYNCCLIENT_MAX_RETRIES,
  GOOGLE_RECAPTCHA_SITE_KEY,
  COUNTRY_ACCESS_STATUS,
  LIDL_TERMS,
  LESSON_PURCHASE_TYPES,
};
