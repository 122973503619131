import * as React from "react";
import { Container, Theme } from "@material-ui/core";
import { Styles, withStyles } from "@material-ui/styles";
import get from "lodash.get";

const styles: Styles<Theme, any> = theme => ({
  wrapper: props => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: get(props, "fullWidth", false) ? 0 : "16px",
      paddingRight: get(props, "fullWidth", false) ? 0 : "16px",
      paddingTop: get(props, "fullHeight", false) ? 0 : "20px",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: get(props, "fullWidth") ? "100%" : "460px",
      paddingTop: get(props, "fullHeight", false) ? 0 : "60px",
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: get(props, "fullWidth") ? "100%" : "500px",
      paddingTop: get(props, "fullHeight", false) ? 0 : "80px",
    },
  }),
});

interface Props {
  classes: Record<string, string>;
  children: React.ReactChild;
}

const ContentWrapper: React.FC<Props> = ({ children, classes }) => (
  <Container className={classes.wrapper} maxWidth={false}>
    {children}
  </Container>
);

export default withStyles(styles)(ContentWrapper);
