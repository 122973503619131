/* Helper methods for working with instructor prices data */
const getInstructorsByTransmission = (instructorPrices, transmission) =>
  instructorPrices.filter(
    instructorPrice => instructorPrice.transmissionType === transmission,
  );

const getInstructorByInstructorId = (instructorPrices, instructorId) =>
  instructorPrices.find(
    instructorPrice => instructorPrice.instructor.id === instructorId,
  );

const getSelectedPackageIndex = (packages, selectedHours) =>
  packages.findIndex(({ hours }) => hours === selectedHours);

const hasTransmissionType = (instructorPrices, transmission) =>
  instructorPrices.some(
    instructorPrice => instructorPrice.transmissionType === transmission,
  );

const hasInstructorId = (instructorPrices, instructorId) =>
  instructorPrices.some(
    instructorPrice => instructorPrice.instructor.id === instructorId,
  );

export {
  getInstructorsByTransmission,
  getInstructorByInstructorId,
  getSelectedPackageIndex,
  hasTransmissionType,
  hasInstructorId,
};
