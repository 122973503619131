import * as React from "react";
import { node } from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  textContainer: {
    flex: 2,
  },
  textItem: {
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: "1.6",
  },
  secondaryText: {
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: "20px",
  },
}));

const Text = ({ text, secondaryText }) => {
  const classes = useStyles();
  return (
    <div className={classes.textContainer}>
      <Typography color="textPrimary" classes={{ root: classes.textItem }}>
        {text}
      </Typography>
      <Typography
        color="textSecondary"
        classes={{ root: classes.secondaryText }}
      >
        {secondaryText}
      </Typography>
    </div>
  );
};

Text.propTypes = {
  text: node.isRequired,
  secondaryText: node,
};

export default Text;
