import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import mergeDeep from "lodash.merge";
import TestPassedOptionList from "./TestPassedOptionList";
import ContentWrapper from "../../../../common/ContentWrapper/ContentWrapper";
import Titlebar from "../../../../common/Titlebar/Titlebar";
import ContinueButton from "../../../../common/ContinueButton/ContinueButton";
import { useBrand } from "../../../../common/BrandProvider/BrandProvider";
import { useStateContext } from "../../../../../context/stateContext";
import RadioList from "../../../../common/RadioItemList/RadioList";
import { useTealium } from "../../../../common/TealiumProvider/TealiumProvider";
import { changeLessonType } from "../../Prices/helpers";
import { NEW_USER_JOURNEY_PATH_NAMES } from "../../../../../constants";
import { goBack } from "../../../../../utils/navigation";
import { capitalizeEachWordOfPathname } from "../../../../../utils/capitalisePathname";

const useStyles = makeStyles(theme =>
  mergeDeep(theme.journey.testPassed, {
    testPassed: {
      width: "100%",
      maxWidth: "384px",
      margin: "0 auto",
      padding: "0 0 20px 0",
      [theme.breakpoints.up("lg")]: {
        padding: "0 0 30px 0",
      },
      [theme.breakpoints.up("md")]: {
        width: "700px",
        margin: "auto",
      },
    },
    testPassedLabel: {
      color: theme.palette.grey[500],
      fontSize: "16px",
      fontWeight: "normal",
      margin: "0 0 12px 0",
      lineHeight: "24px",
    },
  }),
);

const NewUserJourneyStepsLessonTypesTestPassed = () => {
  const classes = useStyles();
  const { getText } = useBrand();
  const buttonEl = useRef(null);
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    state: {
      nujHistory: { selectedLessonType: selectedLessonTypeFromContext },
    },
    dispatch,
  } = useStateContext();
  const { trackEvent, updateDataLayer } = useTealium();

  const [selectedLessonType, setSelectedLessonType] = useState(
    selectedLessonTypeFromContext,
  );

  const handleContinueButtonClick = () => {
    trackEvent({
      eventCategory: "Test Passed",
      eventAction: "Button Click",
      eventLabel: "Continue",
    });
    updateDataLayer({
      refresher_type: selectedLessonType,
    });
    changeLessonType({ dispatch, selectedLessonType });
  };

  useEffect(() => {
    if (buttonEl.current) {
      buttonEl.current.focus();
    }
  });

  const handleBackButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    goBack({ history, pathname });
  };

  return (
    <Fragment>
      <ContentWrapper>
        <Titlebar
          showBackButton
          onBackClick={handleBackButtonClick}
          headingProps={{ "data-test": "test-passed-title-bar" }}
        >
          {getText("test-passed.pageTitle")}
        </Titlebar>
      </ContentWrapper>

      <div className={classes.testPassed}>
        <ContentWrapper fullHeight>
          <RadioList label={getText("test-passed.lessonTypesLabel")}>
            <TestPassedOptionList
              selectedLessonType={selectedLessonType}
              onClick={setSelectedLessonType}
            />
          </RadioList>
          <ContinueButton
            navButton
            to={NEW_USER_JOURNEY_PATH_NAMES.PRICES}
            text={getText("test-passed.continueButtonText")}
            data-test="continue-button"
            buttonRef={buttonEl}
            onClick={handleContinueButtonClick}
          />
        </ContentWrapper>
      </div>
    </Fragment>
  );
};

export default NewUserJourneyStepsLessonTypesTestPassed;
