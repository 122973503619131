import React, { Fragment } from "react";
import TestPassedOptionItem from "./TestPassedOptionItem";
import { LESSON_TYPES } from "../../../../../constants";
import { useBrand } from "../../../../common/BrandProvider/BrandProvider";

const TestPassedOptionList = ({ selectedLessonType, onClick }) => {
  const { getText } = useBrand();

  return (
    <Fragment>
      <TestPassedOptionItem
        text={getText("test-passed.refresher")}
        summary={getText("test-passed.refresherSummary")}
        value={LESSON_TYPES.REFRESHER}
        key={LESSON_TYPES.REFRESHER}
        modalTitle={getText("test-passed.refresherInfoTitle")}
        modalContent={[getText("test-passed.refresherInfoContent")]}
        modalMoreInfoLinkPath={getText("test-passed.refresherInfoPath")}
        modalMoreInfoLinkText={getText("test-passed.moreInfoButtonText")}
        modalActionButtonText={getText("test-passed.closeButtonText")}
        onClick={onClick}
        selected={selectedLessonType === LESSON_TYPES.REFRESHER}
        testId="refresher-option"
      />
      <TestPassedOptionItem
        text={getText("test-passed.motorway")}
        summary={getText("test-passed.motorwaySummary")}
        value={LESSON_TYPES.MOTORWAY}
        key={LESSON_TYPES.MOTORWAY}
        modalTitle={getText("test-passed.motorwayInfoTitle")}
        modalContent={[getText("test-passed.motorwayInfoContent")]}
        modalMoreInfoLinkPath={getText("test-passed.motorwayInfoPath")}
        modalMoreInfoLinkText={getText("test-passed.moreInfoButtonText")}
        modalActionButtonText={getText("test-passed.closeButtonText")}
        onClick={onClick}
        selected={selectedLessonType === LESSON_TYPES.MOTORWAY}
        testId="motorway-option"
      />
      <TestPassedOptionItem
        text={getText("test-passed.passPlus")}
        summary={getText("test-passed.passPlusSummary")}
        value={LESSON_TYPES.PASS_PLUS}
        key={LESSON_TYPES.PASS_PLUS}
        modalTitle={getText("test-passed.passPlusInfoTitle")}
        modalContent={[
          getText("test-passed.passPlusInfoContent1"),
          getText("test-passed.passPlusInfoContent2"),
          getText("test-passed.passPlusInfoContent3"),
        ]}
        modalMoreInfoLinkPath={getText("test-passed.passPlusInfoPath")}
        modalMoreInfoLinkText={getText("test-passed.moreInfoButtonText")}
        modalActionButtonText={getText("test-passed.closeButtonText")}
        onClick={onClick}
        selected={selectedLessonType === LESSON_TYPES.PASS_PLUS}
        testId="pass-plus-option"
      />
    </Fragment>
  );
};

export default TestPassedOptionList;
