import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import learnerDetails from "../../../graphql/learnerDetails.graphql";
import topUpDetails from "../../../graphql/topUpDetails.graphql";
import { useStateContext } from "../../../context/stateContext";
import bsmRUJConfirmationImage from "../../../svg/bsm-ruj-success.svg";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import Body from "./Body";
import CommonConfirmation from "../../common/Confirmation/Confirmation";
import {
  RETURNING_USER_JOURNEY_PATH_NAMES,
  USE_AUTH_PORTAL,
} from "../../../constants";
import { Brand } from "@graphql/types";

const ReturningUserJourneyConfirmation = ({ history }) => {
  const { brand, getText } = useBrand();
  const {
    state: {
      rujHistory: {
        confirmation: {
          topUpId,
          // Removing from RUJ confirmation for now as this will have to come from the server
          // pickupPostcode,
          lesson: { startTime, endTime },
          instructorName,
          pupilFirstName,
          pupilBalance,
        },
      },
    },
  } = useStateContext();

  const { data } = useQuery(USE_AUTH_PORTAL ? learnerDetails : topUpDetails, {
    variables: USE_AUTH_PORTAL
      ? {}
      : {
          topUpId,
        },
    // Force apollo to re-fetch the data from the server, as the hours should be updated
    fetchPolicy: "no-cache",
  });

  const calculatedBalance = parseInt(pupilBalance - 2, 10);

  const balanceFromServer = data?.learnerTopUpDetails?.allocations[0].balance;

  /* A faux optimistic response - we'll calculate the new balance based on the
     information from context, then replace it with the server response once
    it arrives */
  const newBalance = balanceFromServer || calculatedBalance;

  const handleContinueClick = () => {
    const continueUrl =
      newBalance >= 2
        ? RETURNING_USER_JOURNEY_PATH_NAMES.TIMESLOTS
        : RETURNING_USER_JOURNEY_PATH_NAMES.TOPUP_AMOUNT;
    history.push(continueUrl);
  };

  const confirmationProps =
    brand === Brand.Aads
      ? {}
      : {
          bsmBannerImage: bsmRUJConfirmationImage,
        };

  return (
    <CommonConfirmation
      title={getText("returning/confirmation.pageTitle", { pupilFirstName })}
      // see above
      // pickupPostcode={pickupPostcode}
      startTime={startTime}
      endTime={endTime}
      instructorName={instructorName}
      pupilFirstName={pupilFirstName}
      bannerImage={bsmRUJConfirmationImage}
      {...confirmationProps}
    >
      <Body
        name={pupilFirstName}
        balance={newBalance}
        onContinueClick={handleContinueClick}
      />
    </CommonConfirmation>
  );
};

export default withRouter(ReturningUserJourneyConfirmation);
