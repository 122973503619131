import React from "react";
import { useLocation } from "react-router-dom";
import { Typography, makeStyles, Theme } from "@material-ui/core";
import { ReportProblem, ReportProblemOutlined } from "@material-ui/icons";
import merge from "lodash.merge";
import clsx from "clsx";

import InfoPanel from "../../common/InfoPanel/InfoPanel";
import ContinueButton from "../../common/ContinueButton/ContinueButton";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import Titlebar from "../../common/Titlebar/Titlebar";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import { BSM_BRAND_NAME } from "../../../constants";
// @ts-ignore
import FaceCoveringSvgBSM from "../../../svg/bsm-nuj-face-covering.svg";

import { CommonFaceCoveringProps } from "./types";

import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";

const useStyles = makeStyles(
  (theme: Theme & { journey: { faceCovering: Record<string, any> } }) =>
    merge(theme.journey.faceCovering, {
      form: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
      },
      contentWrapper: {
        flex: "1 0 auto",
      },
      footerWrapper: {
        flexShrink: 0,
      },
      svg: {
        marginTop: "-0.5em",
        marginRight: "0.5em",
      },
      title: {
        marginBottom: "24px",
      },
      ol: {
        listStyle: "none",
      },
      imageContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.up("md")]: {
          marginTop: 0,
        },

        "&>img": {
          maxWidth: "100%",
        },
      },
      cancellationWarning: {
        marginBottom: "18px",
      },
    }),
);

export const CommonFaceCovering: React.FC<CommonFaceCoveringProps> = ({
  handleSubmit = () => {},
  handleBackButtonClick = () => {},
}) => {
  const classes = useStyles();
  const { brand, getText } = useBrand();
  const { trackEvent } = useTealium();
  const { pathname } = useLocation();

  const handleContinueButtonClick = (eventLabel: string) => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Button Click",
      eventLabel,
    });
  };

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit}
      data-test="face-covering-form"
    >
      <div className={classes.contentWrapper}>
        <div className={classes.headerWrapper} data-test="header-wrapper">
          <ContentWrapper fullWidth>
            <ContentWrapper fullHeight>
              <Titlebar
                showBackButton
                onBackClick={handleBackButtonClick}
                className={classes.title}
                headingProps={{ "data-test": "face-covering-title-bar" }}
                reducedBottomMargin
              >
                {getText("face-covering.pageTitle")}
              </Titlebar>
            </ContentWrapper>
          </ContentWrapper>
        </div>
        <ContentWrapper fullWidth fullHeight>
          <ContentWrapper fullHeight>
            <InfoPanel
              svg={
                brand === BSM_BRAND_NAME ? (
                  <ReportProblem classes={{ root: classes.infoPanelIcon }} />
                ) : (
                  <ReportProblemOutlined
                    classes={{ root: classes.infoPanelIcon }}
                  />
                )
              }
              svgClasses={classes.svg}
              svgFlexAlignItems="flex-start"
            >
              <Typography
                variant="body1"
                className={clsx(
                  classes.infoPanelText,
                  classes.cancellationWarning,
                )}
              >
                {getText("face-covering.selfIsolateAdvice")}
              </Typography>

              <Typography
                variant="body1"
                className={clsx(
                  classes.infoPanelText,
                  classes.cancellationWarning,
                )}
              >
                {getText("face-covering.cancellationAdvice")}
              </Typography>
            </InfoPanel>
            <ContinueButton
              data-test="face-covering-continue-button"
              type="submit"
              onClick={() =>
                handleContinueButtonClick(getText("face-covering.buttonLabel"))
              }
              text={getText("face-covering.buttonLabel")}
            />
          </ContentWrapper>
        </ContentWrapper>
      </div>
      <div className={classes.footerWrapper}>
        <ContentWrapper>
          {brand === BSM_BRAND_NAME && (
            <div className={classes.imageContainer}>
              <img src={FaceCoveringSvgBSM} />
            </div>
          )}
        </ContentWrapper>
      </div>
    </form>
  );
};

export default CommonFaceCovering;
