import get from "lodash.get";
import merge from "lodash.merge";
import { State } from "models/state";
import { ENVIRONMENT, INITIAL_STATE } from "../constants";

let initialized = false;

const getInitialState = (sessionId: string): State & { sessionId: string } => {
  if (!initialized) {
    initialized = true;
    const savedState = sessionStorage.getItem("appState");
    if (savedState) {
      return { ...JSON.parse(savedState), sessionId };
    }
  }

  const stateWithSessionId: State & {
    sessionId: string;
  } = {
    ...INITIAL_STATE,
    sessionId,
  };

  if (ENVIRONMENT.toUpperCase() !== "PROD") {
    const stateFromEnv = get(window._env_, "REACT_APP_mock_state", {});
    let stateFromLS = {};
    // Browsers with cookies disabled will error when attempting to interact with localStorage
    try {
      stateFromLS = JSON.parse(localStorage.getItem("mockState") || "");
    } catch {}

    const newState = {};
    return merge(newState, stateWithSessionId, stateFromLS, stateFromEnv);
  }

  return stateWithSessionId;
};

export default getInitialState;
