import React from "react";
import { func, node } from "prop-types";
import { Icon, Link, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette: { grey } }) => ({
  secondaryTextLink: {
    display: "flex",
    alignItems: "center",
  },
  secondaryText: {
    color: grey[400],
    opacity: 0.9,
    margin: "0 5px",
    fontSize: "12px",
  },
  secondaryTextIcon: {
    color: grey[400],
    fontSize: "16px",
    opacity: "0.9",
  },
  traineeMessage: {
    paddingTop: "2px",
    display: "flex",
    alignItems: "center",
  },
}));

const FormInstructorPickerSecondaryText = ({ text, onClick = () => {} }) => {
  const classes = useStyles();
  return (
    <Link
      className={classes.secondaryTextLink}
      component="button"
      onClick={onClick}
      data-test="secondary-text"
    >
      <span className={classes.secondaryText}>{text}</span>
      <Icon className={classes.secondaryTextIcon} fontSize="small">
        info_outlined
      </Icon>
    </Link>
  );
};

FormInstructorPickerSecondaryText.propTypes = {
  text: node.isRequired,
  onClick: func,
};

export default FormInstructorPickerSecondaryText;
