import React from "react";
import { Helmet } from "react-helmet";
import { useBrand } from "./components/common/BrandProvider/BrandProvider";

const AppMeta = () => {
  const { getText } = useBrand();
  const webRoot = `${process.env.PUBLIC_URL.replace(/\/+$/, "") || ""}/`;
  const favIconDirectory =
    webRoot + getText("common.favIconDirectory").replace(/^\/+/, "");

  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href={`${favIconDirectory}/apple-icon-57x57.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href={`${favIconDirectory}/apple-icon-60x60.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href={`${favIconDirectory}/apple-icon-72x72.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={`${favIconDirectory}/apple-icon-76x76.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={`${favIconDirectory}/apple-icon-114x114.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={`${favIconDirectory}/apple-icon-120x120.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={`${favIconDirectory}/apple-icon-144x144.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={`${favIconDirectory}/apple-icon-152x152.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${favIconDirectory}/apple-icon-180x180.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={`${favIconDirectory}/android-icon-192x192.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${favIconDirectory}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href={`${favIconDirectory}/favicon-96x96.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${favIconDirectory}/favicon-16x16.png`}
      />
      <link rel="manifest" href={`${favIconDirectory}/manifest.json`} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  );
};

export default AppMeta;
