import React from "react";
import mergeDeep from "lodash.merge";
import { bool, number } from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";
import { SwapHoriz } from "@material-ui/icons";

import { formatCurrency } from "../../../../utils/formatCurrency";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";

const useStyles = makeStyles(theme =>
  mergeDeep(theme.journey.prices, {
    changeInstructorButtonText: {
      marginLeft: "4px",
      fontSize: "14px",
      [theme.breakpoints.up("md")]: {
        marginLeft: "8px",
      },
    },
    changeInstructorButtonInner: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    changeInstructorButtonIconWrap: {
      display: "flex",
      alignItems: "center",
    },
    changeInstructorButtonFrom: {
      fontSize: "12px",
      color: theme.palette.grey[400],
      display: "block",
      textTransform: "none",
      textAlign: "center",
      lineHeight: "1.1",
      opacity: "0.9",
      letterSpacing: "normal",
      marginTop: "5px",
    },
  }),
);

const ChangeInstructorButton = ({
  startingPrice,
  showChangeInstructorText = false,
}) => {
  const { getText } = useBrand();
  const classes = useStyles();
  return (
    <div className={classes.changeInstructorButtonInner}>
      <div className={classes.changeInstructorButtonIconWrap}>
        <SwapHoriz />
        {showChangeInstructorText && (
          <span className={classes.changeInstructorButtonText}>
            {getText("prices.change")}
          </span>
        )}
      </div>
      {startingPrice && (
        <Typography
          variant="body1"
          classes={{ root: classes.changeInstructorButtonFrom }}
          data-test="alt-instructors-from-text"
        >
          {`from £${formatCurrency(startingPrice)}/hr`}
        </Typography>
      )}
    </div>
  );
};

ChangeInstructorButton.propTypes = {
  startingPrice: number,
  showChangeInstructorText: bool,
};

export default ChangeInstructorButton;
