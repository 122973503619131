import React, { useState, useEffect } from "react";
import get from "lodash.get";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";
import { useHistory, withRouter } from "react-router-dom";
import { BOOKING_FEE } from "@ds-developer/constants";

import {
  useStateContext,
  UPDATE_NUJ_HISTORY,
} from "../../../../context/stateContext";

import { OrderStatus } from "@graphql/types";

import orderStatus from "../../../../graphql/orderStatus.graphql";
import Payment from "./Payment";
import { useTealium } from "../../../common/TealiumProvider/TealiumProvider";
import {
  GLOBAL_PATH_NAMES,
  LESSON_TYPES,
  NEW_USER_JOURNEY_PATH_NAMES,
} from "../../../../constants";
import { TIMEOUT_DELAY } from "../../../common/Payment/constants";
import { getOrderStatusQueryOpts } from "../../../common/Payment/helpers";
import { StateContext } from "models/state";

export const PaymentWithState = () => {
  const history = useHistory();
  const { trackEvent, updateDataLayer } = useTealium();

  const doTimeout = () => {
    history.push(GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG);
    trackEvent({
      eventCategory: "Somethings Gone Wrong",
      eventAction: "Pay",
      eventLabel: "Payment Timeout (NUJ)",
    });
    updateDataLayer({
      booking_status: "Payment Timeout (NUJ)",
    });
  };

  // Init as an empty function to keep TS happy
  let timeout = setTimeout(() => {}, 0);

  const startTimeoutTimer = () => {
    timeout = setTimeout(doTimeout, TIMEOUT_DELAY);
  };

  const {
    dispatch,
    state: {
      nujHistory: {
        order,
        selectedPackage,
        pickupPostcode,
        selectedLessonType,
        selectedInstructor,
        selectedLessonTimeslot: lesson,
        pupilDetails: { firstName: pupilFirstName },
      },
    },
  }: StateContext = useStateContext();

  const instructorName = selectedInstructor?.instructor.name;

  const orderId = get(order, "orderId");
  const discountedPackagePrice = get(selectedPackage, "discountedPackagePrice");
  const total = (discountedPackagePrice || 0) + BOOKING_FEE;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPaymentDeclined, setIsPaymentDeclined] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentOrderStatus, setCurrentOrderStatus] = useState();

  const confirmationUrl =
    selectedLessonType === LESSON_TYPES.SHORT_NOTICE_TEST
      ? NEW_USER_JOURNEY_PATH_NAMES.SNT_CONFIRMATION
      : NEW_USER_JOURNEY_PATH_NAMES.CONFIRMATION;

  let iFrameRef: HTMLFormElement;

  const setIFrameForm = (element: HTMLFormElement) => {
    if (element) {
      iFrameRef = element;
    }
    if (element && !isSubmitting) {
      setIsSubmitting(true);
      element.submit();
    }
  };

  const setIFrameLoading = (iframe: HTMLIFrameElement) => {
    if (iframe && isLoading) {
      iframe.addEventListener("load", () => {
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    startTimeoutTimer();
    return () => {
      clearTimeout(timeout);
    };
  });

  const { error, data, stopPolling } = useQuery(
    orderStatus,
    getOrderStatusQueryOpts(orderId, timeout, "NUJ") as QueryHookOptions,
  );

  if (error) {
    throw new Error(error.toString());
  }

  const status = get(data, "orderNUJ.status");

  if (status !== currentOrderStatus) {
    setCurrentOrderStatus(status);
  }

  const handleStatusChange = () => {
    if (currentOrderStatus === OrderStatus.Paid) {
      stopPolling();
      clearTimeout(timeout);
      dispatch({
        type: UPDATE_NUJ_HISTORY,
        payload: {
          confirmation: {
            topUpId: get(data, "orderNUJ.topUpId", ""),
            pickupPostcode,
            lesson: {
              ...lesson,
              lessonType: selectedLessonType,
            },
            instructorName,
            pupilFirstName,
          },
        },
      });
      trackEvent({
        eventCategory: "Confirmation",
        eventAction: "Pay",
        eventLabel: "Payment Successful (NUJ)",
        eventValue: total.toString(),
      });
      updateDataLayer({
        booking_status: "Payment Successful (NUJ)",
        booking_total: total.toString(),
      });
      setIsSubmitting(false);
      setIsPaymentDeclined(false);
      history.push(confirmationUrl);
    } else if (currentOrderStatus === OrderStatus.Failed) {
      setIsPaymentDeclined(true);
      trackEvent({
        eventCategory: "Payment",
        eventAction: "Pay",
        eventLabel: "Payment Failed (NUJ)",
        eventValue: total.toString(),
      });
      updateDataLayer({
        booking_status: "Payment Failed (NUJ)",
        booking_total: total.toString(),
      });
      if (iFrameRef) {
        iFrameRef.submit();
      }
    }
  };

  useEffect(handleStatusChange, [currentOrderStatus, data, total]);

  return (
    <Payment
      order={order}
      isLoading={isLoading}
      isPaymentDeclined={isPaymentDeclined}
      setIFrameForm={setIFrameForm}
      setIFrameLoading={setIFrameLoading}
    />
  );
};

export default withRouter(PaymentWithState);
