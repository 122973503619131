import React, { useState, useEffect } from "react";
import get from "lodash.get";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BOOKING_FEE } from "@ds-developer/constants";

import {
  useStateContext,
  UPDATE_QTU_HISTORY,
} from "../../../context/stateContext";
import createOrderMutation from "../../../graphql/createExistingPupilOrder.graphql";
import orderStatus from "../../../graphql/orderStatus.graphql";

import Payment from "./Payment";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";

import {
  ORDER_SUCCESS,
  ORDER_DECLINED,
  TIMEOUT_DELAY,
} from "../../common/Payment/constants";
import { getOrderStatusQueryOpts } from "../../common/Payment/helpers";
import {
  GLOBAL_PATH_NAMES,
  RETURNING_USER_JOURNEY_PATH_NAMES,
} from "../../../constants";

export const PaymentWithState = ({ history }: RouteComponentProps) => {
  const { trackEvent, updateDataLayer } = useTealium();

  const doTimeout = () => {
    history.push(GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG);
    trackEvent({
      eventCategory: "Somethings Gone Wrong",
      eventAction: "Pay",
      eventLabel: "Payment Timeout (QTU)",
    });
    updateDataLayer({
      booking_status: "Payment Timeout (QTU)",
    });
  };

  const timeout = setTimeout(doTimeout, TIMEOUT_DELAY);

  useEffect(() => () => clearTimeout(timeout));

  const {
    dispatch,
    state: {
      qtuHistory: {
        learnerId,
        selectedHours,
        selectedHoursCost,
        pupilFirstName,
      },
    },
  } = useStateContext();

  const [createOrder, createOrderResult] = useMutation(createOrderMutation);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPaymentDeclined, setIsPaymentDeclined] = useState(false);
  const [isLoadingPaymentForm, setIsLoadingPaymentForm] = useState(true);
  const [currentOrderStatus, setCurrentOrderStatus] = useState();

  if (createOrderResult.error) {
    throw new Error(createOrderResult.error.message);
  }

  const orderId = get(createOrderResult, "data.createTopUpOrder.order.id");

  const amount = get(createOrderResult, "data.createTopUpOrder.order.amount");
  const discount = get(
    createOrderResult,
    "data.createTopUpOrder.order.discount",
  );

  const orderCostSummary = {
    hours: selectedHours,
    packagePrice: amount - BOOKING_FEE,
    discount: Number(discount) || 0,
    bookingFee: BOOKING_FEE,
    total: amount,
  };

  const hours = get(orderCostSummary, "hours", selectedHours);
  const packageCost = get(orderCostSummary, "packagePrice", selectedHoursCost);
  const discountAmount = get(orderCostSummary, "discount", selectedHours);
  const totalCost = get(orderCostSummary, "total");
  const bookingFee = get(orderCostSummary, "bookingFee", 0);

  const token = get(createOrderResult, "data.createTopUpOrder.token");
  const endpoint = get(
    createOrderResult,
    "data.createTopUpOrder.paymentEndpoint",
  );

  const isCreatingOrder = get(createOrderResult, "loading");

  let iFrameRef: HTMLFormElement | null = null;

  const setIFrameForm = (element: HTMLFormElement) => {
    if (element) {
      iFrameRef = element;
    }
    if (element && !isSubmitting) {
      setIsSubmitting(true);
      element.submit();
    }
  };

  const setIFrameLoading = (iframe: HTMLIFrameElement) => {
    if (iframe && isLoadingPaymentForm) {
      iframe.addEventListener("load", () => {
        setIsLoadingPaymentForm(false);
      });
    }
  };

  const [getOrderStatus, { error, called, data, stopPolling }] = useLazyQuery(
    orderStatus,
    getOrderStatusQueryOpts(orderId, timeout, "QTU"),
  );

  if (error) {
    throw new Error(error.message);
  }

  const status = get(data, "orderNUJ.status");
  const topUpId = get(data, "orderNUJ.topUpId");

  if (status !== currentOrderStatus) {
    setCurrentOrderStatus(status);
  }

  const handleStatusChange = () => {
    if (currentOrderStatus && currentOrderStatus === ORDER_SUCCESS) {
      stopPolling && stopPolling();
      clearTimeout(timeout);
      dispatch({
        type: UPDATE_QTU_HISTORY,
        payload: {
          paymentSuccess: true,
          confirmation: {
            selectedHours,
            topUpId,
            pupilFirstName,
          },
        },
      });
      setIsSubmitting(false);
      setIsPaymentDeclined(false);
      trackEvent({
        eventCategory: "Confirmation",
        eventAction: "Pay",
        eventLabel: "Payment Successful (QTUJ)",
        eventValue: totalCost,
      });
      updateDataLayer({
        booking_status: "Payment Successful (QTUJ)",
        booking_total: totalCost,
      });
      history.push(RETURNING_USER_JOURNEY_PATH_NAMES.TOPUP_CONFIRMATION);
    } else if (currentOrderStatus === ORDER_DECLINED) {
      setIsSubmitting(false);
      setIsPaymentDeclined(true);
      trackEvent({
        eventCategory: "Payment",
        eventAction: "Pay",
        eventLabel: "Payment Failed (QTUJ)",
        eventValue: totalCost,
      });
      updateDataLayer({
        booking_status: "Payment Failed (QTUJ)",
        booking_total: totalCost,
      });
      if (iFrameRef) {
        iFrameRef.submit();
      }
    }
  };

  useEffect(() => {
    if (orderId && !called) {
      getOrderStatus({ variables: { orderId } });
    }
  }, [called, getOrderStatus, orderId]);

  useEffect(handleStatusChange, [currentOrderStatus]);

  if (!orderId && !isCreatingOrder) {
    createOrder({
      variables: {
        input: {
          learnerId: learnerId,
          hours: selectedHours,
        },
      },
    });
  }

  return (
    <Payment
      token={token}
      endpoint={endpoint}
      selectedHours={hours}
      selectedHoursCost={packageCost}
      discountAmount={discountAmount}
      totalCost={totalCost}
      bookingFee={bookingFee}
      isLoadingPage={isCreatingOrder}
      isLoadingPaymentForm={isLoadingPaymentForm}
      isPaymentDeclined={isPaymentDeclined}
      setIFrameForm={setIFrameForm}
      setIFrameLoading={setIFrameLoading}
      learnerName={pupilFirstName}
    />
  );
};

export default withRouter(PaymentWithState);
