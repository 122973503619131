import React, { Fragment } from "react";
import { string, node } from "prop-types";
import { List as MuiList, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.grey[500],
    fontSize: theme.journey.radioItemList.labelFontSize,
    fontWeight: "normal",
    margin: theme.journey.radioItemList.labelMargin,
    lineHeight: "24px",
  },
}));

const RadioList = ({ label, children }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Typography className={classes.label}>{label}</Typography>
      <MuiList disablePadding>{children}</MuiList>
    </Fragment>
  );
};

RadioList.propTypes = {
  label: string,
  children: node,
};

export default RadioList;
