import React from "react";
import { Slider, Typography, Grid, Theme } from "@material-ui/core";
import { Styles, withStyles } from "@material-ui/styles";
import { formatCurrency } from "../../../../utils/formatCurrency";

const styles: Styles<any, any, string> = (theme: Theme) => ({
  rate: {
    opacity: "0.5",
    fontSize: "15px",
    lineHeight: "24px",
    textAlign: "right",
  },
  discountHint: {
    opacity: "0.5",
    fontSize: "12px",
    lineHeight: "24px",
    marginBottom: "10px",
  },
  discount: {
    fontSize: "12px",
    lineHeight: "24px",
    textAlign: "right",
    color: theme.palette.secondary.main,
  },
  slider: {
    width: "94%",
    margin: "50px 3% 0 3%",
  },
  sliderThumb: {
    color: theme.palette.primary.main,
  },
});

interface FormTopUpAmountProps {
  classes: any;
  hourlyRate: number;
  selectedHours: number;
  threshold: number;
  discountAmount: number;
  onChange: (event: Partial<Event>, value: number | number[]) => void;
}

const FormTopUpAmount = ({
  classes,
  hourlyRate,
  selectedHours = 1,
  threshold = 6,
  discountAmount = 0,
  onChange,
}: FormTopUpAmountProps) => (
  <div>
    <Grid container>
      <Grid item xs={9} md={9}>
        <Typography>Choose top-up amount</Typography>
        {threshold > 0 && discountAmount > 0 && (
          <Typography
            className={classes.discountHint}
            data-test="discount-hint"
          >
            Buy {threshold} hours and get £{formatCurrency(discountAmount)}/hr
            off
          </Typography>
        )}
      </Grid>
      <Grid item xs={3} md={3}>
        <Typography className={classes.rate}>
          @£{formatCurrency(hourlyRate)}/hr
        </Typography>
        <Typography className={classes.discount} data-test="discount-amount">
          {discountAmount > 0 && selectedHours >= threshold ? (
            `£${formatCurrency(discountAmount)}/hr discount`
          ) : (
            <span>&nbsp;</span>
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Slider
          min={1}
          max={20}
          aria-labelledby="discrete-slider-always"
          valueLabelDisplay="on"
          classes={{
            root: classes.slider,
            valueLabel: classes.sliderThumb,
          }}
          defaultValue={selectedHours}
          data-test="topup-slider"
          tabIndex={1}
          marks={[
            {
              value: 1,
              label: 1,
            },
            {
              value: 20,
              label: 20,
            },
          ]}
          onChangeCommitted={(e, value) => onChange(e, value)}
        />
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(React.memo(FormTopUpAmount));
