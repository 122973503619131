import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useStateContext } from "../../../../context/stateContext";
import LessonCountry from "../../../CommonRoutes/LessonCountry/LessonCountry";
import { submitSelectCountryForm } from "./helpers";
import { usePostcodeCountryDetails } from "../../../../hooks";

import { Country, CountryDetails } from "@lessons/models";

const SelectCountryWithState = () => {
  const history = useHistory();

  const {
    state: {
      nujHistory: { pickupPostcode, selectedCountry },
    },
    dispatch,
  } = useStateContext();

  const [selected, setSelected] = useState<Country | undefined>(
    selectedCountry?.name,
  );

  const { countries } = usePostcodeCountryDetails({
    postcode: pickupPostcode,
  });

  const handleChange = (country: Country) => setSelected(country);
  const handleSubmit = (selectedCountryDetails?: CountryDetails) => {
    submitSelectCountryForm({
      selectedCountryDetails,
      dispatch,
      history,
    });
  };

  return (
    <LessonCountry
      countries={countries}
      selected={selected}
      onChange={handleChange}
      onContinueClick={handleSubmit}
    />
  );
};

export default SelectCountryWithState;
