import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import startCase from "lodash.startcase";
import merge from "lodash.merge";
import {
  FormControl,
  FormLabel,
  Typography,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { ReportProblem, ReportProblemOutlined } from "@material-ui/icons";

import ContinueButton from "../../common/ContinueButton/ContinueButton";
import RadioCardGroup from "../../common/Form/RadioCardGroup/RadioCardGroup";
import RadioCard from "../../common/Form/RadioCard/RadioCard";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import Titlebar from "../../common/Titlebar/Titlebar";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";
import { goBack } from "../../../utils/navigation";
import InfoPanel from "../../common/InfoPanel/InfoPanel";
import { BSM_BRAND_NAME } from "../../../constants";

import { LessonCountryProps } from "./types";

const useStyles = makeStyles(
  (theme: Theme & { journey: { keyWorker: Record<string, any> } }) =>
    merge(theme.journey.keyWorker, {
      root: {
        flex: "1",
        color: theme.palette.grey[500],
        fontWeight: theme.typography.fontWeightMedium,
        "&:first-child": {
          margin: "16px 15px 0 4px",
        },
        "&:last-child": {
          margin: "16px 4px 0 15px",
        },
        "&$checked": {
          color: theme.palette.text.primary,
        },
      },
      checked: {
        color: theme.palette.text.primary,
      },
      svg: {
        marginTop: "-0.5em",
        marginRight: "0.5em",
      },
    }),
);

const LessonCountry: React.FC<LessonCountryProps> = ({
  countries = [],
  disabled = false,
  onContinueClick = () => {},
  selected,
  onChange,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { brand, getText } = useBrand();
  const { trackEvent, updateDataLayer } = useTealium();
  const classes = useStyles();

  // We should move this functionality to the titlebar component and stop repeating in multiple places
  const handleBackButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    goBack({ history, pathname });
  };

  const handleContinueButtonClick = () => {
    trackEvent({
      eventCategory: "Select Country",
      eventAction: "Button Click",
      eventLabel: "Continue",
    });
    updateDataLayer({
      country: selected,
    });
    onContinueClick(countries.find(({ name }) => name === selected));
  };

  return (
    <ContentWrapper>
      <Titlebar
        showBackButton
        onBackClick={handleBackButtonClick}
        headingProps={{ "data-test": "lesson-country-title-bar" }}
      >
        {getText("lesson-country.pageTitle")}
      </Titlebar>

      <ContentWrapper fullHeight fullWidth>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">
            {getText("lesson-country.countrySelectorLabel")}
          </FormLabel>
          <RadioCardGroup
            label=""
            name="country"
            value={selected}
            onChange={onChange}
            disabled={disabled}
          >
            {countries.map(({ name }) => (
              <RadioCard
                key={name}
                value={name}
                cardContent={<Typography>{startCase(name)}</Typography>}
                cardContentChecked={<Typography>{startCase(name)}</Typography>}
                classes={classes}
                onClick={() => {
                  trackEvent({
                    eventCategory: "Select Country",
                    eventAction: "Interaction",
                    eventLabel: startCase(name),
                  });
                }}
              />
            ))}
          </RadioCardGroup>
        </FormControl>

        <ContinueButton
          onClick={handleContinueButtonClick}
          data-test="lesson-country-continue"
        />

        <InfoPanel
          svg={
            brand === BSM_BRAND_NAME ? (
              <ReportProblem classes={{ root: classes.infoPanelIcon }} />
            ) : (
              <ReportProblemOutlined
                classes={{ root: classes.infoPanelIcon }}
              />
            )
          }
          svgClasses={classes.svg}
          svgFlexAlignItems="flex-start"
          title={getText("lesson-country.infoPanelLabel")}
        >
          {getText("lesson-country.infoPanelText")}
        </InfoPanel>
      </ContentWrapper>
    </ContentWrapper>
  );
};

export default LessonCountry;
