import React from "react";
import { Avatar } from "@material-ui/core";

const AA = () => (
  <Avatar>
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="#FFCC00"
      />
      <path
        d="M16.996 17.8075V21.5287H15.3521L16.996 17.8075ZM26.4386 17.8075V21.5287H24.7948L26.4386 17.8075ZM25.5785 13L19.5573 26.7385V13H16.1167L10 27H12.9628L14.1288 24.3448H16.996V27H22.4054L23.5714 24.3448H26.4386V27H29V13H25.5785Z"
        fill="black"
      />
    </svg>
  </Avatar>
);

export default AA;
