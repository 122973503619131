/* eslint-disable react/require-default-props */
/* eslint-disable react/default-props-match-prop-types */

import React, { Fragment } from "react";
import { string, number, shape, instanceOf } from "prop-types";
import { Card, CardHeader, makeStyles, CardContent } from "@material-ui/core";
import { format } from "date-fns";
import mergeDeep from "lodash.merge";

import LessonDetailSection from "./LessonDetailSection";
import { getInitials, formatPostcode } from "../../../utils";
import { formatLessonDateTime } from "../../../utils/formatLessonDateTime";
import { useBrand } from "../BrandProvider/BrandProvider";
import mapIcon from "../../../svg/map-icon.svg";

const useStyles = makeStyles(theme =>
  mergeDeep(theme.journey.lessonDetails, {
    card: {
      color: theme.palette.grey[400],
    },
    header: {
      paddingTop: "23px",
      paddingBottom: "0",
    },
    headerTitle: {
      fontSize: "20px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    content: {
      paddingTop: "23px",
    },
  }),
);

const LessonDetails = ({
  instructorName = "",
  lesson: { duration, location, startTime },
  title = "",
}) => {
  const { getText } = useBrand();
  const classes = useStyles();
  const [, firstName, lastName] = instructorName.split(" ");

  return (
    <Card classes={{ root: classes.card }} data-test="lesson-details">
      {title && (
        <CardHeader
          classes={{ root: classes.header, title: classes.headerTitle }}
          title={title}
        />
      )}
      <CardContent classes={{ root: classes.content }}>
        <Fragment>
          {startTime && duration && !Number.isNaN(duration) && (
            <LessonDetailSection
              avatarText={format(startTime, "dd")}
              title={formatLessonDateTime(startTime)}
              subtitle={`${duration} ${getText("confirmation.hourLesson")}`}
              avatarColor={classes.primaryAvatar}
            />
          )}
          {firstName && lastName && (
            <LessonDetailSection
              avatarText={getInitials(firstName, lastName)}
              title={`${firstName} ${lastName}`}
              subtitle={getText("confirmation.lessonDetailsInstructor")}
              avatarColor={classes.secondaryAvatar}
              data-test="lesson-detail-section-instructor"
            />
          )}
          {location && (
            <LessonDetailSection
              avatarImage={mapIcon}
              title={formatPostcode(location)}
              subtitle={getText("confirmation.lessonDetailsAddress")}
            />
          )}
        </Fragment>
      </CardContent>
    </Card>
  );
};

LessonDetails.propTypes = {
  title: string,
  lesson: shape({
    startTime: instanceOf(Date),
    duration: number,
    location: string,
  }),
  instructorName: string,
};

export default LessonDetails;
