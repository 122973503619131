import React, { FormEvent, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import TopUp from "./TopUp";
import {
  useStateContext,
  UPDATE_QTU_HISTORY,
} from "../../../context/stateContext";
import { RETURNING_USER_JOURNEY_PATH_NAMES } from "../../../constants";

export const calculateTotalCost = (
  hours: number,
  hourlyRate: number,
  discountedRate = 0,
  discountThreshold = 6,
): number => {
  const discountApplies =
    discountedRate > 0 &&
    discountedRate < hourlyRate &&
    hours >= discountThreshold;
  const rateToApply = discountApplies ? discountedRate : hourlyRate;
  return hours * rateToApply;
};

const TopUpWithQuery = ({ history }: RouteComponentProps) => {
  const {
    state: {
      qtuHistory: {
        pupilFirstName,
        pupilBalance,
        selectedHours,
        selectedHoursCost,
        discountedHourlyRate,
        threshold,
        hourlyRate,
      },
    },
    dispatch,
  } = useStateContext();

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    history.push(RETURNING_USER_JOURNEY_PATH_NAMES.TOPUP_PAYMENT);
  };

  const setSelectedHours = (hours: number) => {
    dispatch({
      type: UPDATE_QTU_HISTORY,
      payload: {
        selectedHours: hours,
        selectedHoursCost: calculateTotalCost(
          hours,
          hourlyRate,
          discountedHourlyRate,
          threshold,
        ),
      },
    });
  };

  useEffect(() => {
    setSelectedHours(selectedHours);
  }, []);

  return (
    <TopUp
      name={pupilFirstName}
      balance={pupilBalance}
      hourlyRate={hourlyRate}
      discountAmount={hourlyRate - discountedHourlyRate}
      discountThreshold={threshold}
      selectedHours={selectedHours}
      onHoursChange={setSelectedHours}
      totalCost={selectedHoursCost}
      onSubmit={onSubmit}
    />
  );
};

export default withRouter(TopUpWithQuery);
