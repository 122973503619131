import { useEffect, useState } from "react";
import useQueryString from "./useQueryString";
import { UPDATE_NUJ_HISTORY } from "../context/stateContext";
import { MARKETING_CHANNELS, REFERRAL_ID_PARAM } from "../constants";

const useReferral = ({ dispatch, search }) => {
  const [referralCode, setReferralCode] = useState("");
  useEffect(() => {
    const referralId = useQueryString(search)[REFERRAL_ID_PARAM];
    if (referralId) {
      dispatch({
        type: UPDATE_NUJ_HISTORY,
        payload: {
          marketingChannel: MARKETING_CHANNELS.REFERRAL,
          marketingChannelId: referralId,
        },
      });
      setReferralCode(referralId);
    }
  }, [search]);

  return referralCode;
};

export default useReferral;
