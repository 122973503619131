import React, { Fragment } from "react";

import { Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { isSameDay } from "date-fns";

import TimeslotsBanner from "./TimeslotsBanner";
import Button from "../Button/Button";
import ContentWrapper from "../ContentWrapper/ContentWrapper";
import ContinueButton from "../ContinueButton/ContinueButton";
import DatePickerCarousel from "../Form/DatePickerCarousel/DatePickerCarousel";
import TimeslotPicker from "../Form/TimeSlotPicker/TimeSlotPicker";
import Titlebar from "../Titlebar/Titlebar";

import { useBrand } from "../BrandProvider/BrandProvider";
import { LONG_DATE_FORMAT } from "../../../constants";
import { TimeslotsProps } from "./types";

const styles = (
  theme: Theme | Record<string, any>,
): WithStyles | Record<string, any> => ({
  calendarIcon: {
    marginRight: "10px",
  },
  buttonWrap: {
    display: "flex",
    position: "fixed",
    width: "100%",
    bottom: "30px",
    right: 0,
    textAlign: "center",
    zIndex: "3",
    [theme.breakpoints.up("md")]: {
      width: "75%",
    },
  },
  datePickerCarousel: {
    margin: "0 -15px 12px -15px",
  },
  timeslotPicker: {
    margin: "15px 0 150px 0",
  },
  datePicker: {
    display: "none",
  },
  bannerContainer: {
    marginBottom: "24px",
  },
});

const Timeslots = ({
  onBackClick,
  titleText,
  classes,
  carouselDates,
  onCarouselDateSelect,
  selectedDate,
  onCarouselNextClick,
  onCarouselPreviousClick,
  carouselSlideIndex,
  minDisplayableDate,
  maxDisplayableDate,
  onViewCalenderClick,
  availableTimeslots,
  onTimeslotSelected,
  selectedTime,
  showDatePicker,
  onDatePickerClose,
  onDatePickerChange,
  onContinueButtonClick,
  disableContinueButton,
  continueButtonText,
  showBanner = false,
  disableAnimation = false,
}: TimeslotsProps) => {
  const { getText } = useBrand();
  return (
    <Fragment>
      <ContentWrapper>
        <Titlebar
          showBackButton
          onBackClick={onBackClick}
          headingProps={{ "data-test": "timeslots-title-bar" }}
        >
          {titleText}
        </Titlebar>
      </ContentWrapper>

      {carouselDates.length === 0 ? (
        <Typography>{getText("timeslots.noslots")}</Typography>
      ) : (
        <ContentWrapper fullHeight>
          {showBanner && <TimeslotsBanner />}
          <div className={classes.datePickerCarousel}>
            <DatePickerCarousel
              dates={carouselDates}
              onChange={onCarouselDateSelect}
              value={selectedDate}
              radioGroupProps={{
                name: "timeslots-date-picker",
                "data-test": "timeslots-date-picker-carousel",
              }}
              onNextClick={onCarouselNextClick}
              onPreviousClick={onCarouselPreviousClick}
              slideIndex={carouselSlideIndex}
              disableAnimation={disableAnimation}
            />
          </div>
          <Button
            text={getText("timeslots.viewCalendar")}
            onClick={onViewCalenderClick}
            secondary
            icon="calendar_today"
            iconProps={{
              classes: {
                root: classes.calendarIcon,
              },
            }}
            data-test="timeslots-view-calendar"
          />
          <div className={classes.timeslotPicker}>
            <TimeslotPicker
              availableTimes={availableTimeslots}
              onChange={onTimeslotSelected}
              value={selectedTime}
              radioCardGroupProps={{
                "data-test": "timeslots-timeslot-picker",
                "data-testid": "timeslots-timeslot-picker",
              }}
            />
          </div>
          <DatePicker
            //@ts-ignore
            classes={{ root: classes.datePicker }}
            variant="dialog"
            value={selectedDate}
            open={showDatePicker}
            minDate={minDisplayableDate}
            maxDate={maxDisplayableDate}
            onClose={onDatePickerClose}
            onChange={onDatePickerChange}
            okLabel={getText("timeslots.datePickerOk")}
            cancelLabel={getText("timeslots.datePickerCancel")}
            format={LONG_DATE_FORMAT}
            shouldDisableDate={day =>
              (day && !carouselDates.find(d => isSameDay(new Date(d), day))) ||
              false
            }
            disableToolbar
          />

          <div className={classes.buttonWrap}>
            {selectedDate &&
              selectedTime &&
              selectedTime.startTime &&
              selectedTime.endTime && (
                <ContinueButton
                  text={continueButtonText}
                  onClick={onContinueButtonClick}
                  disabled={disableContinueButton}
                  disableVerticalMargin
                  data-test="timeslots-continue-button"
                />
              )}
          </div>
        </ContentWrapper>
      )}
    </Fragment>
  );
};

export default withStyles(styles)(Timeslots);
