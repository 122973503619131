import React, { FormEvent } from "react";
import { useLocation } from "react-router-dom";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { formatCurrency } from "../../../utils/formatCurrency";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import TopUpAmount from "../../common/Form/TopUpAmount/TopUpAmount";
import ContinueButton from "../../common/ContinueButton/ContinueButton";
import TopupHeader from "../../common/TopupHeader/TopupHeader";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import RefundGuarantee from "../../common/RefundGuarantee/RefundGuarantee";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";
import { useStateContext } from "@context";

const useStyles = makeStyles(() => ({
  totalHours: {
    "& span": {
      display: "block",
    },
  },
  totalCost: {
    "& span": {
      display: "block",
      textAlign: "right",
    },
  },
  label: {
    fontSize: "12px",
    lineHeight: "12px",
    marginBottom: "10px",
    textTransform: "uppercase",
  },
  totals: {
    marginTop: "30px",
  },
  cost: {
    fontSize: "24px",
    fontWeight: "bold",
  },
}));

interface TopUpProps {
  name: string;
  balance: number;
  hourlyRate: number;
  discountAmount: number;
  selectedHours: number;
  discountThreshold: number;
  onHoursChange: (val: number) => void;
  totalCost: number;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

const TopUp = ({
  name,
  balance,
  hourlyRate,
  discountAmount,
  selectedHours = 1,
  onHoursChange,
  totalCost = 0,
  discountThreshold = 6,
  onSubmit,
}: TopUpProps) => {
  const { getText } = useBrand();
  const { trackEvent, updateDataLayer } = useTealium();
  const { pathname } = useLocation();
  const classes = useStyles();

  const {
    state: { isQTUJourney },
  } = useStateContext();

  return (
    <ContentWrapper>
      <form onSubmit={onSubmit}>
        <Grid container spacing={1}>
          <TopupHeader
            title={getText("top-up/amount.pageTitle", { name })}
            balance={balance}
            showBackButton={!isQTUJourney}
          />
          <Grid item xs={12} md={12}>
            <TopUpAmount
              onChange={(event: Partial<Event>, value: number | number[]) => {
                trackEvent({
                  eventCategory: capitalizeEachWordOfPathname(pathname),
                  eventAction: "Interaction",
                  eventLabel: `Top-up Amount - ${value}`,
                });
                updateDataLayer({
                  topup_amount: `Top-up Amount - ${value}`,
                });
                onHoursChange(value as number);
              }}
              value={selectedHours}
              hourlyRate={hourlyRate}
              discountAmount={discountAmount}
              selectedHours={selectedHours}
              threshold={discountThreshold}
            />
          </Grid>
          <Grid container justify="space-between" className={classes.totals}>
            <Grid item xs={6} md={6}>
              <Typography className={classes.totalHours}>
                <span className={classes.label}>Total</span>
                <span data-test="topup-hours">
                  {selectedHours} hour{selectedHours > 1 ? "s" : ""}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className={classes.totalCost}>
                <span className={classes.label}>Price</span>
                <span className={classes.cost}>
                  £{formatCurrency(totalCost)}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <ContinueButton
            data-test="continue-button"
            navButton={false}
            type="submit"
            onClick={() => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(pathname),
                eventAction: "Button Click",
                eventLabel: "Continue",
              });
            }}
          />
          <ContentWrapper fullHeight>
            <RefundGuarantee />
          </ContentWrapper>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default React.memo(TopUp);
