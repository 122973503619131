import React from "react";
import { shape, string } from "prop-types";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  helperText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  warningIcon: {
    fontSize: "16px",
  },
}));

const TextFieldErrorMessage = ({
  errorMessage,
  spanProps,
}: {
  errorMessage: string;
  spanProps?: Record<string, any>;
}) => {
  const classes = useStyles();
  return (
    <span className={classes.helperText} {...spanProps}>
      {errorMessage}
      <Icon className={classes.warningIcon}>warning</Icon>
    </span>
  );
};

TextFieldErrorMessage.propTypes = {
  errorMessage: string,
  spanProps: shape({}),
};

export default TextFieldErrorMessage;
