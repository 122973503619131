import React from "react";

import { Typography, makeStyles } from "@material-ui/core";

import { useTealium } from "../TealiumProvider/TealiumProvider";
import ContentWrapper from "../ContentWrapper/ContentWrapper";
import ContinueButton from "../ContinueButton/ContinueButton";
import { useBrand } from "../BrandProvider/BrandProvider";
import Warning from "../Warning/Warning";

const useStyles = makeStyles(theme => ({
  notice: {
    display: "flex",
    alignItems: "center",
    "& h1": {
      marginLeft: "20px",
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  callText: {
    marginTop: "20px",
  },
}));

const AccountLocked = () => {
  const { getText } = useBrand();
  const classes = useStyles();
  const { trackEvent } = useTealium();
  return (
    <ContentWrapper>
      <Warning
        title={getText("login.lockedAccountTitle")}
        data-test="account-locked-title"
      />
      <Typography
        classes={{ root: classes.callText }}
        data-test="account-locked-message"
      >
        {getText("login.lockedAccountSubtitle")}
      </Typography>

      <ContinueButton
        href={getText("common.chatPage")}
        text={getText("login.lockedAccountButton")}
        data-test="call-button"
        onClick={() =>
          trackEvent({
            eventCategory: "Call Button",
            eventAction: "click",
          })
        }
      />
    </ContentWrapper>
  );
};

AccountLocked.propTypes = {};

export default AccountLocked;
