import React, { useState, useEffect, Children, cloneElement } from "react";
import { func, bool, number, shape, node } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import findIndex from "lodash.findindex";
import isFunction from "lodash.isfunction";
import get from "lodash.get";
import Carousel from "nuka-carousel";

import { RadioGroup } from "@material-ui/core";
import PreviousButton from "./PreviousButton";
import NextButton from "./NextButton";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  slideWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "3px 0",
  },
}));

const RadioCardGroupCarousel = ({
  children,
  value = "",
  onChange,
  onPreviousClick = () => {},
  onNextClick = () => {},
  disabled = false,
  visibleItems = 3,
  radioGroupProps = {},
  carouselProps = {},
  slideIndex,
  disableAnimation = false,
}) => {
  const [selected, setSelected] = useState(value);
  const [visibleSlideIndex, setVisibleSlideIndex] = useState(
    slideIndex ||
      (selected ? findIndex(children, ["props.value", selected]) : 1),
  );

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    if (typeof slideIndex === "number") {
      setVisibleSlideIndex(slideIndex);
    }
  }, [slideIndex]);

  const handleClick = (event, val) => {
    setSelected(val);
    onChange(val);
  };

  const classes = useStyles();

  return (
    <RadioGroup
      {...radioGroupProps}
      value={selected}
      onChange={handleClick}
      classes={{
        root: classes.root,
      }}
      disabled={disabled}
    >
      <Carousel
        {...carouselProps}
        slidesToShow={visibleItems}
        slideIndex={visibleSlideIndex}
        cellSpacing={10}
        cellAlign="center"
        framePadding="0 50px"
        dragging={!disabled}
        enableKeyboardControls={!disabled}
        renderBottomCenterControls={() => null}
        renderCenterLeftControls={() => (
          <PreviousButton
            dataTestId="previous-button"
            onClick={() => {
              if (isFunction(onPreviousClick)) {
                onPreviousClick();
              } else {
                setVisibleSlideIndex(visibleSlideIndex - 1);
              }
            }}
            disabled={disabled || visibleSlideIndex === 0}
          />
        )}
        renderCenterRightControls={({ slideCount }) => (
          <NextButton
            dataTestId="next-button"
            onClick={() => {
              if (isFunction(onNextClick)) {
                onNextClick();
              } else {
                setVisibleSlideIndex(slideIndex + 1);
              }
            }}
            disabled={disabled || visibleSlideIndex >= slideCount - 2}
          />
        )}
        disableAnimation={disableAnimation}
      >
        {Children.map(children, child => (
          <div className={classes.slideWrapper}>
            {cloneElement(child, {
              checked: get(child, "props.value") === selected,
              disabled,
            })}
          </div>
        ))}
      </Carousel>
    </RadioGroup>
  );
};

RadioCardGroupCarousel.propTypes = {
  value: node,
  onChange: func,
  onPreviousClick: func,
  onNextClick: func,
  visibleItems: number,
  disabled: bool,
  carouselProps: shape({}),
  radioGroupProps: shape({}),
  slideIndex: number,
};

export default RadioCardGroupCarousel;
