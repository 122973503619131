import React, { Fragment } from "react";

import {
  FormHelperText,
  Grid,
  InputAdornment,
  makeStyles,
  InputLabel,
} from "@material-ui/core";
import {
  AccessTime,
  CalendarToday,
  CalendarTodayOutlined,
} from "@material-ui/icons";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";

import { useBrand } from "../../../../common/BrandProvider/BrandProvider";

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: "22px",
  },
  adornment: {
    fontSize: "14px",
    color: theme.palette.text.primary,
    opacity: 0.4,
    transform: "translateY(-1px)",
  },
}));

const DateTimePicker = ({
  dateValue,
  onDateChange,
  timeValue,
  onTimeChange,
  minimumDate,
  error,
}) => {
  const classes = useStyles();

  const { getText } = useBrand();

  return (
    <Fragment>
      <InputLabel>{getText("test-booked.testTimeLabel")}</InputLabel>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <KeyboardDatePicker
            format="dd/MM/yyyy"
            fullWidth
            keyboardIcon={<CalendarToday className={classes.icon} />}
            minDate={minimumDate}
            name="date"
            onChange={onDateChange}
            placeholder="dd/mm/yyyy"
            value={dateValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayOutlined className={classes.adornment} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyboardTimePicker
            format="HH:mm"
            fullWidth
            keyboardIcon={<AccessTime className={classes.icon} />}
            minDate={minimumDate}
            minutesStep={5}
            name="time"
            onChange={onTimeChange}
            placeholder="hh:mm"
            value={timeValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccessTime className={classes.adornment} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {error && (
          <Grid item xs={12}>
            <FormHelperText data-test="test-booked-date-error" error>
              {getText("test-booked.dateTooEarlyError")}
            </FormHelperText>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default DateTimePicker;
