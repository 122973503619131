import React, { useState } from "react";
import { string, func } from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "../Button/Button";
import { useTealium } from "../TealiumProvider/TealiumProvider";

const useStyles = makeStyles(theme => ({
  shareLink: {
    display: "flex",
    alignItems: "center",
    margin: "15px 0 20px 0",
    color: "inherit",
  },
  shareLinkButton: {
    padding: "11px 20px",
    fontSize: "1rem",
    marginBottom: "20px",
    [theme.breakpoints.up("sm")]: {
      padding: "20px",
      minWidth: "226px",
    },
  },
  shareIcon: {
    fontSize: "14px",
    marginRight: "10px",
    color: theme.palette.grey[400],
  },
}));

const ShareLink = ({ link, actionText, actionConfirmationText, onClick }) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const classes = useStyles();
  const { trackEvent } = useTealium();

  const handleOnCopy = () => {
    if (typeof onClick === "function") {
      onClick();
    }
    setLinkCopied(true);
    trackEvent({
      eventCategory: "Confirmation",
      eventAction: "Interaction",
      eventLabel: "Copy Top Up link",
    });
  };

  return (
    <CopyToClipboard text={link} onCopy={handleOnCopy}>
      <Button
        text={linkCopied ? actionConfirmationText : actionText}
        icon="file_copy_outlined"
        data-test="share-button"
        classes={{ root: classes.shareLinkButton }}
        secondary
      />
    </CopyToClipboard>
  );
};

ShareLink.propTypes = {
  link: string.isRequired,
  actionText: string,
  actionConfirmationText: string,
  onClick: func,
};

export default ShareLink;
