import React from "react";
import { differenceInHours } from "date-fns";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import {
  LESSON_PURCHASE_TYPES,
  NEW_USER_JOURNEY_PATH_NAMES,
} from "../../../../constants";
import {
  UPDATE_NUJ_HISTORY,
  useStateContext,
} from "../../../../context/stateContext";
import createOrderMutation from "../../../../graphql/createNewPupilOrder.graphql";
import { gatewayClient } from "../../../../client";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import { mapLessonTypeToLearnerLessonType } from "../Prices/helpers";

import PersonalDetails from "./PersonalDetails";
import { getJourneyType } from "@utils";

export const INITIAL_FORM_VALUES = {
  honoraryTitle: "",
  firstName: "",
  surname: "",
  mobileNumber: "",
  emailAddress: "",
  dateOfBirth: null,
  pickupAddress: "",
  billingAddress: "",
  marketingPreferences: {
    email: true,
    sms: true,
    phone: true,
    post: true,
  },
  termsAndConditions: false,
  isGift: false,
  shouldBeASurprise: false,
  giftGiversEmail: "",
  giftGiversMobile: "",
};

const PersonalDetailsWithCreateOrder = (props: any) => {
  const { dispatch, state } = useStateContext();

  const history = useHistory();
  const journeyType = getJourneyType(history.location.pathname, state);
  const { brand, getText } = useBrand();

  const apolloClient = gatewayClient({
    sessionId: state?.sessionId,
    journeyType,
  });

  const [createOrder, { loading, error }] = useMutation(createOrderMutation, {
    onCompleted: ({ createLearnerWithTopupForNUJ }) => {
      if (createLearnerWithTopupForNUJ.successful === false) {
        throw new Error(createLearnerWithTopupForNUJ.errorType);
      }

      dispatch({
        type: UPDATE_NUJ_HISTORY,
        payload: {
          order: createLearnerWithTopupForNUJ.order,
        },
      });

      return history.push(NEW_USER_JOURNEY_PATH_NAMES.PAYMENT);
    },
  });

  if (error) {
    throw error;
  }

  const onFormSubmit = (values: any) => {
    const {
      honoraryTitle,
      firstName,
      surname,
      dateOfBirth,
      mobileNumber,
      emailAddress,
      pickupAddress,
      selectedLessonTimeslot,
      selectedLessonType,
      selectedInstructor,
      selectedPackage,
      selectedTransmissionType,
      marketingPreferences: { email, sms, phone, post },
      isGift,
      shouldBeASurprise,
      billingAddress,
      keyWorkerType,
      keyWorkerEmployer,
      shortNoticeLessonTime,
      shortNoticeTestCentre,
      giftGiversEmail,
      giftGiversMobile,
    } = values;

    let lessonPurchaseType = LESSON_PURCHASE_TYPES.Caller;

    if (isGift) {
      lessonPurchaseType = shouldBeASurprise
        ? LESSON_PURCHASE_TYPES.Surprise
        : LESSON_PURCHASE_TYPES.ThirdParty;
    }

    createOrder({
      variables: {
        input: {
          brand,
          learner: {
            title: honoraryTitle,
            firstName,
            lastName: surname,
            dateOfBirth,
            mobileNumber,
            email: emailAddress,
            pickupAddress: {
              line1: pickupAddress.line1 || "",
              line2: pickupAddress.line2 || "",
              town: pickupAddress.town || "",
              county: pickupAddress.county || "",
              postcode: pickupAddress.postcode || "",
            },
            ...(billingAddress && {
              billingAddress: {
                line1: billingAddress.line1 || "",
                line2: billingAddress.line2 || "",
                town: billingAddress.town || "",
                county: billingAddress.county || "",
                postcode: billingAddress.postcode || "",
              },
            }),
          },
          lesson: {
            startDateTime: selectedLessonTimeslot.startTime,
            length: differenceInHours(
              new Date(selectedLessonTimeslot.endTime),
              new Date(selectedLessonTimeslot.startTime),
            ),
            instructorId: selectedInstructor.instructor.id,
            transmissionType: selectedTransmissionType,
          },
          marketing: {
            email: email || false,
            sms: sms || false,
            phone: phone || false,
            post: post || false,
          },
          packageHours: parseInt(selectedPackage.hours, 10),
          lessonType: mapLessonTypeToLearnerLessonType(selectedLessonType),
          lessonPurchaseType,
          testDateTime: shortNoticeLessonTime,
          testCentre: shortNoticeTestCentre,
          ...(keyWorkerEmployer && keyWorkerType
            ? {
                keyWorker: { type: keyWorkerType, employer: keyWorkerEmployer },
              }
            : {}),
          ...(giftGiversEmail && giftGiversMobile
            ? {
                giftGiver: {
                  email: giftGiversEmail,
                  mobileNumber: giftGiversMobile,
                },
              }
            : {}),
        },
      },
    });
  };

  return (
    <PersonalDetails
      {...props}
      onSubmit={onFormSubmit}
      isSubmittingOrder={loading}
      getText={getText}
      apolloClient={apolloClient}
      initialValues={
        state.nujHistory?.tempPupilDetailFormValues || INITIAL_FORM_VALUES
      }
    />
  );
};
export default PersonalDetailsWithCreateOrder;
