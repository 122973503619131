import React, { Fragment } from "react";
import { bool, node, string } from "prop-types";
import { Typography, ListItemText as MuiListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  listItemText: {
    fontSize: "16px",
    color: theme.palette.grey[500],
    fontWeight: theme.journey.radioItemList.fontWeight,
  },
  listItemTextSelected: {
    fontSize: "16px",
    color: theme.palette.grey[500],
    fontWeight: theme.journey.radioItemList.selectedFontWeight,
  },
}));

const RadioListItemText = ({ text, selected, className, children }) => {
  const classes = useStyles();

  return (
    <MuiListItemText
      className={className}
      secondary={
        <Fragment>
          <Typography
            className={
              selected ? classes.listItemTextSelected : classes.listItemText
            }
            component="span"
          >
            {text}
          </Typography>
          {children}
        </Fragment>
      }
    />
  );
};

RadioListItemText.propTypes = {
  text: string,
  selected: bool,
  children: node,
};

export default RadioListItemText;
