import {
  GLOBAL_PATH_NAMES,
  NEW_USER_JOURNEY_PATH_NAMES,
  RETURNING_USER_JOURNEY_PATH_NAMES,
  THEORY_APP_PATH_NAMES,
} from "@constants";

export const BrowserTitles: Record<string, string> = {
  [NEW_USER_JOURNEY_PATH_NAMES.PICKUP]: "pickup-location.browserTitle",
  [NEW_USER_JOURNEY_PATH_NAMES.FACE_COVERING]: "face-covering.browserTitle",
  [NEW_USER_JOURNEY_PATH_NAMES.SELECT_COUNTRY]: "lesson-country.browserTitle",
  [NEW_USER_JOURNEY_PATH_NAMES.KEY_WORKER]: "key-worker.browserTitle",
  [NEW_USER_JOURNEY_PATH_NAMES.LESSON_TYPES]: "lesson-types.browserTitle",
  [NEW_USER_JOURNEY_PATH_NAMES.PRICES]: "prices.browserTitle",
  [NEW_USER_JOURNEY_PATH_NAMES.SNT_PRICES]: "prices.browserTitle",
  [NEW_USER_JOURNEY_PATH_NAMES.TIMESLOTS]: "timeslots.browserTitle",
  [NEW_USER_JOURNEY_PATH_NAMES.PERSONAL_DETAILS]:
    "personal-details.browserTitle",
  [NEW_USER_JOURNEY_PATH_NAMES.PAYMENT]: "payment.browserTitle",
  [NEW_USER_JOURNEY_PATH_NAMES.CONFIRMATION]: "confirmation.browserTitle",
  [NEW_USER_JOURNEY_PATH_NAMES.SNT_CONFIRMATION]: "confirmation.browserTitle",
  [NEW_USER_JOURNEY_PATH_NAMES.CHANGE_INSTRUCTOR]:
    "prices/change-instructor.browserTitle",
  [GLOBAL_PATH_NAMES.CALL_TO_COMPLETE]:
    "call-to-complete-booking.browserTitle.callUs",
  [NEW_USER_JOURNEY_PATH_NAMES.TEST_PASSED]:
    "call-to-complete-booking.browserTitle.testPassed",
  [NEW_USER_JOURNEY_PATH_NAMES.TEST_BOOKED]: "test-booked.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.TOPUP_AMOUNT]:
    "top-up/amount.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.TOPUP_PAYMENT]:
    "top-up/payment.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.TOPUP_CONFIRMATION]:
    "top-up/confirmation.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.KEY_WORKER]:
    "returning/keyWorker.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.FACE_COVERING]:
    "face-covering.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.TIMESLOTS]:
    "returning/timeslots.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.SELECT_COUNTRY]:
    "returning/confirmation.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.CONFIRMATION]:
    "returning/confirmation.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.LOGIN]: "login.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT]: "account.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.CHANGE_INSTRUCTOR_CONFIRMATION]:
    "returning/change-instructor/confirmation.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.RATE_INSTRUCTOR]:
    "returning/rate-instructor.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.RATE_INSTRUCTOR_CONFIRMATION]:
    "returning/rate-instructor/confirmation.browserTitle",
  [`${RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT}/:topUpId`]: "account.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.TALK_TO_US]:
    "returning/talk-to-us.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.TALK_TO_US_CONFIRMATION]:
    "returning/talk-to-us/confirmation.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.HELP_CENTRE]: "account.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.RECOMMENDED_DRIVING_PRODUCTS]:
    "recommended-driving-products.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.BOOKED_LESSONS]:
    "returning/booked-lessons.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.COMPLAINTS]:
    "returning/complaints.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.COMPLAINTS_CONFIRMATION]:
    "returning/complaints/confirmation.browserTitle",
  [GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG]:
    "somethings-gone-wrong.browserTitle",
  [GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG_500]:
    "somethings-gone-wrong-500.browserTitle",
  [NEW_USER_JOURNEY_PATH_NAMES.EXISTING_PUPIL]: "existing-pupil.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.REFUND_REQUEST]:
    "requestRefund/request.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.REFUND_REQUEST_CONFIRMATION]:
    "requestRefund/confirmation.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.CANCEL_LESSON]:
    "returning/cancel-lesson.browserTitle",
  [RETURNING_USER_JOURNEY_PATH_NAMES.PROGRESS]:
    "returning/progress.browserTitle",
  [THEORY_APP_PATH_NAMES.THEORY_APP_PRIVACY_NOTICE]:
    "theory-app/privacy-notice.browserTitle",
  [THEORY_APP_PATH_NAMES.THEORY_APP_TERMS_AND_CONDITIONS]:
    "theory-app/terms-and-conditions.browserTitle",
};
