import React from "react";
import { string } from "prop-types";
import { Avatar, makeStyles } from "@material-ui/core";
import { getInitialsFromName } from "../../../../../utils";

const useStyles = makeStyles(({ journey, typography }) => ({
  avatarRoot: {
    fontWeight: typography.fontWeightMedium,
    fontSize: "1.125rem",
    background: journey.itemListItem.selectedBackground,
  },
}));

const InitialsSelected = ({ name }) => {
  const classes = useStyles();
  return (
    <Avatar className={classes.avatarRoot}>{getInitialsFromName(name)}</Avatar>
  );
};

InitialsSelected.propTypes = {
  name: string.isRequired,
};

export default InitialsSelected;
