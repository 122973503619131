import { MIN_PHONE_NUMBER_LENGTH } from "../constants";

export const POSTCODE_REGEX = /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;

export const isValidPostcodeFormat = postcode => {
  if (typeof postcode !== "string") return false;

  return postcode.trim().match(POSTCODE_REGEX);
};

export const validateText = value =>
  /\d/.test(value.trim()) ? "personal-details.validation.text" : "";

export const isValidPhoneNumber = value => {
  const trimmedValue = value.trim();
  return (
    trimmedValue.length <= 14 &&
    trimmedValue.length >= MIN_PHONE_NUMBER_LENGTH &&
    /^[0-9+()#.\s]+$/g.test(trimmedValue)
  );
};

export const validatePhoneNumber = value =>
  isValidPhoneNumber(value) ? "" : "personal-details.validation.phoneNumber";

export const validateEmail = value =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
    value.trim(),
  )
    ? ""
    : "personal-details.validation.email";

export default {
  isValidPostcodeFormat,
  isValidPhoneNumber,
  validateText,
  validatePhoneNumber,
};
