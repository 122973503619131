import { UPDATE_NUJ_HISTORY } from "../context/stateContext";
import { AUTOMATIC_TRANSMISSION, MANUAL_TRANSMISSION } from "../constants";
import { TransmissionType } from "@graphql/types";
import { Dispatch } from "models/state";

const queryStringParams = ["postcode", "tT", "lT", "tC"];

export const isCrossSellQueryString = (obj: Record<string, any>) => {
  const keys = Object.keys(obj);
  return queryStringParams.every(
    param => keys.includes(param) && obj[param] !== "",
  );
};

interface QueryString {
  postcode: string;
  tT: TransmissionType | "";
  ins: string;
  lT: string;
  mC: string | null;
  mCid: string | null;
  tC: string | null;
}

interface Props {
  onTransmissionChange: (_transmission: TransmissionType) => void;
  selectedTransmissionType: TransmissionType;
  dispatch: Dispatch;
  queryString: QueryString;
  isCrossSell: boolean;
}

const useCrossSellHandover = ({
  onTransmissionChange,
  selectedTransmissionType,
  dispatch,
  queryString: {
    lT: lessonType,
    mC: marketingChannel,
    mCid: marketingChannelId,
    postcode,
    tT: transmissionType,
    tC: testCentre,
  },
  isCrossSell,
}: Props) => {
  const trimmedUrlPostcode = postcode?.trim();

  if (trimmedUrlPostcode) {
    dispatch({
      type: UPDATE_NUJ_HISTORY,
      payload: {
        pickupPostcode: trimmedUrlPostcode,
      },
    });
  }

  if (isCrossSell) {
    dispatch({
      type: UPDATE_NUJ_HISTORY,
      payload: {
        selectedLessonType: decodeURIComponent(lessonType).trim(),
        marketingChannel: marketingChannel
          ? decodeURIComponent(marketingChannel).trim()
          : null,
        marketingChannelId: marketingChannelId
          ? decodeURIComponent(marketingChannelId).trim()
          : null,
        shortNoticeTestCentre: testCentre
          ? decodeURIComponent(testCentre).trim()
          : null,
      },
    });

    if (onTransmissionChange && transmissionType) {
      if (
        [MANUAL_TRANSMISSION, AUTOMATIC_TRANSMISSION].includes(
          transmissionType,
        ) &&
        transmissionType !== selectedTransmissionType
      ) {
        onTransmissionChange(transmissionType);
      }
    }
  }
};

export default useCrossSellHandover;
