import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { useBrand } from "../components/common/BrandProvider/BrandProvider";
import getTheme from "./index";

const ThemeProviderWithBrand = ({ children, themeOverrides = {} }) => {
  const { brand } = useBrand();

  return (
    <ThemeProvider theme={getTheme(brand, themeOverrides)}>
      {children}
    </ThemeProvider>
  );
};

export default ThemeProviderWithBrand;
