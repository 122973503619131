import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { RETURNING_USER_JOURNEY_PATH_NAMES } from "../../../../constants";
import { goBack } from "../../../../utils/navigation";
import KeyWorker from "./KeyWorker";
import { capitalizeEachWordOfPathname } from "../../../../utils/capitalisePathname";
import { useTealium } from "../../../common/TealiumProvider/TealiumProvider";
import {
  UPDATE_RUJ_HISTORY,
  useStateContext,
} from "../../../../context/stateContext";

import { StateContext } from "@lessons/models";

const ReturningUserJourneyKeyWorker = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { trackEvent } = useTealium();
  const {
    dispatch,
    state: {
      rujHistory: {
        keyWorker: { type, employer },
      },
    },
  }: StateContext = useStateContext();

  const [isKeyWorker, setIsKeyWorker] = useState(
    type !== "" && employer !== "",
  );
  const [keyWorkerType, setKeyWorkerType] = useState(type);
  const [keyWorkerEmployer, setKeyWorkerEmployer] = useState(employer);

  const handleBackClick = () => {
    goBack({ history, pathname });
  };

  const handleIsKeyWorkerChange = (value: boolean) => {
    setIsKeyWorker(value);
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Interaction",
      eventLabel: `Key Worker - ${isKeyWorker ? "Yes" : "No"}`,
    });
  };

  const handleContinueButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Button Click",
      eventLabel: "Continue",
    });
    if (isKeyWorker) {
      dispatch({
        type: UPDATE_RUJ_HISTORY,
        payload: {
          keyWorker: {
            type: keyWorkerType,
            employer: keyWorkerEmployer,
          },
        },
      });
      history.push(RETURNING_USER_JOURNEY_PATH_NAMES.TIMESLOTS);
    } else {
      history.push(RETURNING_USER_JOURNEY_PATH_NAMES.KEY_WORKER_TALK_TO_US);
    }
  };

  return (
    <KeyWorker
      onBackClick={handleBackClick}
      isKeyWorker={isKeyWorker}
      onIsKeyWorkerChange={handleIsKeyWorkerChange}
      keyWorkerEmployer={keyWorkerEmployer}
      onKeyWorkerEmployerChange={setKeyWorkerEmployer}
      keyWorkerType={keyWorkerType}
      onKeyWorkerTypeChange={setKeyWorkerType}
      onContinueClick={handleContinueButtonClick}
    />
  );
};

export default ReturningUserJourneyKeyWorker;
