import React, { Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { string, func } from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import mergeDeep from "lodash.merge";

import Titlebar from "../../common/Titlebar/Titlebar";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import ContinueButton from "../../common/ContinueButton/ContinueButton";
import errorImage from "../../../png/ice-cream.png";
import { goBack } from "../../../utils/navigation";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";

const useStyles = makeStyles(theme =>
  mergeDeep(theme.journey.somethingsGoneWrong, {
    headerWrapper: {
      paddingBottom: "20px",
    },
    bodyText: {
      fontSize: "1rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "1.125rem",
      },
    },
    actionText: {
      marginTop: "30px",
      [theme.breakpoints.up("md")]: {
        fontSize: "1.2rem",
      },
    },
    actionButton: {
      marginTop: "40px",
      [theme.breakpoints.up("md")]: {
        marginTop: "60px",
      },
    },
  }),
);

const renderButton = (classes, getText) => (
  <ContinueButton
    text={getText("somethings-gone-wrong.buttonText")}
    className={classes.actionButton}
    href={getText("common.chatPage")}
    data-test="continue-button"
  />
);

const NewUserJourneyStepsSomethingsGoneWrong = ({
  bodyTextKey = "somethings-gone-wrong.body",
  renderActionButton = renderButton,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { getText } = useBrand();
  const history = useHistory();
  const { pathname } = useLocation();
  const { trackEvent } = useTealium();

  const handleBackButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    goBack({ history, pathname });
  };

  return (
    <Fragment>
      <div className={classes.headerWrapper} data-test="header-wrapper">
        <ContentWrapper>
          <Titlebar showBackButton onBackClick={handleBackButtonClick}>
            {getText("somethings-gone-wrong.pageTitle")}
          </Titlebar>

          {theme.journey.somethingsGoneWrong.errorImage && (
            <img
              src={errorImage}
              className={classes.errorImage}
              alt=""
              data-test="header-image"
            />
          )}
        </ContentWrapper>
      </div>
      <ContentWrapper>
        <Typography
          component="p"
          className={classes.bodyText}
          data-test="error-body"
        >
          {getText(bodyTextKey)}
        </Typography>

        {renderActionButton(classes, getText)}
      </ContentWrapper>
    </Fragment>
  );
};

NewUserJourneyStepsSomethingsGoneWrong.propTypes = {
  bodyTextKey: string,
  renderActionButton: func,
};

export default NewUserJourneyStepsSomethingsGoneWrong;
