import defaultsDeep from "lodash.defaultsdeep";
import { configureScope } from "@sentry/browser";
import omit from "lodash.omit";
import omitDeep from "../utils/omitDeep";

import {
  SENTRY_REDACTED_KEYS,
  NUJ_HISTORY_INITIAL_STATE,
  QTU_HISTORY_INITIAL_STATE,
} from "../constants";
import {
  NUJHistoryState,
  QTUHistoryState,
  RUJHistoryState,
  State,
} from "models/state";

export const updateSessionId = ({
  state,
  payload,
}: {
  state: State;
  payload: string;
}) => {
  configureScope(scope => {
    scope.setExtra("sessionID", payload);
  });
  return defaultsDeep({}, { sessionId: payload }, state);
};

export const updateNUJHistory = ({
  state,
  payload,
}: {
  state: State;
  payload: NUJHistoryState;
}) => {
  const updatedState = defaultsDeep({}, { nujHistory: payload }, state);
  configureScope(scope => {
    scope.setExtra(
      "NUJAppState",
      omit(updatedState.nujHistory, "pupilDetails"),
    );
  });

  return updatedState;
};

export const availableNUJInstructorsLoaded = ({
  state,
  payload,
}: {
  state: State;
  payload: NUJHistoryState["availableInstructors"];
}) => {
  const stateClone = { ...state };
  stateClone.nujHistory.availableInstructorsLoaded = true;

  stateClone.nujHistory.availableInstructors = payload;

  return stateClone;
};

export const clearAvailableNUJInstructors = ({ state }: { state: State }) => {
  const stateClone = { ...state };
  stateClone.nujHistory.availableInstructors =
    NUJ_HISTORY_INITIAL_STATE.availableInstructors;
  return stateClone;
};

export const clearNUJSelectedInstructor = ({ state }: { state: State }) => {
  const stateClone = { ...state };
  stateClone.nujHistory.selectedInstructor = null;
  return stateClone;
};

export const setQTUJourney = ({ state }: { state: State }) => {
  const stateClone = { ...state };
  stateClone.isQTUJourney = true;
  return stateClone;
};

export const clearNUJSelectedInstructorHistory = ({
  state,
}: {
  state: State;
}) => {
  const stateClone = { ...state };
  stateClone.nujHistory.selectedInstructorHistory = null;
  return stateClone;
};

export const clearNUJSelectionHistory = ({ state }: { state: State }) =>
  defaultsDeep(
    {},
    {
      nujHistory: {
        ...NUJ_HISTORY_INITIAL_STATE,
        confirmation: state.nujHistory.confirmation,
      },
    },
    state,
  );

export const updateQTUHistory = ({
  state,
  payload,
}: {
  state: State;
  payload: QTUHistoryState;
}) => {
  const updatedState = defaultsDeep(
    {},
    {
      qtuHistory: payload,
    },
    state,
  );

  const sentryPayload = omitDeep(updatedState.qtuHistory, SENTRY_REDACTED_KEYS);
  configureScope(scope => {
    scope.setExtra("QTUAppState", sentryPayload);
  });

  return updatedState;
};

export const updateRUJHistory = ({
  state,
  payload,
}: {
  state: State;
  payload: RUJHistoryState;
}) => {
  const updatedState = defaultsDeep(
    {},
    {
      rujHistory: payload,
    },
    state,
  );

  const sentryPayload = omitDeep(updatedState.rujHistory, SENTRY_REDACTED_KEYS);
  configureScope(scope => {
    scope.setExtra("rujHistory", sentryPayload);
  });

  return updatedState;
};

export const clearQTUHistory = ({ state }: { state: State }) =>
  defaultsDeep(
    {},
    {
      qtuHistory: {
        ...QTU_HISTORY_INITIAL_STATE,
        confirmation: state.qtuHistory.confirmation,
      },
    },
    state,
  );
