import React, { useState, useEffect } from "react";

import TextField from "../TextField/TextField";
import { useBrand } from "../../BrandProvider/BrandProvider";

import { FormPostcodeValidatorProps } from "./types";
import { isValidPostcodeFormat } from "../../../../utils/validation";

const FormPostcodeValidator: React.FC<FormPostcodeValidatorProps> = ({
  initialValue = "",
  onChange = () => {},
  label = undefined,
  placeholder = undefined,
  onClick = () => {},
  inputRef = undefined,
  ...restProps
}) => {
  const { getText } = useBrand();
  const [postcodeValue, setPostcodeValue] = useState(initialValue);
  const [inputError, setInputError] = useState<string | undefined>();

  const dirty = Boolean(inputError);

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setPostcodeValue(value);
  };

  useEffect(() => {
    setInputError(undefined);

    if (postcodeValue.length < 5 || !postcodeValue) {
      setInputError(undefined);
      onChange({ value: postcodeValue, isInvalid: true });
    } else if (!isValidPostcodeFormat(postcodeValue)) {
      onChange({ value: postcodeValue, isInvalid: true });
      setInputError(getText("pickup-location.postCodeError"));
    } else {
      setInputError(undefined);
      onChange({ value: postcodeValue, isInvalid: false });
    }
  }, [postcodeValue]);

  return (
    <TextField
      value={postcodeValue.toUpperCase()}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
      error={dirty}
      helperText={inputError}
      inputIconLeft="place_outlined"
      data-test="postcode-field"
      FormHelperTextProps={{
        // @ts-ignore
        "data-test": "postcode-field-error",
      }}
      inputProps={{
        "data-test": "postcode-field-input",
      }}
      label={label}
      placeholder={placeholder}
      onClick={onClick}
      inputRef={inputRef}
      {...restProps}
    />
  );
};

export default FormPostcodeValidator;
