import React, { Fragment } from "react";

import { makeStyles, SvgIcon, Theme, Typography } from "@material-ui/core";
import QueryBuilder from "@material-ui/icons/QueryBuilder";
import { format } from "date-fns";
import merge from "lodash.merge";

import { useBrand } from "../../common/BrandProvider/BrandProvider";
import RadioCard from "../../common/Form/RadioCard/RadioCard";
import { soapTimeToLocalTime } from "../../../utils/soapTimeToLocalTime";

import { Brand } from "@graphql/types";

type TimeSlotProps = {
  bookingId: string;
  lessonDate: Date;
};

type CardContentProps = {
  selected?: boolean;
  lessonDate: Date;
};

interface CustomTheme extends Theme {
  journey: {
    timeSlotPicker: Record<string, any>;
  };
}

const useStyles = makeStyles<CustomTheme>(theme =>
  merge(theme.journey.timeSlotPicker, {
    icon: {
      height: "20px",
      width: "20px",
      color: theme.palette.grey.A200,
      margin: "0 18px 0 0",
      transform: "scale(-1,1)",
    },
    radio: {
      width: "100%",
      margin: "10px 0 0 0",
      padding: "13px",
      flex: "0 0 100%",
      justifyContent: "flex-start",
      fontWeight: theme.typography.fontWeightMedium,
      "&:first-child": {
        margin: "0",
      },
    },
  }),
);

const formatDate = (date: Date) =>
  format(new Date(date), "iiii do MMM - h:mmaa");

const CardContent: React.FC<CardContentProps> = ({
  selected = false,
  lessonDate,
}) => {
  const classes = useStyles();
  const { brand } = useBrand();
  return (
    <Fragment>
      {brand === Brand.Bsm && (
        <SvgIcon className={classes.icon}>
          <QueryBuilder />
        </SvgIcon>
      )}
      <Typography
        color="textPrimary"
        classes={{ root: selected ? classes.selectedContent : "" }}
      >
        {formatDate(soapTimeToLocalTime(lessonDate))}
      </Typography>
    </Fragment>
  );
};

const TimeSlot: React.FC<TimeSlotProps> = ({ bookingId, lessonDate }) => {
  const classes = useStyles();
  return (
    <RadioCard
      classes={{ root: classes.radio }}
      key={bookingId}
      value={bookingId}
      cardContent={<CardContent lessonDate={lessonDate} />}
      cardContentChecked={<CardContent lessonDate={lessonDate} selected />}
    />
  );
};

export default TimeSlot;
