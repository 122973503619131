import React, { useState, Fragment } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { differenceInHours, parseISO } from "date-fns";
import createBookingMutation from "../../../graphql/createBooking.graphql";
import LoadingScreen from "../../common/LoadingScreen/LoadingScreen";
import CommonTimeslots from "../../common/Timeslots";
import BookingConfirmationModal from "./BookingConfirmationModal";
import { RETURNING_USER_JOURNEY_PATH_NAMES } from "../../../constants";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import {
  useStateContext,
  UPDATE_RUJ_HISTORY,
} from "../../../context/stateContext";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";
import { RouterHistory } from "@sentry/react/dist/reactrouter";
import { LessonStatus } from "@graphql/types";

interface Props {
  history: RouterHistory;
  todayDate?: Date;
  disableAnimation?: boolean;
}

const ReturningUserJourneyTimeslots = ({
  history,
  todayDate,
  disableAnimation = false,
}: Props) => {
  const {
    dispatch,
    state: {
      rujHistory: {
        instructorId,
        instructorName,
        transmissionType,
        pupilFirstName,
        pupilSurname,
        pupilDateOfBirth,
        pupilBalance,
        learnerId,
        topUpId,
        selectedLessonDate,
        selectedLessonTimeslot,
      },
    },
  } = useStateContext();
  const { getText } = useBrand();
  const { trackEvent } = useTealium();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { pathname } = useLocation();

  const toggleConfirmationModal = () =>
    setShowConfirmationModal(!showConfirmationModal);

  const [booking, { error, loading }] = useMutation(createBookingMutation);

  if (error) {
    throw error;
  }

  const handleTimeslotUpdate = (selectedTime: string) => {
    dispatch({
      type: UPDATE_RUJ_HISTORY,
      payload: {
        selectedLessonTimeslot:
          typeof selectedTime === "string"
            ? JSON.parse(selectedTime)
            : selectedTime,
      },
    });
  };

  const handleBookingConfirmation = async () => {
    const { data: bookingResponse } = await booking({
      variables: {
        input: {
          startDateTime: selectedLessonTimeslot.startTime,
          endDateTime: selectedLessonTimeslot.endTime,
          status: LessonStatus.Active,
          learnerId,
          instructorId,
        },
      },
    });

    if (bookingResponse && !bookingResponse.createLesson.id) {
      // something went wrong with the auth, force user back to login
      history.push(RETURNING_USER_JOURNEY_PATH_NAMES.LOGIN);
    }

    // We clear the old history stuff to ensure that the user can't try to
    // book again for the same time
    dispatch({
      type: UPDATE_RUJ_HISTORY,
      payload: {
        pupilFirstName: "",
        instructorName: "",
        selectedLessonDate: "",
        selectedLessonTimeslot: {
          startTime: "",
          endTime: "",
        },
        confirmation: {
          topUpId,
          lesson: {
            ...selectedLessonTimeslot,
          },
          instructorName,
          pupilFirstName,
          pupilBalance,
        },
        pupilSurname,
        pupilDateOfBirth,
      },
    });

    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Booking",
      eventLabel: "Booking Successful (RUJ)",
    });

    history.push(RETURNING_USER_JOURNEY_PATH_NAMES.CONFIRMATION);
  };

  if (loading) {
    return (
      <LoadingScreen messageText={getText("returning/timeslots.processing")} />
    );
  }

  return (
    <Fragment>
      {selectedLessonTimeslot.startTime && selectedLessonTimeslot.endTime && (
        <BookingConfirmationModal
          showModal={showConfirmationModal}
          toggleModal={toggleConfirmationModal}
          pupilBalance={pupilBalance}
          startDateTime={parseISO(selectedLessonTimeslot.startTime)}
          duration={differenceInHours(
            new Date(selectedLessonTimeslot.endTime),
            new Date(selectedLessonTimeslot.startTime),
          )}
          onConfirmation={handleBookingConfirmation}
        />
      )}
      <CommonTimeslots
        instructor={{ id: instructorId, name: instructorName }}
        lessonDate={selectedLessonDate}
        lessonTimeslot={selectedLessonTimeslot}
        titleText={getText("returning/timeslots.pageTitle", {
          instructorName: instructorName.split(" ")[1],
        })}
        updateContext={toggleConfirmationModal}
        onTimeslotSelected={handleTimeslotUpdate}
        continueButtonText={getText("returning/timeslots.bookNow")}
        fetchPolicy="no-cache"
        showBanner
        todayDate={todayDate}
        disableAnimation={disableAnimation}
        transmissionType={transmissionType}
      />
    </Fragment>
  );
};

export default withRouter(ReturningUserJourneyTimeslots);
