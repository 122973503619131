import * as React from "react";
import { bool, func, shape } from "prop-types";
import { Typography, makeStyles, Box } from "@material-ui/core";

import BackButton from "../BackButton/BackButton";

const useStyles = centerText =>
  makeStyles(theme => ({
    root: {
      display: "flex",
      alignItems: "flex-start",
      position: "relative",
      textAlign: centerText ? "center" : "initial",
      justifyContent: centerText ? "center" : "initial",
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: "1.2em",
      fontSize: "20px",
      marginBottom: "40px",
      letterSpacing: "0px",
      [theme.breakpoints.up("sm")]: {
        margin: "0 auto 60px auto",
        fontSize: "27px",
        textAlign: "center",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "30px",
      },
    },
    lightText: {
      color: theme.palette.primary.light,
    },
    reducedBottomMargin: {
      marginBottom: "30px",
      [theme.breakpoints.up("sm")]: {
        marginBottom: "40px",
      },
    },
    backButton: {
      position: "absolute",
      top: -5,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      [theme.breakpoints.up("md")]: {
        top: 5,
        left: -130,
      },
      [theme.breakpoints.up("lg")]: {
        left: -200,
      },
    },
  }));

const Titlebar = ({
  onBackClick = () => {},
  showBackButton = false,
  children,
  headingProps = {},
  backButtonProps = {},
  centerText = false,
  lightText = false,
  reducedBottomMargin = false,
  ...restProps
}) => {
  const classes = useStyles(centerText)(restProps);
  return (
    <Box component="div" classes={{ root: classes.root }} data-test="title">
      {showBackButton && (
        <div className={classes.backButton}>
          <BackButton onClick={onBackClick} {...backButtonProps} />
        </div>
      )}

      <Typography
        classes={{
          root: classes.title,
          ...(lightText ? { h1: classes.lightText } : {}),
          ...(reducedBottomMargin ? { h1: classes.reducedBottomMargin } : {}),
        }}
        component="h1"
        variant="h1"
        {...headingProps}
      >
        {children}
      </Typography>
    </Box>
  );
};

Titlebar.propTypes = {
  showBackButton: bool,
  onBackClick: func,
  headingProps: shape({}),
  backButtonProps: shape({}),
  centerText: bool,
  lightText: bool,
};

export default React.memo(Titlebar);
