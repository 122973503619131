import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";

import {
  GLOBAL_PATH_NAMES,
  RETURNING_USER_JOURNEY_PATH_NAMES,
} from "../../../constants";
import { goBack } from "../../../utils/navigation";
import { useStateContext } from "../../../context/stateContext";
import createInstructorRatingMutation from "../../../graphql/createInstructorRating.graphql";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import LoadingScreen from "../../common/LoadingScreen/LoadingScreen";

import RateInstructor from "./RateInstructor";

const ReturningUserJourneyRateInstructor = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { brand, getText } = useBrand();

  const {
    state: {
      rujHistory: { instructorId, learnerId },
    },
  } = useStateContext();

  const [rating, setRating] = useState<undefined | number>();
  const [review, setReview] = useState<undefined | string>();

  const submitDisabled = !rating || !review;

  const [createInstructorRating, { loading }] = useMutation(
    createInstructorRatingMutation,
    {
      onCompleted: data => {
        if (
          data &&
          data.createInstructorRating &&
          data.createInstructorRating.successful
        ) {
          history.push(
            RETURNING_USER_JOURNEY_PATH_NAMES.RATE_INSTRUCTOR_CONFIRMATION,
          );
        } else {
          history.push(GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG);
        }
      },
      onError: () => {
        history.push(GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG);
      },
    },
  );

  const onSubmit = () => {
    createInstructorRating({
      variables: {
        input: {
          brand,
          learnerId: learnerId,
          instructorId,
          rating,
          review,
        },
      },
    });
  };

  if (loading) {
    return (
      <LoadingScreen
        messageText={getText("returning/rate-instructor.processing")}
      />
    );
  }

  return (
    <RateInstructor
      submitDisabled={submitDisabled}
      rating={rating}
      onRatingChanged={value => setRating(value)}
      review={review}
      onReviewChanged={value => setReview(value)}
      onSubmit={onSubmit}
      onBackClick={() => goBack({ history, pathname })}
    />
  );
};

export default ReturningUserJourneyRateInstructor;
