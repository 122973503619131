/* THIS FILE IS AUTOGENERATED. DO NOT MODIFY */
/* eslint-disable */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  EmailAddress: any;
  GraphQLDate: any;
  PhoneNumber: any;
  Postcode: any;
  Upload: any;
};

export type AccountAdjustmentProduct = {
  __typename?: 'AccountAdjustmentProduct';
  name: Scalars['String'];
  productId: Scalars['ID'];
};

export type AdditionalDriver = {
  __typename?: 'AdditionalDriver';
  dateOfBirth: Scalars['GraphQLDate'];
  fromDate: Scalars['GraphQLDate'];
  id: Scalars['ID'];
  instructor: Instructor;
  licence: Licence;
  name?: Maybe<Name>;
  relationship: Relationship;
};

export type Address = {
  __typename?: 'Address';
  county?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['Postcode']>;
  town?: Maybe<Scalars['String']>;
};

export type AddressForTopUpDetails = {
  __typename?: 'AddressForTopUpDetails';
  postcode: Scalars['String'];
};

export type AddressInput = {
  county?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['Postcode']>;
  town?: Maybe<Scalars['String']>;
};

export enum AgreementDocumentStatus {
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export enum AgreementDocumentType {
  Custom = 'Custom',
  Generic = 'Generic',
  Template = 'Template',
  Upload = 'Upload',
  Uploaded = 'Uploaded'
}

export type AgreementDocuments = {
  __typename?: 'AgreementDocuments';
  active: Scalars['Boolean'];
  agreementName: Scalars['String'];
  documents: Array<AgreementInstructorDocument>;
  fromDate?: Maybe<Scalars['GraphQLDate']>;
  toDate?: Maybe<Scalars['GraphQLDate']>;
};

export type AgreementInstructorDocument = {
  __typename?: 'AgreementInstructorDocument';
  active: Scalars['Boolean'];
  agreementDocumentType: Scalars['String'];
  approvedBy?: Maybe<Scalars['String']>;
  dateApproved?: Maybe<Scalars['GraphQLDate']>;
  documentDescription?: Maybe<Scalars['String']>;
  documentRevisionKey?: Maybe<Scalars['String']>;
  documentType: Scalars['String'];
  expiry?: Maybe<Scalars['GraphQLDate']>;
  FADocumentKey: Scalars['String'];
  status: Scalars['String'];
  uploadedBy?: Maybe<Scalars['String']>;
  uploadedDate?: Maybe<Scalars['GraphQLDate']>;
  url?: Maybe<Scalars['String']>;
};

export type AllocateVehicleInput = {
  personKey: Scalars['ID'];
  vehicleKey: Scalars['ID'];
};

export type Allocation = {
  __typename?: 'Allocation';
  active: Scalars['Boolean'];
  allocationStatus?: Maybe<LearnerAllocationStatus>;
  balance: Scalars['Float'];
  discounts: Array<Discount>;
  fromDate: Scalars['GraphQLDate'];
  fundingSources: Array<FundingSource>;
  id: Scalars['ID'];
  instructor: Instructor;
  learnerLessonType: LearnerLessonType;
  lessonType: LessonType;
  productCategory?: Maybe<ProductCategoryType>;
  proposition: Proposition;
  propositionAllocationKey?: Maybe<Scalars['ID']>;
  purchaseRate?: Maybe<Scalars['Float']>;
  /** @deprecated Not being resolved correctly for DIT. Use purchaseVatRate. */
  purchaseVAT?: Maybe<Scalars['Float']>;
  purchaseVatRate?: Maybe<Scalars['Float']>;
  /** @deprecated Use purchaseRate and purchaseVatRate. */
  rate?: Maybe<Scalars['Float']>;
  toDate?: Maybe<Scalars['GraphQLDate']>;
  transmission: TransmissionType;
};


export type AllocationDiscountsArgs = {
  input?: Maybe<DiscountFilter>;
};

export type AllocationFilter = {
  active?: Maybe<Scalars['Boolean']>;
  instructorId?: Maybe<Scalars['ID']>;
};

export type AnalyticsInput = {
  campaign?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type AreaCoverageRegionResult = {
  __typename?: 'AreaCoverageRegionResult';
  id: Scalars['ID'];
};

export type Arrears = {
  __typename?: 'Arrears';
  amount: Scalars['Float'];
  date?: Maybe<Scalars['GraphQLDate']>;
  id?: Maybe<Scalars['ID']>;
  reference: Scalars['String'];
};

export type ArrearsDetailInput = {
  id: Scalars['ID'];
  propositionKey?: Maybe<Scalars['ID']>;
};

export type ArrearsFlag = {
  __typename?: 'ArrearsFlag';
  inArrears: Scalars['Boolean'];
};

export type AssignNextTicketInput = {
  ticketQueueKey: Scalars['ID'];
};

export type Availability = {
  __typename?: 'Availability';
  endDateTime: Scalars['GraphQLDate'];
  id: Scalars['ID'];
  startDateTime: Scalars['GraphQLDate'];
};

export type AvailabilityFilter = {
  brand: Brand;
  end: Scalars['GraphQLDate'];
  lessonLengthHours: Scalars['Int'];
  start: Scalars['GraphQLDate'];
  startTimeOffset?: Maybe<Scalars['Int']>;
  transmissionType: TransmissionType;
};

export type AvailabilityNuj = {
  __typename?: 'AvailabilityNUJ';
  availableDates: Array<AvailableDateNuj>;
  initialDate: Scalars['GraphQLDate'];
};

export type AvailabilityNujInput = {
  brand: Brand;
  endDate: Scalars['GraphQLDate'];
  instructorId: Scalars['ID'];
  startDate: Scalars['GraphQLDate'];
  transmissionType: TransmissionType;
};

export type AvailableDateNuj = {
  __typename?: 'AvailableDateNUJ';
  date: Scalars['GraphQLDate'];
  timeSlots: Array<Maybe<TimeSlotNuj>>;
};

export type BankDetails = {
  __typename?: 'BankDetails';
  accountNumber: Scalars['String'];
  active: Scalars['Boolean'];
  bankName: Scalars['String'];
  id: Scalars['ID'];
  nameOnAccount?: Maybe<Scalars['String']>;
  sapDirectDebitReference?: Maybe<Scalars['String']>;
  sapId?: Maybe<Scalars['String']>;
  sortCode: Scalars['String'];
};

export type BookedLesson = {
  __typename?: 'BookedLesson';
  bookingId: Scalars['ID'];
  lessonDate?: Maybe<Scalars['GraphQLDate']>;
  lessonLength?: Maybe<Scalars['Int']>;
};

export type BookedLessons = {
  __typename?: 'BookedLessons';
  past: Array<BookedLesson>;
  upcoming: Array<BookedLesson>;
};

export enum Brand {
  Aads = 'AADS',
  Bsm = 'BSM',
  Dual = 'DUAL'
}

export type BrandAdaptions = {
  __typename?: 'BrandAdaptions';
  price: Scalars['Float'];
  vehicleAdaptions: VehicleAdaptions;
};

export type BrandOrm = {
  __typename?: 'BrandOrm';
  active: Scalars['Boolean'];
  createdDate: Scalars['GraphQLDate'];
  fromDate: Scalars['GraphQLDate'];
  itemDescription: Scalars['String'];
  referenceId: Scalars['Int'];
  referenceItemId: Scalars['Int'];
  toDate?: Maybe<Scalars['GraphQLDate']>;
};

export type Buffers = {
  __typename?: 'Buffers';
  customerData?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  signatures?: Maybe<Scalars['String']>;
};

export type CabRank = {
  __typename?: 'CabRank';
  aa: Array<Instructor>;
  bsm: Array<Instructor>;
  dual: Array<Instructor>;
};

export type CabRankInput = {
  brand: Brand;
  excludeInstructorIds?: Maybe<Array<Scalars['ID']>>;
  lessonLengthHours: Scalars['Int'];
  lessonType: LearnerLessonType;
  mustBeAvailableAtEndDate?: Maybe<Scalars['GraphQLDate']>;
  mustBeAvailableAtStartDate?: Maybe<Scalars['GraphQLDate']>;
  offersShortNoticeTest?: Maybe<Scalars['Boolean']>;
  postcode: Scalars['Postcode'];
  transmission: TransmissionType;
};

export type CabRankNujInput = {
  brand: Brand;
  clientId?: Maybe<Scalars['String']>;
  excludeInstructorIds?: Maybe<Array<Scalars['ID']>>;
  keyWorker?: Maybe<KeyWorkerInfo>;
  lessonLengthHours: Scalars['Int'];
  lessonPurchaseType?: Maybe<LessonPurchaseType>;
  lessonType: LearnerLessonType;
  marketingChannel?: Maybe<MarketingChannel>;
  marketingChannelId?: Maybe<Scalars['String']>;
  offersShortNoticeTest: Scalars['Boolean'];
  postcode: Scalars['Postcode'];
  testCentre?: Maybe<Scalars['String']>;
  testDateTime?: Maybe<Scalars['GraphQLDate']>;
  transmission: TransmissionType;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CalculateChangeInstructorBalanceResult = {
  __typename?: 'CalculateChangeInstructorBalanceResult';
  balance?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
};

export type CalculateChangeInstructorInput = {
  brand: Brand;
  instructorId: Scalars['ID'];
  isShortNoticeTest?: Maybe<Scalars['Boolean']>;
  learnerId: Scalars['ID'];
  lessonType: LearnerLessonType;
  transmission: TransmissionType;
};

export type CalendarEvent = Lesson | TimeOff;

export type CalendarEventInput = {
  endDate: Scalars['GraphQLDate'];
  startDate: Scalars['GraphQLDate'];
};

export type CancelLessonForRujInput = {
  bookingId: Scalars['ID'];
  learnerId: Scalars['ID'];
  reason: Scalars['String'];
};

export type CancelLessonInput = {
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  refundLearner?: Maybe<Scalars['Boolean']>;
};

export type CancelPendingAccountAdjustmentInput = {
  cancelRecurring?: Maybe<Scalars['Boolean']>;
  pendingLedgerItemKey: Scalars['ID'];
};

export type CancelPendingLessonsInput = {
  ids: Array<Scalars['ID']>;
};

export type CancelTimeOffInput = {
  cancelSeries?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
};

export type Certification = {
  __typename?: 'Certification';
  fromDate?: Maybe<Scalars['GraphQLDate']>;
  id: Scalars['ID'];
  reference?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['GraphQLDate']>;
  type: CertificationType;
};

export type CertificationListItem = {
  __typename?: 'CertificationListItem';
  id: Scalars['ID'];
  title: CertificationType;
};

export enum CertificationType {
  AdiBadge = 'ADIBadge',
  Dbs = 'DBS',
  Diamond = 'Diamond',
  Full = 'Full',
  HiTech = 'HiTech',
  Iam = 'IAM',
  PdiBadge = 'PDIBadge',
  Provisional = 'Provisional',
  PublicLiability = 'PublicLiability',
  Qef = 'QEF',
  Rospa = 'ROSPA',
  StandardsCheck = 'StandardsCheck'
}

export type ChangeDitInstructorInput = {
  instructorKey: Scalars['ID'];
  learnerKey: Scalars['ID'];
};

export type ChangeInstructorInput = {
  brand: Brand;
  businessCoversExcess?: Maybe<Scalars['Boolean']>;
  instructorId: Scalars['ID'];
  isShortNoticeTest?: Maybe<Scalars['Boolean']>;
  learnerId: Scalars['ID'];
  lessonEndDateTime?: Maybe<Scalars['GraphQLDate']>;
  lessonStartDateTime?: Maybe<Scalars['GraphQLDate']>;
  lessonType: LearnerLessonType;
  transmission: TransmissionType;
};

export type ChangeInstructorPaymentCompleteInput = {
  brand: Brand;
  businessCoversExcess: Scalars['Boolean'];
  instructorId: Scalars['ID'];
  isShortNoticeTest: Scalars['Boolean'];
  learnerId: Scalars['ID'];
  lessonEndDateTime?: Maybe<Scalars['GraphQLDate']>;
  lessonStartDateTime?: Maybe<Scalars['GraphQLDate']>;
  lessonType: LearnerLessonType;
  orderId: Scalars['ID'];
  pendingReallocationOrders: Array<PaymentReallocationOrder>;
  staffUserId: Scalars['String'];
  transmission: TransmissionType;
};

export type ChangeInstructorPaymentCompleteResult = {
  __typename?: 'ChangeInstructorPaymentCompleteResult';
  success: Scalars['Boolean'];
};

export type ChangeInstructorPaymentResult = {
  __typename?: 'ChangeInstructorPaymentResult';
  learner: Learner;
  newOrderPayment?: Maybe<InflightOrderPayment>;
};

export enum ChangeInstructorReason {
  Complaint = 'Complaint',
  InstructorAvailability = 'InstructorAvailability',
  InstructorLeaving = 'InstructorLeaving',
  MovingAddress = 'MovingAddress',
  PracticalTestCover = 'PracticalTestCover',
  UnhappyWithService = 'UnhappyWithService'
}

export type ChangeLessonInput = {
  endDateTime: Scalars['GraphQLDate'];
  id: Scalars['ID'];
  startDateTime: Scalars['GraphQLDate'];
  test?: Maybe<Scalars['Boolean']>;
  travelTime: Scalars['Int'];
};

export type ChangeMotabilityToLearnerInput = {
  learnerKey: Scalars['ID'];
};

export type ClearDownLearnerInput = {
  learnerId: Scalars['ID'];
};

export type CombinedAvailability = {
  __typename?: 'CombinedAvailability';
  availability: Array<Availability>;
  instructors: Array<Instructor>;
};

export type CombinedAvailabilityInput = {
  brand: Brand;
  end: Scalars['GraphQLDate'];
  instructorIds: Array<Scalars['ID']>;
  lessonLengthHours: Scalars['Int'];
  start: Scalars['GraphQLDate'];
  startTimeOffset?: Maybe<Scalars['Int']>;
  transmissionType: TransmissionType;
};

export type CommunicationConsent = {
  __typename?: 'CommunicationConsent';
  marketing: CommunicationMarketingConsent;
  reminder: CommunicationReminderConsent;
};

export type CommunicationConsentInput = {
  marketing?: Maybe<CommunicationMarketingConsentInput>;
  personKey?: Maybe<Scalars['ID']>;
  reminder?: Maybe<CommunicationReminderConsentInput>;
};

export type CommunicationMarketingConsent = {
  __typename?: 'CommunicationMarketingConsent';
  email: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  post: Scalars['Boolean'];
  sms: Scalars['Boolean'];
};

export type CommunicationMarketingConsentInput = {
  email?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['Boolean']>;
  post?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
};

export type CommunicationReminderConsent = {
  __typename?: 'CommunicationReminderConsent';
  sms: Scalars['Boolean'];
};

export type CommunicationReminderConsentInput = {
  sms?: Maybe<Scalars['Boolean']>;
};

export type CompleteDitCheckoutInput = {
  accountNumber?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Int']>;
  leadEmail: Scalars['String'];
  nameOnAccount?: Maybe<Scalars['String']>;
  orderKey: Scalars['ID'];
  paymentPlanMonths?: Maybe<Scalars['Int']>;
  propositionKeys: Array<Scalars['ID']>;
  purchaseSource?: Maybe<PurchaseSource>;
  sortCode?: Maybe<Scalars['String']>;
};

export type CompleteIgCheckoutInput = {
  accountNumber?: Maybe<Scalars['String']>;
  adminFeeDiscountAmount?: Maybe<Scalars['Float']>;
  discountKey?: Maybe<Scalars['ID']>;
  franchiseStartDate: Scalars['GraphQLDate'];
  instructorKey?: Maybe<Scalars['ID']>;
  isDit?: Maybe<Scalars['Boolean']>;
  isPending?: Maybe<Scalars['Boolean']>;
  leadEmail?: Maybe<Scalars['String']>;
  nameOnAccount?: Maybe<Scalars['String']>;
  orderKey?: Maybe<Scalars['ID']>;
  propositionKeys: Array<Scalars['ID']>;
  sortCode?: Maybe<Scalars['String']>;
};

export type CompleteOnlineDitCheckoutInput = {
  accountNumber?: Maybe<Scalars['String']>;
  courseType: CourseType;
  instalments?: Maybe<Scalars['Int']>;
  leadEmail: Scalars['String'];
  nameOnAccount?: Maybe<Scalars['String']>;
  orderKey: Scalars['ID'];
  purchaseSource?: Maybe<PurchaseSource>;
  sortCode?: Maybe<Scalars['String']>;
};

export type CompletePaymentForDepositAndAdminFeeInput = {
  franchiseStartDate: Scalars['GraphQLDate'];
  instructorKey: Scalars['ID'];
  orderKey: Scalars['ID'];
  propositionKey: Scalars['ID'];
};

export type ConvertPendingLessonsInput = {
  ids: Array<Scalars['ID']>;
};

export enum CourseType {
  One2One = 'One2One',
  Vr = 'VR'
}

export type CoveredRegion = {
  __typename?: 'CoveredRegion';
  discounts?: Maybe<Array<Maybe<Discount>>>;
  id: Scalars['ID'];
  pricing?: Maybe<Array<Maybe<RegionCoveragePricing>>>;
  region: Region;
};

export type CreateAccountAdjustmentInput = {
  customValue: Scalars['Float'];
  frequency: Frequency;
  fromDate: Scalars['GraphQLDate'];
  instructorKey: Scalars['ID'];
  productKey: Scalars['ID'];
  toDate: Scalars['GraphQLDate'];
};

export type CreateAdditionalDriverInput = {
  dateOfBirth: Scalars['GraphQLDate'];
  firstName: Scalars['String'];
  instructorId: Scalars['ID'];
  lastName: Scalars['String'];
  licenceExpiryDate: Scalars['GraphQLDate'];
  licenceNumber: Scalars['String'];
  relationship: Relationship;
};

export type CreateAdhocPaymentOrderInput = {
  amount: Scalars['Float'];
  instructorId: Scalars['ID'];
};

export type CreateAreaCoverageInput = {
  discounts: Array<RegionalDiscountsInput>;
  districtOptIns: Array<CreateDistrictCoverageInput>;
  instructorId: Scalars['ID'];
  lineItemKey: Scalars['ID'];
  postTestPrice?: Maybe<Scalars['Float']>;
  preTestPrice?: Maybe<Scalars['Float']>;
  regionCodeKey: Scalars['ID'];
};

export type CreateAreaCoverageResult = {
  __typename?: 'CreateAreaCoverageResult';
  discounts?: Maybe<Array<Maybe<DiscountUpdateResult>>>;
  lineItemCoverageKey: Scalars['ID'];
  price: PricingUpdateResult;
  region: AreaCoverageRegionResult;
};

export type CreateCertificationInput = {
  certificationReference?: Maybe<Scalars['String']>;
  certificationTypeId: Scalars['Int'];
  instructorId: Scalars['ID'];
  toDate?: Maybe<Scalars['GraphQLDate']>;
};

export type CreateDefermentAccountAdjustmentInput = {
  customValue: Scalars['Float'];
  fromDate: Scalars['GraphQLDate'];
  instructorKey: Scalars['ID'];
  planFromDate: Scalars['GraphQLDate'];
  planToDate: Scalars['GraphQLDate'];
  productKey: Scalars['ID'];
  toDate: Scalars['GraphQLDate'];
};

export type CreateDistrictCoverageInput = {
  id: Scalars['String'];
  sectorOptOuts: Array<CreateSectorCoverageInput>;
};

export type CreateDitLeadCheckoutOrderInput = {
  discountAmount?: Maybe<Scalars['Int']>;
  fundingSourceKey?: Maybe<Scalars['ID']>;
  fundingSourceValue?: Maybe<Scalars['Float']>;
  leadEmail: Scalars['String'];
  paymentPlanMonths?: Maybe<Scalars['Int']>;
  propositionKeys: Array<Scalars['ID']>;
};

export type CreateEndCallEventInput = {
  amount?: Maybe<Scalars['Float']>;
  reason: Scalars['String'];
};

export type CreateEnquiryLeadInput = {
  address?: Maybe<AddressInput>;
  analytics: AnalyticsInput;
  brand: Brand;
  channel: TicketSource;
  clientId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['GraphQLDate']>;
  emailAddress: Scalars['String'];
  enquiryType: LeadType;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber: Scalars['String'];
  postcode: Scalars['Postcode'];
  title?: Maybe<Salutation>;
};

export type CreateFranchiseAgreementInput = {
  adminFeeDiscountAmount?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  discountKey?: Maybe<Scalars['ID']>;
  instructorKey: Scalars['ID'];
  leadEmail?: Maybe<Scalars['String']>;
  leadType: Scalars['String'];
  propositionIds: Array<Scalars['ID']>;
  startDate: Scalars['GraphQLDate'];
};

export type CreateIgLeadCheckoutOrderInput = {
  adminFeeDiscountAmount?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  instructorKey?: Maybe<Scalars['ID']>;
  leadEmail?: Maybe<Scalars['String']>;
  propositionIds: Array<Scalars['ID']>;
};

export type CreateInstructorRatingInput = {
  brand: Brand;
  instructorId: Scalars['ID'];
  learnerId: Scalars['ID'];
  rating: Scalars['Int'];
  review: Scalars['String'];
};

export type CreateInstructorRatingResult = {
  __typename?: 'CreateInstructorRatingResult';
  errorMessage?: Maybe<Scalars['String']>;
  successful: Scalars['Boolean'];
};

export type CreateLeadInput = {
  address: AddressInput;
  assignedStaffKey?: Maybe<Scalars['ID']>;
  channel: TicketSource;
  communication: CommunicationConsentInput;
  dateOfBirth: Scalars['GraphQLDate'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  licenceNumber?: Maybe<Scalars['String']>;
  mobileNumber: Scalars['String'];
  recommenderId?: Maybe<Scalars['Int']>;
  salutation: Salutation;
  type: LeadType;
};

export type CreateLearnerInput = {
  addressCounty: Scalars['String'];
  addressIsPickup: Scalars['Boolean'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  addressPostcode: Scalars['String'];
  addressTown?: Maybe<Scalars['String']>;
  appointedBy?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<AddressInput>;
  contactEmail: Scalars['String'];
  contactMobile: Scalars['String'];
  dateOfBirth: Scalars['GraphQLDate'];
  firstName: Scalars['String'];
  hourlyRate?: Maybe<Scalars['Float']>;
  instructorId?: Maybe<Scalars['ID']>;
  lastName: Scalars['String'];
  learnerType: PersonType;
  lessonType: LearnerLessonType;
  lineItemId?: Maybe<Scalars['ID']>;
  smsRemindersOptIn: Scalars['Boolean'];
  sourcedBy: UserType;
  status: PersonStatus;
  title: Salutation;
  transmissionId?: Maybe<Scalars['Int']>;
};

export type CreateLearnerWithTopupForNujInput = {
  brand: Brand;
  giftGiver?: Maybe<GiftPersonInput>;
  keyWorker?: Maybe<KeyWorkerInfo>;
  learner: NujLearnerInput;
  lesson: LessonSummaryInput;
  lessonPurchaseType?: Maybe<LessonPurchaseType>;
  lessonType: LearnerLessonType;
  marketing?: Maybe<CommunicationMarketingConsentInput>;
  packageHours: Scalars['Int'];
  testCentre?: Maybe<Scalars['String']>;
  testDateTime?: Maybe<Scalars['GraphQLDate']>;
};

export type CreateLearnerWithTopupForNujResult = {
  __typename?: 'CreateLearnerWithTopupForNUJResult';
  errorType?: Maybe<Scalars['String']>;
  order: NujOrder;
  successful: Scalars['Boolean'];
};

export type CreateLearnerWithTopupInput = {
  addressCounty: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  addressTown?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<AddressInput>;
  brand: Brand;
  dateOfBirth: Scalars['GraphQLDate'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  fundingSourceKey?: Maybe<Scalars['ID']>;
  fundingSourceValue?: Maybe<Scalars['Float']>;
  instructorId: Scalars['ID'];
  isShortNoticeTest?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  lessonEndDateTime: Scalars['GraphQLDate'];
  lessonRecipient: Scalars['String'];
  lessonStartDateTime: Scalars['GraphQLDate'];
  lessonType: LearnerLessonType;
  marketing?: Maybe<CommunicationMarketingConsentInput>;
  marketingPreferences?: Maybe<Array<Scalars['String']>>;
  mobileNumber: Scalars['String'];
  packageHours: Scalars['Int'];
  pickupPostcode: Scalars['String'];
  referredBy: Scalars['String'];
  title: Salutation;
  transmission: TransmissionType;
};

export type CreateLearnerWithTopupResult = {
  __typename?: 'CreateLearnerWithTopupResult';
  inflightOrderPayment: InflightOrderPayment;
  learner: Learner;
};

export type CreateLessonInput = {
  createFromLearnerView?: Maybe<Scalars['Boolean']>;
  ditLessonType?: Maybe<DitLessonType>;
  endDateTime: Scalars['GraphQLDate'];
  instructorId: Scalars['ID'];
  learnerId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  startDateTime: Scalars['GraphQLDate'];
  status: LessonStatus;
  test?: Maybe<Scalars['Boolean']>;
  travelTime?: Maybe<Scalars['Int']>;
};

export type CreateMessageMutationInput = {
  body: Scalars['String'];
  from?: Maybe<Scalars['Int']>;
  instructorId: Scalars['ID'];
};

export type CreateNewPriceInput = {
  id?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Float']>;
};

export type CreateNudgebackInput = {
  brand: Brand;
  ip?: Maybe<Scalars['String']>;
  lessonType: LessonType;
  mobileNumber: Scalars['String'];
  postcode: Scalars['String'];
  timestamp: Scalars['String'];
  transmissionType: TransmissionType;
  type: NudgebackType;
};

export type CreateNudgebackResult = {
  __typename?: 'CreateNudgebackResult';
  successful: Scalars['Boolean'];
};

export type CreateOnlinePurchaseOrderInput = {
  courseType: CourseType;
  leadEmail: Scalars['String'];
  paymentPlanMonths?: Maybe<Scalars['Int']>;
};

export type CreatePaymentPlanAccountAdjustmentInput = {
  customValue: Scalars['Float'];
  frequency: Frequency;
  fromDate: Scalars['GraphQLDate'];
  instructorKey: Scalars['ID'];
  toDate: Scalars['GraphQLDate'];
};

export type CreatePurchaseBankDetailsInput = {
  accountHolderName: Scalars['String'];
  accountNumber: Scalars['String'];
  leadEmail: Scalars['String'];
  sortCode: Scalars['String'];
};

export type CreatePurchaseLeadError = {
  __typename?: 'CreatePurchaseLeadError';
  reason: CreatePurchaseLeadFailureReason;
  type: CreatePurchaseLeadFailureType;
};

export type CreatePurchaseLeadFailure = {
  __typename?: 'CreatePurchaseLeadFailure';
  error: CreatePurchaseLeadError;
};

export enum CreatePurchaseLeadFailureReason {
  Contact = 'Contact',
  Processing = 'Processing'
}

export enum CreatePurchaseLeadFailureType {
  Generic = 'Generic',
  Referral = 'Referral'
}

export type CreatePurchaseLeadInput = {
  address: PurchaseLeadAddressInput;
  analytics: AnalyticsInput;
  billingAddress?: Maybe<PurchaseLeadAddressInput>;
  brand: Brand;
  clientId?: Maybe<Scalars['String']>;
  course: PurchaseLeadCourseInput;
  marketing: PurchaseLeadMarketingInput;
  personal: PurchaseLeadPersonalInput;
  purchaseType: LeadType;
  terms: Scalars['Boolean'];
};

export type CreatePurchaseLeadResult = CreatePurchaseLeadFailure | CreatePurchaseLeadSuccess;

export type CreatePurchaseLeadSuccess = {
  __typename?: 'CreatePurchaseLeadSuccess';
  id: Scalars['ID'];
};

export type CreateSectorCoverageInput = {
  id: Scalars['String'];
};

export type CreateSelfSourcedLearnerInput = {
  addressCounty: Scalars['String'];
  addressIsPickup: Scalars['Boolean'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  addressPostcode: Scalars['String'];
  addressTown?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactMobile?: Maybe<Scalars['String']>;
  dateOfBirth: Scalars['GraphQLDate'];
  firstName: Scalars['String'];
  hourlyRate: Scalars['Float'];
  instructorId: Scalars['ID'];
  lastName: Scalars['String'];
  learnerType: PersonType;
  lessonType: LearnerLessonType;
  lineItemId: Scalars['ID'];
  smsRemindersOptIn: Scalars['Boolean'];
  sourcedBy: UserType;
  title: Salutation;
};

export type CreateSupportMessageInput = {
  message: Scalars['String'];
  pupilId: Scalars['String'];
};

export type CreateTestMutationInput = {
  date: Scalars['GraphQLDate'];
  learnerId: Scalars['ID'];
  status: TestStatus;
  type: TestType;
};

export type CreateTicketInput = {
  assignedStaffKey: Scalars['ID'];
  personKey: Scalars['ID'];
  ticketQueueKey: Scalars['ID'];
  ticketSource: TicketSource;
};

export type CreateTimeOffInput = {
  endDateTime: Scalars['GraphQLDate'];
  instructorId: Scalars['ID'];
  notes: Scalars['String'];
  repeatSchedule?: Maybe<RepeatSchedule>;
  startDateTime: Scalars['GraphQLDate'];
};

export type CreateTopUpOrderInput = {
  fundingSourceKey?: Maybe<Scalars['ID']>;
  fundingSourceValue?: Maybe<Scalars['Float']>;
  hours: Scalars['Int'];
  learnerId: Scalars['ID'];
};

export type CreateUnassignedTicketInput = {
  initialContactId?: Maybe<Scalars['String']>;
  personKey: Scalars['ID'];
  ticketQueueType: TicketQueueType;
  ticketSource: TicketSource;
};

export type CreateVehicleInput = {
  certificateNumber?: Maybe<Scalars['String']>;
  colour: Scalars['String'];
  fuelType: FuelType;
  instructorId: Scalars['ID'];
  insuranceEndDate?: Maybe<Scalars['GraphQLDate']>;
  insuranceStartDate?: Maybe<Scalars['GraphQLDate']>;
  manufacturer: Scalars['String'];
  mileage?: Maybe<Scalars['Int']>;
  model: Scalars['String'];
  registration: Scalars['String'];
  specification: Scalars['String'];
  transmissionType: TransmissionType;
  vehicleType: VehicleType;
};

export type CreateWelfareCategoryInput = {
  categoryTypeId: Scalars['Int'];
  dateTo?: Maybe<Scalars['GraphQLDate']>;
  note?: Maybe<Scalars['String']>;
  outcomes?: Maybe<Array<CreateWelfareOutcomeInput>>;
  personKey: Scalars['ID'];
};

export type CreateWelfareOutcomeInput = {
  note: Scalars['String'];
  outcomeTypeId: Scalars['Int'];
};

export type CreateWorkingHoursMutationInput = {
  days: Array<Maybe<DayInput>>;
  instructorId: Scalars['String'];
};

export type CreatedOrder = {
  __typename?: 'CreatedOrder';
  endpoint: Scalars['String'];
  token: Scalars['String'];
};

export type CustomDocument = {
  __typename?: 'CustomDocument';
  buffers?: Maybe<Buffers>;
  documentKey: Scalars['String'];
  documentName: Scalars['String'];
  documentRevisionKey: Scalars['String'];
};

export type Customer = Instructor | Lead | Learner;

export type CustomerAndFranchiseDocuments = {
  __typename?: 'CustomerAndFranchiseDocuments';
  customerDocuments: Array<AgreementInstructorDocument>;
  franchiseDocuments: Array<AgreementDocuments>;
};

export type CustomerSearchInput = {
  customerStatuses?: Maybe<Array<PersonStatus>>;
  customerTypes?: Maybe<Array<PersonType>>;
  term: Scalars['String'];
};

export type CustomerType = {
  __typename?: 'CustomerType';
  fromDate: Scalars['GraphQLDate'];
  hasActivePDIFranchise?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  type: PersonType;
};

export type DayInput = {
  dayNumber: Scalars['Int'];
  endTime?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
};

export type DeallocateInstructorInput = {
  learnerId: Scalars['ID'];
};

export type DeallocateVehicleInput = {
  vehiclePersonHistoryKey: Scalars['ID'];
};

export type DeleteLedgerMutationInput = {
  ledgerKey: Scalars['ID'];
};

export type DeleteWorkingHoursMutationInput = {
  dayKeys: Array<Maybe<Scalars['String']>>;
};

export type DeletedResult = {
  __typename?: 'DeletedResult';
  affected: Array<Scalars['ID']>;
};

export type DirectDebitInstalments = {
  __typename?: 'DirectDebitInstalments';
  firstInstalment: Scalars['Float'];
  monthlyInstalment: Scalars['Float'];
};

export enum DisabledWorkItemType {
  DitSupportAccountManagerOther = 'DitSupportAccountManagerOther',
  DitSupportBsmVrBooking = 'DitSupportBsmVrBooking',
  DitSupportBsmVrReschedule = 'DitSupportBsmVrReschedule',
  DitSupportDbsApplicationSubmitted = 'DitSupportDbsApplicationSubmitted',
  DitSupportDbsFollowUp = 'DitSupportDbsFollowUp',
  DitSupportDbsRequested = 'DitSupportDbsRequested',
  DitSupportOnlineClassroomBooking = 'DitSupportOnlineClassroomBooking',
  DitSupportPrnApplicationSubmitted = 'DitSupportPrnApplicationSubmitted',
  DitSupportPrnFollowUp = 'DitSupportPrnFollowUp',
  DitSupportPrnRequested = 'DitSupportPrnRequested'
}

export type Discount = {
  __typename?: 'Discount';
  description: Scalars['String'];
  discountName: Scalars['String'];
  discountType?: Maybe<DiscountType>;
  hoursRangeEnd?: Maybe<Scalars['Int']>;
  hoursRangeStart?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  value: Scalars['Float'];
  valueType?: Maybe<DiscountValueType>;
};

export type DiscountFilter = {
  discountType?: Maybe<DiscountType>;
};

export enum DiscountType {
  ExistingLearner = 'ExistingLearner',
  NewLearner = 'NewLearner'
}

export type DiscountUpdateResult = {
  __typename?: 'DiscountUpdateResult';
  id: Scalars['ID'];
  optIn: Scalars['Boolean'];
};

export enum DiscountValueType {
  Hourly = 'Hourly',
  Percentage = 'Percentage',
  Variable = 'Variable'
}

export type DiscountsInput = {
  productCategoryId: Scalars['ID'];
  propositionId: Scalars['ID'];
};

export type District = {
  __typename?: 'District';
  covered?: Maybe<Scalars['Boolean']>;
  districtCode: Scalars['String'];
  id: Scalars['ID'];
  sectors: Array<Maybe<Sector>>;
};

export enum DitLessonType {
  DitCar = 'DITCar',
  DitClassroom = 'DITClassroom'
}

export type DitOrderStatusResult = {
  __typename?: 'DitOrderStatusResult';
  id: Scalars['ID'];
  status: Scalars['String'];
};

export type Document = {
  __typename?: 'Document';
  documentId: Scalars['Int'];
  documentKey: Scalars['String'];
  documentName: Scalars['String'];
  permissions: Array<Maybe<DocumentPermission>>;
  versions: Array<Maybe<DocumentRevision>>;
};

export type DocumentPermission = {
  __typename?: 'DocumentPermission';
  active: Scalars['Boolean'];
  dateFrom: Scalars['GraphQLDate'];
  dateTo: Scalars['GraphQLDate'];
  document?: Maybe<Document>;
  documentKey?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<DocumentPermissionType>>>;
  person?: Maybe<Person>;
};

export enum DocumentPermissionType {
  R = 'r',
  Rw = 'rw',
  W = 'w'
}

export type DocumentRevision = {
  __typename?: 'DocumentRevision';
  active: Scalars['Boolean'];
  dateFrom: Scalars['GraphQLDate'];
  dateTo: Scalars['GraphQLDate'];
  document?: Maybe<Document>;
  documentHash: Scalars['String'];
  documentKey?: Maybe<Scalars['String']>;
  documentRevisionId: Scalars['Int'];
  documentRevisionKey: Scalars['String'];
  person?: Maybe<Person>;
  versionName: Scalars['String'];
};

export type DocumentRevisionResult = {
  __typename?: 'DocumentRevisionResult';
  dateFrom: Scalars['GraphQLDate'];
  dateTo?: Maybe<Scalars['GraphQLDate']>;
  documentHash: Scalars['String'];
  documentRevisionKey: Scalars['String'];
  versionName?: Maybe<Scalars['Int']>;
};

export type DocumentVersion = {
  __typename?: 'DocumentVersion';
  dateArchived?: Maybe<Scalars['GraphQLDate']>;
  dateUploaded: Scalars['GraphQLDate'];
  documentKey: Scalars['String'];
  documentName: Scalars['String'];
  documentRevisionKey: Scalars['String'];
  documentType: Scalars['String'];
  history?: Maybe<Array<Maybe<DocumentVersion>>>;
  uploadedBy: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type DsGlobalConfig = {
  __typename?: 'DsGlobalConfig';
  authPortalEnabled: Scalars['Boolean'];
  fleetDashboardUsageEnabled: Scalars['Boolean'];
  fleetDatabaseSchemaMigrationEnabled: Scalars['Boolean'];
  helixNewInvoiceTableEnabled: Scalars['Boolean'];
  hubcappReoccurringLessonsEnable: Scalars['Boolean'];
  instructorArrearsEnabled: Scalars['Boolean'];
  instructorFutureTransactionsTableEnabled: Scalars['Boolean'];
  learnerClearDownEnabled: Scalars['Boolean'];
  pendingFranchiseEnabled: Scalars['Boolean'];
  sendMotabilityWelcomeEmailButtonEnabled: Scalars['Boolean'];
};

export type EditAdditionalDriverInput = {
  additionalDriverId: Scalars['ID'];
  dateOfBirth?: Maybe<Scalars['GraphQLDate']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licenceExpiryDate?: Maybe<Scalars['GraphQLDate']>;
  licenceNumber?: Maybe<Scalars['String']>;
  relationship?: Maybe<Relationship>;
};

export type EditBankDetailsInput = {
  accountNumber: Scalars['String'];
  active: Scalars['Boolean'];
  bankName: Scalars['String'];
  nameOnAccount: Scalars['String'];
  personKey: Scalars['ID'];
  sapDirectDebitReference?: Maybe<Scalars['String']>;
  sortCode: Scalars['String'];
};

export type EditTestMutationInput = {
  date?: Maybe<Scalars['GraphQLDate']>;
  id: Scalars['ID'];
  learnerId: Scalars['ID'];
  status?: Maybe<TestStatus>;
  type?: Maybe<TestType>;
};

export type EditWorkingHoursMutationInput = {
  days: Array<Maybe<DayInput>>;
};


export type EmailAddressExistsCheckInput = {
  emailAddress: Scalars['EmailAddress'];
  excludePersonStatuses?: Maybe<Array<Maybe<PersonStatus>>>;
};

export type EmailAddressExistsCheckResult = {
  __typename?: 'EmailAddressExistsCheckResult';
  exists: Scalars['Boolean'];
};

export type EndFranchiseInput = {
  dateToFeeCharged: Scalars['Boolean'];
  endDate: Scalars['GraphQLDate'];
  endingReasonId: Scalars['Int'];
  goodTerms: Scalars['Boolean'];
  instructorId: Scalars['ID'];
  lockCap: Scalars['Boolean'];
  propositionAllocationId: Scalars['ID'];
  reallocateLearners: Scalars['Boolean'];
};

export type EventLog = {
  __typename?: 'EventLog';
  id?: Maybe<Scalars['String']>;
};

export type ExcessOrderPayment = {
  __typename?: 'ExcessOrderPayment';
  businessFunded: Scalars['Boolean'];
  cardEnding: Scalars['String'];
  id: Scalars['ID'];
  total: Scalars['Float'];
};

export enum FcaAgreementStatus {
  No = 'No',
  Yes = 'Yes'
}

export type FormError = {
  __typename?: 'FormError';
  field?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type FranchiseAgreement = {
  __typename?: 'FranchiseAgreement';
  active: Scalars['Boolean'];
  agreementKey: Scalars['String'];
  brand: BrandOrm;
  createdDate: Scalars['GraphQLDate'];
  dateFrom: Scalars['GraphQLDate'];
  dateTo?: Maybe<Scalars['GraphQLDate']>;
  franchiseAgreementId: Scalars['Int'];
  franchiseAgreementKey: Scalars['String'];
  franchiseAgreementsDocuments?: Maybe<Array<Maybe<FranchiseAgreementDocument>>>;
  person: Learner;
  personKey: Scalars['String'];
  proposition: PropositionOrm;
  status: FranchiseAgreementStatus;
  statusId: Scalars['Int'];
};

export type FranchiseAgreementAcceptance = {
  __typename?: 'FranchiseAgreementAcceptance';
  address?: Maybe<Scalars['String']>;
  adiNumber: Scalars['Int'];
  brand: Brand;
  channel: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  communication: CommunicationConsent;
  contactPreferences: Scalars['String'];
  dateOfBirth: Scalars['GraphQLDate'];
  documents: Array<SignedUrl>;
  drivingNumber: Scalars['String'];
  duration: Scalars['Float'];
  emailAddress?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  franchiseAgreementKey: Scalars['String'];
  franchiseName: Scalars['String'];
  franchiseType: Scalars['String'];
  leadType: LeadType;
  paymentDueWeekly: Scalars['Float'];
  paymentTerms: Scalars['String'];
  paymentType: Scalars['String'];
  personKey: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  startDateTime: Scalars['GraphQLDate'];
  surname: Scalars['String'];
  title: Scalars['String'];
  totalPayable?: Maybe<Scalars['Float']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type FranchiseAgreementDocument = {
  __typename?: 'FranchiseAgreementDocument';
  active: Scalars['Boolean'];
  agreement?: Maybe<FranchiseAgreement>;
  AgreementDocumentStatusId: Scalars['Int'];
  agreementDocumentTypeId: Scalars['Int'];
  createdDate: Scalars['GraphQLDate'];
  dateFrom: Scalars['GraphQLDate'];
  dateTo?: Maybe<Scalars['GraphQLDate']>;
  document?: Maybe<Document>;
  franchiseAgreementDocumentId: Scalars['Int'];
  franchiseAgreementDocumentKey: Scalars['String'];
  version?: Maybe<DocumentRevision>;
};

export type FranchiseAgreementStatus = {
  __typename?: 'FranchiseAgreementStatus';
  active: Scalars['Boolean'];
  createdDate: Scalars['GraphQLDate'];
  dateFrom: Scalars['GraphQLDate'];
  dateTo?: Maybe<Scalars['GraphQLDate']>;
  itemDescription: Scalars['String'];
  referenceId: Scalars['Int'];
  referenceItemId: Scalars['Int'];
};

export type FranchiseDocuments = {
  __typename?: 'FranchiseDocuments';
  generics: Array<GenericDocument>;
  templates: Array<Maybe<TemplateDocument>>;
};

export enum FranchiseType {
  Dit = 'DIT',
  Full = 'Full',
  Motability = 'Motability',
  PartnerLite = 'PartnerLite',
  Solo = 'Solo',
  ThirdParty = 'ThirdParty',
  Trainee = 'Trainee'
}

export enum Frequency {
  Daily = 'Daily',
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Weekly = 'Weekly'
}

export enum FuelType {
  Diesel = 'Diesel',
  Electric = 'Electric',
  Hybrid = 'Hybrid',
  Petrol = 'Petrol'
}

export type FundingSource = {
  __typename?: 'FundingSource';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FundingSourcesInput = {
  brand: Brand;
  lessonType: LearnerLessonType;
};

export type FutureLessonsInput = {
  id: Scalars['ID'];
};

export type FutureTransaction = {
  __typename?: 'FutureTransaction';
  frequency: Scalars['String'];
  futureAmountToBeBilled: Scalars['Float'];
  id: Scalars['ID'];
  lineItemBillingName: Scalars['String'];
  pastAmount: Scalars['Float'];
  pastOccurrences: Scalars['Int'];
  remainingOccurrences: Scalars['Int'];
  totalAmount: Scalars['Float'];
  totalOccurrences: Scalars['Int'];
};

export type GenericDocument = {
  __typename?: 'GenericDocument';
  documentKey: Scalars['String'];
  documentRevisionKey: Scalars['String'];
};

export type GetDirectDebitInstalmentsInput = {
  courseType: CourseType;
  paymentPlanMonths: Scalars['Int'];
};

export type GetPaymentDataInput = {
  brand: Brand;
  customerKey: Scalars['ID'];
  orderId: Scalars['Int'];
  orderKey: Scalars['ID'];
  total: Scalars['Float'];
};

export type GetVehicleDetailsInput = {
  vehicleKey: Scalars['ID'];
};

export type GiftPersonInput = {
  email: Scalars['String'];
  mobileNumber: Scalars['String'];
};


export type HistoricalFranchise = {
  __typename?: 'HistoricalFranchise';
  dateString?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  lineItemKey: Scalars['ID'];
  weeklyFee?: Maybe<Scalars['Float']>;
};

export type InflightOrderPayment = {
  __typename?: 'InflightOrderPayment';
  order: Order;
  paymentEndpoint: Scalars['String'];
  token: Scalars['String'];
};

export enum InitialContactMethod {
  Email = 'Email',
  PrimaryMobile = 'PrimaryMobile'
}

export type Instructor = Person & {
  __typename?: 'Instructor';
  activeComplaint?: Maybe<Scalars['Boolean']>;
  additionalDrivers?: Maybe<Array<AdditionalDriver>>;
  address?: Maybe<Array<Address>>;
  arrears?: Maybe<Array<Arrears>>;
  availability: Array<Availability>;
  bankDetails?: Maybe<BankDetails>;
  brands: Array<Brand>;
  calendarEvents?: Maybe<Array<CalendarEvent>>;
  certifications?: Maybe<Array<Certification>>;
  cognitoSub?: Maybe<Scalars['String']>;
  coveredRegion?: Maybe<CoveredRegion>;
  created: Scalars['GraphQLDate'];
  customerTypes: Array<CustomerType>;
  dateOfBirth?: Maybe<Scalars['GraphQLDate']>;
  description?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['EmailAddress']>;
  franFreeDaysBalance?: Maybe<Scalars['Int']>;
  franFreeDaysDayRateExVat?: Maybe<Scalars['Float']>;
  futureTransactions: Array<FutureTransaction>;
  historicalFranchises?: Maybe<Array<Maybe<HistoricalFranchise>>>;
  id: Scalars['ID'];
  invoices: Array<Invoice>;
  landlineNumber?: Maybe<Scalars['PhoneNumber']>;
  languages: Array<SpokenLanguage>;
  learnerCapacity: Array<LearnerCapacity>;
  learners: Array<Maybe<Learner>>;
  ledgerItems: Array<LedgerItem>;
  lessonTypes: Array<LessonTypePreference>;
  messages?: Maybe<Array<Message>>;
  mobileNumber?: Maybe<Scalars['PhoneNumber']>;
  name: Name;
  packagePrices: Array<PackagePrice>;
  pastLedgerItemsForLineItem: Array<LedgerItem>;
  pendingLearners: Array<Maybe<Learner>>;
  pendingLedgerItems: Array<PendingLedgerItem>;
  personId: Scalars['ID'];
  posts: Array<Maybe<Post>>;
  prices: Array<InstructorPrice>;
  propositions?: Maybe<Array<PropositionAllocation>>;
  regionalPrice?: Maybe<InstructorRegionPrice>;
  status: PersonStatus;
  testCentres?: Maybe<Array<TestCentre>>;
  totalOwed: Scalars['Float'];
  travelTime?: Maybe<Array<TravelTime>>;
  vehicleAssignments?: Maybe<Array<VehicleAssignment>>;
  welfare?: Maybe<Array<Maybe<Welfare>>>;
  workingHours: Array<WorkingHour>;
};


export type InstructorAvailabilityArgs = {
  input: AvailabilityFilter;
};


export type InstructorCalendarEventsArgs = {
  input: CalendarEventInput;
};


export type InstructorCoveredRegionArgs = {
  brand: Brand;
  postcode: Scalars['Postcode'];
  transmission: TransmissionType;
};


export type InstructorLearnersArgs = {
  input?: Maybe<InstructorLearnersInput>;
};


export type InstructorLedgerItemsArgs = {
  prev?: Maybe<Scalars['Boolean']>;
};


export type InstructorMessagesArgs = {
  input: MessageInput;
};


export type InstructorPackagePricesArgs = {
  input: PackagePriceInput;
};


export type InstructorPastLedgerItemsForLineItemArgs = {
  lineItemKey?: Maybe<Scalars['ID']>;
};


export type InstructorPendingLedgerItemsArgs = {
  lineItemKey?: Maybe<Scalars['ID']>;
};


export type InstructorPostsArgs = {
  input?: Maybe<PostsInput>;
};


export type InstructorPricesArgs = {
  postcode: Scalars['Postcode'];
  propositionAllocationId: Scalars['ID'];
};


export type InstructorPropositionsArgs = {
  input?: Maybe<PropositionInput>;
};


export type InstructorRegionalPriceArgs = {
  input?: Maybe<InstructorRegionPriceInput>;
};

export type InstructorLearnersInput = {
  learnerStatusFilter?: Maybe<LearnerStatusFilter>;
  searchTerm?: Maybe<Scalars['String']>;
};

export type InstructorPrice = {
  __typename?: 'InstructorPrice';
  id: Scalars['ID'];
  price: Scalars['Float'];
  productCategory: ProductCategoryType;
};

export type InstructorRegionPrice = {
  __typename?: 'InstructorRegionPrice';
  discounts: Array<Discount>;
  isInstructorRegionalPrice?: Maybe<Scalars['Boolean']>;
  price: Scalars['Float'];
  shortNoticePrice?: Maybe<Scalars['Float']>;
};

export type InstructorRegionPriceInput = {
  brand: Brand;
  isShortNoticeTest?: Maybe<Scalars['Boolean']>;
  learnerKey?: Maybe<Scalars['ID']>;
  lessonType: LearnerLessonType;
  postcode: Scalars['String'];
  transmission: TransmissionType;
};

export type Insurance = {
  __typename?: 'Insurance';
  certificateNumber?: Maybe<Scalars['String']>;
  endDate: Scalars['GraphQLDate'];
  id: Scalars['ID'];
  personKey?: Maybe<Scalars['ID']>;
  startDate: Scalars['GraphQLDate'];
  vehicleKey?: Maybe<Scalars['ID']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['ID'];
  invoiceDate: Scalars['GraphQLDate'];
  invoiceId: Scalars['Int'];
  paid: Scalars['Boolean'];
  paymentStatus?: Maybe<Scalars['String']>;
  pdfCreated: Scalars['Boolean'];
  value: Scalars['Float'];
  valueExVat: Scalars['Float'];
};

export type InvoiceUrl = {
  __typename?: 'InvoiceUrl';
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
};

export enum Journey {
  Nuj = 'NUJ',
  Oij = 'OIJ',
  Ruj = 'RUJ'
}

export type KeyWorkerInfo = {
  employer: Scalars['String'];
  type: KeyWorkerType;
};

export enum KeyWorkerType {
  EducationChildcare = 'EducationChildcare',
  FoodNecessaryGoods = 'FoodNecessaryGoods',
  HealthSocialCare = 'HealthSocialCare',
  KeyPublicServices = 'KeyPublicServices',
  LocalNationalGovernment = 'LocalNationalGovernment',
  SafetySecurity = 'SafetySecurity',
  Transport = 'Transport',
  UtilitiesCommunicationFinancial = 'UtilitiesCommunicationFinancial'
}

export type Lead = Person & {
  __typename?: 'Lead';
  channel: TicketSource;
  cognitoSub?: Maybe<Scalars['String']>;
  communication?: Maybe<CommunicationConsent>;
  created: Scalars['GraphQLDate'];
  dateOfBirth?: Maybe<Scalars['GraphQLDate']>;
  emailAddress?: Maybe<Scalars['EmailAddress']>;
  id: Scalars['ID'];
  landlineNumber?: Maybe<Scalars['PhoneNumber']>;
  leadAddress?: Maybe<Address>;
  licenceNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['PhoneNumber']>;
  name: Name;
  personId: Scalars['ID'];
  recommended?: Maybe<Scalars['String']>;
  status: PersonStatus;
  tickets?: Maybe<Array<Ticket>>;
  welfare?: Maybe<Array<Maybe<Welfare>>>;
};

export enum LeadType {
  Dit = 'DIT',
  Ig = 'IG'
}

export type Learner = Person & {
  __typename?: 'Learner';
  activeComplaint?: Maybe<Scalars['Boolean']>;
  address?: Maybe<LearnerAddress>;
  age?: Maybe<Scalars['Int']>;
  allocations?: Maybe<Array<Allocation>>;
  allocationStatus: LearnerAllocationStatus;
  appointedBy?: Maybe<Scalars['String']>;
  averageHourlyRatePaid?: Maybe<Scalars['Float']>;
  bankDetails?: Maybe<BankDetails>;
  cognitoSub?: Maybe<Scalars['String']>;
  created: Scalars['GraphQLDate'];
  customerTypes: Array<CustomerType>;
  dateOfBirth?: Maybe<Scalars['GraphQLDate']>;
  emailAddress?: Maybe<Scalars['EmailAddress']>;
  id: Scalars['ID'];
  isShortNoticeTest?: Maybe<Scalars['Boolean']>;
  landlineNumber?: Maybe<Scalars['PhoneNumber']>;
  lastLesson?: Maybe<Scalars['GraphQLDate']>;
  learnerLessonType?: Maybe<LearnerLessonType>;
  /** @deprecated Not being resolved correctly for DIT. Not generic. Use `lessonBalances`. */
  lessonBalance?: Maybe<LessonBalance>;
  lessonBalances: Array<LessonBalanceWithCategory>;
  lessons: Array<Lesson>;
  lessonType?: Maybe<LessonType>;
  mobileNumber?: Maybe<Scalars['PhoneNumber']>;
  name: Name;
  overallBalance?: Maybe<Array<OverallContributionAmount>>;
  pendingLedgerItems: Array<PendingLedgerItem>;
  personId: Scalars['ID'];
  pickupAddress?: Maybe<LearnerAddress>;
  progress?: Maybe<LearnerProgress>;
  referralId?: Maybe<Scalars['String']>;
  refundableBalance?: Maybe<Array<LearnerContributionAmount>>;
  sourcedBy?: Maybe<UserType>;
  status: PersonStatus;
  tests: Array<Test>;
  transactions: Array<LearnerTransaction>;
  transmissionType?: Maybe<TransmissionType>;
  welfare?: Maybe<Array<Maybe<Welfare>>>;
};


export type LearnerAllocationsArgs = {
  input?: Maybe<AllocationFilter>;
};


export type LearnerAllocationStatusArgs = {
  instructorId: Scalars['ID'];
};

export type LearnerAddress = {
  __typename?: 'LearnerAddress';
  county?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isBilling: Scalars['Boolean'];
  isPickup: Scalars['Boolean'];
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['Postcode']>;
  town?: Maybe<Scalars['String']>;
};

export enum LearnerAllocationStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Unallocated = 'Unallocated'
}

export type LearnerCapacity = {
  __typename?: 'LearnerCapacity';
  current: Scalars['Int'];
  id: Scalars['ID'];
  lineItemKey?: Maybe<Scalars['ID']>;
  locked: Scalars['Boolean'];
  maximum: Scalars['Int'];
  proposition?: Maybe<Proposition>;
};

export type LearnerContributionAmount = {
  __typename?: 'LearnerContributionAmount';
  hours: Scalars['Float'];
  learnerContribution: Scalars['Float'];
};

export type LearnerForTopUpDetails = {
  __typename?: 'LearnerForTopUpDetails';
  allocations?: Maybe<Array<Allocation>>;
  customerTypes?: Maybe<Array<CustomerType>>;
  id: Scalars['ID'];
  name: NameForTopUpDetails;
  personId: Scalars['ID'];
  pickupAddress?: Maybe<AddressForTopUpDetails>;
  referralId?: Maybe<Scalars['String']>;
};


export type LearnerForTopUpDetailsAllocationsArgs = {
  input?: Maybe<AllocationFilter>;
};

export type LearnerInput = {
  created?: Maybe<Scalars['GraphQLDate']>;
  id: Scalars['ID'];
  lessonType?: Maybe<LessonType>;
  status?: Maybe<PersonStatus>;
};

export enum LearnerLessonType {
  Dit = 'DIT',
  Motability = 'Motability',
  Motorway = 'Motorway',
  PassPlus = 'PassPlus',
  Qualified = 'Qualified',
  Standard = 'Standard'
}

export type LearnerProgress = {
  __typename?: 'LearnerProgress';
  categories: Array<SkillCategory>;
  overallProgress: Scalars['Float'];
};

export enum LearnerStatusFilter {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type LearnerTransaction = {
  __typename?: 'LearnerTransaction';
  bookingFee: Scalars['Float'];
  businessFunded?: Maybe<Scalars['Boolean']>;
  customDescription?: Maybe<Scalars['String']>;
  excessPayment?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  itemDescription: Scalars['String'];
  orderId: Scalars['Int'];
  orderValue?: Maybe<Scalars['Float']>;
  originalBalanceInHours: Scalars['Float'];
  propositionCategory?: Maybe<Scalars['String']>;
  remainingBalanceInPoundsAndPence: Scalars['Float'];
  remainingHours: Scalars['Float'];
  transactionDate: Scalars['GraphQLDate'];
  transactionType: LearnerTransactionType;
  transactionValue?: Maybe<Scalars['Float']>;
};

export enum LearnerTransactionType {
  AccountCleardown = 'AccountCleardown',
  Invoice = 'Invoice',
  Purchase = 'Purchase',
  Reallocation = 'Reallocation',
  ReallocationTransferIn = 'ReallocationTransferIn',
  ReallocationTransferOut = 'ReallocationTransferOut',
  Refund = 'Refund',
  Setup = 'Setup'
}

export type LeaverReasonListItem = {
  __typename?: 'LeaverReasonListItem';
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<PropositionAllocationLeaverReason>;
};

export type LedgerItem = {
  __typename?: 'LedgerItem';
  date: Scalars['GraphQLDate'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isCancellable: Scalars['Boolean'];
  name: Scalars['String'];
  paymentStatus: PaymentStatus;
  value: Scalars['Float'];
  valueExVat: Scalars['Float'];
};

export type Lesson = {
  __typename?: 'Lesson';
  cancellationDate?: Maybe<Scalars['GraphQLDate']>;
  created: Scalars['GraphQLDate'];
  endDateTime: Scalars['GraphQLDate'];
  franTransmission?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructor: Instructor;
  instructorAllocationKey?: Maybe<Scalars['String']>;
  learner: Learner;
  newLearner?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  paid: Scalars['Boolean'];
  pickupPostcode?: Maybe<Scalars['String']>;
  startDateTime: Scalars['GraphQLDate'];
  status: LessonStatus;
  test?: Maybe<Scalars['Boolean']>;
  travelTimeInMinutes: Scalars['Int'];
};

export type LessonBalance = {
  __typename?: 'LessonBalance';
  ditClassroomLessonBalance?: Maybe<Scalars['Float']>;
  ditInCarLessonBalance?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  standardLessonBalance?: Maybe<Scalars['Float']>;
};

export type LessonBalanceFilter = {
  instructorId?: Maybe<Scalars['ID']>;
};

export type LessonBalanceWithCategory = {
  __typename?: 'LessonBalanceWithCategory';
  category: ProductCategoryType;
  hours: Scalars['Float'];
  id: Scalars['ID'];
};

export enum LessonPurchaseType {
  Caller = 'Caller',
  Surprise = 'Surprise',
  ThirdParty = 'ThirdParty'
}

export enum LessonStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Deleted = 'Deleted',
  Pending = 'Pending'
}

export type LessonSummaryInput = {
  instructorId: Scalars['ID'];
  length: Scalars['Int'];
  startDateTime: Scalars['String'];
  transmissionType: TransmissionType;
};

export enum LessonType {
  Dit = 'DIT',
  Motability = 'Motability',
  PassPlus = 'PassPlus',
  ShortNoticeTest = 'ShortNoticeTest',
  Standard = 'Standard'
}

export type LessonTypePreference = {
  __typename?: 'LessonTypePreference';
  enabled: Scalars['Boolean'];
  lessonType: LessonType;
  propositionAllocationId: Scalars['String'];
};

export type LessonTypePreferenceInput = {
  enabled: Scalars['Boolean'];
  lessonType: LessonType;
};

export type Licence = {
  __typename?: 'Licence';
  expiryDate: Scalars['GraphQLDate'];
  licenceNumber: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  description: Scalars['String'];
  id: Scalars['String'];
};

export enum MarketingChannel {
  NudgeBack = 'NudgeBack',
  Referral = 'Referral'
}

export type MarketingFeed = {
  __typename?: 'MarketingFeed';
  aa: Array<MarketingFeedInstructor>;
  bsm: Array<MarketingFeedInstructor>;
};


export type MarketingFeedAaArgs = {
  input: MarketingFeedInput;
};


export type MarketingFeedBsmArgs = {
  input: MarketingFeedInput;
};

export type MarketingFeedCount = {
  __typename?: 'MarketingFeedCount';
  aa: Scalars['Int'];
  bsm: Scalars['Int'];
};

export enum MarketingFeedFuelType {
  Diesel = 'Diesel',
  Electric = 'Electric',
  Hybrid = 'Hybrid',
  Petrol = 'Petrol',
  Unknown = 'Unknown'
}

export type MarketingFeedInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type MarketingFeedInstructor = {
  __typename?: 'MarketingFeedInstructor';
  bio: Scalars['String'];
  brand: Brand;
  dbsChecked: Scalars['Boolean'];
  instructorId: Scalars['Int'];
  languagesSpoken: Array<Scalars['String']>;
  locationsServiced: MarketingFeedLocations;
  minHourlyRate?: Maybe<Scalars['Float']>;
  monthsProficient?: Maybe<Scalars['Int']>;
  name: MarketingFeedName;
  pdi: Scalars['Boolean'];
  personKey: Scalars['String'];
  uniqueUserId: Scalars['String'];
  vehicles: Array<MarketingFeedVehicle>;
};

export type MarketingFeedLocations = {
  __typename?: 'MarketingFeedLocations';
  postcodes: Array<Scalars['String']>;
  regions: Array<Scalars['String']>;
};

export type MarketingFeedName = {
  __typename?: 'MarketingFeedName';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type MarketingFeedVehicle = {
  __typename?: 'MarketingFeedVehicle';
  fuel: MarketingFeedFuelType;
  make: Scalars['String'];
  model: Scalars['String'];
  transmission: Scalars['String'];
};

export type Message = {
  __typename?: 'Message';
  body: Scalars['String'];
  created: Scalars['GraphQLDate'];
  from: Scalars['Int'];
  id: Scalars['ID'];
};

export type MessageInput = {
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type Mileage = {
  __typename?: 'Mileage';
  captureDate: Scalars['GraphQLDate'];
  value: Scalars['Int'];
};

export type MotabilityProduct = {
  __typename?: 'MotabilityProduct';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addBankDetails?: Maybe<Scalars['Boolean']>;
  addNewDocument: Scalars['Boolean'];
  allocateVehicle: VehiclePersonHistory;
  assignNextTicket: Ticket;
  cancelLesson: Lesson;
  cancelLessonForRUJ: RujCancelledLesson;
  cancelPendingAccountAdjustment: LedgerItem;
  cancelPendingLessons: Array<Lesson>;
  cancelTimeOff: TimeOff;
  changeDITInstructor: Learner;
  changeInstructor: ChangeInstructorPaymentResult;
  changeInstructorPaymentComplete: ChangeInstructorPaymentCompleteResult;
  changeLesson: Lesson;
  changeMotabilityToLearner?: Maybe<Learner>;
  clearDownLearner: Scalars['Boolean'];
  closeTicket: DeletedResult;
  completeDitCheckout: Learner;
  completeIgCheckout: Instructor;
  completeOnlineDitCheckout: Scalars['Boolean'];
  completePaymentForDepositAndAdminFee: Scalars['Boolean'];
  convertPendingLessons: Array<Lesson>;
  createAccountAdjustment: Instructor;
  createAdditionalDriver: AdditionalDriver;
  createAdhocPaymentOrder: InflightOrderPayment;
  createAreaCoverage: CoveredRegion;
  createCertification: Certification;
  createDefermentAccountAdjustment: Instructor;
  createDitLeadCheckoutOrder: InflightOrderPayment;
  createEndCallEvent: EventLog;
  createEnquiryLead: Scalars['Boolean'];
  createFranchiseAgreement: Array<FranchiseAgreement>;
  createIgLeadCheckoutOrder: InflightOrderPayment;
  createInstructorRating: CreateInstructorRatingResult;
  createLead: Lead;
  createLearner: Learner;
  createLearnerWithTopup: CreateLearnerWithTopupResult;
  createLearnerWithTopupForNUJ: CreateLearnerWithTopupForNujResult;
  createLesson: Lesson;
  createMessage: Message;
  createNudgeback: CreateNudgebackResult;
  createOnlinePurchaseOrder: OnlinePurchaseOrder;
  createPaymentPlanAccountAdjustment: Instructor;
  createPurchaseBankDetails: Scalars['Boolean'];
  createPurchaseLead: CreatePurchaseLeadResult;
  createSelfSourcedLearner: Learner;
  createSupportMessage: Ticket;
  createTest: Test;
  createTicket: Ticket;
  createTimeOff: TimeOff;
  createTopUpOrder: InflightOrderPayment;
  createUnassignedTicket: Ticket;
  createVehicle: Vehicle;
  createWelfareCategory: Welfare;
  deallocateInstructor: Learner;
  deallocateVehicle: VehiclePersonHistory;
  deleteAgreementDocument: Scalars['String'];
  deleteAreaCoverage: DeletedResult;
  deleteLedger: LedgerItem;
  deleteTest: Test;
  deleteWelfare: Welfare;
  deleteWelfareOutcome: WelfareOutcome;
  editAdditionalDriver: AdditionalDriver;
  editBankDetails: BankDetails;
  endFranchise: PropositionAllocation;
  fileUpload: Scalars['Boolean'];
  onboardInstructorLogin: OnboardInstructorLoginResult;
  portalAuthLogin: PortalAuthLoginResult;
  portalAuthRequestPassword: PortalAuthRequestPasswordResult;
  portalAuthSetPassword: PortalAuthSetPasswordResult;
  reassignTicket: Scalars['ID'];
  refundOrder: LearnerTransaction;
  rejectLearner: Learner;
  removePendingFranchise: Scalars['Boolean'];
  removeVehicle?: Maybe<VehiclePersonHistory>;
  /** @deprecated Use removeVehicle instead */
  removeVehicleAssignment: VehicleAssignment;
  renewFranchise: Instructor;
  requestNewInstructor: RequestNewInstructorResult;
  requestNewInstructorWithReason: RequestNewInstructorWithReasonResult;
  requestRefundWithReason: RequestRefundWithReasonResult;
  reviewAgreementDocument: Scalars['String'];
  rujLogin: RujLoginResult;
  saveWorkItem: WorkItem;
  searchAgreementKey: Scalars['String'];
  sendComplaintEmail: Scalars['Boolean'];
  sendMotabilityWelcomeEmail: Scalars['Boolean'];
  sendPupilhelpEmail: Scalars['Boolean'];
  sendRemainder: Scalars['Boolean'];
  setConsents: CommunicationConsent;
  signFranchiseAgreementDocument: Scalars['String'];
  submitQualifyingStatements: Scalars['Boolean'];
  toggleDirectDebitMandate: BankDetails;
  undoEndFranchise: PropositionAllocation;
  updateAgreementDocument: Scalars['String'];
  updateAllocation: Allocation;
  updateAreaCoverage: CoveredRegion;
  updateCertification: Certification;
  updateFranchiseAgreementDocument?: Maybe<Array<SignedUrl>>;
  updateFranchiseRegulationStatus: PropositionAllocation;
  updateFranchiseRenewalDate: PropositionAllocation;
  updateFranchiseStartDate: PropositionAllocation;
  updateInstructor: Instructor;
  updateInstructorPreferences: Instructor;
  updateInsurance?: Maybe<Insurance>;
  updateInsuranceHistory?: Maybe<Insurance>;
  updateInvoiceStatus: Invoice;
  updateLead: Lead;
  updateLearner: Learner;
  updateLearnerProgress: LearnerProgress;
  updateLesson: Lesson;
  updateMileage: Mileage;
  updateMotabilityAllocation: UpdatedMotabilityAllocation;
  updateTest: Test;
  updateTimeOff: TimeOff;
  updateWelfare: Welfare;
  uploadDocumentVersion: DocumentVersion;
  uploadNewDocument: DocumentVersion;
};


export type MutationAddBankDetailsArgs = {
  accountNumber: Scalars['String'];
  nameOnAccount: Scalars['String'];
  personKey: Scalars['String'];
  sortCode: Scalars['String'];
};


export type MutationAddNewDocumentArgs = {
  documentDescription?: Maybe<Scalars['String']>;
  documentName: Scalars['String'];
  expiryDate?: Maybe<Scalars['GraphQLDate']>;
  file: Scalars['Upload'];
  instructorKey: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};


export type MutationAllocateVehicleArgs = {
  input: AllocateVehicleInput;
};


export type MutationAssignNextTicketArgs = {
  input?: Maybe<AssignNextTicketInput>;
};


export type MutationCancelLessonArgs = {
  input: CancelLessonInput;
};


export type MutationCancelLessonForRujArgs = {
  input: CancelLessonForRujInput;
};


export type MutationCancelPendingAccountAdjustmentArgs = {
  input?: Maybe<CancelPendingAccountAdjustmentInput>;
};


export type MutationCancelPendingLessonsArgs = {
  input: CancelPendingLessonsInput;
};


export type MutationCancelTimeOffArgs = {
  input: CancelTimeOffInput;
};


export type MutationChangeDitInstructorArgs = {
  input?: Maybe<ChangeDitInstructorInput>;
};


export type MutationChangeInstructorArgs = {
  input?: Maybe<ChangeInstructorInput>;
};


export type MutationChangeInstructorPaymentCompleteArgs = {
  input?: Maybe<ChangeInstructorPaymentCompleteInput>;
};


export type MutationChangeLessonArgs = {
  input: ChangeLessonInput;
};


export type MutationChangeMotabilityToLearnerArgs = {
  input?: Maybe<ChangeMotabilityToLearnerInput>;
};


export type MutationClearDownLearnerArgs = {
  input: ClearDownLearnerInput;
};


export type MutationCloseTicketArgs = {
  id: Scalars['ID'];
};


export type MutationCompleteDitCheckoutArgs = {
  input: CompleteDitCheckoutInput;
};


export type MutationCompleteIgCheckoutArgs = {
  input: CompleteIgCheckoutInput;
};


export type MutationCompleteOnlineDitCheckoutArgs = {
  input: CompleteOnlineDitCheckoutInput;
};


export type MutationCompletePaymentForDepositAndAdminFeeArgs = {
  input: CompletePaymentForDepositAndAdminFeeInput;
};


export type MutationConvertPendingLessonsArgs = {
  input: ConvertPendingLessonsInput;
};


export type MutationCreateAccountAdjustmentArgs = {
  input?: Maybe<CreateAccountAdjustmentInput>;
};


export type MutationCreateAdditionalDriverArgs = {
  input?: Maybe<CreateAdditionalDriverInput>;
};


export type MutationCreateAdhocPaymentOrderArgs = {
  input?: Maybe<CreateAdhocPaymentOrderInput>;
};


export type MutationCreateAreaCoverageArgs = {
  input?: Maybe<CreateAreaCoverageInput>;
};


export type MutationCreateCertificationArgs = {
  input?: Maybe<CreateCertificationInput>;
};


export type MutationCreateDefermentAccountAdjustmentArgs = {
  input?: Maybe<CreateDefermentAccountAdjustmentInput>;
};


export type MutationCreateDitLeadCheckoutOrderArgs = {
  input: CreateDitLeadCheckoutOrderInput;
};


export type MutationCreateEndCallEventArgs = {
  input?: Maybe<CreateEndCallEventInput>;
};


export type MutationCreateEnquiryLeadArgs = {
  input: CreateEnquiryLeadInput;
};


export type MutationCreateFranchiseAgreementArgs = {
  input: CreateFranchiseAgreementInput;
};


export type MutationCreateIgLeadCheckoutOrderArgs = {
  input: CreateIgLeadCheckoutOrderInput;
};


export type MutationCreateInstructorRatingArgs = {
  input: CreateInstructorRatingInput;
};


export type MutationCreateLeadArgs = {
  input: CreateLeadInput;
};


export type MutationCreateLearnerArgs = {
  input: CreateLearnerInput;
};


export type MutationCreateLearnerWithTopupArgs = {
  input?: Maybe<CreateLearnerWithTopupInput>;
};


export type MutationCreateLearnerWithTopupForNujArgs = {
  input?: Maybe<CreateLearnerWithTopupForNujInput>;
};


export type MutationCreateLessonArgs = {
  input: CreateLessonInput;
};


export type MutationCreateMessageArgs = {
  input?: Maybe<CreateMessageMutationInput>;
};


export type MutationCreateNudgebackArgs = {
  input: CreateNudgebackInput;
};


export type MutationCreateOnlinePurchaseOrderArgs = {
  input: CreateOnlinePurchaseOrderInput;
};


export type MutationCreatePaymentPlanAccountAdjustmentArgs = {
  input?: Maybe<CreatePaymentPlanAccountAdjustmentInput>;
};


export type MutationCreatePurchaseBankDetailsArgs = {
  input: CreatePurchaseBankDetailsInput;
};


export type MutationCreatePurchaseLeadArgs = {
  input: CreatePurchaseLeadInput;
};


export type MutationCreateSelfSourcedLearnerArgs = {
  input: CreateSelfSourcedLearnerInput;
};


export type MutationCreateSupportMessageArgs = {
  input: CreateSupportMessageInput;
};


export type MutationCreateTestArgs = {
  input?: Maybe<CreateTestMutationInput>;
};


export type MutationCreateTicketArgs = {
  input?: Maybe<CreateTicketInput>;
};


export type MutationCreateTimeOffArgs = {
  input: CreateTimeOffInput;
};


export type MutationCreateTopUpOrderArgs = {
  input: CreateTopUpOrderInput;
};


export type MutationCreateUnassignedTicketArgs = {
  input?: Maybe<CreateUnassignedTicketInput>;
};


export type MutationCreateVehicleArgs = {
  input?: Maybe<CreateVehicleInput>;
};


export type MutationCreateWelfareCategoryArgs = {
  input: CreateWelfareCategoryInput;
};


export type MutationDeallocateInstructorArgs = {
  input: DeallocateInstructorInput;
};


export type MutationDeallocateVehicleArgs = {
  input: DeallocateVehicleInput;
};


export type MutationDeleteAgreementDocumentArgs = {
  franAgreeDocKey: Scalars['String'];
};


export type MutationDeleteAreaCoverageArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLedgerArgs = {
  input?: Maybe<DeleteLedgerMutationInput>;
};


export type MutationDeleteTestArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWelfareArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWelfareOutcomeArgs = {
  id: Scalars['ID'];
};


export type MutationEditAdditionalDriverArgs = {
  input?: Maybe<EditAdditionalDriverInput>;
};


export type MutationEditBankDetailsArgs = {
  input?: Maybe<EditBankDetailsInput>;
};


export type MutationEndFranchiseArgs = {
  input?: Maybe<EndFranchiseInput>;
};


export type MutationFileUploadArgs = {
  file?: Maybe<Scalars['Upload']>;
  name: Scalars['String'];
};


export type MutationOnboardInstructorLoginArgs = {
  input: OnboardInstructorLoginInput;
};


export type MutationPortalAuthLoginArgs = {
  input: PortalAuthLoginInput;
};


export type MutationPortalAuthRequestPasswordArgs = {
  input: PortalAuthRequestPasswordInput;
};


export type MutationPortalAuthSetPasswordArgs = {
  input: PortalAuthSetPasswordInput;
};


export type MutationReassignTicketArgs = {
  input?: Maybe<ReassignTicketInput>;
};


export type MutationRefundOrderArgs = {
  input?: Maybe<RefundOrderInput>;
};


export type MutationRejectLearnerArgs = {
  input: RejectLearnerInput;
};


export type MutationRemovePendingFranchiseArgs = {
  input: RemovePendingFranchiseInput;
};


export type MutationRemoveVehicleArgs = {
  input: RemoveVehicleInput;
};


export type MutationRemoveVehicleAssignmentArgs = {
  input: RemoveVehicleAssignmentInput;
};


export type MutationRenewFranchiseArgs = {
  input: RenewFranchiseInput;
};


export type MutationRequestNewInstructorArgs = {
  input: RequestNewInstructorInput;
};


export type MutationRequestNewInstructorWithReasonArgs = {
  input: RequestNewInstructorWithReasonInput;
};


export type MutationRequestRefundWithReasonArgs = {
  input: RequestRefundWithReasonInput;
};


export type MutationReviewAgreementDocumentArgs = {
  input: ReviewAgreementDocumentInput;
};


export type MutationRujLoginArgs = {
  input: RujLoginInput;
};


export type MutationSaveWorkItemArgs = {
  input?: Maybe<WorkItemInput>;
};


export type MutationSearchAgreementKeyArgs = {
  dateOfBirth: Scalars['GraphQLDate'];
  email: Scalars['String'];
  personKey: Scalars['String'];
  postcode: Scalars['String'];
};


export type MutationSendComplaintEmailArgs = {
  input: SendComplaintEmailInput;
};


export type MutationSendMotabilityWelcomeEmailArgs = {
  input: SendMotabilityWelcomeEmailInput;
};


export type MutationSendPupilhelpEmailArgs = {
  input: SendPupilhelpEmailInput;
};


export type MutationSendRemainderArgs = {
  franAgreeDoc: Scalars['String'];
};


export type MutationSetConsentsArgs = {
  input?: Maybe<CommunicationConsentInput>;
};


export type MutationSignFranchiseAgreementDocumentArgs = {
  agreementKey?: Maybe<Scalars['String']>;
};


export type MutationSubmitQualifyingStatementsArgs = {
  input: PurchaseLeadQualifyingStatementsInput;
};


export type MutationToggleDirectDebitMandateArgs = {
  input: ToggleDirectDebitMandateInput;
};


export type MutationUndoEndFranchiseArgs = {
  input?: Maybe<UndoEndFranchiseInput>;
};


export type MutationUpdateAgreementDocumentArgs = {
  documentDescription?: Maybe<Scalars['String']>;
  documentName: Scalars['String'];
  expiryDate?: Maybe<Scalars['GraphQLDate']>;
  file: Scalars['Upload'];
  franAgreeDocKey: Scalars['String'];
  personKey: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};


export type MutationUpdateAllocationArgs = {
  input?: Maybe<UpdateAllocationInput>;
};


export type MutationUpdateAreaCoverageArgs = {
  input?: Maybe<UpdateAreaCoverageInput>;
};


export type MutationUpdateCertificationArgs = {
  input: UpdateCertificationInput;
};


export type MutationUpdateFranchiseAgreementDocumentArgs = {
  agreementKey?: Maybe<Scalars['String']>;
};


export type MutationUpdateFranchiseRegulationStatusArgs = {
  input: UpdateFranchiseRegulationStatusInput;
};


export type MutationUpdateFranchiseRenewalDateArgs = {
  input: UpdateFranchiseDateInput;
};


export type MutationUpdateFranchiseStartDateArgs = {
  input: UpdateFranchiseDateInput;
};


export type MutationUpdateInstructorArgs = {
  input: UpdateInstructorInput;
};


export type MutationUpdateInstructorPreferencesArgs = {
  input: UpdateInstructorPreferencesInput;
};


export type MutationUpdateInsuranceArgs = {
  input: UpdateInsuranceInput;
};


export type MutationUpdateInsuranceHistoryArgs = {
  input: UpdateInsuranceHistoryInput;
};


export type MutationUpdateInvoiceStatusArgs = {
  input: UpdateInvoiceStatusInput;
};


export type MutationUpdateLeadArgs = {
  input: UpdateLeadInput;
};


export type MutationUpdateLearnerArgs = {
  input: UpdateLearnerInput;
};


export type MutationUpdateLearnerProgressArgs = {
  input: UpdateLearnerProgressInput;
};


export type MutationUpdateLessonArgs = {
  input: UpdateLessonInput;
};


export type MutationUpdateMileageArgs = {
  input: UpdateMileageInput;
};


export type MutationUpdateMotabilityAllocationArgs = {
  input: UpdateMotabilityAllocationInput;
};


export type MutationUpdateTestArgs = {
  input?: Maybe<EditTestMutationInput>;
};


export type MutationUpdateTimeOffArgs = {
  input: UpdateTimeOffInput;
};


export type MutationUpdateWelfareArgs = {
  input: UpdateWelfareInput;
};


export type MutationUploadDocumentVersionArgs = {
  buffer: Scalars['Upload'];
  documentKey: Scalars['String'];
  personKey: Scalars['String'];
};


export type MutationUploadNewDocumentArgs = {
  brand: Scalars['String'];
  buffer: Scalars['Upload'];
  documentName: Scalars['String'];
  documentType: Scalars['String'];
  personKey: Scalars['String'];
};

export type NujLearnerInput = {
  billingAddress?: Maybe<AddressInput>;
  dateOfBirth: Scalars['GraphQLDate'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  giftGiver?: Maybe<GiftPersonInput>;
  lastName: Scalars['String'];
  mobileNumber: Scalars['String'];
  pickupAddress: AddressInput;
  title: Salutation;
};

export type Name = {
  __typename?: 'Name';
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  lastName: Scalars['String'];
  title?: Maybe<Salutation>;
};

export type NameForTopUpDetails = {
  __typename?: 'NameForTopUpDetails';
  firstName: Scalars['String'];
};

export type NewDay = {
  day: Scalars['Int'];
  endTime?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
};

export enum NudgebackType {
  Web = 'Web',
  WebCovid = 'WebCovid'
}

export type NujOrder = {
  __typename?: 'NujOrder';
  bookingId?: Maybe<Scalars['ID']>;
  orderCostSummary?: Maybe<OrderCostSummary>;
  orderId?: Maybe<Scalars['ID']>;
  payment?: Maybe<CreatedOrder>;
  pupilId?: Maybe<Scalars['ID']>;
};

export type OnboardInstructorLoginInput = {
  brand: Brand;
  dateOfBirth: Scalars['GraphQLDate'];
  email: Scalars['String'];
  personKey: Scalars['String'];
  postcode: Scalars['String'];
};

export type OnboardInstructorLoginResult = {
  __typename?: 'OnboardInstructorLoginResult';
  accessToken?: Maybe<Scalars['String']>;
  attemptsRemaining?: Maybe<Scalars['Int']>;
  authenticated: Scalars['Boolean'];
  encryptedPersonId?: Maybe<Scalars['String']>;
  lockoutExpiry?: Maybe<Scalars['GraphQLDate']>;
};

export type OnlinePurchaseOrder = {
  __typename?: 'OnlinePurchaseOrder';
  orderId: Scalars['ID'];
  paymentEndpoint: Scalars['String'];
  token: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  amount?: Maybe<Scalars['Float']>;
  customer?: Maybe<Customer>;
  discount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  status: OrderStatus;
  topUpId?: Maybe<Scalars['String']>;
};

export type OrderCostSummary = {
  __typename?: 'OrderCostSummary';
  bookingFee?: Maybe<Scalars['Float']>;
  discount: Scalars['Float'];
  hours: Scalars['Int'];
  packagePrice: Scalars['Float'];
  total: Scalars['Float'];
};

export type OrderDetail = {
  __typename?: 'OrderDetail';
  orderDetailsKey?: Maybe<Scalars['ID']>;
  orderKey?: Maybe<Scalars['ID']>;
  product?: Maybe<OrderDetailProduct>;
  refundValue?: Maybe<Scalars['Float']>;
  totalValue?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Float']>;
};

export type OrderDetailProduct = {
  __typename?: 'OrderDetailProduct';
  productDescription?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
};

export type OrderPayment = {
  __typename?: 'OrderPayment';
  bookingFeeValue: Scalars['Float'];
  cardEnding: Scalars['String'];
  excessPayments?: Maybe<Array<ExcessOrderPayment>>;
  id: Scalars['ID'];
  orderValue: Scalars['Float'];
  remainingBalance: Scalars['Float'];
};

export enum OrderStatus {
  Failed = 'Failed',
  Paid = 'Paid',
  Pending = 'Pending'
}

export type OverallContributionAmount = {
  __typename?: 'OverallContributionAmount';
  hours: Scalars['Float'];
  overallContribution: Scalars['Float'];
};

export type OwnershipHistory = {
  __typename?: 'OwnershipHistory';
  fromDate: Scalars['GraphQLDate'];
  id: Scalars['ID'];
  personKey: Scalars['ID'];
  personName: Scalars['String'];
  toDate?: Maybe<Scalars['GraphQLDate']>;
};

export type PackagePrice = {
  __typename?: 'PackagePrice';
  discount?: Maybe<Discount>;
  discountAmount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  packageLengthInHours: Scalars['Int'];
  packagePrice: Scalars['Float'];
};

export type PackagePriceInput = {
  brand: Brand;
  isShortNoticeTest: Scalars['Boolean'];
  learnerLessonType: LearnerLessonType;
  packageLengthsInHours: Array<Scalars['Int']>;
  postcode: Scalars['Postcode'];
  transmission: TransmissionType;
};

export type PaymentData = {
  __typename?: 'PaymentData';
  endpoint: Scalars['String'];
  orderId: Scalars['Int'];
  orderKey: Scalars['ID'];
  token: Scalars['String'];
};

export type PaymentReallocationOrder = {
  oldOrderHours: Scalars['Int'];
  oldOrderKey: Scalars['ID'];
  pendingOrderHours: Scalars['Int'];
  pendingOrderKey: Scalars['ID'];
};

export enum PaymentStatus {
  Paid = 'Paid',
  Pending = 'Pending',
  Unpaid = 'Unpaid'
}

export type PendingLedgerItem = {
  __typename?: 'PendingLedgerItem';
  amountExcVAT: Scalars['Float'];
  amountIncVAT: Scalars['Float'];
  date: Scalars['GraphQLDate'];
  description: Scalars['String'];
  endDate?: Maybe<Scalars['GraphQLDate']>;
  frequency?: Maybe<Frequency>;
  id: Scalars['ID'];
  isCancellable: Scalars['Boolean'];
  name: Scalars['String'];
  paymentStatus: PaymentStatus;
  productCategory?: Maybe<ProductCategoryType>;
  totalInSeries: Scalars['Int'];
};

export type Permissions = {
  __typename?: 'Permissions';
  personKey: Scalars['String'];
  readable: Scalars['Boolean'];
  writable: Scalars['Boolean'];
};

export type Person = {
  cognitoSub?: Maybe<Scalars['String']>;
  created: Scalars['GraphQLDate'];
  dateOfBirth?: Maybe<Scalars['GraphQLDate']>;
  emailAddress?: Maybe<Scalars['EmailAddress']>;
  id: Scalars['ID'];
  landlineNumber?: Maybe<Scalars['PhoneNumber']>;
  mobileNumber?: Maybe<Scalars['PhoneNumber']>;
  name: Name;
  personId: Scalars['ID'];
  status: PersonStatus;
  welfare?: Maybe<Array<Maybe<Welfare>>>;
};

export enum PersonStatus {
  Active = 'Active',
  Deleted = 'Deleted',
  Inactive = 'Inactive',
  Lead = 'Lead',
  Pending = 'Pending'
}

export enum PersonType {
  Dit = 'DIT',
  Instructor = 'Instructor',
  Learner = 'Learner',
  Motability = 'Motability',
  TestCustomer = 'TestCustomer'
}


export type PoolHistory = {
  __typename?: 'PoolHistory';
  active: Scalars['Boolean'];
  fromDate: Scalars['GraphQLDate'];
  poolHistoryKey: Scalars['ID'];
  poolId: Scalars['Int'];
  poolItemDescription?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['GraphQLDate']>;
  vehicleKey: Scalars['ID'];
};

export type PortalAuthLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  personType: PersonType;
};

export type PortalAuthLoginResult = {
  __typename?: 'PortalAuthLoginResult';
  accessToken?: Maybe<Scalars['String']>;
  authenticated: Scalars['Boolean'];
  message: Scalars['String'];
};

export type PortalAuthRequestPasswordInput = {
  email: Scalars['String'];
  personType: PersonType;
};

export type PortalAuthRequestPasswordResult = {
  __typename?: 'PortalAuthRequestPasswordResult';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type PortalAuthSetPasswordInput = {
  code: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  personType: PersonType;
};

export type PortalAuthSetPasswordResult = {
  __typename?: 'PortalAuthSetPasswordResult';
  errors?: Maybe<Array<FormError>>;
  success: Scalars['Boolean'];
};

export type Post = {
  __typename?: 'Post';
  brand: Brand;
  content: Scalars['String'];
  date: Scalars['GraphQLDate'];
  id: Scalars['ID'];
  status: PostStatus;
  type: PostType;
};

export enum PostStatus {
  Draft = 'Draft',
  Live = 'Live',
  Removed = 'Removed'
}

export enum PostType {
  File = 'File',
  News = 'News',
  Tech = 'Tech'
}


export type PostsInput = {
  limit?: Maybe<Scalars['Int']>;
  status?: Maybe<PostStatus>;
  type?: Maybe<Array<Maybe<PostType>>>;
};

export enum PriceType {
  Bespoke = 'Bespoke',
  Instructor = 'Instructor',
  Region = 'Region'
}

export type PricingUpdateResult = {
  __typename?: 'PricingUpdateResult';
  automaticPrice?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  manualPrice?: Maybe<Scalars['String']>;
  shortNoticeTestPrice?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  adjustablePrice?: Maybe<Scalars['Boolean']>;
  defaultPriceExVat?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  vat?: Maybe<Vat>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id: Scalars['ID'];
  type: ProductCategoryType;
};

export enum ProductCategoryType {
  Charge = 'Charge',
  Deferral = 'Deferral',
  Discount = 'Discount',
  Dit = 'DIT',
  DitCar = 'DITCar',
  DitClassroom = 'DITClassroom',
  DitLearnerResource = 'DITLearnerResource',
  Instructor = 'Instructor',
  Motability = 'Motability',
  Motability2015 = 'Motability2015',
  Motability2018 = 'Motability2018',
  Motability2022 = 'Motability2022',
  Motability2023 = 'Motability2023',
  Payment = 'Payment',
  PaymentPlan = 'PaymentPlan',
  PayPlan = 'PayPlan',
  PostTest = 'PostTest',
  PreTest = 'PreTest'
}

export type Proposition = {
  __typename?: 'Proposition';
  adminFee?: Maybe<PropositionAdminFee>;
  brand: Brand;
  depositFee?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  fundingSources?: Maybe<Array<FundingSource>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  price: PropositionPrice;
  productCategories?: Maybe<Array<ProductCategory>>;
  transmission?: Maybe<TransmissionType>;
  type?: Maybe<FranchiseType>;
};

export type PropositionAdminFee = {
  __typename?: 'PropositionAdminFee';
  excludingVAT: Scalars['Float'];
  includingVAT: Scalars['Float'];
  vat?: Maybe<Scalars['Float']>;
};

export type PropositionAllocation = {
  __typename?: 'PropositionAllocation';
  coverage: Array<Maybe<CoveredRegion>>;
  dateToFeeCharged: Scalars['Boolean'];
  estimatedRenewalDate?: Maybe<Scalars['GraphQLDate']>;
  fromDate: Scalars['GraphQLDate'];
  id: Scalars['ID'];
  learnerCaps?: Maybe<Array<LearnerCapacity>>;
  leavingReason?: Maybe<LeaverReasonListItem>;
  orderDetails?: Maybe<Array<Maybe<OrderDetail>>>;
  orderId?: Maybe<Scalars['Int']>;
  orderKey?: Maybe<Scalars['ID']>;
  originalEndDate?: Maybe<Scalars['GraphQLDate']>;
  proposition: Proposition;
  regulationStatus?: Maybe<FcaAgreementStatus>;
  status: PropositionAllocationStatus;
  toDate?: Maybe<Scalars['GraphQLDate']>;
};

export enum PropositionAllocationLeaverReason {
  CancellationEarlyExit = 'CancellationEarlyExit',
  CancellationNotTakenUpNtu = 'CancellationNotTakenUpNTU',
  CareerChange = 'CareerChange',
  Covid19 = 'COVID19',
  CrossBrandTransfer = 'CrossBrandTransfer',
  CscServiceIssue = 'CSCServiceIssue',
  Deceased = 'Deceased',
  FamilyIssues = 'FamilyIssues',
  FranchiseCost2ndYearFranchiseIncrease = 'FranchiseCost2ndYearFranchiseIncrease',
  FranchiseCostCheaperCompetitor = 'FranchiseCostCheaperCompetitor',
  FranchiseCostFranchiseIncrease = 'FranchiseCostFranchiseIncrease',
  FranchiseCostPupilSupply = 'FranchiseCostPupilSupply',
  FranchiseCosts = 'FranchiseCosts',
  LackOfWork = 'LackOfWork',
  LackOfWorkAadt = 'LackOfWorkAADT',
  LackOfWorkPupils = 'LackOfWorkPupils',
  MedicalReasons = 'MedicalReasons',
  Migration = 'Migration',
  MoveToAnotherFranchise = 'MoveToAnotherFranchise',
  MoveToOtherAAfranchise = 'MoveToOtherAAfranchise',
  MoveToOtherFranchise = 'MoveToOtherFranchise',
  MoveToSelfEmployed = 'MoveToSelfEmployed',
  NotGiven = 'NotGiven',
  Other = 'Other',
  PersonalReasons = 'PersonalReasons',
  Relocation = 'Relocation',
  Retired = 'Retired',
  TerminatedByAads = 'TerminatedByAADS',
  TerminatedByAadsPermanentlyDeclined = 'TerminatedByAADSPermanentlyDeclined',
  TerminatedByBusiness = 'TerminatedByBusiness',
  TerminatedByBusinessPermanentlyDeclined = 'TerminatedByBusinessPermanentlyDeclined',
  TerminationConduct = 'TerminationConduct',
  TerminationDebt = 'TerminationDebt',
  TerminationFailedPart3For3rdTime = 'TerminationFailedPart3For3rdTime',
  TerminationGreenBadge = 'TerminationGreenBadge',
  TerminationPinkBadge = 'TerminationPinkBadge',
  UnhappyWithServiceCsc = 'UnhappyWithServiceCSC',
  UnhappyWithServiceFleet = 'UnhappyWithServiceFleet'
}

export enum PropositionAllocationStatus {
  Active = 'Active',
  Deleted = 'Deleted',
  Pending = 'Pending'
}

export enum PropositionFeeFrequency {
  Weekly = 'Weekly'
}

export type PropositionInput = {
  future?: Maybe<Scalars['Boolean']>;
};

export type PropositionOrm = {
  __typename?: 'PropositionOrm';
  propositionDescription: Scalars['String'];
  propositionName: Scalars['String'];
};

export type PropositionPrice = {
  __typename?: 'PropositionPrice';
  excludingVAT: Scalars['Float'];
  frequency: PropositionFeeFrequency;
  includingVAT: Scalars['Float'];
  vat?: Maybe<Scalars['Float']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type PropositionProduct = {
  __typename?: 'PropositionProduct';
  products: Array<Product>;
  propositionId: Scalars['ID'];
};

export enum PurchasablePropositionType {
  Dit = 'DIT',
  InstructorGrowth = 'InstructorGrowth'
}

export type PurchaseLeadAddressInput = {
  country: Scalars['String'];
  county: Scalars['String'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  postcode: Scalars['Postcode'];
  town: Scalars['String'];
};

export type PurchaseLeadCourseInput = {
  studyFormat: StudyFormat;
  type: CourseType;
};

export type PurchaseLeadMarketingInput = {
  email: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  post: Scalars['Boolean'];
  text: Scalars['Boolean'];
};

export type PurchaseLeadPersonalInput = {
  dateOfBirth: Scalars['GraphQLDate'];
  drivingLicenceNumber?: Maybe<Scalars['String']>;
  drivingLicenceType: TransmissionType;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  title: Salutation;
};

export type PurchaseLeadQualifyingStatementsInput = {
  email: Scalars['String'];
  purchaseType: LeadType;
  statements: Array<QualifyingStatement>;
};

export enum PurchaseSource {
  DitOnline = 'DitOnline',
  Helix = 'Helix'
}

export enum QualifyingStatement {
  Convictions = 'Convictions',
  Disqualified = 'Disqualified',
  FullLicence = 'FullLicence',
  Points = 'Points',
  Vision = 'Vision'
}

export type Query = {
  __typename?: 'Query';
  accessToken: Scalars['String'];
  accountAdjustmentChargeProducts: Array<Product>;
  accountAdjustmentDefermentProducts: Array<Product>;
  accountAdjustmentDiscountProducts: Array<Product>;
  accountAdjustmentFrequencies: Array<Frequency>;
  availabilityNUJ: AvailabilityNuj;
  cabRank: CabRank;
  cabRankNUJ: CabRank;
  calculateChangeInstructorBalance: CalculateChangeInstructorBalanceResult;
  certificationList: Array<CertificationListItem>;
  combinedAvailability: CombinedAvailability;
  consents: CommunicationConsent;
  coverage: CoveredRegion;
  currentMotabilityProduct?: Maybe<MotabilityProduct>;
  customer?: Maybe<Customer>;
  customerByPersonId?: Maybe<Customer>;
  customerSearch: Array<Customer>;
  DITOrder?: Maybe<DitOrderStatusResult>;
  downloadVersions?: Maybe<Array<Maybe<CustomDocument>>>;
  emailAddressExistsCheck: EmailAddressExistsCheckResult;
  existingLead?: Maybe<Lead>;
  franchiseAgreement: FranchiseAgreementAcceptance;
  fundingSources?: Maybe<Array<FundingSource>>;
  futureLessons: BookedLessons;
  getAgreementDocumentRejectReason?: Maybe<Array<Reference>>;
  getAgreementsDocumentsByPerson?: Maybe<Array<Maybe<AgreementDocuments>>>;
  getAllGenerics: Array<DocumentVersion>;
  getAllTemplates: Array<DocumentVersion>;
  getArrearsDetailForProposition: Array<Arrears>;
  getCustomerAndFranchiseDocumentsByPerson?: Maybe<CustomerAndFranchiseDocuments>;
  getDDIDocumentUrl?: Maybe<Scalars['String']>;
  getDirectDebitInstalments: DirectDebitInstalments;
  getDocumentPermsion?: Maybe<Array<Maybe<Permissions>>>;
  getDocumentsByFranchise: FranchiseDocuments;
  getDocumentURL?: Maybe<Scalars['String']>;
  getDsGlobalConfig: DsGlobalConfig;
  getFullAddress: SearchAddress;
  getPaymentData: PaymentData;
  instructor?: Maybe<Instructor>;
  instructors: Array<Instructor>;
  instructorSearch: Array<Instructor>;
  invoiceUrl: InvoiceUrl;
  isInstructorInArrears: Scalars['Boolean'];
  learner?: Maybe<Learner>;
  learnerProgressByPersonId: LearnerProgress;
  learnerTopUpDetails: LearnerForTopUpDetails;
  leaverReasons: Array<LeaverReasonListItem>;
  lesson: Lesson;
  marketingFeed: MarketingFeed;
  marketingFeedCount: MarketingFeedCount;
  motabilityCustomerUploadUrl?: Maybe<Scalars['String']>;
  motabilityProducts: Array<MotabilityProduct>;
  numberOfTicketsAssignedToUser: Scalars['Int'];
  numberOfTicketsAssignedToUserByStatus: Scalars['Int'];
  numberOfTicketsClosedByUser: Scalars['Int'];
  numberOfTicketsPickedUpByUser: Scalars['Int'];
  order?: Maybe<Order>;
  orderNUJ?: Maybe<Order>;
  orderPayment?: Maybe<OrderPayment>;
  productCategory?: Maybe<Array<Maybe<ProductCategory>>>;
  propositionDiscounts: Array<Discount>;
  propositionProducts: Array<PropositionProduct>;
  purchasablePropositions: Array<Proposition>;
  queueProductivity: QueueProductivity;
  queueTicketCount: Scalars['Int'];
  queueTicketCountWithStatus: Scalars['Int'];
  randomInstructors: Array<Instructor>;
  region: Region;
  regionLookup: Array<Maybe<RegionLookup>>;
  regions: Array<Maybe<Region>>;
  retrieveDocumentURLs?: Maybe<Array<Maybe<SignedUrl>>>;
  searchForAddress: Array<Location>;
  searchVehicles: Array<VehicleInformation>;
  sendEmail: Scalars['Boolean'];
  staff: Array<Staff>;
  teamMembersAssignedToQueue: Array<Staff>;
  testCentres: Array<TestCentre>;
  thisLearner?: Maybe<Learner>;
  ticket: Ticket;
  ticketQueues: Array<TicketQueue>;
  ticketQueuesForUser: Array<TicketQueue>;
  tickets: Array<Ticket>;
  ticketsAssignedToStaffMember: Array<StaffMemberTicket>;
  ticketsForCustomer: Array<Ticket>;
  ticketsInQueue: Array<Ticket>;
  timeOff: TimeOff;
  timeSlotsAvailability: Array<TimeSlot>;
  vehicle: VehicleInformation;
  vehiclesByAssignmentType: Array<VehicleInformation>;
  vehiclesCountByAssignmentType: Scalars['Int'];
  welfare?: Maybe<Welfare>;
  welfareCategoryTypes: Array<WelfareCategoryType>;
  welfareOutcomeTypes: Array<WelfareOutcomeType>;
  welfaresByPersonId?: Maybe<Array<Maybe<Welfare>>>;
  workItemsToday?: Maybe<Array<WorkItem>>;
};


export type QueryAccessTokenArgs = {
  service: Service;
};


export type QueryAccountAdjustmentChargeProductsArgs = {
  instructorKey: Scalars['ID'];
};


export type QueryAccountAdjustmentDefermentProductsArgs = {
  instructorKey: Scalars['ID'];
};


export type QueryAccountAdjustmentDiscountProductsArgs = {
  instructorKey: Scalars['ID'];
};


export type QueryAvailabilityNujArgs = {
  input: AvailabilityNujInput;
};


export type QueryCabRankArgs = {
  input: CabRankInput;
};


export type QueryCabRankNujArgs = {
  input: CabRankNujInput;
};


export type QueryCalculateChangeInstructorBalanceArgs = {
  input?: Maybe<CalculateChangeInstructorInput>;
};


export type QueryCombinedAvailabilityArgs = {
  input: CombinedAvailabilityInput;
};


export type QueryConsentsArgs = {
  personKey: Scalars['ID'];
};


export type QueryCoverageArgs = {
  coverageId: Scalars['ID'];
};


export type QueryCurrentMotabilityProductArgs = {
  learnerId: Scalars['ID'];
};


export type QueryCustomerArgs = {
  id: Scalars['String'];
};


export type QueryCustomerByPersonIdArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerSearchArgs = {
  input: CustomerSearchInput;
};


export type QueryDitOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryDownloadVersionsArgs = {
  documentRevisionKeys: Array<Maybe<Scalars['String']>>;
};


export type QueryEmailAddressExistsCheckArgs = {
  input: EmailAddressExistsCheckInput;
};


export type QueryExistingLeadArgs = {
  email: Scalars['String'];
};


export type QueryFranchiseAgreementArgs = {
  agreementKey: Scalars['String'];
};


export type QueryFundingSourcesArgs = {
  input: FundingSourcesInput;
};


export type QueryFutureLessonsArgs = {
  input: FutureLessonsInput;
};


export type QueryGetAgreementsDocumentsByPersonArgs = {
  personKey: Scalars['String'];
};


export type QueryGetArrearsDetailForPropositionArgs = {
  input: ArrearsDetailInput;
};


export type QueryGetCustomerAndFranchiseDocumentsByPersonArgs = {
  personKey: Scalars['String'];
};


export type QueryGetDirectDebitInstalmentsArgs = {
  input: GetDirectDebitInstalmentsInput;
};


export type QueryGetDocumentPermsionArgs = {
  documentKey: Scalars['String'];
};


export type QueryGetDocumentsByFranchiseArgs = {
  brand: Scalars['String'];
};


export type QueryGetDocumentUrlArgs = {
  bucket: Scalars['String'];
  document: Scalars['String'];
};


export type QueryGetFullAddressArgs = {
  id: Scalars['String'];
};


export type QueryGetPaymentDataArgs = {
  input: GetPaymentDataInput;
};


export type QueryInstructorArgs = {
  id: Scalars['ID'];
};


export type QueryInstructorsArgs = {
  instructorIds: Array<Scalars['ID']>;
};


export type QueryInstructorSearchArgs = {
  term: Scalars['String'];
};


export type QueryInvoiceUrlArgs = {
  transactionKey: Scalars['ID'];
};


export type QueryIsInstructorInArrearsArgs = {
  id: Scalars['ID'];
};


export type QueryLearnerArgs = {
  id: Scalars['ID'];
};


export type QueryLearnerProgressByPersonIdArgs = {
  personId: Scalars['Int'];
};


export type QueryLearnerTopUpDetailsArgs = {
  topUpId: Scalars['ID'];
};


export type QueryLessonArgs = {
  id: Scalars['ID'];
};


export type QueryMotabilityCustomerUploadUrlArgs = {
  fileName: Scalars['String'];
};


export type QueryMotabilityProductsArgs = {
  activeOnly: Scalars['Boolean'];
};


export type QueryNumberOfTicketsAssignedToUserByStatusArgs = {
  ticketStatus?: Maybe<TicketStatus>;
};


export type QueryOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryOrderNujArgs = {
  orderId: Scalars['ID'];
};


export type QueryOrderPaymentArgs = {
  id: Scalars['ID'];
};


export type QueryPropositionDiscountsArgs = {
  propositionIds: Array<Scalars['ID']>;
};


export type QueryPropositionProductsArgs = {
  propositionIds: Array<Scalars['ID']>;
};


export type QueryPurchasablePropositionsArgs = {
  type: PurchasablePropositionType;
};


export type QueryQueueProductivityArgs = {
  queueDescription?: Maybe<Scalars['String']>;
};


export type QueryQueueTicketCountArgs = {
  queueDescription?: Maybe<Scalars['String']>;
};


export type QueryQueueTicketCountWithStatusArgs = {
  queueDescription: Scalars['String'];
  ticketStatus: Scalars['String'];
};


export type QueryRandomInstructorsArgs = {
  input: RandomInstructorsInput;
};


export type QueryRegionArgs = {
  id: Scalars['ID'];
};


export type QueryRegionLookupArgs = {
  term: Scalars['String'];
};


export type QueryRegionsArgs = {
  input?: Maybe<RegionInput>;
};


export type QueryRetrieveDocumentUrLsArgs = {
  documentRevisionKeys: Array<Maybe<Scalars['String']>>;
};


export type QuerySearchForAddressArgs = {
  isExactMatch?: Maybe<Scalars['Boolean']>;
  term: Scalars['String'];
};


export type QuerySearchVehiclesArgs = {
  input: SearchVehiclesInput;
};


export type QuerySendEmailArgs = {
  brand: Scalars['String'];
  email: Scalars['String'];
  instructorName: Scalars['String'];
  link: Scalars['String'];
  personKey: Scalars['String'];
  productName: Scalars['String'];
};


export type QueryTeamMembersAssignedToQueueArgs = {
  queueDescription: Scalars['String'];
};


export type QueryTicketArgs = {
  ticketKey: Scalars['ID'];
};


export type QueryTicketsAssignedToStaffMemberArgs = {
  input: TicketsAssignedToStaffMemberInput;
};


export type QueryTicketsForCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryTicketsInQueueArgs = {
  input: TicketsInQueueInput;
};


export type QueryTimeOffArgs = {
  id: Scalars['ID'];
};


export type QueryTimeSlotsAvailabilityArgs = {
  input: TimeSlotsAvailabilityInput;
};


export type QueryVehicleArgs = {
  input: GetVehicleDetailsInput;
};


export type QueryVehiclesByAssignmentTypeArgs = {
  input: VehiclesByAssignmentTypeInput;
};


export type QueryVehiclesCountByAssignmentTypeArgs = {
  input: VehiclesByAssignmentTypeCountInput;
};


export type QueryWelfareArgs = {
  id: Scalars['ID'];
};


export type QueryWelfaresByPersonIdArgs = {
  id: Scalars['ID'];
};

export type QueueProductivity = {
  __typename?: 'QueueProductivity';
  closedToday: Scalars['Int'];
  pickedUpToday: Scalars['Int'];
};

export type RandomInstructorsInput = {
  brand?: Maybe<Brand>;
  count?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['String']>;
};

export type ReassignTicketInput = {
  queueKey: Scalars['ID'];
  ticketKey: Scalars['ID'];
  userId?: Maybe<Scalars['String']>;
};

export type Reference = {
  __typename?: 'Reference';
  active?: Maybe<Scalars['Boolean']>;
  itemDescription: Scalars['String'];
  referenceId: Scalars['Int'];
  referenceItemId: Scalars['Int'];
};

export type RefundOrderInput = {
  businessFunded?: Maybe<Scalars['Boolean']>;
  orderPaymentId: Scalars['ID'];
  refundBookingFee: Scalars['Boolean'];
};

export type Region = {
  __typename?: 'Region';
  discounts: Array<Discount>;
  districts: Array<Maybe<District>>;
  id: Scalars['ID'];
  pricing?: Maybe<Array<Maybe<RegionPricing>>>;
  regionCode: Scalars['String'];
  regionName: Scalars['String'];
};


export type RegionDiscountsArgs = {
  input?: Maybe<DiscountsInput>;
};

export type RegionCoveragePricing = {
  __typename?: 'RegionCoveragePricing';
  id: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  productCategory: ProductCategory;
};

export type RegionInput = {
  regionCode: Scalars['String'];
};

export type RegionLookup = {
  __typename?: 'RegionLookup';
  discounts?: Maybe<Array<Maybe<Discount>>>;
  districts: Array<Maybe<District>>;
  id: Scalars['ID'];
  pricing?: Maybe<Array<Maybe<RegionPricing>>>;
  regionCode: Scalars['String'];
  regionName: Scalars['String'];
};


export type RegionLookupDiscountsArgs = {
  input?: Maybe<DiscountsInput>;
};


export type RegionLookupPricingArgs = {
  input?: Maybe<RegionPricingInput>;
};

export type RegionPricing = {
  __typename?: 'RegionPricing';
  franchiseType: FranchiseType;
  id: Scalars['ID'];
  maxPrice?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
  productCategory?: Maybe<ProductCategory>;
  recommendedPrice?: Maybe<Scalars['Float']>;
};

export type RegionPricingInput = {
  propositionId: Scalars['ID'];
};

export type RegionalDiscountsInput = {
  discountId: Scalars['ID'];
  optIn: Scalars['Boolean'];
};

export type RejectLearnerInput = {
  instructorId: Scalars['ID'];
  learnerId: Scalars['ID'];
  rejectionReason: Scalars['String'];
};

export enum Relationship {
  Child = 'Child',
  Other = 'Other',
  Partner = 'Partner',
  Spouse = 'Spouse'
}

export type RemovePendingFranchiseInput = {
  propositionAllocationId: Scalars['ID'];
};

export type RemoveVehicleAssignmentInput = {
  vehicleAssignmentId: Scalars['ID'];
  vehicleType: VehicleType;
};

export type RemoveVehicleInput = {
  vehiclePersonHistoryKey: Scalars['ID'];
};

export type RenewFranchiseInput = {
  currentLineItemKey: Scalars['ID'];
  franchiseStartDate: Scalars['GraphQLDate'];
  instructorKey: Scalars['ID'];
  isPending?: Maybe<Scalars['Boolean']>;
  newFranchiseKey: Scalars['ID'];
};

export enum RepeatFrequency {
  Daily = 'Daily',
  Fortnightly = 'Fortnightly',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type RepeatSchedule = {
  endDate: Scalars['GraphQLDate'];
  frequency: RepeatFrequency;
  startDate: Scalars['GraphQLDate'];
};

export type RequestNewInstructorInput = {
  pupilId: Scalars['ID'];
};

export type RequestNewInstructorResult = {
  __typename?: 'RequestNewInstructorResult';
  errorMessage?: Maybe<Scalars['String']>;
  successful: Scalars['Boolean'];
};

export type RequestNewInstructorWithReasonInput = {
  pupilId: Scalars['ID'];
  reason: ChangeInstructorReason;
};

export type RequestNewInstructorWithReasonResult = {
  __typename?: 'RequestNewInstructorWithReasonResult';
  errorMessage?: Maybe<Scalars['String']>;
  successful: Scalars['Boolean'];
};

export type RequestRefundWithReasonInput = {
  pupilId: Scalars['ID'];
  reason: Scalars['String'];
};

export type RequestRefundWithReasonResult = {
  __typename?: 'RequestRefundWithReasonResult';
  errorMessage?: Maybe<Scalars['String']>;
  successful: Scalars['Boolean'];
};

export type ReviewAgreementDocumentInput = {
  expirationDate?: Maybe<Scalars['GraphQLDate']>;
  franchiseAgreementDocumentKey: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  newAgreementDocumentStatus: AgreementDocumentStatus;
};

export enum Role {
  Agent = 'AGENT',
  Instructor = 'INSTRUCTOR',
  Learner = 'LEARNER',
  LearnerJourney = 'LEARNER_JOURNEY',
  Microservice = 'MICROSERVICE',
  Public = 'PUBLIC'
}

export type RujCancelledLesson = {
  __typename?: 'RujCancelledLesson';
  errorType?: Maybe<Scalars['String']>;
  lessonTime?: Maybe<Scalars['GraphQLDate']>;
  successful: Scalars['Boolean'];
};

export type RujLoginInput = {
  brand: Brand;
  clientId?: Maybe<Scalars['String']>;
  dateOfBirth: Scalars['GraphQLDate'];
  pupilId: Scalars['ID'];
  surname: Scalars['String'];
};

export type RujLoginResult = {
  __typename?: 'RujLoginResult';
  accessToken?: Maybe<Scalars['String']>;
  attemptsRemaining?: Maybe<Scalars['Int']>;
  authenticated: Scalars['Boolean'];
  customerTypes?: Maybe<Array<CustomerType>>;
  encryptedPersonId?: Maybe<Scalars['String']>;
  lockoutExpiry?: Maybe<Scalars['GraphQLDate']>;
};

export enum Salutation {
  Dr = 'Dr',
  Master = 'Master',
  Miss = 'Miss',
  Mr = 'Mr',
  Mrs = 'Mrs',
  Ms = 'Ms',
  Mx = 'Mx'
}

export type SearchAddress = {
  __typename?: 'SearchAddress';
  country: Scalars['String'];
  county: Scalars['String'];
  id: Scalars['ID'];
  line1: Scalars['String'];
  line2: Scalars['String'];
  postcode: Scalars['String'];
  town: Scalars['String'];
};

export type SearchVehiclesInput = {
  identifiers: Array<VehicleIdentifier>;
  term: Scalars['String'];
};

export type Sector = {
  __typename?: 'Sector';
  covered?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  sectorCode: Scalars['String'];
};

export type SendComplaintEmailInput = {
  brand: Brand;
  complaintDetails: Scalars['String'];
  complaintResolution: Scalars['String'];
  reason: Scalars['String'];
  requiresResponse: Scalars['Boolean'];
};

export type SendMotabilityWelcomeEmailInput = {
  instructorId: Scalars['ID'];
  learnerId: Scalars['ID'];
};

export type SendPupilhelpEmailInput = {
  brand: Brand;
  message: Scalars['String'];
};

export enum Service {
  DrivingLessons = 'DrivingLessons',
  Helix = 'Helix',
  Hubcapp = 'Hubcapp'
}

export type SignedUrl = {
  __typename?: 'SignedUrl';
  documentName: Scalars['String'];
  url: Scalars['String'];
};

export type Skill = {
  __typename?: 'Skill';
  id: Scalars['ID'];
  score: Scalars['Int'];
  skillName: Scalars['String'];
};

export type SkillCategory = {
  __typename?: 'SkillCategory';
  categoryName: Scalars['String'];
  id: Scalars['ID'];
  progressPercentage: Scalars['Float'];
  skills: Array<Maybe<Skill>>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SpokenLanguage = {
  __typename?: 'SpokenLanguage';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  person: Person;
};

export type Staff = {
  __typename?: 'Staff';
  createdOn?: Maybe<Scalars['GraphQLDate']>;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  userId: Scalars['String'];
};

export type StaffMember = {
  __typename?: 'StaffMember';
  createdOn?: Maybe<Scalars['GraphQLDate']>;
  displayName?: Maybe<Scalars['String']>;
  staffKey: Scalars['ID'];
  userId?: Maybe<Scalars['String']>;
};

export type StaffMemberTicket = {
  __typename?: 'StaffMemberTicket';
  customer: Customer;
  displayName: Scalars['String'];
  fromDate: Scalars['GraphQLDate'];
  id: Scalars['ID'];
  status: TicketStatus;
  ticketId: Scalars['Int'];
  userCanRead: Scalars['Boolean'];
  userCanWrite: Scalars['Boolean'];
  workItemDescription?: Maybe<Scalars['String']>;
};

export enum StudyFormat {
  FullTime = 'FullTime',
  PartTime = 'PartTime',
  Tbc = 'TBC'
}

export type TemplateDocument = {
  __typename?: 'TemplateDocument';
  buffers?: Maybe<Buffers>;
  documentKey: Scalars['String'];
  documentName: Scalars['String'];
  documentRevisionKey: Scalars['String'];
};

export type Test = {
  __typename?: 'Test';
  date: Scalars['GraphQLDate'];
  editable: Scalars['Boolean'];
  id: Scalars['ID'];
  learner: Learner;
  status: TestStatus;
  type: TestType;
};

export type TestCentre = {
  __typename?: 'TestCentre';
  county: Scalars['String'];
  id: Scalars['ID'];
  line1: Scalars['String'];
  line2: Scalars['String'];
  lineItemKey?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  postcode: Scalars['Postcode'];
  town: Scalars['String'];
};

export type TestDeleteResponse = {
  __typename?: 'TestDeleteResponse';
  id: Scalars['ID'];
};

export enum TestStatus {
  Cancelled = 'Cancelled',
  Failed = 'Failed',
  Passed = 'Passed',
  Pending = 'Pending',
  Upcoming = 'Upcoming'
}

export enum TestType {
  Dit1 = 'DIT1',
  Dit2 = 'DIT2',
  Dit3 = 'DIT3',
  Practical = 'Practical',
  Theory = 'Theory'
}

export type Ticket = {
  __typename?: 'Ticket';
  assignedTo?: Maybe<StaffMember>;
  description?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['GraphQLDate']>;
  id: Scalars['ID'];
  person: Customer;
  personKey: Scalars['String'];
  queue?: Maybe<TicketQueue>;
  status?: Maybe<TicketStatus>;
  ticketId?: Maybe<Scalars['Int']>;
  ticketType: Scalars['String'];
  workItems: Array<WorkItem>;
};

export type TicketQueue = {
  __typename?: 'TicketQueue';
  amountOpen: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  unassignedAmount: Scalars['Int'];
};

export enum TicketQueueType {
  Approval = 'Approval',
  Complaint = 'Complaint',
  Ditig = 'DITIG',
  DitSupport = 'DITSupport',
  Finance = 'Finance',
  Fleet = 'Fleet',
  FranchiseAdmin = 'FranchiseAdmin',
  InstructorService = 'InstructorService',
  LearnerService = 'LearnerService',
  Motability = 'Motability',
  Reallocation = 'Reallocation',
  RefundRequests = 'RefundRequests',
  Retention = 'Retention'
}

export enum TicketSource {
  AAdvance = 'AAdvance',
  App = 'App',
  ContactForm = 'ContactForm',
  Email = 'Email',
  Message = 'Message',
  OnlinePurchaseJourney = 'OnlinePurchaseJourney',
  Phone = 'Phone',
  Ruj = 'RUJ'
}

export enum TicketStatus {
  Active = 'Active',
  Closed = 'Closed',
  InProgress = 'InProgress'
}

export type TicketsAssignedToStaffMemberInput = {
  offset: Scalars['Int'];
  rowsPerPage: Scalars['Int'];
  sort: Scalars['String'];
  ticketStatus: TicketStatus;
};

export type TicketsInQueueInput = {
  offset: Scalars['Int'];
  queueDescription: Scalars['String'];
  rowsPerPage: Scalars['Int'];
  sort: Scalars['String'];
  ticketStatus: TicketStatus;
};

export type TimeOff = {
  __typename?: 'TimeOff';
  created: Scalars['GraphQLDate'];
  endDateTime: Scalars['GraphQLDate'];
  id: Scalars['ID'];
  instructor: Instructor;
  notes?: Maybe<Scalars['String']>;
  repeatFrequency?: Maybe<RepeatFrequency>;
  startDateTime: Scalars['GraphQLDate'];
  status: TimeOffStatus;
};

export enum TimeOffStatus {
  Active = 'Active',
  Cancelled = 'Cancelled'
}

export type TimeSlot = {
  __typename?: 'TimeSlot';
  availability: Scalars['Boolean'];
  bookedPendingLessonId?: Maybe<Scalars['ID']>;
  endDateTime: Scalars['GraphQLDate'];
  insufficientBalanceForBooking?: Maybe<Scalars['Boolean']>;
  prepaid: Scalars['Boolean'];
  startDateTime: Scalars['GraphQLDate'];
  travelTime: Scalars['Int'];
};

export type TimeSlotInput = {
  endDateTime: Scalars['GraphQLDate'];
  startDateTime: Scalars['GraphQLDate'];
  travelTime: Scalars['Int'];
};

export type TimeSlotNuj = {
  __typename?: 'TimeSlotNUJ';
  endTime: Scalars['GraphQLDate'];
  startTime: Scalars['GraphQLDate'];
};

export type TimeSlotsAvailabilityInput = {
  ignoreCalendarItemIds?: Maybe<Array<Scalars['ID']>>;
  ignoreTimeOff?: Maybe<Scalars['Boolean']>;
  ignoreWorkHours?: Maybe<Scalars['Boolean']>;
  instructorKey: Scalars['ID'];
  learnerKey: Scalars['ID'];
  prebook?: Maybe<Scalars['Boolean']>;
  timeSlots: Array<TimeSlotInput>;
};

export type ToggleDirectDebitMandateInput = {
  active: Scalars['Boolean'];
  personKey: Scalars['ID'];
};

export enum TransmissionType {
  Automatic = 'Automatic',
  Manual = 'Manual'
}

export type TravelTime = {
  __typename?: 'TravelTime';
  id: Scalars['ID'];
  lineItemKey?: Maybe<Scalars['ID']>;
  proposition?: Maybe<Proposition>;
  time: Scalars['String'];
  travelTimeId: Scalars['ID'];
};

export type UndoEndFranchiseInput = {
  instructorId: Scalars['ID'];
  lockCap: Scalars['Boolean'];
  propositionAllocationId: Scalars['ID'];
};

export type UpdateAllocationInput = {
  allocationKey: Scalars['ID'];
  lessonType?: Maybe<LearnerLessonType>;
  rate?: Maybe<Scalars['Float']>;
};

export type UpdateAreaCoverageInput = {
  discounts?: Maybe<Array<Maybe<RegionalDiscountsInput>>>;
  districtOptIns?: Maybe<Array<Maybe<UpdateDistrictInput>>>;
  lineItemCoverageId: Scalars['String'];
  postTestPrice?: Maybe<Scalars['Float']>;
  preTestPrice?: Maybe<Scalars['Float']>;
};

export type UpdateAreaCoverageResult = {
  __typename?: 'UpdateAreaCoverageResult';
  discounts?: Maybe<Array<Maybe<DiscountUpdateResult>>>;
  pricing?: Maybe<PricingUpdateResult>;
  region?: Maybe<UpdateRegionResponse>;
};

export type UpdateCertificationInput = {
  id: Scalars['ID'];
  reference?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['GraphQLDate']>;
};

export type UpdateCoveragePricingInput = {
  automaticPrice?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  manualPrice?: Maybe<Scalars['String']>;
  shortNoticePrice?: Maybe<Scalars['String']>;
};

export type UpdateDistrictInput = {
  covered?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  sectorOptOuts?: Maybe<Array<Maybe<UpdateSectorInput>>>;
};

export type UpdateFranchiseDateInput = {
  instructorId: Scalars['ID'];
  newDate: Scalars['GraphQLDate'];
  propositionAllocationId: Scalars['ID'];
};

export type UpdateFranchiseRegulationStatusInput = {
  newRegulationStatus: FcaAgreementStatus;
  propositionAllocationId: Scalars['ID'];
};

export type UpdateInstructorInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressPostcode?: Maybe<Scalars['String']>;
  addressTown?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactLandline?: Maybe<Scalars['String']>;
  contactMobile?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['GraphQLDate']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Salutation>;
  vehicleMileage?: Maybe<UpdatedVehicleMileage>;
};

export type UpdateInstructorPreferencesInput = {
  capLock?: Maybe<Scalars['Boolean']>;
  instructorId: Scalars['ID'];
  learnerCapacity?: Maybe<Scalars['Int']>;
  lessonTypes?: Maybe<Array<Maybe<LessonTypePreferenceInput>>>;
  propositionAllocationId: Scalars['ID'];
  testCentres?: Maybe<Array<Scalars['ID']>>;
  travelTime?: Maybe<Scalars['Int']>;
  userType: UserType;
  workingHours?: Maybe<Array<Maybe<DayInput>>>;
};

export type UpdateInsuranceHistoryInput = {
  certificateNumber: Scalars['String'];
  endDate: Scalars['GraphQLDate'];
  id?: Maybe<Scalars['ID']>;
  instructorId: Scalars['ID'];
  startDate: Scalars['GraphQLDate'];
  vehicleId: Scalars['ID'];
};

export type UpdateInsuranceInput = {
  certificateNumber: Scalars['String'];
  endDate: Scalars['GraphQLDate'];
  id: Scalars['String'];
  instructorId: Scalars['String'];
  startDate: Scalars['GraphQLDate'];
};

export type UpdateInvoiceStatusInput = {
  notes?: Maybe<Scalars['String']>;
  paid: Scalars['Boolean'];
  transactionId: Scalars['ID'];
};

export type UpdateLeadInput = {
  address?: Maybe<AddressInput>;
  channel?: Maybe<TicketSource>;
  communication?: Maybe<CommunicationConsentInput>;
  dateOfBirth?: Maybe<Scalars['GraphQLDate']>;
  emailAddress: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  licenceNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  recommenderId?: Maybe<Scalars['Int']>;
  salutation?: Maybe<Salutation>;
};

export type UpdateLearnerInput = {
  addressKey?: Maybe<Scalars['ID']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressPostcode?: Maybe<Scalars['String']>;
  addressTown?: Maybe<Scalars['String']>;
  appointedBy?: Maybe<Scalars['String']>;
  billingAddressUpdate?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactLandline?: Maybe<Scalars['String']>;
  contactMobile?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['GraphQLDate']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  pickupAddressUpdate?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Salutation>;
};

export type UpdateLearnerProgressInput = {
  learnerId: Scalars['ID'];
  skills: Array<Maybe<UpdateLearnerProgressSkillInput>>;
};

export type UpdateLearnerProgressSkillInput = {
  id: Scalars['ID'];
  score: Scalars['Int'];
};

export type UpdateLessonInput = {
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
};

export type UpdateMileageInput = {
  mileage: Scalars['Int'];
  vehicleKey: Scalars['ID'];
};

export type UpdateMotabilityAllocationInput = {
  currentProductId: Scalars['ID'];
  learnerId: Scalars['ID'];
  newProductId: Scalars['ID'];
  transmissionType?: Maybe<TransmissionType>;
};

export type UpdateRegionCoveragePriceInput = {
  id: Scalars['ID'];
  price: Scalars['Float'];
};

export type UpdateRegionPriceInput = {
  id: Scalars['ID'];
  price: Scalars['Float'];
};

export type UpdateRegionResponse = {
  __typename?: 'UpdateRegionResponse';
  id: Scalars['ID'];
};

export type UpdateSectorInput = {
  id: Scalars['ID'];
  optedOut?: Maybe<Scalars['Boolean']>;
};

export type UpdateTimeOffInput = {
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
};

export type UpdateWelfareInput = {
  dateTo?: Maybe<Scalars['GraphQLDate']>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  outcomes?: Maybe<Array<Maybe<UpdateWelfareOutcomeInput>>>;
};

export type UpdateWelfareOutcomeInput = {
  id?: Maybe<Scalars['ID']>;
  note: Scalars['String'];
  outcomeTypeId?: Maybe<Scalars['Int']>;
};

export type UpdatedLearnerCapacity = {
  maximum: Scalars['Int'];
};

export type UpdatedMotabilityAllocation = {
  __typename?: 'UpdatedMotabilityAllocation';
  currentProductId: Scalars['ID'];
  learnerId: Scalars['ID'];
  newProductId: Scalars['ID'];
  transmissionType?: Maybe<TransmissionType>;
};

export type UpdatedVehicleMileage = {
  id: Scalars['ID'];
  mileage: Scalars['Int'];
};


export enum UserType {
  Business = 'Business',
  Instructor = 'Instructor'
}

export type Vat = {
  __typename?: 'Vat';
  id: Scalars['ID'];
  rate?: Maybe<Scalars['Float']>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  colour: Scalars['String'];
  fuelType: FuelType;
  id: Scalars['ID'];
  instructor?: Maybe<Instructor>;
  insurance?: Maybe<Insurance>;
  manufacturer: Scalars['String'];
  mileage?: Maybe<Array<Mileage>>;
  model: Scalars['String'];
  registration: Scalars['String'];
  specification: Scalars['String'];
  transmissionType: TransmissionType;
  vehicleBands: Array<VehicleBand>;
  vehicleType: VehicleType;
};

export enum VehicleAdaptions {
  No = 'No',
  Yes = 'Yes'
}

export type VehicleAssignment = {
  __typename?: 'VehicleAssignment';
  active: Scalars['Boolean'];
  assignmentDate: Scalars['GraphQLDate'];
  id: Scalars['ID'];
  instructor?: Maybe<Instructor>;
  vehicle: Vehicle;
};

export enum VehicleAssignmentType {
  Courtesy = 'Courtesy',
  Franchise = 'Franchise',
  Interim = 'Interim'
}

export type VehicleBand = {
  __typename?: 'VehicleBand';
  name: Scalars['String'];
};

export enum VehicleIdentifier {
  OrderId = 'OrderID',
  Registration = 'Registration',
  Vin = 'VIN'
}

export type VehicleInformation = {
  __typename?: 'VehicleInformation';
  brandAdaptions?: Maybe<BrandAdaptions>;
  colour?: Maybe<Scalars['String']>;
  design?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelType>;
  id: Scalars['ID'];
  livery?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  order?: Maybe<VehicleOrder>;
  orderId?: Maybe<Scalars['Int']>;
  ownershipHistory?: Maybe<Array<OwnershipHistory>>;
  poolHistory?: Maybe<Array<PoolHistory>>;
  priceBand?: Maybe<Scalars['String']>;
  registration?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  totalMileage?: Maybe<Scalars['Int']>;
  totalTimeWithADI?: Maybe<Scalars['Int']>;
  transmissionType?: Maybe<TransmissionType>;
  VIN?: Maybe<Scalars['String']>;
};

export type VehicleOrder = {
  __typename?: 'VehicleOrder';
  createdDate?: Maybe<Scalars['GraphQLDate']>;
  id: Scalars['ID'];
  orderDate?: Maybe<Scalars['GraphQLDate']>;
  orderId: Scalars['Int'];
};

export type VehiclePersonHistory = {
  __typename?: 'VehiclePersonHistory';
  active?: Maybe<Scalars['Boolean']>;
  fromDate?: Maybe<Scalars['GraphQLDate']>;
  personKey?: Maybe<Scalars['ID']>;
  toDate?: Maybe<Scalars['GraphQLDate']>;
  vehicleKey?: Maybe<Scalars['ID']>;
  vehiclePersonHistoryKey: Scalars['ID'];
};

export enum VehiclePool {
  Courtesy = 'Courtesy',
  Interim = 'Interim',
  OnlineSales = 'OnlineSales',
  Renewals = 'Renewals',
  Sales = 'Sales',
  Unassigned = 'Unassigned'
}

export enum VehicleSortField {
  TimeWithAdi = 'TimeWithADI',
  TotalMileage = 'TotalMileage'
}

export enum VehicleType {
  Business = 'Business',
  Instructor = 'Instructor'
}

export type VehiclesByAssignmentTypeCountInput = {
  assignmentType: VehicleAssignmentType;
  searchVehicleKeys?: Maybe<Array<Scalars['ID']>>;
};

export type VehiclesByAssignmentTypeInput = {
  assignmentType: VehicleAssignmentType;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchVehicleKeys?: Maybe<Array<Scalars['ID']>>;
  sortBy?: Maybe<VehicleSortField>;
  sortOrder?: Maybe<SortOrder>;
};

export type Welfare = {
  __typename?: 'Welfare';
  category?: Maybe<WelfareCategoryType>;
  dateFrom?: Maybe<Scalars['GraphQLDate']>;
  dateTo?: Maybe<Scalars['GraphQLDate']>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  outcomes?: Maybe<Array<Maybe<WelfareOutcome>>>;
  personKey?: Maybe<Scalars['ID']>;
};

export type WelfareCategoryType = {
  __typename?: 'WelfareCategoryType';
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};

export type WelfareOutcome = {
  __typename?: 'WelfareOutcome';
  dateFrom?: Maybe<Scalars['GraphQLDate']>;
  dateTo?: Maybe<Scalars['GraphQLDate']>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  outcomeTypeId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};

export type WelfareOutcomeType = {
  __typename?: 'WelfareOutcomeType';
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};

export type WorkItem = {
  __typename?: 'WorkItem';
  confidence: Scalars['Float'];
  estimatedDate?: Maybe<Scalars['GraphQLDate']>;
  id: Scalars['ID'];
  initialContactMethod: InitialContactMethod;
  note?: Maybe<Scalars['String']>;
  person: Customer;
  schedule?: Maybe<WorkItemSchedule>;
  status: WorkItemStatus;
  ticket: Ticket;
  type: WorkItemType;
};

export type WorkItemInput = {
  confidence?: Maybe<Scalars['Float']>;
  estimatedDate?: Maybe<Scalars['GraphQLDate']>;
  note?: Maybe<Scalars['String']>;
  scheduleFrom?: Maybe<Scalars['GraphQLDate']>;
  scheduleTo?: Maybe<Scalars['GraphQLDate']>;
  status: WorkItemStatus;
  ticketKey: Scalars['ID'];
  workItemKey?: Maybe<Scalars['ID']>;
  workItemType?: Maybe<WorkItemType>;
};

export type WorkItemSchedule = {
  __typename?: 'WorkItemSchedule';
  fromDate: Scalars['GraphQLDate'];
  id: Scalars['ID'];
  toDate: Scalars['GraphQLDate'];
};

export enum WorkItemStatus {
  Complete = 'Complete',
  InProgress = 'InProgress',
  Scheduled = 'Scheduled',
  Waiting = 'Waiting'
}

export enum WorkItemType {
  AandSApplicationReceived = 'AandSApplicationReceived',
  AandSApplicationSent = 'AandSApplicationSent',
  AandSClaimFormSent = 'AandSClaimFormSent',
  Accounts = 'Accounts',
  AccountsDepositRefunded = 'AccountsDepositRefunded',
  AccountsPayPlanSetUp = 'AccountsPayPlanSetUp',
  AdiCallBackRequest = 'ADICallBackRequest',
  AssociatedFranchisesEndedCheck = 'AssociatedFranchisesEndedCheck',
  AwaitingCreditCheck = 'AwaitingCreditCheck',
  BalanceQuery = 'BalanceQuery',
  BankDetailsUpdated = 'BankDetailsUpdated',
  BespokeRateCreated = 'BespokeRateCreated',
  BespokeRateRemoved = 'BespokeRateRemoved',
  BlueDualBrakeRequest = 'BlueDualBrakeRequest',
  BusinessSupportCall = 'BusinessSupportCall',
  CalendarUpdates = 'CalendarUpdates',
  Call1stNoContact = 'Call1stNoContact',
  Call2ndNoContact = 'Call2ndNoContact',
  Call3rdNoContact = 'Call3rdNoContact',
  Call4thNoContact = 'Call4thNoContact',
  Call5thNoContact = 'Call5thNoContact',
  CallAbandoned = 'CallAbandoned',
  CallBackNotReceived = 'CallBackNotReceived',
  CallBackRequest = 'CallBackRequest',
  CallContacted = 'CallContacted',
  CallInbound = 'CallInbound',
  CallListeningRequest = 'CallListeningRequest',
  CallReengagement = 'CallReengagement',
  CancellationRequest = 'CancellationRequest',
  Capability = 'Capability',
  CloseAccount = 'CloseAccount',
  Commendation = 'Commendation',
  ComplaintAdi = 'ComplaintADI',
  ComplaintCsc = 'ComplaintCSC',
  ComplaintFleet = 'ComplaintFleet',
  ComplaintMotability = 'ComplaintMotability',
  ComplaintNotLogged = 'ComplaintNotLogged',
  ComplaintsProcess = 'ComplaintsProcess',
  ConsolidationCall = 'ConsolidationCall',
  CoverageUpdated = 'CoverageUpdated',
  Covid19BalanceWithAccounts = 'COVID19BalanceWithAccounts',
  Covid19DeferredBalanceRepaid = 'COVID19DeferredBalanceRepaid',
  CreateNewPupil = 'CreateNewPupil',
  CreateSelfSourcedPupil = 'CreateSelfSourcedPupil',
  CreDitCheckCompleted = 'CreDITCheckCompleted',
  CrossSaleAabsm = 'CrossSaleAABSM',
  CscTelephoneOptionsIvr = 'CSCTelephoneOptionsIVR',
  CscWaitTimeResponseTime = 'CSCWaitTimeResponseTime',
  DbsContact = 'DBSContact',
  DbsDuetoExpire = 'DBSDuetoExpire',
  DbsExperianApplication = 'DBSExperianApplication',
  DbsExpired = 'DBSExpired',
  DbsUpdated = 'DBSUpdated',
  DebtAdhocPaymentMade = 'DebtADHOCPaymentMade',
  DebtConfirmationOfSuspensionSent = 'DebtConfirmationOfSuspensionSent',
  DebtContactWk1 = 'DebtContactWk1',
  DebtContactWk2 = 'DebtContactWk2',
  DebtContactWk3 = 'DebtContactWk3',
  DebtContactWk4 = 'DebtContactWk4',
  DebtDcaReferralWarningSent = 'DebtDCAReferralWarningSent',
  DebtDefaultNoticeSent = 'DebtDefaultNoticeSent',
  DebtFinancialStatementRequested = 'DebtFinancialStatementRequested',
  DebtNosiaSent = 'DebtNOSIASent',
  DebtRecoveryProcess = 'DebtRecoveryProcess',
  DebtReferredToDca = 'DebtReferredToDCA',
  DebtSuspensionWarningSent = 'DebtSuspensionWarningSent',
  DebtTerminationSaved = 'DebtTerminationSaved',
  DebtTerminationWarningSent = 'DebtTerminationWarningSent',
  DefermentPlanCreated = 'DefermentPlanCreated',
  DefermentQuery = 'DefermentQuery',
  DetailsUpdated = 'DetailsUpdated',
  DiaryManagement = 'DiaryManagement',
  DirectDebitCancelled = 'DirectDebitCancelled',
  DirectDebitReinstate = 'DirectDebitReinstate',
  DirectDebitRtp = 'DirectDebitRTP',
  DirectDebitUpdateDetails = 'DirectDebitUpdateDetails',
  DitDrivingTestResult = 'DITDrivingTestResult',
  DitInitialSetUp = 'DITInitialSetUp',
  DitInstructionalTestResult = 'DITInstructionalTestResult',
  DitNoContact1stAttempt = 'DITNoContact1stAttempt',
  DitNoContact2ndAttempt = 'DITNoContact2ndAttempt',
  DitNoContact3rdAttempt = 'DITNoContact3rdAttempt',
  DitNoContact4thAttempt = 'DITNoContact4thAttempt',
  DitNoContact5thAttempt = 'DITNoContact5thAttempt',
  DitntuDeclinedbytheBusiness = 'DITNTUDeclinedbytheBusiness',
  DitntuFailedtoMakeContactbyPhoneEndofContactStrategy = 'DITNTUFailedtoMakeContactbyPhoneEndofContactStrategy',
  DitntuFailedtoMakeContactbyPhoneIncorrectNumber = 'DITNTUFailedtoMakeContactbyPhoneIncorrectNumber',
  DitntuGonewithaCompetitor = 'DITNTUGonewithaCompetitor',
  DitntuNotEligible = 'DITNTUNotEligible',
  DitntuStayingwithCurrentEmployer = 'DITNTUStayingwithCurrentEmployer',
  DitntuTakenEmploymentElsewhere = 'DITNTUTakenEmploymentElsewhere',
  DitntuTooExpensive = 'DITNTUTooExpensive',
  DitntuTrainerNotAvailable = 'DITNTUTrainerNotAvailable',
  DitntuTrainingIsTooLong = 'DITNTUTrainingIsTooLong',
  DitntuUnwillingtoTravel = 'DITNTUUnwillingtoTravel',
  DitReallocation = 'DITReallocation',
  DitRefundRequestBereavement = 'DITRefundRequestBereavement',
  DitRenewalFleetOrderNewCar = 'DITRenewalFleetOrderNewCar',
  DitRenewalPdItoAdIadmin = 'DITRenewalPDItoADIadmin',
  DitRenewalPdItoAdiFranchiseKeyed = 'DITRenewalPDItoADIFranchiseKeyed',
  DitRenewalPdItoAdiInitialContact = 'DITRenewalPDItoADIInitialContact',
  DitResponsefromImiOffer = 'DITResponsefromImiOffer',
  DitRetentionFollowupContact = 'DITRetentionFollowupContact',
  DitRetentionInitialContact = 'DITRetentionInitialContact',
  DitRetentionIssueExitInterview = 'DITRetentionIssueExitInterview',
  DitRetentionLeaverChecklist = 'DITRetentionLeaverChecklist',
  DitRetentionOutcomeLeaving = 'DITRetentionOutcomeLeaving',
  DitRetentionOutcomeSaved = 'DITRetentionOutcomeSaved',
  DitRetentionRefundProcessed = 'DITRetentionRefundProcessed',
  DitStatusAgreed = 'DITStatusAgreed',
  DitStatusCreditCheck = 'DITStatusCreditCheck',
  DitStatusCreditCheckFailed = 'DITStatusCreditCheckFailed',
  DitStatusCreditCheckPassed = 'DITStatusCreditCheckPassed',
  DitStatusDead = 'DITStatusDead',
  DitStatusNew = 'DITStatusNew',
  DitStatusOngoing = 'DITStatusOngoing',
  DitStatusPaid = 'DITStatusPaid',
  DitStatusPaidWithDiscount = 'DITStatusPaidWithDiscount',
  DitStatusRefunded = 'DITStatusRefunded',
  DitStatusRejected = 'DITStatusRejected',
  DitSupportAccountManagerOther = 'DitSupportAccountManagerOther',
  DitSupportAccountManagerReview = 'DitSupportAccountManagerReview',
  DitSupportAccountManagerWelcome = 'DitSupportAccountManagerWelcome',
  DitSupportAdi21AtUpdateProvided = 'DITSupportADI21ATUpdateProvided',
  DitSupportAdi21tCompleted = 'DitSupportAdi21tCompleted',
  DitSupportAdi21tRequested = 'DitSupportAdi21tRequested',
  DitSupportAdi21TUpdateProvided = 'DITSupportADI21TUpdateProvided',
  DitSupportAdi2atCompleted = 'DitSupportAdi2atCompleted',
  DitSupportAdi2atRequested = 'DitSupportAdi2atRequested',
  DitSupportAdi3lmdCompleted = 'DitSupportAdi3lmdCompleted',
  DitSupportAdi3lmdRequested = 'DitSupportAdi3lmdRequested',
  DitSupportBsmVrBooking = 'DitSupportBsmVrBooking',
  DitSupportBsmVrCancel = 'DitSupportBsmVrCancel',
  DitSupportBsmvrQuery = 'DITSupportBSMVRQuery',
  DitSupportBsmVrReschedule = 'DitSupportBsmVrReschedule',
  DitSupportComplaint = 'DITSupportComplaint',
  DitSupportContactAttempted = 'DitSupportContactAttempted',
  DitSupportContinuingRights = 'DITSupportContinuingRights',
  DitSupportDbsApplicationSubmitted = 'DitSupportDbsApplicationSubmitted',
  DitSupportDbsFollowUp = 'DitSupportDbsFollowUp',
  DitSupportDbsRequested = 'DitSupportDbsRequested',
  DitSupportDormant = 'DITSupportDormant',
  DitSupportFeefo = 'DITSupportFeefo',
  DitSupportInCarBooking = 'DitSupportInCarBooking',
  DitSupportLookingToleave = 'DitSupportLookingToleave',
  DitSupportOnlineClassroomBooking = 'DitSupportOnlineClassroomBooking',
  DitSupportOther = 'DitSupportOther',
  DitSupportPart1Support = 'DitSupportPart1Support',
  DitSupportPart1TestBooked = 'DitSupportPart1TestBooked',
  DitSupportPart1TestResult = 'DitSupportPart1TestResult',
  DitSupportPart2Support = 'DitSupportPart2Support',
  DitSupportPart2TestBooked = 'DitSupportPart2TestBooked',
  DitSupportPart2TestResult = 'DitSupportPart2TestResult',
  DitSupportPart3Support = 'DitSupportPart3Support',
  DitSupportPart3TestBooked = 'DitSupportPart3TestBooked',
  DitSupportPart3TestResult = 'DitSupportPart3TestResult',
  DitSupportPrnApplicationSubmitted = 'DitSupportPrnApplicationSubmitted',
  DitSupportPrnFollowUp = 'DitSupportPrnFollowUp',
  DitSupportPrnRequested = 'DitSupportPrnRequested',
  DitSupportRefundChangeofCircumstances = 'DITSupportRefundChangeofCircumstances',
  DitSupportRefundMedicalReasons = 'DITSupportRefundMedicalReasons',
  DitSupportRefundUnhappywithCourse = 'DITSupportRefundUnhappywithCourse',
  DitSupportRefundUnhappywithTrainer = 'DITSupportRefundUnhappywithTrainer',
  DitSupportRefundWithinCoolingoffPeriod = 'DITSupportRefundWithinCoolingoffPeriod',
  DitSupportStoppedTuition = 'DITSupportStoppedTuition',
  DitSupportThrive = 'DITSupportThrive',
  DitSupportTopUp = 'DITSupportTopUp',
  DitSupportTraineeLicenceRegisteredInterest = 'DitSupportTraineeLicenceRegisteredInterest',
  DitSupportTrainerAllocation = 'DitSupportTrainerAllocation',
  DitSupportTrainerReallocation = 'DitSupportTrainerReallocation',
  DitTheoryCertificateHasExpired = 'DITTheoryCertificateHasExpired',
  DitTheoryTestResult = 'DITTheoryTestResult',
  DitTrainerAllocation = 'DITTrainerAllocation',
  DocumentToExpire = 'DocumentToExpire',
  DpaChecks = 'DPAChecks',
  DriveConfidentAccidentResponse = 'DriveConfidentAccidentResponse',
  DriveConfidentMla = 'DriveConfidentMLA',
  DriveConfidentSaga = 'DriveConfidentSAGA',
  DrivingInstructorTraineeComplaintCsc = 'DrivingInstructorTraineeComplaintCSC',
  DrivingInstructorTraineeComplaintOther = 'DrivingInstructorTraineeComplaintOther',
  DrivingInstructorTraineeComplaintProductPackage = 'DrivingInstructorTraineeComplaintProductPackage',
  DrivingInstructorTraineeComplaintTrainer = 'DrivingInstructorTraineeComplaintTrainer',
  DrivingLessonAvailability = 'DrivingLessonAvailability',
  DrivingLicenceDuetoExpire = 'DrivingLicenceDuetoExpire',
  DualBrakeBespokeAdaptionsRequired = 'DualBrakeBespokeAdaptionsRequired',
  DualBrakeNoCoverage = 'DualBrakeNoCoverage',
  EmailAnsweredQuery = 'EmailAnsweredQuery',
  EmailFinalChase = 'EmailFinalChase',
  EmailGeneralFollow = 'EmailGeneralFollow',
  EmailInitialIntro = 'EmailInitialIntro',
  EmailReengagement = 'EmailReengagement',
  EmergencyExchangeApproved = 'EmergencyExchangeApproved',
  EmergencyExchangeDenied = 'EmergencyExchangeDenied',
  EmergencyExchangeRequested = 'EmergencyExchangeRequested',
  EstimatedVehicleReadyDate = 'EstimatedVehicleReadyDate',
  ExistingPupilDetailQuery = 'ExistingPupilDetailQuery',
  FaultFeeApplied = 'FaultFeeApplied',
  FinancialResilience = 'FinancialResilience',
  FixedPenaltyApplied = 'FixedPenaltyApplied',
  FleetChargeChallenge = 'FleetChargeChallenge',
  FleetQuery = 'FleetQuery',
  FranchiseApplicationReceived = 'FranchiseApplicationReceived',
  FranchiseeComplaintCsc = 'FranchiseeComplaintCSC',
  FranchiseeComplaintFranchiseTandCs = 'FranchiseeComplaintFranchiseTandCs',
  FranchiseeComplaintOther = 'FranchiseeComplaintOther',
  FranchiseeComplaintVehicle = 'FranchiseeComplaintVehicle',
  FranchiseFinancialSupport = 'FranchiseFinancialSupport',
  FranchisePayment = 'FranchisePayment',
  FranchiseRenewal = 'FranchiseRenewal',
  FranchiseSalesNtuDeclinedbytheBusiness = 'FranchiseSalesNTUDeclinedbytheBusiness',
  FranchiseSalesNtuFailedtoMakeContactbyPhoneEndofContactStrategy = 'FranchiseSalesNTUFailedtoMakeContactbyPhoneEndofContactStrategy',
  FranchiseSalesNtuFailedtoMakeContactbyPhoneIncorrectNumber = 'FranchiseSalesNTUFailedtoMakeContactbyPhoneIncorrectNumber',
  FranchiseSalesNtuGoingIndependent = 'FranchiseSalesNTUGoingIndependent',
  FranchiseSalesNtuGonewithaCompetitor = 'FranchiseSalesNTUGonewithaCompetitor',
  FranchiseSalesNtuKeepingCurrentFranchise = 'FranchiseSalesNTUKeepingCurrentFranchise',
  FranchiseSalesNtuNotEligible = 'FranchiseSalesNTUNotEligible',
  FranchiseSalesNtuTooExpensive = 'FranchiseSalesNTUTooExpensive',
  FranchiseSalesSetUp = 'FranchiseSalesSetUp',
  FranchiseSupport = 'FranchiseSupport',
  GeneralEnquiry = 'GeneralEnquiry',
  GreenBadgeContact = 'GreenBadgeContact',
  GreenBadgeDuetoExpire = 'GreenBadgeDuetoExpire',
  GreenBadgeHasExpired = 'GreenBadgeHasExpired',
  GreenBadgeUpdated = 'GreenBadgeUpdated',
  GreenNewDiAccountActive = 'GreenNewDIAccountActive',
  HubCappQuery = 'HubCappQuery',
  HubcappSupport = 'HubcappSupport',
  IgSignupComplete = 'IGSignupComplete',
  IgStatusAgreed = 'IGStatusAgreed',
  IgStatusCreditCheck = 'IGStatusCreditCheck',
  IgStatusCreditCheckFailed = 'IGStatusCreditCheckFailed',
  IgStatusCreditCheckPassed = 'IGStatusCreditCheckPassed',
  IgStatusDead = 'IGStatusDead',
  IgStatusDormant = 'IGStatusDormant',
  IgStatusLeft = 'IGStatusLeft',
  IgStatusNew = 'IGStatusNew',
  IgStatusOngoing = 'IGStatusOngoing',
  IgStatusPaid = 'IGStatusPaid',
  IgStatusPaidWithDiscount = 'IGStatusPaidWithDiscount',
  IgStatusRejected = 'IGStatusRejected',
  InappropriateBehaviour = 'InappropriateBehaviour',
  InappropriateContact = 'InappropriateContact',
  IncorrectIncompleteActionTaken = 'IncorrectIncompleteActionTaken',
  IncorrectInformationGiven = 'IncorrectInformationGiven',
  IncorrectPaymentTaken = 'IncorrectPaymentTaken',
  IncorrectPupilAllocation = 'IncorrectPupilAllocation',
  InstructorLead = 'InstructorLead',
  InstructorMessage = 'InstructorMessage',
  InstructorSetup = 'InstructorSetup',
  InvoiceMissing = 'InvoiceMissing',
  InvoiceQuery = 'InvoiceQuery',
  KeyedFranchiseAdjustment = 'KeyedFranchiseAdjustment',
  KeyedLeavingDate = 'KeyedLeavingDate',
  LanguageToneCallHandling = 'LanguageToneCallHandling',
  LeadCustomer = 'LeadCustomer',
  LearnerComplaint = 'LearnerComplaint',
  LearnerDriverComplaintCsc = 'LearnerDriverComplaintCSC',
  LearnerDriverComplaintFranchiseeInstructor = 'LearnerDriverComplaintFranchiseeInstructor',
  LearnerDriverComplaintOther = 'LearnerDriverComplaintOther',
  LearnerRejection = 'LearnerRejection',
  LearnerTopup = 'LearnerTopup',
  LeftOnGoodTerms = 'LeftOnGoodTerms',
  LessonAmendment = 'LessonAmendment',
  LessonCancellation = 'LessonCancellation',
  LessonClaimedinError = 'LessonClaimedinError',
  LessonEnquiry = 'LessonEnquiry',
  LifeEvent = 'LifeEvent',
  LockedLearnerCaps = 'LockedLearnerCaps',
  ManualRefund = 'ManualRefund',
  MarketingPreferences = 'MarketingPreferences',
  MentalHealth = 'MentalHealth',
  MileageUpdate = 'MileageUpdate',
  MoPComplaintCsc = 'MoPComplaintCSC',
  MoPComplaintFranchiseeInstructor = 'MoPComplaintFranchiseeInstructor',
  MopComplaintOther = 'MopComplaintOther',
  Motability14HoursTheoryCheck = 'Motability14HoursTheoryCheck',
  Motability20HoursCompleted = 'Motability20HoursCompleted',
  Motability30HoursCompleted = 'Motability30HoursCompleted',
  Motability40HoursCompleted = 'Motability40HoursCompleted',
  Motability8HoursCompleted = 'Motability8HoursCompleted',
  MotabilityAdditionalHours = 'MotabilityAdditionalHours',
  MotabilityAdditionalHoursApproved = 'MotabilityAdditionalHoursApproved',
  MotabilityAdditionalHoursRequested = 'MotabilityAdditionalHoursRequested',
  MotabilityAllocateInstructor = 'MotabilityAllocateInstructor',
  MotabilityAllocationNote = 'MotabilityAllocationNote',
  MotabilityAppointee = 'MotabilityAppointee',
  MotabilityAwaitingAdiResponse = 'MotabilityAwaitingAdiResponse',
  MotabilityCancellationDeceased = 'MotabilityCancellationDeceased',
  MotabilityCancellationFailedTheoryTest = 'MotabilityCancellationFailedTheoryTest',
  MotabilityCancellationIllHealth = 'MotabilityCancellationIllHealth',
  MotabilityCancellationLackOfProgress = 'MotabilityCancellationLackOfProgress',
  MotabilityCancellationLearnerRequest = 'MotabilityCancellationLearnerRequest',
  MotabilityCancellationLicenceSuspended = 'MotabilityCancellationLicenceSuspended',
  MotabilityCancellationMotabilityRequest = 'MotabilityCancellationMotabilityRequest',
  MotabilityCancellationNoLongerEligibleForGrant = 'MotabilityCancellationNoLongerEligibleForGrant',
  MotabilityCancellationReappliedForLicence = 'MotabilityCancellationReappliedForLicence',
  MotabilityCancellationReferredBack = 'MotabilityCancellationReferredBack',
  MotabilityCancellationUnableToContact = 'MotabilityCancellationUnableToContact',
  MotabilityCsi = 'MotabilityCSI',
  MotabilityDbs = 'MotabilityDBS',
  MotabilityFamHofUrgent = 'MotabilityFamHofUrgent',
  MotabilityFccComplete = 'MotabilityFccComplete',
  MotabilityFccOutstanding = 'MotabilityFccOutstanding',
  MotabilityFirstLessonCheck = 'MotabilityFirstLessonCheck',
  MotabilityFourWeekFollowUp = 'MotabilityFourWeekFollowUp',
  MotabilityGrantsTeamContact = 'MotabilityGrantsTeamContact',
  MotabilityGrantTeamFeedback = 'MotabilityGrantTeamFeedback',
  MotabilityGreenBadge = 'MotabilityGreenBadge',
  MotabilityHigherRateCreated = 'MotabilityHigherRateCreated',
  MotabilityHigherRateRemoved = 'MotabilityHigherRateRemoved',
  MotabilityHigherRateRequested = 'MotabilityHigherRateRequested',
  MotabilityHofDl = 'MotabilityHofDL',
  MotabilityHofFam = 'MotabilityHofFam',
  MotabilityIdFormOutstanding = 'MotabilityIDFormOutstanding',
  MotabilityIdFormReceived = 'MotabilityIDFormReceived',
  MotabilityInitialSetUp = 'MotabilityInitialSetUp',
  MotabilityInsuranceDocuments = 'MotabilityInsuranceDocuments',
  MotabilityMtaResponseReceived = 'MotabilityMtaResponseReceived',
  MotabilityNoLessonsTakenWithin4Weeks = 'MotabilityNoLessonsTakenWithin4Weeks',
  MotabilityNotAllocated = 'MotabilityNotAllocated',
  MotabilityOutstandingPracticalTestResult = 'MotabilityOutstandingPracticalTestResult',
  MotabilityOutstandingTheoryTestResult = 'MotabilityOutstandingTheoryTestResult',
  MotabilityPoChange = 'MotabilityPOChange',
  MotabilityPracticalTestDateRequired = 'MotabilityPracticalTestDateRequired',
  MotabilityPracticalTestGoodLuckCall = 'MotabilityPracticalTestGoodLuckCall',
  MotabilityPublicLiability = 'MotabilityPublicLiability',
  MotabilityRedAwaitingSystemUpdate = 'MotabilityRedAwaitingSystemUpdate',
  MotabilityRogFormOutstanding = 'MotabilityROGFormOutstanding',
  MotabilityRogReceived = 'MotabilityROGReceived',
  MotabilityVehicleTypeHiTech = 'MotabilityVehicleTypeHiTech',
  MotabilityVehicleTypeSimple = 'MotabilityVehicleTypeSimple',
  MtaAwaitingToAgreeCancellation = 'MTAAwaitingToAgreeCancellation',
  MtaDeceased = 'MTADeceased',
  MtaDualControlsRequested = 'MTADualControlsRequested',
  MtaHigherRateRequest = 'MTAHigherRateRequest',
  MtaIllHealth = 'MTAIllHealth',
  MtaIncorrectDetailsOnLead = 'MTAIncorrectDetailsOnLead',
  MtaLackofProgress = 'MTALackofProgress',
  MtaLicenceSuspended = 'MTALicenceSuspended',
  MtaMotabilityRequest = 'MTAMotabilityRequest',
  MtaNoContactAfter3Attempts = 'MTANoContactAfter3Attempts',
  MtaNoLongerEligibleforGrante = 'MTANoLongerEligibleforGrante',
  MtaPupilRequest = 'MTAPupilRequest',
  MtaReappliedforLicence = 'MTAReappliedforLicence',
  MtaReferredBack = 'MTAReferredBack',
  MtattNotPassed = 'MTATTNotPassed',
  MtaUnabletoContact = 'MTAUnabletoContact',
  NewDocumentUpload = 'NewDocumentUpload',
  NewPupilDetailQuery = 'NewPupilDetailQuery',
  OnboardingInitialContactAttempted = 'OnboardingInitialContactAttempted',
  OnboardingInitialContactCompleted = 'OnboardingInitialContactCompleted',
  OnboardingPostContactAttempted = 'OnboardingPostContactAttempted',
  OnboardingPostContactCompleted = 'OnboardingPostContactCompleted',
  OnlineBookingError = 'OnlineBookingError',
  OrangeChangeOfTransmission = 'OrangeChangeOfTransmission',
  OutsourcedReasonAdaptionsNeededAreMorethanBsmFleetLevel3 = 'OutsourcedReasonAdaptionsNeededAreMorethanBSMFleetLevel3',
  OutsourcedReasonBsmdiAllocatedComplaint = 'OutsourcedReasonBSMDIAllocatedComplaint',
  OutsourcedReasonBsmdiAvailablePupilRequestedtoStaywithTheirOwnDi = 'OutsourcedReasonBSMDIAvailablePupilRequestedtoStaywithTheirOwnDi',
  OutsourcedReasonHighlyAdaptedOwnVehicleNoExperiencedBsmDi = 'OutsourcedReasonHighlyAdaptedOwnVehicleNoExperiencedBSMDi',
  OutsourcedReasonNoBsmAutoAvailable = 'OutsourcedReasonNoBSMAutoAvailable',
  OutsourcedReasonNoBsmLevel3DiAvailable = 'OutsourcedReasonNoBSMLevel3DIAvailable',
  OutsourcedReasonNoBsmManualAvailable = 'OutsourcedReasonNoBSMManualAvailable',
  OutsourcedReasonNorthernIreland = 'OutsourcedReasonNorthernIreland',
  PaymentError = 'PaymentError',
  PaymentErrorEnquiry = 'PaymentErrorEnquiry',
  PaymentGeneralEnquiry = 'PaymentGeneralEnquiry',
  PaymentPlan = 'PaymentPlan',
  PaymentPlanCreated = 'PaymentPlanCreated',
  PhysicalHealth = 'PhysicalHealth',
  PinkAwaiting3rdPartyDIdetails = 'PinkAwaiting3rdPartyDIdetails',
  PinkBadgeDuetoExpire = 'PinkBadgeDuetoExpire',
  PinkBadgeHasExpired = 'PinkBadgeHasExpired',
  PinkBadgeUpdated = 'PinkBadgeUpdated',
  PotentialFuture = 'PotentialFuture',
  PracticalTestCover = 'PracticalTestCover',
  PracticalTestCoverEnquiry = 'PracticalTestCoverEnquiry',
  PracticalTestResult = 'PracticalTestResult',
  Prestige = 'Prestige',
  Price = 'Price',
  PricingUpdated = 'PricingUpdated',
  PupilReallocationInstructorAvailability = 'PupilReallocationInstructorAvailability',
  PupilReallocationInstructorConcern = 'PupilReallocationInstructorConcern',
  PupilReallocationPupilMovedAddress = 'PupilReallocationPupilMovedAddress',
  PupilReallocationVehicleTransmission = 'PupilReallocationVehicleTransmission',
  PupilRejectionEnquiry = 'PupilRejectionEnquiry',
  PupilSupplyQuery = 'PupilSupplyQuery',
  PurpleReferToMotability = 'PurpleReferToMotability',
  Reallocation = 'Reallocation',
  ReallocationDormancy = 'ReallocationDormancy',
  ReallocationInstructorLeaving = 'ReallocationInstructorLeaving',
  ReallocationNoInstructorAvailable = 'ReallocationNoInstructorAvailable',
  ReallocationTestCover = 'ReallocationTestCover',
  ReceiptRequest = 'ReceiptRequest',
  RefundIncorrectNotActioned = 'RefundIncorrectNotActioned',
  RefundRequest = 'RefundRequest',
  RefundRequestInstructorAvailability = 'RefundRequestInstructorAvailability',
  RefundRequestInstructorConcern = 'RefundRequestInstructorConcern',
  RefundRequestPassedTest = 'RefundRequestPassedTest',
  RefundRequestStoppedLessons = 'RefundRequestStoppedLessons',
  RefundStatusEnquiry = 'RefundStatusEnquiry',
  RegisteredSelfsourcedLearner = 'RegisteredSelfsourcedLearner',
  RejectedPupil = 'RejectedPupil',
  RenewalApplicationReceivedKeyNewTerm = 'RenewalApplicationReceivedKeyNewTerm',
  RenewalFleetOrderNewCar = 'RenewalFleetOrderNewCar',
  RenewalFollowupContact = 'RenewalFollowupContact',
  RenewalInitialContact = 'RenewalInitialContact',
  RenewalIssueApplication = 'RenewalIssueApplication',
  RenewalOutcomeLeaving = 'RenewalOutcomeLeaving',
  RenewalOutcomeRenewed = 'RenewalOutcomeRenewed',
  ResignationReceived = 'ResignationReceived',
  RetentionDealOffered = 'RetentionDealOffered',
  RetentionFollowupContact = 'RetentionFollowupContact',
  RetentionInitialContact = 'RetentionInitialContact',
  RetentionIssueExitInterview = 'RetentionIssueExitInterview',
  RetentionLeaverChecklist = 'RetentionLeaverChecklist',
  RetentionLeaverDeferment = 'RetentionLeaverDeferment',
  RetentionOutcomeLeaving = 'RetentionOutcomeLeaving',
  RetentionOutcomeSaved = 'RetentionOutcomeSaved',
  RetentionReferral = 'RetentionReferral',
  RetentionResignationReceived = 'RetentionResignationReceived',
  RujSupport = 'RujSupport',
  SavedLeaver = 'SavedLeaver',
  ShortNoticeCancellation = 'ShortNoticeCancellation',
  ShortNoticeCancellationCharged = 'ShortNoticeCancellationCharged',
  ShortNoticeCancellationEnquiry = 'ShortNoticeCancellationEnquiry',
  SmsClosed = 'SMSClosed',
  SmsFinalChase = 'SMSFinalChase',
  SmsGeneralChase = 'SMSGeneralChase',
  SmsInitialIntro = 'SMSInitialIntro',
  SmsReengagement = 'SMSReengagement',
  Stage1WarningIssued = 'Stage1WarningIssued',
  Stage2WarningIssued = 'Stage2WarningIssued',
  TerminationComplaints = 'TerminationComplaints',
  TerminationConduct = 'TerminationConduct',
  TerminationDbs = 'TerminationDBS',
  TerminationDebt = 'TerminationDebt',
  TerminationFailedPart3 = 'TerminationFailedPart3',
  TerminationGreenBadge = 'TerminationGreenBadge',
  TerminationPinkBadge = 'TerminationPinkBadge',
  TheoryTestResult = 'TheoryTestResult',
  TopUpAccount = 'TopUpAccount',
  TuitionStandards = 'TuitionStandards',
  UnabletosaveLeaver = 'UnabletosaveLeaver',
  UpdateAddressDetails = 'UpdateAddressDetails',
  UpdateContactDetails = 'UpdateContactDetails',
  UpdateCoverage = 'UpdateCoverage',
  UpdateDiary = 'UpdateDiary',
  UpdateLessonType = 'UpdateLessonType',
  UpdateMileage = 'UpdateMileage',
  UpdatePersonalDetails = 'UpdatePersonalDetails',
  UpdatePersonalInfo = 'UpdatePersonalInfo',
  UpdatePrices = 'UpdatePrices',
  UpdatePupilCap = 'UpdatePupilCap',
  UpdateTestResults = 'UpdateTestResults',
  UpdateWorkingHours = 'UpdateWorkingHours',
  VehicleAaam = 'VehicleAAAM',
  VehicleCourtesyCar = 'VehicleCourtesyCar',
  VehicleDelivery = 'VehicleDelivery',
  VehicleExchangeQuery = 'VehicleExchangeQuery',
  VehicleFaultFee = 'VehicleFaultFee',
  VehicleFixedPenalty = 'VehicleFixedPenalty',
  VehicleParkingPermitRequest = 'VehicleParkingPermitRequest',
  VehiclePrestigeQuery = 'VehiclePrestigeQuery',
  VehicleRepair = 'VehicleRepair',
  VehicleStandards = 'VehicleStandards',
  VehicleType = 'VehicleType',
  WebChat = 'WebChat',
  WebsiteApp = 'WebsiteApp',
  WhiteAwaitingAllocation = 'WhiteAwaitingAllocation',
  YellowHigherRateRequestedToMbt = 'YellowHigherRateRequestedToMBT'
}

export type WorkingHour = {
  __typename?: 'WorkingHour';
  day: Scalars['String'];
  end?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lineItemKey?: Maybe<Scalars['String']>;
  proposition?: Maybe<Proposition>;
  start?: Maybe<Scalars['String']>;
};
