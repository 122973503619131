import React, { CSSProperties } from "react";

import {
  Card,
  Typography,
  ButtonBase,
  Grid,
  Box,
  Theme,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeStyles } from "@material-ui/styles";

import { ActionCardProps } from "./types";

const useStyles = (disabled: boolean) =>
  makeStyles((theme: Theme) => ({
    root: {
      border: "2px solid",
      borderColor: theme.palette.grey[200],
      borderRadius: "4px",
      width: "100%",
      opacity: disabled ? 0.2 : 1,
    },
    cardRoot: {
      width: "100%",
    },
    cardContent: {
      padding: "24px 16px 24px 0",
    },
    title: {
      fontSize: "20px",
      lineHeight: "22px",
      marginBottom: "12px",
      textAlign: "left",
    },
    subtitle: {
      fontSize: "12px",
      lineHeight: "15px",
      opacity: 0.6,
      textAlign: "left",
    },
  }));

const ActionCard = ({
  title,
  subtitle,
  IconComponent,
  action,
  actionIcon,
  disabled = false,
  isExternalLink = false,
  externalLink,
  ...restProps
}: ActionCardProps) => {
  const classes = useStyles(disabled)();

  const ActionCardComponent = (
    <Card className={classes.cardRoot} elevation={0}>
      <Box className={classes.cardContent}>
        <Grid container>
          <Grid item xs={2}>
            <Box
              alignItems="center"
              display="flex"
              height="100%"
              justifyContent="center"
            >
              {IconComponent}
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box
              alignContent="center"
              display="flex"
              flexDirection="column"
              height="100%"
            >
              <Typography component="h1" className={classes.title}>
                {title}
              </Typography>
              {subtitle && (
                <Typography
                  component="h2"
                  variant="h4"
                  className={classes.subtitle}
                >
                  {subtitle}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box
              display="flex"
              alignItems="center"
              height="100%"
              justifyContent="center"
            >
              {actionIcon ? actionIcon : <ChevronRightIcon fontSize="large" />}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );

  const cursorStyle =
    !action || disabled ? { pointerEvents: "none", cursor: "default" } : {};

  if (isExternalLink && externalLink) {
    return (
      <a
        onClick={() => typeof action === "function" && action()}
        target="_blank"
        rel="noreferrer"
        href={externalLink}
        style={{ ...(cursorStyle as CSSProperties), textDecoration: "none" }}
        {...restProps}
      >
        <div className={classes.root}>{ActionCardComponent}</div>
      </a>
    );
  }

  return (
    <ButtonBase
      disabled={!action || disabled}
      onClick={() => typeof action === "function" && action()}
      className={classes.root}
      {...restProps}
    >
      {ActionCardComponent}
    </ButtonBase>
  );
};

export default ActionCard;
