import React from "react";
import { bool, func } from "prop-types";
import { IconButton, Icon } from "@material-ui/core";

const FormRadioCardGroupCarouselPreviousButton = ({
  onClick = () => {},
  disabled = false,
  dataTestId,
}) => (
  <IconButton onClick={onClick} disabled={disabled} data-test={dataTestId}>
    <Icon>keyboard_arrow_left</Icon>
  </IconButton>
);

FormRadioCardGroupCarouselPreviousButton.propTypes = {
  onClick: func,
  disabled: bool,
};

export default FormRadioCardGroupCarouselPreviousButton;
