import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import Titlebar from "../../common/Titlebar/Titlebar";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import SummaryRow from "../../common/Payment/SummaryRow/SummaryRow";
import { formatCurrency } from "../../../utils/formatCurrency";
import PaymentForm from "../../common/Payment/Form/Form";
import Summary from "../../common/Payment/Summary/Summary";
import LoadingScreen from "../../common/LoadingScreen/LoadingScreen";
import { goBack } from "../../../utils/navigation";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";

const useStyles = makeStyles((theme: Theme) => ({
  paymentError: {
    marginTop: "2em",
  },
  loader: {
    marginTop: "30px",
  },
  total: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  discountText: {
    marginLeft: "-0.37em",
  },
}));

interface QuickTopUpJourneyStepsPaymentProps {
  history: History;
  location: { pathname: string };
  token: string;
  endpoint: string;
  learnerName: string;
  selectedHours: number;
  selectedHoursCost: number;
  discountAmount: number;
  bookingFee: number;
  totalCost: number;
  isPaymentDeclined: boolean;
  setIFrameForm: (element: HTMLFormElement) => void;
  setIFrameLoading: (iframe: HTMLIFrameElement) => void;
  isLoadingPage: boolean;
  isLoadingPaymentForm: boolean;
}

const QuickTopUpJourneyStepsPayment = ({
  history,
  location: { pathname },
  token,
  endpoint,
  learnerName,
  selectedHours,
  selectedHoursCost,
  discountAmount,
  bookingFee = 0,
  totalCost,
  isPaymentDeclined,
  setIFrameForm,
  setIFrameLoading,
  isLoadingPage,
  isLoadingPaymentForm,
}: QuickTopUpJourneyStepsPaymentProps & RouteComponentProps) => {
  const { getText } = useBrand();
  const classes = useStyles();
  const { trackEvent } = useTealium();

  const handleBackButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    goBack({ history, pathname });
  };

  if (isLoadingPage)
    return <LoadingScreen messageText={getText("top-up/payment.loading")} />;

  return (
    <ContentWrapper>
      <Titlebar showBackButton onBackClick={handleBackButtonClick}>
        {getText("top-up/payment.pageTitle", { name: learnerName })}
      </Titlebar>

      <Summary>
        <SummaryRow
          left={`${getText("payment.lessons")} - ${selectedHours} hrs`}
          right={`£${formatCurrency(selectedHoursCost)}`}
        />
        {discountAmount > 0 && (
          <SummaryRow
            left={getText("payment.discount")}
            right={
              <span className={classes.discountText}>
                -£{formatCurrency(discountAmount)}
              </span>
            }
          />
        )}
        <SummaryRow
          left={getText("payment.bookingFee")}
          right={`£${formatCurrency(bookingFee)}`}
        />
        <SummaryRow
          left={getText("payment.total")}
          right={`£${formatCurrency(totalCost - discountAmount)}`}
          className={classes.total}
        />
      </Summary>

      {isPaymentDeclined && (
        <Typography variant="h3" color="error" className={classes.paymentError}>
          {getText("payment.paymentDeclined")}
        </Typography>
      )}

      {token && endpoint && (
        <PaymentForm
          token={token}
          endpoint={endpoint}
          formRef={setIFrameForm}
          iFrameRef={setIFrameLoading}
          isLoading={isLoadingPaymentForm}
          classes={classes}
        />
      )}
    </ContentWrapper>
  );
};

export default withRouter(QuickTopUpJourneyStepsPayment);
