import React from "react";
import { withRouter } from "react-router-dom";
import Body from "./Body";
import { useStateContext } from "../../../context/stateContext";
import { RETURNING_USER_JOURNEY_PATH_NAMES } from "../../../constants";
const NewInstructorRequest = ({ history }) => {
  const {
    state: {
      rujHistory: { topUpId },
    },
  } = useStateContext();
  const handleAccountClick = () => {
    history.push(`${RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT}/${topUpId}`);
  };

  return <Body onMyAccountClick={handleAccountClick} />;
};

export default withRouter(NewInstructorRequest);
