import React from "react";
import { string } from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

const useStyles = makeStyles(theme => ({
  notice: {
    display: "flex",
    alignItems: "center",
    "& h1": {
      marginLeft: "20px",
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

const Warning = ({ title, ...rest }) => {
  const classes = useStyles();
  return (
    <div className={classes.notice} {...rest}>
      <WarningRoundedIcon fontSize="large" color="error" />
      <Typography variant="h1" component="h1" color="error">
        {title}
      </Typography>
    </div>
  );
};

Warning.propTypes = {
  title: string,
};

export default Warning;
