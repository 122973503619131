import React, { useEffect } from "react";

import {
  useStateContext,
  CLEAR_NUJ_SELECTION_HISTORY,
} from "../../../../context/stateContext";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";

import SntConfirmation from "./SntConfirmation";

const NewUserJourneySntConfirmation = () => {
  const { getText } = useBrand();
  const {
    state: {
      sessionId,
      nujHistory: {
        confirmation: {
          topUpId,
          pickupPostcode,
          lesson: { startTime, endTime },
          instructorName,
          pupilFirstName,
        },
      },
    },
    dispatch,
  } = useStateContext();
  useEffect(() => {
    dispatch({
      type: CLEAR_NUJ_SELECTION_HISTORY,
    });
  }, [dispatch]);

  return (
    <SntConfirmation
      title={getText("sntConfirmation.pageTitle", { pupilFirstName })}
      pickupPostcode={pickupPostcode}
      startTime={startTime}
      endTime={endTime}
      instructorName={instructorName}
      topUpId={topUpId}
      sessionId={sessionId}
    />
  );
};

export default NewUserJourneySntConfirmation;
