import { useReducer } from "react";
import createUseContext from "constate"; // State Context Object Creator
import { has, defaultsDeep } from "lodash";

import getInitialState from "./getInitialState";
import * as NUJActions from "./stateActions";
import { State } from "models/state";

export const UPDATE_SESSION_ID = "updateSessionId";
export const UPDATE_NUJ_HISTORY = "updateNUJHistory";
export const UPDATE_RUJ_HISTORY = "updateRUJHistory";
export const UPDATE_QTU_HISTORY = "updateQTUHistory";
export const AVAILABLE_NUJ_INSTRUCTORS_LOADED = "availableNUJInstructorsLoaded";
export const CLEAR_AVAILABLE_NUJ_INSTRUCTORS = "clearAvailableNUJInstructors";
export const CLEAR_NUJ_SELECTED_INSTRUCTOR = "clearNUJSelectedInstructor";
export const CLEAR_NUJ_SELECTED_INSTRUCTOR_HISTORY =
  "clearNUJSelectedInstructorHistory";
export const CLEAR_NUJ_SELECTION_HISTORY = "clearNUJSelectionHistory";
export const CLEAR_QTU_SELECTION_HISTORY = "clearQTUHistory";
export const LOGOUT = "logout";
export const SET_QTU_JOURNEY = "setQTUJourney";

export const types = {
  UPDATE_SESSION_ID,
  UPDATE_NUJ_HISTORY,
  UPDATE_RUJ_HISTORY,
  UPDATE_QTU_HISTORY,
  AVAILABLE_NUJ_INSTRUCTORS_LOADED,
  CLEAR_AVAILABLE_NUJ_INSTRUCTORS,
  CLEAR_NUJ_SELECTED_INSTRUCTOR,
  CLEAR_NUJ_SELECTED_INSTRUCTOR_HISTORY,
  CLEAR_NUJ_SELECTION_HISTORY,
  CLEAR_QTU_SELECTION_HISTORY,
  LOGOUT,
  SET_QTU_JOURNEY,
};

type ActionTypes =
  | typeof UPDATE_SESSION_ID
  | typeof UPDATE_NUJ_HISTORY
  | typeof UPDATE_RUJ_HISTORY
  | typeof UPDATE_QTU_HISTORY
  | typeof AVAILABLE_NUJ_INSTRUCTORS_LOADED
  | typeof CLEAR_AVAILABLE_NUJ_INSTRUCTORS
  | typeof CLEAR_NUJ_SELECTED_INSTRUCTOR
  | typeof CLEAR_NUJ_SELECTED_INSTRUCTOR_HISTORY
  | typeof CLEAR_NUJ_SELECTION_HISTORY
  | typeof CLEAR_QTU_SELECTION_HISTORY
  | typeof SET_QTU_JOURNEY;

export const stateReducer = (
  state: State,
  { type, payload }: { type: ActionTypes; payload: any },
): State => {
  if (!type) {
    throw new Error("Invalid action. Actions must have a 'type' property");
  }

  if (!has(NUJActions, type)) {
    throw new Error(`Unknown action type: ${type}`);
  }

  return NUJActions[type]({ state, payload });
};

export const useStateReducer = ({
  sessionId,
  overrideState,
  ldrive,
}: {
  sessionId: string;
  overrideState?: Partial<State>;
  ldrive: boolean;
}): { state: State; dispatch: any } => {
  const initialState = getInitialState(sessionId);
  initialState.rujHistory.ldrive = Boolean(ldrive);
  const initialStateMergedWithProps = defaultsDeep(
    {},
    overrideState,
    initialState,
  );
  const [state, dispatch] = useReducer(
    stateReducer,
    initialStateMergedWithProps,
  );

  const jsonedState = JSON.stringify(state);
  const savedState = sessionStorage.getItem("appState");
  if (jsonedState !== savedState) {
    sessionStorage.setItem("appState", jsonedState);
  }

  return { state, dispatch };
};

export const useStateContext = createUseContext(useStateReducer);
