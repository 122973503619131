import React, { Fragment } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  makeStyles,
  CircularProgress,
  Theme,
  useTheme,
} from "@material-ui/core";
import mergeDeep from "lodash.merge";

import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import ContinueButton from "../../common/ContinueButton/ContinueButton";
import Titlebar from "../../common/Titlebar/Titlebar";

import { useBrand } from "../../common/BrandProvider/BrandProvider";
import { useStateContext } from "@context";

export interface ConfirmationProps {
  name: string;
  hoursAdded: number;
  balance: number;
  onContinueClick: () => void;
}

type CustomTheme = Theme & {
  journey: { qtjConfirmation: Record<string, any> };
};

const useStyles = makeStyles((theme: CustomTheme) =>
  mergeDeep(theme.journey.qtjConfirmation, {
    newHoursBalance: {
      fontSize: "32px",
      lineHeight: "32px",
      textAlign: "right",
      letterSpacing: "-0.5px",
    },
    newHoursText: {
      fontSize: "16px",
    },
    newHoursSubtitle: {
      fontSize: "12px",
      opacity: "0.5",
    },
    bookLessonButton: {
      margin: 0,
    },
    exitButtonWrapper: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      marginBottom: "30px",
    },
  }),
);

const Confirmation = ({
  name,
  hoursAdded,
  balance,
  onContinueClick,
}: ConfirmationProps) => {
  const { getText } = useBrand();
  const classes = useStyles();
  const theme = useTheme<CustomTheme>();

  const {
    state: { isQTUJourney },
  } = useStateContext();

  return (
    <ContentWrapper
      fullWidth
      fullHeight={!!theme.journey.qtjConfirmation.headerWrapper}
    >
      {theme.journey.qtjConfirmation.headerWrapper ? (
        <div className={classes.headerWrapper}>
          <ContentWrapper>
            <Titlebar>
              {getText("top-up/confirmation.pageTitle", {
                name,
                hours: hoursAdded,
              })}
            </Titlebar>
            {theme.journey.qtjConfirmation.qtjConfirmationImage && (
              <img
                className={classes.bannerImage}
                src={theme.journey.qtjConfirmation.qtjConfirmationImage}
                alt=""
              />
            )}
          </ContentWrapper>
        </div>
      ) : (
        <ContentWrapper fullHeight>
          <Titlebar>
            {getText("top-up/confirmation.pageTitle", {
              name,
              hours: hoursAdded,
            })}
          </Titlebar>
        </ContentWrapper>
      )}

      <Card classes={{ root: classes.card }}>
        <CardHeader
          title={
            <Typography variant="h3">
              {getText("top-up/confirmation.newAccountBalance")}
            </Typography>
          }
        />
        <CardContent>
          <Typography component="span">
            {typeof balance === "number" && !Number.isNaN(balance) ? (
              <Fragment>
                <span
                  data-test="new-account-balance"
                  className={classes.newHoursBalance}
                >
                  {balance}
                </span>
                &nbsp;
                <span
                  data-test="balance-hours"
                  className={classes.newHoursText}
                >
                  {`hour${balance > 1 ? "s" : ""}`}
                </span>
              </Fragment>
            ) : (
              <CircularProgress size={14} color="inherit" />
            )}
          </Typography>
          <Typography
            variant="subtitle1"
            classes={{ root: classes.newHoursSubtitle }}
          >
            {getText("top-up/confirmation.availableToBook")}
          </Typography>
        </CardContent>
        {!isQTUJourney && (
          <CardActions>
            <ContinueButton
              data-test="book-lesson-button"
              text={getText("top-up/confirmation.bookLesson")}
              classes={classes.bookLessonButton}
              onClick={onContinueClick}
            />
          </CardActions>
        )}
      </Card>
    </ContentWrapper>
  );
};

export default Confirmation;
