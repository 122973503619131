import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core";
import { ReportProblem, ReportProblemOutlined } from "@material-ui/icons";
import merge from "lodash.merge";

import {
  BSM_BRAND_NAME,
  NEW_USER_JOURNEY_PATH_NAMES,
  NUJ_HISTORY_INITIAL_STATE,
} from "../../../../constants";
import {
  UPDATE_NUJ_HISTORY,
  useStateContext,
} from "../../../../context/stateContext";
import { capitalizeEachWordOfPathname } from "../../../../utils/capitalisePathname";
import { goBack } from "../../../../utils/navigation";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import ContentWrapper from "../../../common/ContentWrapper/ContentWrapper";
import ContinueButton from "../../../common/ContinueButton/ContinueButton";

import InfoPanel from "../../../common/InfoPanel/InfoPanel";
import { useTealium } from "../../../common/TealiumProvider/TealiumProvider";
import Titlebar from "../../../common/Titlebar/Titlebar";
import KeyWorkerRadio from "../../../common/KeyWorkerRadio/KeyWorkerRadio";

import { StateContext } from "@lessons/models";

const useStyles = makeStyles(
  (theme: Theme & { journey: { keyWorker: Record<string, any> } }) =>
    merge(theme.journey.keyWorker, {
      selectInputAdornment: { opacity: 0.4, fontSize: "16px" },
      wrapper: { marginTop: theme.spacing(3) },
      svg: {
        marginTop: "-0.5em",
        marginRight: "0.5em",
      },
    }),
);

const KeyWorkerWithState = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const classes = useStyles();

  const { brand, getText } = useBrand();
  const { trackEvent } = useTealium();
  const { dispatch }: StateContext = useStateContext();

  const [isKeyWorker, setIsKeyWorker] = useState(false);
  const [keyWorkerType, setKeyWorkerType] = useState("");
  const [keyWorkerEmployer, setKeyWorkerEmployer] = useState("");
  const [fieldTouched, setFieldTouched] = useState(false);

  const handleYesNo = (value: boolean) => {
    setIsKeyWorker(value);
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Interaction",
      eventLabel: `Key Worker - ${isKeyWorker ? "Yes" : "No"}`,
    });
  };

  const handleAreaOfWork = (value: string) => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Interaction",
      eventLabel: value,
    });
    setKeyWorkerType(value);
  };

  const handleEmployeeName = (value: string) => {
    if (!fieldTouched) {
      trackEvent({
        eventCategory: capitalizeEachWordOfPathname(pathname),
        eventAction: "Interaction",
        eventLabel: "Employee Name",
      });
      setFieldTouched(true);
    }
    setKeyWorkerEmployer(value);
  };

  useEffect(() => {
    if (!isKeyWorker) {
      dispatch({
        type: UPDATE_NUJ_HISTORY,
        payload: {
          isRestrictedForKeyWorker: true,
          keyWorkerType: NUJ_HISTORY_INITIAL_STATE.keyWorkerType,
          keyWorkerEmployer: NUJ_HISTORY_INITIAL_STATE.keyWorkerEmployer,
        },
      });
    }
  }, [isKeyWorker]);

  const handleBackButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    goBack({ history, pathname });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Button Click",
      eventLabel: "Continue",
    });

    e.preventDefault();

    if (isKeyWorker) {
      dispatch({
        type: UPDATE_NUJ_HISTORY,
        payload: {
          isRestrictedForKeyWorker: true,
          keyWorkerType,
          keyWorkerEmployer,
        },
      });
    }
    history.push(NEW_USER_JOURNEY_PATH_NAMES.LESSON_TYPES);
  };

  return (
    <ContentWrapper>
      <Titlebar
        showBackButton
        onBackClick={handleBackButtonClick}
        headingProps={{ "data-test": "key-worker-title-bar" }}
      >
        {getText("key-worker.pageTitle")}
      </Titlebar>

      <ContentWrapper fullHeight fullWidth>
        <KeyWorkerRadio
          isKeyWorker={isKeyWorker}
          onIsKeyWorkerChange={handleYesNo}
          keyWorkerType={keyWorkerType}
          onKeyWorkerTypeChange={handleAreaOfWork}
          keyWorkerEmployer={keyWorkerEmployer}
          onKeyWorkerEmployerChange={handleEmployeeName}
        />
        <ContinueButton
          type="submit"
          disabled={isKeyWorker && !(keyWorkerType && keyWorkerEmployer)}
          onClick={handleSubmit}
          data-test="key-worker-continue"
        />

        {!isKeyWorker && (
          <InfoPanel
            svg={
              brand === BSM_BRAND_NAME ? (
                <ReportProblem classes={{ root: classes.infoPanelIcon }} />
              ) : (
                <ReportProblemOutlined
                  classes={{ root: classes.infoPanelIcon }}
                />
              )
            }
            svgClasses={classes.svg}
            svgFlexAlignItems="flex-start"
            title={getText("key-worker.infoPanelLabel")}
          >
            {getText("key-worker.infoPanelText")}
          </InfoPanel>
        )}
      </ContentWrapper>
    </ContentWrapper>
  );
};

export default KeyWorkerWithState;
