import React from "react";
import { node } from "prop-types";
import { withStyles } from "@material-ui/styles";
import mergeDeep from "lodash.merge";
import Button from "../Button/Button";

const styles = theme =>
  mergeDeep(theme.journey.submitButton, {
    root: {
      margin: "40px auto",
      display: "block",
      [theme.breakpoints.up("lg")]: {
        margin: "60px auto 0 auto",
      },
    },
  });

const SubmitButton = ({ text, classes, ...restProps }) => (
  <Button
    type="submit"
    text={text || ""}
    className={classes.root}
    {...restProps}
  />
);

SubmitButton.propTypes = {
  text: node,
};

export default withStyles(styles)(SubmitButton);
