import React, { Fragment } from "react";
import { bool, string, func } from "prop-types";
import { CircularProgress } from "@material-ui/core";

const PaymentForm = ({
  token,
  endpoint,
  isLoading = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formRef = _element => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  iFrameRef = _iframe => {},
  classes,
}) => (
  <Fragment>
    <form
      id="form"
      action={endpoint}
      method="post"
      target="output_frame"
      ref={formRef}
    >
      <input type="hidden" name="token" value={token} />
    </form>

    {isLoading && <CircularProgress classes={{ root: classes.loader }} />}

    <iframe
      title="Payment form"
      name="output_frame"
      src=""
      id="output_frame"
      width="100%"
      height="1000px"
      ref={iFrameRef}
      style={{ opacity: isLoading ? "0" : "1" }}
    />
  </Fragment>
);

PaymentForm.propTypes = {
  token: string.isRequired,
  endpoint: string.isRequired,
  isLoading: bool,
  formRef: func,
  iFrameRef: func,
};

export default PaymentForm;
