import { ArrowBack, Check } from "@material-ui/icons";
import palette, { gradients } from "./palette-bsm";
import callUs from "../svg/call-us.svg";
import testPassed from "../svg/test-passed.svg";
import testBooked from "../svg/test-booked.svg";
import surpriseGift from "../svg/surprise-gift.svg";
import qtjConfirmationImage from "../svg/bsm-qtj-success.svg";
import wavingGif from "../gif/bsm-waving.gif";
import noInstructorsImage from "../svg/noInstructors.svg";
import breakpoints from "./breakpoints";

const journey = {
  mobileStepper: {
    dot: {
      "&:first-child": {
        borderTopLeftRadius: "20px",
        borderBottomLeftRadius: "20px",
      },
      "&:last-child": {
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
      },
    },
  },
  exitButton: {
    label: {
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        color: palette.common.white,
      },
    },
    icon: {
      width: "24px",
      height: "24px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        color: palette.common.white,
      },
    },
  },
  backIcon: ArrowBack,
  appTopbar: {
    backButtonWrapper: {
      height: "18px",
    },
    logo: {
      marginTop: "0",
      marginRight: "0",
    },
  },
  backButton: {
    label: {
      marginLeft: "5px",
    },
  },
  continueButton: ({ disableVerticalMargin, disableHorizontalMargin }) => ({
    root: {
      minWidth: "226px",
      margin: `${disableVerticalMargin ? "0" : "40px"} ${
        disableHorizontalMargin ? "0" : "auto"
      }`,
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        fontSize: "1.125rem",
        margin: `${disableVerticalMargin ? "0" : "60px"} ${
          disableHorizontalMargin ? "0" : "auto"
        }`,
      },
    },
  }),
  submitButton: {
    root: {
      minWidth: "226px",
    },
  },
  radioItemList: {
    borderRadius: "6px",
    marginBottom: "10px",
    opacity: "0.7",
    padding: "12px",
    labelFontSize: "16px",
    labelMargin: "0 0 10px 0",
    borderColor: palette.grey[200],
    hoverBorderColor: palette.grey[400],
    fontWeight: 600,
    selectedFontWeight: 600,
  },
  infoPanel: {
    body1: {
      color: palette.grey[400],
    },
    body1Variation: {
      color: palette.grey[500],
    },
    body2Variation: {
      color: palette.grey[400],
      fontWeight: 600,
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },
  noInstructors: {
    image: noInstructorsImage,
  },
  prices: {
    avatar: {
      background: "linear-gradient(90deg, #F9812A 0%, #FDA147 100%)",
      color: palette.primary.contrastText,
    },
    alert: {
      backgroundColor: "transparent",
    },
    alertInfo: {
      backgroundColor: palette.background.dark,
    },

    inputField: {
      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        marginTop: "32px",
      },
      [`@media (max-width:${breakpoints.values.sm}px)`]: {
        marginTop: "24px",
        width: "285px",
        margin: "0 auto",
      },
    },
    subHeadingText: {
      padding: "20px 0 4px 0",
      textTransform: "uppercase",
      fontWeight: 600,
      fontSize: "12px",
      color: palette.text.primary,
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        padding: "30px 0 0 0",
        fontSize: "16px",
        fontWeight: "normal",
      },
    },
    infoPanel: {
      flex: "flex-end",
    },
  },
  lessonPackageText: {
    numLessons: {
      "& span": {
        textTransform: "uppercase",
      },
      "&.--checked": {
        color: palette.primary.main,
      },
    },
    price: {
      "&.--discount": {
        color: palette.primary.main,
      },
      "& span": {
        fontWeight: 600,
      },
    },
  },
  datePickerCarousel: {
    dayText: {
      textTransform: "uppercase",
      letterSpacing: "0.05em",
      fontWeight: 700,
    },
    monthText: {
      textTransform: "uppercase",
    },
    dateText: {
      "&.--checked": {
        color: palette.primary.main,
      },
    },
  },
  callToComplete: {
    headerWrapper: {
      background: palette.grey[50],
    },
    image: {
      alignSelf: "center",
      width: "100%",
      maxWidth: "375px",
    },
    callUs,
    testPassed,
    testBooked,
    surpriseGift,
    bodyText: {
      lineHeight: 1.5,
      color: palette.grey[500],
      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        margin: "50px 0 0",
      },
    },
  },
  marketingPreferences: {
    switchColor: "secondary",
  },
  itemListItem: {
    background: palette.grey[200],
    selectedBackground: palette.primary.main,
    selectedBorder: palette.primary.main,
  },
  lessonDetails: {
    card: {
      boxShadow: "0px 12px 24px rgba(78,- 62, 37, 0.15)",
    },
    primaryAvatar: {
      backgroundColor: "#E6604F",
      color: palette.primary.contrastText,
    },
    secondaryAvatar: {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
    },
    costAvatar: {
      backgroundColor: "#807B84",
      color: palette.common.white,
    },
  },
  loadingScreen: {
    container: {
      background: gradients.primaryLinear,
      height: "calc(100vh - 60px)",
    },
  },
  circularProgress: {
    root: {
      border: `5px solid rgba(255, 255, 255, 0.5)`,
    },
    circle: {
      stroke: palette.common.white,
    },
    percentage: {
      color: palette.common.white,
    },
    step: {
      color: palette.common.white,
    },
  },
  circularProgressAlt: {
    root: {
      border: `5px solid #F4F3F5`,
    },
    circle: {
      stroke: palette.primary.main,
    },
    percentage: {
      color: palette.grey[400],
    },
    step: {
      color: palette.text.primary,
    },
  },
  commonConfirmation: {
    detailBanner: {
      maxWidth: "650px",
      margin: "0 0 20px 0",
      paddingBottom: "30px",
      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        paddingBottom: "10px",
        flexDirection: "row",
        margin: "0 auto ",
        alignItems: "flex-end",
      },
    },
    bsmBannerImage: {
      alignSelf: "center",
      width: "100%",
      marginTop: "-20px",
      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        width: "calc(100% - 282px)",
        marginBottom: "-50px",
      },
    },
    detailsBlock: {
      marginTop: "-11.5%",
      width: "80%",
      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        transform: "translateX(-25px)",
        flex: "0 0 282px",
      },
    },
    shareLink: {
      color: palette.primary.main,
    },
    shareLinkButton: {
      color: palette.primary.main,
    },
    appFooter: {
      width: "100%",
    },
  },
  nujConfirmation: {
    bodyWrapper: {
      padding: "55px 0 200px 0",
      [`@media (min-width:${breakpoints.values.md}px)`]: {
        padding: "55px 0 200px 0",
      },
    },
    titleText: {
      color: palette.grey[400],
      marginBottom: "24px",
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "center",
      padding: "40px 0 60px",
      [`@media (max-width:${breakpoints.values.xs}px)`]: {
        padding: "40px",
      },
    },
    bulletList: {
      listStyle: "outside",
      paddingLeft: "20px",
    },
  },
  RUJConfirmation: {
    bodyWrapper: {
      padding: "20px 0 150px 0",
      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        padding: "60px 0 150px 0",
      },
    },
  },
  RUJTalkToUs: {
    sendMessageButton: {
      color: palette.primary.main,
      margin: "1em",
    },
  },
  qtjConfirmation: {
    headerWrapper: {
      background: palette.grey[50],
    },
    card: {
      margin: "-40px auto 50px auto",
      width: "95%",
      maxWidth: "340px",
      padding: " 8px 0 24px 0",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.23)",
    },
    exitButton: {
      border: "2px solid",
      minWidth: "150px",
    },
    bannerImage: {
      alignSelf: "center",
      width: "100%",
      maxWidth: "375px",
      marginTop: "-100px",
    },
    qtjConfirmationImage,
  },
  existingPupil: {
    headerWrapper: {
      background: palette.grey[50],
    },
    bannerImage: wavingGif,
    headerImage: {
      width: "100%",
      maxWidth: "375px",
      alignSelf: "center",
      marginTop: "-50px",
    },
  },
  somethingsGoneWrong: {
    headerWrapper: {
      background: palette.grey[50],
    },
    errorImage: {
      alignSelf: "center",
      width: "80%",
    },
  },
  bookedLessons: {
    avatar: {
      color: palette.grey[400],
      background: palette.grey[200],
      fontWeight: 600,
    },
    listTitle: {
      marginBottom: "4px",
      textTransform: "uppercase",
      fontSize: "0.75rem",
      fontWeight: 600,
      padding: "0 16px",
      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        fontSize: "1rem",
        marginBottom: "12px",
        padding: 0,
        fontWeight: 300,
      },
    },
  },
  bookedLessonsListIcon: Check,
  bookedLessonsListIconColor: "inherit",
  keyWorker: {
    infoPanelIcon: {
      width: "36px",
      height: "36px",
      position: "relative",
      top: "16px",
      left: "10px",
      color: "#4E1EB5",
    },
  },
  faceCovering: {
    headerWrapper: {
      fontSize: "30px",
    },
    infoPanelIcon: {
      width: "36px",
      height: "36px",
      position: "relative",
      top: "16px",
      left: "10px",
      color: "#4E1EB5",
    },
    infoPanelText: {
      fontSize: "14px",
      color: palette.grey[400],
      lineHeight: "24px",
    },
    infoPanelTitle: {
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "21px",
    },
    infoPanelLink: {
      color: "#3933B7",
    },
    imageContainer: {
      marginTop: "-55px",
    },
  },
  faceCoveringListItemNumber: {
    root: {
      backgroundColor: palette.grey[400],
      width: "15px",
      height: "15px",
      borderRadius: "2px",
      marginRight: "11px",
    },
    number: {
      fontWeight: 600,
      fontSize: "13px",
      lineHeight: "13px",
    },
  },
  personalDetails: {
    infoPanelIcon: {
      width: "36px",
      height: "36px",
      position: "relative",
      top: "16px",
      left: "10px",
      color: "#4E1EB5",
    },
    giftIcon: {
      "& path": {
        stroke: "#4E1EB5",
      },
    },
  },
  lessonTypes: {
    giftInfo: {
      fontSize: "18px",
      color: palette.grey[400],
      marginTop: "16px",
      marginBottom: "6px",
    },
    giftIcon: {
      marginRight: "12px",
      "& path": {
        stroke: "#4E1EB5",
      },
    },
  },
  progressTracker: {
    pageTitle: {
      lineHeight: "32px",
      fontSize: "24px",
    },
    overallProgressPercent: {
      fontSize: "32px",
      lineHeight: "32px",
      fontWeight: "normal",
      letterSpacing: "-0.3px",
    },
    updated: {
      marginBottom: "24px",
    },
    progressMeterWrapper: {
      justifyContent: "center",
      display: "flex",
      marginBottom: "24px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        marginBottom: "48px",
      },
    },
    categoryName: {
      marginBottom: "9px",
      fontSize: "16px",
      lineHeight: "16px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        fontSize: "18px",
        lineHeight: "18px",
      },
    },
    categories: {
      marginBottom: "39px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        marginBottom: "63px",
      },
    },
    percentComplete: {
      fontSize: "16px",
      lineHeight: "16px",
    },
    progress: {
      width: "100%",
    },
    infoText: {
      marginBottom: "12px",
      fontSize: "18px",
      lineHeight: "27px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    skillName: {
      fontSize: "14px",
      lineHeight: "14px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        fontSize: "16px",
        lineHeight: "16px",
      },
    },
    score: {
      fontSize: "20px",
      lineHeight: "20px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        fontSize: "22px",
        lineHeight: "22px",
      },
    },
    skillWrapper: {
      display: "flex",
      justifyContent: "space-between",
      padding: "12px 41px 12px 16px",
      alignItems: "center",
    },
  },
  noProgress: {
    image: {
      alignSelf: "center",
      marginBottom: "25px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        marginBottom: "48px",
      },
    },
    text: {
      fontSize: "16px",
      marginBottom: "20px",
      width: "285px",
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        fontSize: "18px",
        width: "486px",
      },
    },
    para2: {
      fontWeight: "bold",
    },
  },
  accountButton: {
    root: {
      color: palette.grey[400],
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        color: "white",
      },
    },
    label: {
      color: palette.grey[400],
      [`@media(min-width:${breakpoints.values.md}px)`]: {
        color: "white",
      },
    },
  },
};

export default journey;
