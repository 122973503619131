import {
  getInstructorByInstructorId,
  getSelectedPackageIndex,
} from "../../../../../utils/instructor-prices";
import { UPDATE_NUJ_HISTORY } from "../../../../../context/stateContext";
import { goBack } from "../../../../../utils/navigation";

export const changeInstructor = ({
  id,
  history,
  location: { pathname },
  availableInstructors,
  selectedPackage,
  dispatch,
  trackEvent,
}) => {
  if (id) {
    const instructor = getInstructorByInstructorId(availableInstructors, id);

    const selectedPackageIndex = getSelectedPackageIndex(
      instructor.prices,
      selectedPackage.hours,
    );

    if (instructor) {
      dispatch({
        type: UPDATE_NUJ_HISTORY,
        payload: {
          selectedInstructor: instructor,
          selectedLessonDate: "",
          selectedLessonTimeslot: "",
          selectedPackage: instructor.prices[selectedPackageIndex],
        },
      });

      trackEvent({
        eventCategory: "Change Instructor",
        eventAction: "Interaction",
        eventLabel: "Select Instructor",
      });

      goBack({ history, pathname });
    }
  }
};

export default changeInstructor;
