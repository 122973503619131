import { History } from "history";

import {
  UPDATE_NUJ_HISTORY,
  CLEAR_NUJ_SELECTED_INSTRUCTOR,
} from "../../../../context/stateContext";
import { NEW_USER_JOURNEY_PATH_NAMES } from "../../../../constants";

import { CountryDetails, Dispatch } from "@lessons/models";

export const submitSelectCountryForm = ({
  selectedCountryDetails,
  dispatch,
  history,
}: {
  dispatch: Dispatch;
  history: History;
  selectedCountryDetails?: CountryDetails;
}) => {
  dispatch({
    type: UPDATE_NUJ_HISTORY,
    payload: {
      isRestrictedForKeyWorker: true,
      selectedCountry: selectedCountryDetails,
    },
  });

  dispatch({
    type: CLEAR_NUJ_SELECTED_INSTRUCTOR,
  });

  if (selectedCountryDetails && selectedCountryDetails.isOpen) {
    return history.push(NEW_USER_JOURNEY_PATH_NAMES.LESSON_TYPES);
  }

  if (
    selectedCountryDetails &&
    !selectedCountryDetails.isOpen &&
    selectedCountryDetails.isOpenToKeyWorkers
  ) {
    dispatch({
      type: UPDATE_NUJ_HISTORY,
      payload: {
        isRestrictedForKeyWorker: true,
      },
    });
    return history.push(NEW_USER_JOURNEY_PATH_NAMES.KEY_WORKER);
  }

  if (
    selectedCountryDetails &&
    !selectedCountryDetails.isOpen &&
    !selectedCountryDetails.isOpenToKeyWorkers
  ) {
    return history.push(NEW_USER_JOURNEY_PATH_NAMES.LESSON_TYPES);
  }
};

export default {
  submitSelectCountryForm,
};
