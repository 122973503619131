import React from "react";

const Tick: React.FC = () => (
  <svg
    width="96"
    height="76"
    viewBox="0 0 96 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.7726 48.8137L82.5863 0L96 13.4137L33.7726 75.6411L0 41.8685L13.4137 28.4548L33.7726 48.8137Z"
      fill="#07828D"
    />
  </svg>
);

export default Tick;
