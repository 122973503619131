import * as React from "react";

import mergeDeep from "lodash.merge";
import { useHistory, useLocation } from "react-router-dom";
import { Box, makeStyles, Theme } from "@material-ui/core";

import { useBrand } from "../../common/BrandProvider/BrandProvider";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import Titlebar from "../../common/Titlebar/Titlebar";
import { ReturningUserJourneyProgressProps } from "./types";

import WithProgressData from "./components/WithProgressData";
import NoProgress from "./components/NoProgress";
import { goBack } from "../../../utils/navigation";

const useStyles = makeStyles(
  (theme: Theme & { journey: { progressTracker: Record<string, any> } }) =>
    mergeDeep(theme.journey.progressTracker, {
      headerOverrides: {
        margin: "0 0 60px",
        width: "100%",
      },
    }),
);

const ReturningUserJourneyProgress: React.FC<ReturningUserJourneyProgressProps> = ({
  categories = [],
  overallProgress = 0,
}) => {
  const { getText } = useBrand();
  const history = useHistory();
  const { pathname } = useLocation();
  const classes = useStyles();

  return (
    <ContentWrapper>
      <Titlebar
        showBackButton
        onBackClick={() => goBack({ history, pathname })}
        headingProps={{
          "data-test": "progress-tracker",
          className: classes.headerOverrides,
        }}
        centerText={false}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <span className={classes.pageTitle}>
            {getText("returning/progress-tracker.pageTitle")}
          </span>
          <span
            className={classes.overallProgressPercent}
            title="Overall progress percentage"
          >
            {overallProgress.toFixed(0)}%
          </span>
        </Box>
      </Titlebar>

      {overallProgress === 0 || categories.length === 0 ? (
        <NoProgress />
      ) : (
        <WithProgressData
          categories={categories}
          overallProgress={overallProgress}
        />
      )}
    </ContentWrapper>
  );
};
export default ReturningUserJourneyProgress;
