import React, { Fragment } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Link,
  useMediaQuery,
  Theme,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { ReactComponent as AmazonLogo } from "../../../../svg/amazon.svg";
import { ReactComponent as LIDLLogo } from "../../../../svg/lidl.svg";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import { LIDL_REFERRAL, LIDL_TERMS } from "../../../../constants";
import ContentWrapper from "../../../common/ContentWrapper/ContentWrapper";
import { Brand } from "@graphql/types";

const useStyles = ({ isLIDLReferral }: { isLIDLReferral: boolean }) =>
  makeStyles(theme => ({
    container: {
      backgroundColor: isLIDLReferral ? "#3BD1C4" : theme.palette.grey[500],
      marginBottom: "24px",
      padding: "16px",
      [theme.breakpoints.up("sm")]: {
        borderRadius: "4px",
      },
      alignItems: isLIDLReferral ? "start" : "centre",
    },
    title: {
      color: isLIDLReferral
        ? theme.palette.grey[400]
        : theme.palette.common.white,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: "0.875rem",
      lineHeight: "20px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "1rem",
      },
    },
    subTitle: {
      color: isLIDLReferral
        ? theme.palette.grey[400]
        : theme.palette.common.white,
      fontWeight: theme.typography.fontWeightMedium,
    },
    body: {
      color: isLIDLReferral ? theme.palette.grey[400] : theme.palette.grey[300],
      fontSize: "0.875rem",
      lineHeight: "20px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "1rem",
      },
    },
    link: {
      color: isLIDLReferral
        ? theme.palette.grey[400]
        : theme.palette.common.white,
      textDecoration: "underline",
    },
    voucherWrapper: {
      width: "64px",
    },
    voucherValue: {
      color: "#FF9900", // Amazon orange
      textAlign: "center",
      fontWeight: theme.typography.fontWeightBold,
      fontSize: "1.125rem",
      lineHeight: 1.2,
      [theme.breakpoints.up("sm")]: {
        lineHeight: 1.35,
      },
    },
    voucherSubtitle: {
      color: "white",
      textAlign: "center",
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1,
      fontSize: "0.875rem",
    },
  }));

const ReferralLandingBanner = ({ referralCode }: { referralCode: string }) => {
  const { brand, getText } = useBrand();
  const isLIDLReferral = brand === Brand.Aads && referralCode === LIDL_REFERRAL;
  const classes = useStyles({ isLIDLReferral })();
  const theme = useTheme<Theme>();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  return (
    <ContentWrapper fullHeight fullWidth={isMobile}>
      <Grid
        container
        className={classes.container}
        alignItems="center"
        data-test="referral-landing-banner"
      >
        <Grid item xs={3} sm={3}>
          <div className={classes.voucherWrapper}>
            {isLIDLReferral && <LIDLLogo />}
            {!isLIDLReferral && (
              <Fragment>
                <AmazonLogo />
              </Fragment>
            )}
          </div>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom className={classes.body}>
            {isLIDLReferral && (
              <Fragment>
                <span className={classes.subTitle}>Get 1 hour free </span>
                when you buy 6 or 10 hours. This will be credited automatically
                to your account.&nbsp;
                <Link
                  href={LIDL_TERMS}
                  target="_blank"
                  component="a"
                  data-test="lidl-referral-terms"
                  className={classes.link}
                >
                  Terms apply.
                </Link>
              </Fragment>
            )}
            {!isLIDLReferral && (
              <Fragment>
                {getText("pickup-location.referralBannerBody")}
              </Fragment>
            )}
          </Typography>
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

export default ReferralLandingBanner;
