import React, { Fragment } from "react";
import { bool, string } from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import { differenceInHours } from "date-fns";
import mergeDeep from "lodash.merge";

import ContentWrapper from "../ContentWrapper/ContentWrapper";
import AppFooter from "../AppFooter/AppFooter";
import LessonDetails from "../LessonDetails/LessonDetails";
import Titlebar from "../Titlebar/Titlebar";

import { useBrand } from "../BrandProvider/BrandProvider";
import bsmNUJConfirmationImage from "../../../svg/bsm-nuj-success.svg";
import confirmationBackground from "../../../jpg/aad-success.jpg";
import { soapTimeToLocalTime } from "../../../utils/soapTimeToLocalTime";

import { Brand } from "@graphql/types";

const useStyles = makeStyles(theme =>
  mergeDeep(theme.journey.commonConfirmation, {
    headerWrapper: {
      background: theme.palette.grey[50],
    },
    headerWrapperImage: ({ aadBannerImage }) => ({
      backgroundImage: `url(${aadBannerImage})`,
    }),
    detailBanner: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  }),
);

const Confirmation = ({
  title,
  pickupPostcode,
  startTime,
  endTime,
  instructorName,
  children,
  bsmBannerImage = bsmNUJConfirmationImage,
  aadBannerImage = confirmationBackground,
  darkBannerImage = false,
  centerText,
}) => {
  const theme = useTheme();
  const { getText, brand } = useBrand();
  const classes = useStyles({ aadBannerImage });
  return (
    <Fragment>
      <div
        className={
          brand === Brand.Aads
            ? `${classes.headerWrapper} ${classes.headerWrapperImage}`
            : classes.headerWrapper
        }
        data-test="header-wrapper"
      >
        <ContentWrapper>
          <Titlebar
            headingProps={{
              "data-test": "confirmation-title-bar",
            }}
            lightText={darkBannerImage}
            centerText={centerText}
          >
            {title}
          </Titlebar>
        </ContentWrapper>

        <div className={classes.detailBanner} data-test="confirmation-banner">
          {theme.journey.commonConfirmation.bsmBannerImage && (
            <img
              className={classes.bsmBannerImage}
              src={bsmBannerImage}
              alt=""
            />
          )}

          <div className={classes.detailsBlock}>
            <LessonDetails
              title={getText("confirmation.lessonDetailsTitle")}
              lesson={{
                startTime: soapTimeToLocalTime(startTime),
                duration: differenceInHours(
                  new Date(endTime),
                  new Date(startTime),
                ),
                location: pickupPostcode,
              }}
              instructorName={instructorName}
            />
          </div>
        </div>
      </div>

      <ContentWrapper fullHeight>{children}</ContentWrapper>

      {theme.journey.commonConfirmation.appFooter && <AppFooter />}
    </Fragment>
  );
};

Confirmation.propTypes = {
  title: string.isRequired,
  pickupPostcode: string,
  startTime: string.isRequired,
  endTime: string.isRequired,
  instructorName: string.isRequired,
  bsmBannerImage: string,
  aadBannerImage: string,
  darkBannerImage: bool,
  centerText: bool,
};

export default Confirmation;
