import React from "react";

const TheoryAppPrivacyNotice: React.FC = () => (
  <div style={{ padding: 50 }}>
    <h2 id="aa-driving-theory-app-privacy-notice">
      AA Driving Theory App Privacy Notice
    </h2>
    <p>
      This privacy notice lets you know what happens to the personal data we
      collect, use and hold when you, your beneficiaries hold or use the AA
      Driving Theory Test App. For other AA Group Privacy Notice please click{" "}
      <a href="https://www.theaa.com/privacy-notice">here</a>. If you provide us
      with personal information on behalf of another person, you must ensure
      that it is accurate, up to date and that you have their authorisation to
      do so. You should make sure that you provide them with a copy of this
      Privacy Notice or let them know how to access it. Where this Privacy
      Notice refers to &quot;you&quot; this also includes data about anyone
      else’s data you provide us with.{" "}
    </p>
    <p>
      We are the AA. Our main address is Fanum House, Basing View, Basingstoke,
      Hampshire, RG21 4EA. The data controller of the AA Driving Theory App is
      the Automobile Association Automobile Association Developments Limited
      (trading as AA Driving School and BSM Driving School). We have a Data
      Protection Officer who you can contact by using the contact details at the
      bottom of this notice.{" "}
    </p>
    <p>Personal data we hold and use </p>
    <ul>
      <li>Name </li>
      <li>Email Address </li>
      <li>Username/UserID </li>
      <li>Date of birth </li>
      <li>Records of your contact with us </li>
      <li>IP address and device identifiers </li>
      <li>Theory test date </li>
      <li>
        Details of your usage of the AA driving school theory app including
        analytics and daily streak counts{" "}
      </li>
      <li>
        Marketing information, including records of marketing communications,
        details of what you may be interested in, analysis and profiles we build
        up about you and your interests, and whether you open or read
        communications or links;
        <br></br>
        We may be unable to provide you with our products or services if you do
        not provide certain information to us. In cases where providing some
        personal information is optional, we’ll make this clear.{" "}
      </li>
    </ul>
    <p>
      <strong>Sources of personal data</strong>{" "}
    </p>
    <ul>
      <li>
        You directly, and any information from family members, beneficiaries of
        products and services (for example, if they are authorised to act for
        you or are allowed to use a service you have with us);{" "}
      </li>
      <li>
        Information generated about you when you use our products and services;{" "}
      </li>
      <li>Data obtained from your usage of the AA Driving Theory App </li>
    </ul>
    <p>
      <strong>Reasons for using your personal data</strong>
      The reasons for using your personal data are below. We have arranged them
      according to the legal reason we are allowed to use the data.{" "}
    </p>
    <ol>
      <li>To provide you with the AA Driving Theory App: </li>
    </ol>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. Communicating with you and
      holding records about our dealings and interactions with you;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. To manage the operation of
      our business;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. To manage the operation of
      our business and business partners that help support the AA Driving Theory
      App;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d. For analysing and profiling
      aspects of your app usage and test scores including recommending
      improvements and revision tips;{" "}
    </p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e. Updating your records; </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;f. To enable other AA group
      branded and non-branded companies to provide you with products and
      services, quote for products and services, or manage products and services
      you hold; and{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;g. To share information as
      needed with business partners as required for managing your product or
      assessing application account beneficiaries, service providers or as part
      of providing, administering or developing our products and services or our
      business.{" "}
    </p>
    <ol>
      <li>For our legitimate interests or those of others: </li>
    </ol>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. To develop the AA Driving
      School Theory App;
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. To provide personalised
      content and services to you, such as tailoring our products and services,
      our digital customer experience and offerings, and deciding which offers
      or promotions to show you on our digital channels;
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. To test and improve the
      performance of our products, services, processes and systems;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d. To improve the operation of
      our business - and that of our business partners;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e. To develop new products and
      services, and to review and improve current products and services;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;f. For management and auditing
      of our business operations - including accounting;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;g. To monitor and to keep
      records of our communications with you and our staff (see below);{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;h. To understand our customers,
      their use of our products, their preferences and develop models, including
      developing profiles, algorithms and statistical models;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. Subject to your preferences,
      for marketing analysis and related profiling to help us to offer you
      relevant products and services, including deciding whether or not to offer
      you certain products and services;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;j. Subject to your preferences,
      to send you marketing by, push notification, email, social media and
      digital channels (e.g. using Facebook Custom Audiences and Google Custom
      Match). Offers may relate to any of our products and services such as
      cars, insurance, member offers as well as to any other offers and advice
      we think may be of interest;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;k. To provide insight and
      analysis of our customers both for ourselves and business partners based
      on your products, your use of it, your other use of your products by
      others;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;l. For market research,
      profiling, and analysis and developing statistics;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;m. To facilitate the sale of one
      or more parts of our business;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;n. To share information with
      business partners as necessary for the purposes listed in this notice; and{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o. To share information with
      other AA Group, AA branded and non-AA branded companies to enable them to
      perform any of the above purposes.{" "}
    </p>
    <ol>
      <li>
        <p>
          To comply with our legal obligations such as our financial services or
          regulatory obligations such as our financial services or regulatory
          obligations, including Financial Conduct Authority, Prudential Conduct
          Authority and Financial Ombudsman Service rules, regulations and
          guidance.{" "}
        </p>
      </li>
      <li>
        <p>With your consent or explicit consent: </p>
      </li>
    </ol>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. For some direct marketing
      communications which are not based on our legitimate interests;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. For some of our profiling and
      other automated decision making which is not required for contractual or
      legal purposes; and{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. For some of our processing of
      special categories of personal data such as about your health (where
      applicable), or if you are a vulnerable customer, if another legal basis
      does not apply.{" "}
    </p>
    <p>
      <strong>Sharing and disclosures of your personal data</strong>{" "}
    </p>
    <p>
      The categories of third parties we use are listed below. We will use these
      third parties for all the reasons we have described in this notice and
      they may process the types of personal information we also hold or use.{" "}
    </p>
    <ul>
      <li>
        With AA Group, AA branded and non-AA branded companies, including but
        not limited to Automobile Association Developments Limited (including AA
        Breakdown Services), Automobile Association Insurance Services Limited,
        AA Underwriting Insurance Company Limited and AA Financial Services
        Limited;{" "}
      </li>
      <li>
        With service providers who are a part of providing products and services
        to you or help us to operate our business;{" "}
      </li>
      <li>
        Police and law enforcement agencies if we are required or need to
        support a criminal investigation;{" "}
      </li>
      <li>
        Organisations and businesses who provide services to us under our
        authority such as service providers, IT companies, and suppliers of
        business support services; and{" "}
      </li>
      <li>
        Market research organisations who help us to develop and improve our
        products and services.{" "}
      </li>
    </ul>
    <p>
      <strong>Transfers outside of the UK or EEA</strong>{" "}
    </p>
    <p>
      Your personal information may be transferred outside the European Economic
      Area, for example to service providers. If we do so, we’ll make sure that
      suitable safeguards are in place where required, for example by using
      approved contractual agreements or other legal arrangements unless certain
      exceptions apply.{" "}
    </p>
    <p>
      <strong>Use of automated decisions</strong>{" "}
    </p>
    <p>
      There are no automated decisions associated with your usage of the AA
      Driving School Theory App.{" "}
    </p>
    <p>
      <strong>Changes to your data</strong>{" "}
    </p>
    <p>
      You should tell us when there are any changes to Your personal data, you
      can do this any time in the AA Driving Theory App settings.{" "}
    </p>
    <p>
      <strong>Monitoring communications</strong>{" "}
    </p>
    <p>
      We may monitor communications with you, where permitted by law. We do this
      for quality control and staff training purposes, to comply with regulatory
      rules, to prevent or detect crime, to protect the security of our
      communications, data, and systems, and to enforce compliance with our
      internal policies.{" "}
    </p>
    <p>
      <strong>Retention of your personal data</strong>{" "}
    </p>
    <p>
      Unless we explain otherwise to you, we’ll hold your personal information
      based on the following criteria:{" "}
    </p>
    <ul>
      <li>
        For as long as we provide products or services to you and then for as
        long as someone could bring a claim against us;{" "}
      </li>
      <li>To comply with legal and regulatory requirements or guidance; or </li>
      <li>For as long as we have reasonable business needs.  </li>
    </ul>
    <p>
      <strong>Your data protection rights</strong>{" "}
    </p>
    <p>
      Here is a list of the rights that all individuals have under UK data
      protection laws. They don’t apply in all circumstances so your request may
      not always be granted. If you wish to use any of them, we’ll explain at
      that time if they apply or not, and if we will comply or not with you
      request, including the reasons why.{" "}
    </p>
    <ul>
      <li>
        The right to be informed about your processing of your personal
        information;{" "}
      </li>
      <li>
        The right to have your personal information corrected if it is
        inaccurate and to have incomplete personal information completed;{" "}
      </li>
      <li>The right to object to processing of your personal information; </li>
      <li>The right to restrict processing of your personal information; </li>
      <li>The right to have your personal information erased; </li>
      <li>
        The right to request access to your personal information and how we
        process it;{" "}
      </li>
      <li>
        The right to move, copy or transfer your personal information; and{" "}
      </li>
      <li>
        Rights in relation to automated decision making which has a legal effect
        or otherwise significantly affects you.{" "}
      </li>
    </ul>
    <p>
      You have the right to complain to the Information Commissioner’s Office
      which enforces data protection laws -{" "}
      <a href="https://ico.org.uk/">https://ico.org.uk/</a>. You can contact our
      DPO for more details on all the above.{" "}
    </p>
    <p>
      <strong>You have a right to object</strong>{" "}
    </p>
    <p>
      You have the right to object to certain purposes for processing, in
      particular to data used for direct marketing purposes and to data
      processed for certain reasons based on our legitimate interests. You can
      contact us using the contact details in your policy documents or listed
      below to exercise these rights.{" "}
    </p>
    <p>
      <strong>Opting out of marketing</strong>{" "}
    </p>
    <p>
      You can stop our marketing at any time by contacting us using the details
      below or by following the instructions in the communication. You can also
      email dataprotection@theaa.com{" "}
    </p>
    <p>
      <strong>Withdrawing your consent</strong>{" "}
    </p>
    <p>
      Where we rely on your consent, you can withdraw it at any time in the AA
      Driving Theory App settings.{" "}
    </p>
    <p>
      <strong>Changes to this privacy notice</strong>{" "}
    </p>
    <p>
      We may change this privacy notice from time to time to reflect changes in
      the law and/or our privacy practices. We encourage you to check this
      privacy notice for changes periodically, you can do this any time in the
      AA Driving Theory App settings.{" "}
    </p>
    <p>
      <strong>Contact Us or our DPO</strong>{" "}
    </p>
    <p>
      You can use the contact details in your policy book or you can go to the
      Contact Us section of our website. Alternatively, you can write to AA PLC,
      Fanum House, Basing View, Basingstoke, Hampshire, RG21 4EA, marking it for
      the attention of the DPO or email dataprotection@theaa.com
    </p>
  </div>
);

export default TheoryAppPrivacyNotice;
