/* eslint-disable no-unused-vars */
export enum Country {
  Wales = "Wales",
  England = "England",
  Scotland = "Scotland",
  NorthernIreland = "NorthernIreland",
}

export enum CountryAccessStatus {
  Open = "OPEN",
  Closed = "CLOSED",
  Undetermined = "UNDETERMINED",
  Invalid = "INVALID",
}

export type CountryDetails = {
  name: Country;
  isOpen: boolean;
  isOpenToKeyWorkers: boolean;
};

export type PostcodeCountryDetailsResponse = {
  accessStatus?: CountryAccessStatus;
  isOpenToKeyWorkers?: boolean;
  countries?: CountryDetails[];
  loading?: boolean;
};
