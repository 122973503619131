import React from "react";
import { useLocation } from "react-router-dom";
import { Typography, makeStyles } from "@material-ui/core";

import Button from "../Button/Button";
import CalendarIcon from "./CalendarIcon";
import { useTealium } from "../TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  text: {
    margin: `${theme.spacing(4)}px 0`,
  },
}));

const NoBookedLessons = ({ text, buttonURL }) => {
  const classes = useStyles();
  const { trackEvent } = useTealium();
  const { pathname } = useLocation();

  return (
    <div data-test="no-booked-lessons-wrapper" className={classes.wrapper}>
      <CalendarIcon />
      {text && <Typography classes={{ root: classes.text }}>{text}</Typography>}
      {buttonURL && (
        <Button
          to={buttonURL}
          text="Book a lesson"
          navButton
          data-test="book-lesson-button"
          onClick={() => {
            trackEvent({
              eventCategory: capitalizeEachWordOfPathname(pathname),
              eventAction: "Button Click",
              eventLabel: "Book A Lesson",
            });
          }}
        />
      )}
    </div>
  );
};

export default NoBookedLessons;
