import React, { Fragment, useState } from "react";
import { arrayOf, string, func, bool } from "prop-types";
import { Typography, ListItemSecondaryAction } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import { InfoOutlined } from "@material-ui/icons";
import RadioListItem from "../../../../common/RadioItemList/RadioListItem";
import RadioListItemText from "../../../../common/RadioItemList/RadioListItemText";
import InfoModal from "./LessonTypeInfoModal";
import { useTealium } from "../../../../common/TealiumProvider/TealiumProvider";
import { LESSON_TYPES } from "../../../../../constants";

const useStyles = makeStyles(theme => ({
  listItemText: {
    paddingRight: "24px",
  },
  listItemSecondaryText: {
    fontSize: "16px",
  },
  listItemIcon: {
    color: theme.palette.grey[400],
    opacity: 0.7,
  },
}));

export const eventCategory = {
  [LESSON_TYPES.REFRESHER]: "Short Refresher",
  [LESSON_TYPES.MOTORWAY]: "Motorway",
  [LESSON_TYPES.PASS_PLUS]: "Pass Plus",
};

const TestPassedOptionItem = ({
  text,
  summary,
  value,
  modalTitle,
  modalContent,
  modalMoreInfoLinkPath,
  modalMoreInfoLinkText,
  modalActionButtonText,
  onClick,
  selected,
  testId,
}) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const { trackEvent, updateDataLayer } = useTealium();

  const toggleModal = () => {
    trackEvent({
      eventAction: !showModal ? "Modal - Click" : "Modal - Close",
      eventCategory: "Test Passed",
      eventLabel: eventCategory[value],
    });
    setShowModal(!showModal);
  };

  const handleLessonTypeClick = selectedValue => {
    trackEvent({
      eventAction: "Interaction",
      eventCategory: "Test Passed",
      eventLabel: eventCategory[value],
    });
    updateDataLayer({
      refresher_type: eventCategory[value],
    });
    onClick(selectedValue);
  };

  return (
    <Fragment>
      <InfoModal
        title={modalTitle}
        content={modalContent}
        moreInfoLinkPath={modalMoreInfoLinkPath}
        moreInfoLinkText={modalMoreInfoLinkText}
        actionButtonText={modalActionButtonText}
        showModal={showModal}
        toggleModal={toggleModal}
        testId={`${value.toLowerCase()}-modal`}
      />
      <RadioListItem
        value={value}
        selected={selected}
        onClick={() => handleLessonTypeClick(value)}
        testId={testId}
      >
        <RadioListItemText
          className={classes.listItemText}
          text={text}
          selected={selected}
        >
          <Typography
            className={classes.listItemSecondaryText}
            component="span"
          >
            {` - ${summary}`}
          </Typography>
        </RadioListItemText>
        <ListItemSecondaryAction>
          <IconButton
            className={classes.listItemIcon}
            edge="end"
            onClick={toggleModal}
            size="small"
            color="inherit"
            data-test={`${value.toLowerCase()}-info-icon`}
          >
            <InfoOutlined />
          </IconButton>
        </ListItemSecondaryAction>
      </RadioListItem>
    </Fragment>
  );
};

TestPassedOptionItem.propTypes = {
  text: string,
  summary: string,
  value: string,
  modalTitle: string,
  modalContent: arrayOf(string),
  modalMoreInfoLinkPath: string,
  modalMoreInfoLinkText: string,
  modalActionButtonText: string,
  onClick: func,
  selected: bool,
  testId: string,
};

export default TestPassedOptionItem;
