import {
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";

import { BSM_BRAND_NAME } from "../../../../../constants";
import CrossSellAAtoBSMIcon from "./crossSellAAtoBSM.svg";
import CrossSellBSMtoAAIcon from "./crossSellBSMtoAA.svg";

import { CrossSellModalProps } from "./types";

const CrossSellModal: React.FC<CrossSellModalProps> = ({
  currentBrand,
  classes,
  title,
}) => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div className={classes.crossSellModal} data-test="cross-sell-modal">
      <Typography className={classes.crossSellTitle}>{title}</Typography>

      <div className={classes.crossSellModalWrap}>
        <img
          className={classes.illustration}
          src={
            currentBrand === BSM_BRAND_NAME
              ? CrossSellAAtoBSMIcon
              : CrossSellBSMtoAAIcon
          }
        />
        <div className={classes.crossSellSpinner}>
          <CircularProgress size={isMobile ? 40 : 55} />
        </div>
      </div>
    </div>
  );
};

export default CrossSellModal;
