import React from "react";
import { string } from "prop-types";
import { Avatar, makeStyles } from "@material-ui/core";
import { getInitialsFromName } from "../../../../../utils";

const useStyles = makeStyles(({ journey, typography }) => ({
  avatarRoot: {
    fontWeight: typography.fontWeightMedium,
    fontSize: "1.125rem",
    background: journey.itemListItem.background,
  },
}));

const Initials = ({ name }) => {
  const classes = useStyles();
  return (
    <Avatar className={classes.avatarRoot}>{getInitialsFromName(name)}</Avatar>
  );
};

Initials.propTypes = {
  name: string.isRequired,
};

export default Initials;
