import {
  compareAsc,
  differenceInMinutes,
  format,
  isFuture,
  parseISO,
} from "date-fns";

import { LONG_DATE_FORMAT } from "../../../constants";

export const constructListItem = (lessonSlot, avatar) => ({
  id: lessonSlot.bookingId,
  text: format(
    parseISO(lessonSlot.lessonDate, LONG_DATE_FORMAT, new Date()),
    "E do MMMM",
  ),
  avatar,
  selectedAvatar: avatar,
  secondaryText: `${format(
    parseISO(lessonSlot.lessonDate, LONG_DATE_FORMAT, new Date()),
    "hh:mmaaa",
  )} — ${lessonSlot.lessonLength / 60} hours`,
  icon: true,
});

export const constructListItems = ({ upcoming, past }) => {
  const total = upcoming.length + past.length;
  return {
    upcoming: upcoming.map((slot, idx) => constructListItem(slot, total - idx)),
    past: past.map((slot, idx) =>
      constructListItem(slot, total - upcoming.length - idx),
    ),
  };
};

export const categoriseLessons = lessons =>
  lessons.reduce(
    (bookedLessons, lesson) => {
      if (isFuture(new Date(lesson.lessonDate))) {
        bookedLessons.upcoming.push(lesson);
      } else {
        bookedLessons.past.push(lesson);
      }
      return bookedLessons;
    },
    { upcoming: [], past: [] },
  );

export const filterAndSortLessons = lessons =>
  lessons
    .filter(({ status }) => status !== "Cancelled")
    .map(({ id, startDateTime, endDateTime }) => ({
      bookingId: id,
      lessonDate: startDateTime,
      lessonLength: differenceInMinutes(
        new Date(endDateTime),
        new Date(startDateTime),
      ),
    }))
    .sort(({ lessonDate: lessonDateA }, { lessonDate: lessonDateB }) =>
      compareAsc(new Date(lessonDateA), new Date(lessonDateB)),
    );
