import React, { Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Theme,
  Typography,
  Box,
  useMediaQuery,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import mergeDeep from "lodash.merge";

import { RETURNING_USER_JOURNEY_PATH_NAMES } from "../../../constants";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import Titlebar from "../../common/Titlebar/Titlebar";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import { ItemListItemConfig } from "../../common/ItemList/ItemList";
import NoBookedLessons from "../../common/NoBookedLessons";
import { goBack } from "../../../utils/navigation";

import LessonSlotItemList from "./LessonSlotItemList";
import { useStateContext } from "../../../context/stateContext";

interface CustomTheme extends Theme {
  journey: {
    bookedLessons: {
      noteText: Record<string, any>;
    };
  };
}

const useStyles = makeStyles<CustomTheme>(theme =>
  mergeDeep(theme.journey.bookedLessons, {
    noteText: {
      marginBottom: theme.spacing(5),
    },
  }),
);

type BookedLessonsProps = {
  upcomingLessons: ItemListItemConfig[];
  pastLessons: ItemListItemConfig[];
};

const BookedLessons: React.FC<BookedLessonsProps> = ({
  upcomingLessons,
  pastLessons,
}) => {
  const { getText } = useBrand();
  const history = useHistory();
  const { pathname } = useLocation();
  const theme = useTheme();
  const classes = useStyles();

  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  const {
    state: {
      rujHistory: { pupilBalance },
    },
  } = useStateContext();

  const bookButtonURL =
    pupilBalance >= 2
      ? RETURNING_USER_JOURNEY_PATH_NAMES.TIMESLOTS
      : RETURNING_USER_JOURNEY_PATH_NAMES.TOPUP_AMOUNT;

  return (
    <Fragment>
      <ContentWrapper>
        <Titlebar
          showBackButton
          onBackClick={() => goBack({ history, pathname })}
          headingProps={{ "data-test": "booked-lessons-title-bar" }}
        >
          {getText("returning/booked-lessons.pageTitle")}
        </Titlebar>

        <Typography classes={{ root: classes.noteText }}>
          <strong>{getText("returning/booked-lessons.noticeTitle")}</strong>
          {getText("returning/booked-lessons.notice")}
        </Typography>

        {!upcomingLessons.length && !pastLessons.length && (
          <NoBookedLessons
            text={getText("returning/booked-lessons.noLessons")}
            buttonURL={bookButtonURL}
          />
        )}
      </ContentWrapper>

      <ContentWrapper fullHeight fullWidth={isMobile}>
        {upcomingLessons.length > 0 && (
          <Box paddingBottom={3}>
            <Typography classes={{ root: classes.listTitle }}>
              {getText("returning/booked-lessons.upcomingLessonsTitle")}
            </Typography>
            <LessonSlotItemList
              lessonSlots={upcomingLessons}
              isUpcomingLesson
            />
          </Box>
        )}

        {pastLessons.length > 0 && (
          <Box paddingBottom={3}>
            <Typography classes={{ root: classes.listTitle }}>
              {getText("returning/booked-lessons.pastLessonsTitle")}
            </Typography>
            <LessonSlotItemList lessonSlots={pastLessons} />
          </Box>
        )}
      </ContentWrapper>
    </Fragment>
  );
};

export default BookedLessons;
