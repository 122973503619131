import { useQuery } from "@apollo/react-hooks";
import { isEmpty } from "lodash";

import {
  maskLastChars,
  formatInstructors,
  getGAClientId,
  formatBrandForGQLResponse,
} from "@utils";

import getInstructors from "../graphql/getInstructors.graphql";
import {
  AVAILABLE_NUJ_INSTRUCTORS_LOADED,
  UPDATE_NUJ_HISTORY,
} from "../context/stateContext";
import { AA_BRAND_NAME, BSM_BRAND_NAME } from "../constants";
import {
  TransmissionType,
  Brand,
  CabRank,
  CabRankNujInput,
} from "@graphql/types";

export type useGetInstructorsProps = CabRankNujInput & {
  dispatch: any;
  selectedInstructor: any;
  trackEvent: any;
  crossSellInstructorId: string;
  packageLengthsInHours: number[];
  lessonTypeDL: string;
  skip?: boolean;
};

const useGetInstructors = ({
  skip = false,
  brand: currentBrand,
  postcode,
  transmission = TransmissionType.Manual,
  selectedInstructor,
  lessonType,
  lessonTypeDL,
  dispatch,
  crossSellInstructorId,
  trackEvent,
  marketingChannel = null,
  marketingChannelId = null,
  keyWorker = null,
  testDateTime = null,
  testCentre = null,
  offersShortNoticeTest = false,
  lessonLengthHours,
  packageLengthsInHours = [2, 6, 10],
}: useGetInstructorsProps) =>
  useQuery<{ cabRankNUJ: CabRank }>(getInstructors, {
    skip: !postcode || skip,
    variables: {
      brand: currentBrand,
      lessonType,
      offersShortNoticeTest,
      postcode,
      transmission,
      marketingChannel,
      marketingChannelId,
      clientId: getGAClientId(),
      keyWorker,
      testDateTime,
      testCentre,
      lessonLengthHours,
      packageLengthsInHours,
    },
    onCompleted: data => {
      if (!data) {
        return;
      }

      const brandFilter = formatBrandForGQLResponse(currentBrand);

      const currentBrandInstructors = formatInstructors(
        data.cabRankNUJ[brandFilter],
        currentBrand,
        transmission,
        lessonTypeDL,
      );

      dispatch({
        type: AVAILABLE_NUJ_INSTRUCTORS_LOADED,
        payload: {
          [BSM_BRAND_NAME]: formatInstructors(
            data.cabRankNUJ["bsm"],
            Brand.Bsm,
            transmission,
            lessonTypeDL,
          ),
          [AA_BRAND_NAME]: formatInstructors(
            data.cabRankNUJ["aa"],
            Brand.Aads,
            transmission,
            lessonTypeDL,
          ),
        },
      });

      if (!currentBrandInstructors?.length) {
        const eventAction = `No Instructors Found (${transmission}, ${maskLastChars(
          postcode,
        )})`;

        trackEvent({
          eventCategory: "Prices",
          eventAction,
          eventLabel: `${currentBrand}`,
        });

        return;
      }

      if (crossSellInstructorId) {
        const foundInstructor = currentBrandInstructors.find(
          res => res.instructor.id === crossSellInstructorId,
        );

        if (!foundInstructor && trackEvent) {
          trackEvent({
            eventCategory: "Prices",
            eventAction: "X-Sell",
            eventLabel: `X-Sell Instructor Unavailable ${crossSellInstructorId}`,
          });
        }

        const switchToInstructor =
          foundInstructor || currentBrandInstructors[0];

        dispatch({
          type: UPDATE_NUJ_HISTORY,
          payload: {
            selectedInstructor: switchToInstructor,
            selectedPackage:
              switchToInstructor.prices[switchToInstructor.prices.length - 1],
          },
        });
      } else if (isEmpty(selectedInstructor)) {
        const initialSelectedInstructor = currentBrandInstructors[0];
        trackEvent({
          eventCategory: "Prices",
          eventAction: `Instructors Found (${transmission}, ${maskLastChars(
            postcode,
          )})`,
          eventLabel: `${currentBrand}`,
        });

        // set initial instructor and highest available pricing package
        dispatch({
          type: UPDATE_NUJ_HISTORY,
          payload: {
            selectedInstructor: initialSelectedInstructor,
            selectedPackage:
              initialSelectedInstructor.prices[
                initialSelectedInstructor.prices.length - 1
              ],
          },
        });
      }
    },
  });
export default useGetInstructors;
