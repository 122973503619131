import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import * as Sentry from "@sentry/react";
import { arrayOf, shape, string, elementType } from "prop-types";

import AppBar from "../../AppBar/AppBar";

const useStyles = makeStyles(theme => ({
  container: {
    display: ({ enableClickJackFix }) => {
      if (!enableClickJackFix) {
        return "flex";
      }

      return window !== window.top ? "none" : "flex"; // iframe "click jacking" security fix
    },
    position: "relative",
    [theme.breakpoints.up("md")]: {
      minHeight: "100vh",
    },
  },
}));

const BaseLayout = ({ children, steps = [], enableClickJackFix = true }) => {
  const classes = useStyles({ enableClickJackFix });

  return (
    <Grid container spacing={0} classes={{ root: classes.container }}>
      <Grid item xs={12} md={3}>
        <AppBar steps={steps} />
      </Grid>
      <Grid item xs={12} md={9}>
        <Sentry.ErrorBoundary
          beforeCapture={scope => {
            scope.setTag("fromErrorBoundary", true);
          }}
        >
          {children}
        </Sentry.ErrorBoundary>
      </Grid>
    </Grid>
  );
};

BaseLayout.propTypes = {
  steps: arrayOf(
    arrayOf(
      shape({
        path: string,
        Component: elementType,
        routeDependencies: arrayOf(string),
      }),
    ),
  ),
};

export default BaseLayout;
