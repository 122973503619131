import React, { useState } from "react";
import {
  Select,
  MenuItem,
  makeStyles,
  InputAdornment,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress,
  TextFieldProps,
  Link,
} from "@material-ui/core";
import { withTheme } from "@material-ui/styles";
import Room from "@material-ui/icons/RoomOutlined";
import { useBrand } from "../../BrandProvider/BrandProvider";
import { AddressFull } from "@lessons/models";
import { getJourneyURL, NEW_USER_JOURNEY_PATH_NAMES } from "@constants";

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: "18px",
    color: theme.palette.text.primary,
    opacity: 0.4,
  },
}));

type Props = {
  value?: AddressFull | string;
  name: string;
  disabled?: boolean;
  isLoading?: boolean;
  addresses: { value: string; label: string }[];
  onChangeAddress: (_address: string) => void;
  theme: any;
  open?: boolean;
} & TextFieldProps;

const FormAddressPicker: React.FC<Props> = ({
  value,
  name,
  onChangeAddress,
  addresses,
  label,
  disabled,
  isLoading,
  theme,
  open,
}) => {
  const { getText, brand } = useBrand();
  const classes = useStyles();
  const { SelectInput } = theme;
  const [selected, setSelected] = useState(
    typeof value === "object" && "id" in value ? value.id : value,
  );

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setSelected(value as string);
    onChangeAddress(value as string);
  };

  if (addresses && addresses.length === 0) {
    return (
      <>
        <Typography>{getText("common.addressPickerNoAddresses")}</Typography>
        <Link
          href={`${getJourneyURL(brand)}${NEW_USER_JOURNEY_PATH_NAMES.PICKUP}`}
          data-test="personal-details-reset-pickup"
          component="a"
        >
          {getText("common.addressPickerEdit")}
        </Link>
      </>
    );
  }

  const formLabel = label || getText("common.addressPickerLabel");

  return (
    <FormControl fullWidth>
      {formLabel && (
        <InputLabel disableAnimation htmlFor="address">
          {formLabel}
        </InputLabel>
      )}
      <Select
        value={selected}
        onChange={handleChange}
        name={name}
        id={name}
        open={open}
        input={
          <SelectInput
            startAdornment={
              <InputAdornment position="start">
                {isLoading ? (
                  <CircularProgress size={16} />
                ) : (
                  <Room className={classes.icon} />
                )}
              </InputAdornment>
            }
          />
        }
        disabled={disabled || isLoading || !addresses}
      >
        {addresses &&
          addresses.map(address => (
            <MenuItem value={address.value} key={`address-${address.value}`}>
              {address.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default withTheme(FormAddressPicker);
