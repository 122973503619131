import { Brand, Instructor, TransmissionType } from "@graphql/types";

const LESSON_TYPES: any = {
  [Brand.Bsm]: {
    Learner: "BSM.LESSON.BASIC",
    Refresher: "BSM.LESSON.QUAL",
    PassPlus: "BSM.LESSON.PLUS",
    Motorway: "BSM.LESSON.MWAY",
    ShortNoticeTest: "BSM.LESSON.SHORT",
    PDI: "BSM.LESSON.PDI",
    AAMember: "BSM.LESSON.STF",
  },
  [Brand.Aads]: {
    Learner: "AADS001",
    Refresher: "AADS002",
    PassPlus: "AADS003",
    Motorway: "AADS055",
    ShortNoticeTest: "AADS.LESSON.SHORT",
    AAMember: "AADS.LESSON.STF",
  },
};

export const formatInstructors = (
  instructors: Instructor[],
  brand: Brand,
  transmission: TransmissionType,
  lessonTypeDL: string,
) =>
  instructors.map(instructor => ({
    brand,
    instructor: {
      id: instructor.id,
      adiNumber: instructor.personId,
      name: instructor.name.fullName,
      isTrainee: instructor?.propositions?.some(
        ({ proposition }) => proposition.type === "Trainee",
      ),
    },
    transmissionType: transmission,
    prices: instructor.packagePrices.map(price => ({
      hours: price.packageLengthInHours,
      standardHourlyPrice:
        (price.packagePrice +
          (price && price.discountAmount ? price.discountAmount : 0)) /
        price.packageLengthInHours,
      standardPackagePrice:
        price.packagePrice +
        (price && price.discountAmount ? price.discountAmount : 0),
      discountedPackagePrice: price.packagePrice,
      savingOnPackagePrice: price.discountAmount,
      lessonType: LESSON_TYPES[brand][lessonTypeDL],
    })),
  }));

export default {
  formatInstructors,
};
