import React, { ChangeEvent, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";

import { RETURNING_USER_JOURNEY_PATH_NAMES } from "../../../constants";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import sendPupilhelpEmailMutation from "../../../graphql/createPupilHelpEmail.graphql";
import LoadingScreen from "../../common/LoadingScreen/LoadingScreen";
import { goBack } from "../../../utils/navigation";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";
import { TalkToUsProps } from "./types";
import TalkToUsComponent from "./TalkToUs";

const ReturningUserJourneyTalkToUs: React.FC<TalkToUsProps> = (props: any) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { getText, brand } = useBrand();
  const { trackEvent, updateDataLayer } = useTealium();
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [fieldTouched, setFieldTouched] = useState(false);

  const handleBackClick = () => {
    goBack({ history, pathname });
  };

  const handleOpenChat = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Button Click",
      eventLabel: "Start A Chat",
    });
    updateDataLayer({
      chat_started: "true",
    });
  };

  const [sendPupilHelpEmail, { error, loading }] = useMutation(
    sendPupilhelpEmailMutation,
  );

  if (error) {
    throw error;
  }

  const onMessageUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
    if (e.target.value.length > 0 && e.target.value.length <= 10) {
      setErrorMessage("Please enter a longer message");
    } else {
      setErrorMessage("");
    }
    if (!fieldTouched) {
      trackEvent({
        eventCategory: capitalizeEachWordOfPathname(pathname),
        eventAction: "Interaction",
        eventLabel: "Message",
      });
      setFieldTouched(true);
    }
  };

  const submitForm = async () => {
    setErrorMessage("");
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Button Click",
      eventLabel: "Send Message",
    });
    updateDataLayer({
      message_sent: "true",
    });

    const input = {
      brand,
      message,
    };

    try {
      const { data, errors } = await sendPupilHelpEmail({
        variables: {
          input,
        },
      });

      if (data?.sendPupilhelpEmail) {
        history.push(RETURNING_USER_JOURNEY_PATH_NAMES.TALK_TO_US_CONFIRMATION);
        trackEvent({
          eventCategory: capitalizeEachWordOfPathname(pathname),
          eventAction: "Form Submit",
          eventLabel: "Support Message (RUJ)",
        });
      } else if (errors) {
        errors.forEach(error => {
          // eslint-disable-next-line
          console.error(error.message);
        });
        setErrorMessage(
          "Sorry, we were unable to send your message. Please try again later.",
        );
      } else {
        setErrorMessage(
          "Sorry, we were unable to send your message. Please try again later.",
        );
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error("catch error", error);
      setErrorMessage(
        "Sorry, we were unable to send your message. Please try again later.",
      );
    }
  };

  if (loading) {
    return (
      <LoadingScreen messageText={getText("returning/talk-to-us.processing")} />
    );
  }

  return (
    <TalkToUsComponent
      handleSubmit={submitForm}
      message={message}
      onMessageUpdate={onMessageUpdate}
      errorMessage={errorMessage}
      onBackClick={handleBackClick}
      onOpenChat={handleOpenChat}
      {...props}
    />
  );
};

export default ReturningUserJourneyTalkToUs;
