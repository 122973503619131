import React, { Fragment } from "react";
import { func, node, string } from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import Button from "../Button/Button";
import ContentWrapper from "../ContentWrapper/ContentWrapper";
import Titlebar from "../Titlebar/Titlebar";
import SuccessImage from "./SuccessImage";

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingBottom: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contentWrapper: {
    [theme.breakpoints.up("md")]: {
      paddingTop: "2em",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "3em",
      maxWidth: "80%",
    },
  },
  secondaryButtonWrapper: {
    paddingTop: "1em",
  },
  bodyText: {
    textAlign: "center",
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: "1.2em",
    fontSize: "20px",
    letterSpacing: "0px",
    [theme.breakpoints.up("sm")]: {
      margin: "10px auto 20px auto",
      fontSize: "27px",
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "30px",
    },
  },
  gif: {
    height: "150px",
    width: "150px",
  },
  actionButton: {
    margin: "0 auto",
  },
}));

const Success = ({
  testId,
  title,
  buttonText,
  handleButtonClick = () => {},
  buttonIsForChat = false,
  secondaryButtonText,
  handleSecondaryButtonClick,
  Image = SuccessImage,
  children,
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <ContentWrapper>
        <Titlebar
          centerText
          headingProps={{ "data-test": "confirmation-title-bar" }}
        >
          {title}
        </Titlebar>
      </ContentWrapper>

      <div data-test={testId} className={classes.imageWrapper}>
        <Image />
      </div>

      {children && (
        <ContentWrapper classes={{ wrapper: classes.contentWrapper }}>
          <Typography
            classes={{ root: classes.bodyText }}
            component="h1"
            variant="h1"
          >
            {children}
          </Typography>
        </ContentWrapper>
      )}

      {buttonText && (
        <ContentWrapper classes={{ wrapper: classes.contentWrapper }}>
          <Button
            className={classes.actionButton}
            onClick={handleButtonClick}
            data-chat={buttonIsForChat ?? false}
            text={buttonText}
            data-test="success-action-button"
          />
        </ContentWrapper>
      )}
      {secondaryButtonText && (
        <ContentWrapper classes={{ wrapper: classes.secondaryButtonWrapper }}>
          <Button
            className={classes.actionButton}
            onClick={handleSecondaryButtonClick}
            text={secondaryButtonText}
            data-test="success-secondary-button"
            secondary
          />
        </ContentWrapper>
      )}
    </Fragment>
  );
};

Success.propTypes = {
  testId: string,
  title: string.isRequired,
  buttonText: string,
  handleButtonClick: func,
  secondaryButtonText: string,
  handleSecondaryButtonClick: func,
  Image: func,
  children: node,
};

export default Success;
