import { JourneyType, RETURNING_USER_JOURNEY_PATH_NAMES } from "@constants";
import { AllStepDefinitions } from "../StepDefinitions";
import { State } from "models/state";

const qtaAccountPathRegex = new RegExp(
  `^${RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT}/[a-zA-Z0-9]+$`,
);

export const getJourneyType = (path: string, state: State): JourneyType => {
  if (path.match(qtaAccountPathRegex) && !state?.rujHistory?.personId) {
    return JourneyType.QUICK_TOP_UP_JOURNEY;
  }
  const currentStep = AllStepDefinitions.find(
    step => step.path === path.replace(/\/$/, ""),
  );

  if (currentStep?.journeyType === JourneyType.QUICK_TOP_UP_JOURNEY) {
    return state?.rujHistory?.personId
      ? JourneyType.RETURNING_USER_JOURNEY
      : JourneyType.QUICK_TOP_UP_JOURNEY;
  }
  return currentStep?.journeyType ?? JourneyType.NEW_USER_JOURNEY;
};
