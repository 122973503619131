import React from "react";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "../../ProgressBar/CircularProgress/CircularProgress";
import { useBrand } from "../../BrandProvider/BrandProvider";
import { getDesktopAppLogo } from "../../../../utils/logos";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  sidebar: {
    background: palette.grey[100],
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    [breakpoints.up("md")]: {
      position: "fixed",
      width: "25%",
      height: "100%",
    },
  },
  image: {
    width: "81%",
    maxWidth: "380px",
  },
  sidebarItem: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "center",
  },
}));

type AppSidebarAADProps = {
  stepsLength?: number;
  activeStep?: number;
  hideProgressBar?: boolean;
  onActionClick?: () => void;
  ActionButton?: React.FC<{
    onClick: () => void;
  }>;
};

const AppSidebarAAD: React.FC<AppSidebarAADProps> = ({
  stepsLength = 0,
  activeStep = -1,
  hideProgressBar = false,
  onActionClick = () => {},
  ActionButton,
}) => {
  const classes = useStyles();
  const { brand } = useBrand();

  return (
    <div className={classes.sidebar} data-test="app-sidebar">
      <img
        className={classes.image}
        src={getDesktopAppLogo(brand)}
        alt="Logo"
      />

      {!hideProgressBar && (
        <div className={classes.sidebarItem}>
          <CircularProgress
            steps={stepsLength}
            activeStep={activeStep}
            // classes={{ stroke: classes.progressStroke }}
          />
        </div>
      )}

      <div className={classes.sidebarItem}>
        {ActionButton && (
          <React.Fragment>
            {/* @ts-ignore */}
            <ActionButton onClick={onActionClick} color="#1D1D1D" />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default AppSidebarAAD;
