import React, { Fragment } from "react";

import { useBrand } from "../BrandProvider/BrandProvider";
import { BSM_BRAND_NAME } from "../../../constants";

import bsmThumbsUp from "./assets/gif/thumbsup.gif";

import Tick from "./assets/svg/Tick";

const SuccessImage = () => {
  const { brand } = useBrand();
  return (
    <Fragment>
      {brand === BSM_BRAND_NAME ? (
        <img
          alt="Thumbs up animation"
          src={bsmThumbsUp}
          height="150"
          width="150"
        />
      ) : (
        <Tick />
      )}
    </Fragment>
  );
};

export default SuccessImage;
