import React from "react";
import get from "lodash.get";
import CancelLesson from "./CancelLesson";
import { useStateContext } from "../../../context/stateContext";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import LoadingScreen from "../../common/LoadingScreen/LoadingScreen";

import useGetUpcomingLessons from "../../../hooks/useGetUpcomingLessons";
import { ERROR_TYPES } from "../../../constants";
import CancelLessonConfirmation from "./CancelLessonConfirmation";
import CancelLessonChat from "./CancelLessonChat";

import useCancelBooking from "../../../hooks/useCancelBooking";
import { CancelProps } from "./types";

const ReturningUserJourneyCancelLesson = () => {
  const { brand, getText } = useBrand();

  const {
    state: {
      rujHistory: { learnerId },
    },
  } = useStateContext();

  const [
    cancelBooking,
    { loading: isCancellingBooking, data: cancelledBooking },
  ] = useCancelBooking();
  const { loading, data } = useGetUpcomingLessons({ brand, learnerId });

  const handleCancelBooking = ({ bookingId, reason }: CancelProps) =>
    cancelBooking({
      variables: {
        input: {
          learnerId,
          bookingId,
          reason,
        },
      },
    });

  if (cancelledBooking && cancelledBooking.cancelLessonForRUJ) {
    const {
      successful,
      lessonTime,
      errorType,
    } = cancelledBooking.cancelLessonForRUJ;
    if (successful) {
      return <CancelLessonConfirmation lessonTime={lessonTime} />;
    }
    if (errorType === ERROR_TYPES.CANCEL_LESSON_NOTICE_TOO_SHORT) {
      return <CancelLessonChat />;
    }
  }

  if (!data && loading) {
    return (
      <LoadingScreen
        messageText={getText("returning/cancel-lesson.loadingLessons")}
      />
    );
  }

  if (isCancellingBooking) {
    return (
      <LoadingScreen
        messageText={getText("returning/cancel-lesson.cancellingLesson")}
      />
    );
  }

  return (
    <CancelLesson
      lessons={get(data, "futureLessons.upcoming", [])}
      onCancelBooking={handleCancelBooking}
    />
  );
};

export default ReturningUserJourneyCancelLesson;
