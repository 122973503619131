import React from "react";
import { string, number, func } from "prop-types";
import { Typography, makeStyles } from "@material-ui/core";
import merge from "lodash.merge";

import TopupHeader from "../../common/TopupHeader/TopupHeader";
import ContinueButton from "../../common/ContinueButton/ContinueButton";

import { useBrand } from "../../common/BrandProvider/BrandProvider";

const useStyles = makeStyles(theme =>
  merge(theme.journey.RUJConfirmation, {
    detailBanner: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    textContent: {
      marginBottom: "48px",
    },
  }),
);

const ReturningUserJourneyConfirmationBody = ({
  name,
  balance,
  onContinueClick,
}) => {
  const { getText } = useBrand();
  const classes = useStyles();

  return (
    <div className={classes.bodyWrapper}>
      <Typography
        classes={{ root: classes.textContent }}
        component="h2"
        variant="h1"
      >
        Enjoy your lesson!
      </Typography>

      <TopupHeader
        title={`${name}'s account`}
        balance={balance}
        borderPosition="top"
        headingProps={{
          component: "h2",
          classes: {
            root: classes.titleBar,
          },
        }}
      />

      <ContinueButton
        text={getText("returning/confirmation.continueButton")}
        onClick={onContinueClick}
        data-test="confirmation-continue-button"
      />
    </div>
  );
};

ReturningUserJourneyConfirmationBody.propTypes = {
  name: string,
  balance: number,
  onContinueClick: func,
};

export default ReturningUserJourneyConfirmationBody;
