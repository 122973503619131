import React, { Fragment } from "react";

import {
  TextField,
  InputAdornment,
  makeStyles,
  InputLabel,
  TextFieldProps,
} from "@material-ui/core";
import Room from "@material-ui/icons/RoomOutlined";

import { useBrand } from "../../BrandProvider/BrandProvider";

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: "18px",
    color: theme.palette.text.primary,
    opacity: 0.4,
  },
}));

type Props = {
  value: string;
  disabled?: boolean;
  labelText?: string;
};

const FormPostCode: React.FC<Props & TextFieldProps> = ({
  value = "",
  onChange = () => {},
  disabled,
  labelText,
  ...textFieldProps
}) => {
  const { getText } = useBrand();
  const classes = useStyles();
  return (
    <Fragment>
      <InputLabel>{labelText || getText("common.postcodeLabel")}</InputLabel>
      <TextField
        id="postcode"
        value={value}
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Room classes={{ root: classes.icon }} />
            </InputAdornment>
          ),
          autoFocus: true,
        }}
        disabled={disabled}
        {...textFieldProps}
      />
    </Fragment>
  );
};

export default FormPostCode;
