import React from "react";
import { Link } from "@material-ui/core";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";

const MarketingPreferencesNotice = () => {
  const { getText } = useBrand();
  return (
    <span>
      We would like to contact you from time to time about driving advice and
      offers via email, SMS and phone. You can opt out at any time – see our
      &nbsp;
      <Link
        href={getText("personal-details.privacyNoticeUrl")}
        target="_blank"
        rel="noopener noreferrer"
      >
        privacy notice
      </Link>
      &nbsp; to find out how. Choose how we contact you.
    </span>
  );
};

export default MarketingPreferencesNotice;
