import React, { useEffect } from "react";

import Confirmation from "./Confirmation";

import {
  useStateContext,
  CLEAR_NUJ_SELECTION_HISTORY,
} from "../../../../context/stateContext";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import { GiftType, StateContext } from "../../../../models/state";

const NewUserJourneyConfirmation: React.FC = () => {
  const { getText } = useBrand();
  const {
    state: {
      nujHistory: {
        confirmation: {
          topUpId,
          pickupPostcode,
          lesson: { startTime, endTime },
          instructorName,
          pupilFirstName,
          gift,
        },
      },
    },
    dispatch,
  }: StateContext = useStateContext();

  useEffect(() => {
    dispatch({
      type: CLEAR_NUJ_SELECTION_HISTORY,
    });
  }, [dispatch]);

  const title =
    gift === GiftType.NOT_GIFT
      ? "confirmation.pageTitle"
      : "confirmation.giftedPageTitle";

  return (
    <Confirmation
      title={getText(title, { pupilFirstName })}
      pickupPostcode={pickupPostcode}
      startTime={startTime}
      endTime={endTime}
      instructorName={instructorName}
      topUpId={topUpId}
      gift={gift}
      pupilFirstName={pupilFirstName}
    />
  );
};

export default NewUserJourneyConfirmation;
