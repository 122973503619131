import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "raf/polyfill";

import "arrive";

import React from "react";
import ReactDOM from "react-dom";
// @ts-ignore
import queryString from "query-string";

import "./index.css";
import App from "./App";

const qs = queryString.parse(window.location.search);
const root = document.getElementById("root");

// if ldrive=true ISN'T in the url, display the hidden trustarc cookie consent banner
if (!qs.ldrive) {
  const consentTrack = document.getElementById("truste-consent-track");
  if (consentTrack) {
    consentTrack.style.visibility = "initial";
  } else {
    // @ts-ignore
    document.arrive("#truste-consent-track", function () {
      // @ts-ignore
      this.style.visibility = "visible";
    });
  }
}

if (root !== null) {
  ReactDOM.render(<App ldrive={Boolean(qs?.ldrive)} />, root);
}
