import React, { FC } from "react";
import { makeStyles } from "@material-ui/styles";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { History, Location } from "history";

import { Grid, Theme } from "@material-ui/core";
import AccountBalance from "../AccountBalance/AccountBalance";
import Titlebar from "../Titlebar/Titlebar";
import { goBack } from "../../../utils/navigation";
import { useTealium } from "../TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";

type CustomTheme = Theme & { breakpoints: any };

const useStyles = (borderPosition: string, padding: boolean, margin: boolean) =>
  makeStyles((theme: CustomTheme) => ({
    title: {
      "& h1": {
        paddingTop: 0,
        margin: 0,
        [theme.breakpoints.up("sm")]: {
          textAlign: "left",
        },
      },
    },
    header: {
      [borderPosition === "top"
        ? "borderTop"
        : "borderBottom"]: `1px solid ${theme.palette.grey[100]}`,
      [borderPosition === "top" ? "paddingTop" : "paddingBottom"]: padding
        ? "15px"
        : "0",
      marginBottom: margin ? "24px" : 0,
      alignItems: "center",

      [theme.breakpoints.up("sm")]: {
        [borderPosition === "top" ? "paddingTop" : "paddingBottom"]: padding
          ? "24px"
          : "0",
      },
    },
  }));

interface Props {
  title: string;
  balance: number;
  borderPosition?: string;
  padding?: boolean;
  margin?: boolean;
  headingProps?: Record<string, any>;
  history: History;
  location: Location;
  showBackButton?: boolean;
  showBalance?: boolean;
}

const TopupHeader: FC<RouteComponentProps & Props> = ({
  title,
  balance,
  borderPosition = "bottom",
  padding = true,
  margin = true,
  headingProps = {},
  history,
  location: { pathname },
  showBackButton = false,
  showBalance = true,
}) => {
  const classes = useStyles(borderPosition, padding, margin)();
  const { trackEvent } = useTealium();

  const handleBackButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    goBack({ history, pathname });
  };

  return (
    <Grid container spacing={1} className={classes.header}>
      <Grid item xs={showBalance ? 7 : 12} md={showBalance ? 8 : 12}>
        <Titlebar
          classes={{ root: classes.title }}
          headingProps={headingProps}
          onBackClick={handleBackButtonClick}
          showBackButton={showBackButton}
        >
          {title}
        </Titlebar>
      </Grid>
      {showBalance && (
        <Grid item xs={5} md={4}>
          <AccountBalance hours={balance} />
        </Grid>
      )}
    </Grid>
  );
};

export default withRouter(TopupHeader);
