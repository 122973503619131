import React from "react";

import { FormControl, FormLabel, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RadioCardGroup from "../RadioCardGroup/RadioCardGroup";
import RadioCard from "../RadioCard/RadioCard";
import { useBrand } from "../../BrandProvider/BrandProvider";

const useStyles = makeStyles(theme => ({
  root: {
    flex: "1",
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightMedium,
    "&:first-child": {
      margin: "16px 15px 0 4px",
    },
    "&:last-child": {
      margin: "16px 4px 0 15px",
    },
    "&$checked": {
      color: theme.palette.text.primary,
    },
  },
  checked: {
    color: theme.palette.text.primary,
  },
}));

type Props = {
  selected?: boolean;
  onChange: (_value: boolean) => void;
  disabled?: boolean;
  labelKey?: string;
  leftIcon?: any;
};

const YesNoPicker: React.FC<Props> = ({
  selected = true,
  onChange,
  disabled = false,
  labelKey = "personal-details.yesNoPickerLegend",
  leftIcon = null,
  ...formControlProps
}) => {
  const { getText } = useBrand();
  const classes = useStyles();
  return (
    <FormControl component="fieldset" fullWidth {...formControlProps}>
      <Box display="flex" alignItems="center">
        {leftIcon && (
          <Box mr={0.5} display="flex" alignItems="center">
            {leftIcon}
          </Box>
        )}
        <FormLabel component="legend">{getText(labelKey)}</FormLabel>
      </Box>
      <RadioCardGroup
        label=""
        name="yesNo"
        value={selected === true ? "YES" : "NO"}
        onChange={(value: string) => {
          onChange(Boolean(value === "YES"));
        }}
        disabled={disabled}
      >
        <RadioCard
          value="YES"
          cardContent={<Typography>Yes</Typography>}
          cardContentChecked={<Typography>Yes</Typography>}
          classes={classes}
        />
        <RadioCard
          value="NO"
          cardContent={<Typography>No</Typography>}
          cardContentChecked={<Typography>No</Typography>}
          classes={classes}
        />
      </RadioCardGroup>
    </FormControl>
  );
};

export default YesNoPicker;
