import React from "react";
import { shape, number, string } from "prop-types";
import { withStyles } from "@material-ui/styles";
import { useBrand } from "../BrandProvider/BrandProvider";

const styles = theme => ({
  accountBalance: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  accountBalanceWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  hours: {
    display: "block",
    fontSize: "18px",
    letterSpacing: "-0.3px",
    lineHeight: "28px",
  },
  hoursNumber: {
    fontSize: "22px",
    letterSpacing: "-0.3px",
    lineHeight: "28px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "30px",
    },
  },
  label: {
    display: "block",
    fontSize: "12px",
    lineHeight: "12px",
    opacity: "0.5",
  },
});

const AccountBalance = ({ classes, hours = 0, descriptor }) => {
  const { getText } = useBrand();
  return (
    <div className={classes.accountBalance}>
      <div className={classes.accountBalanceWrapper}>
        <span className={classes.hours}>
          <span
            className={classes.hoursNumber}
            data-test="account-balance-hours"
          >
            {hours}
          </span>{" "}
          hours
        </span>
        <span className={classes.label}>
          {descriptor || getText("top-up/amount.balanceDescriptor")}
        </span>
      </div>
    </div>
  );
};

AccountBalance.propTypes = {
  classes: shape({}),
  hours: number,
  descriptor: string,
};

export default withStyles(styles)(React.memo(AccountBalance));
