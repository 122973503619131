import React from "react";
import { makeStyles } from "@material-ui/core";
import mergeDeep from "lodash.merge";
import { string } from "prop-types";
import {
  BSM_BRAND_NAME,
  getJourneyURL,
  RETURNING_USER_JOURNEY_PATH_NAMES,
  USE_AUTH_PORTAL,
} from "@constants";
import { BodyText } from "./BodyText";
import { GiftType } from "../../../../../models/state";

const useStyles = makeStyles(theme =>
  mergeDeep((theme as any).journey.nujConfirmation, {
    titleText: {
      color: theme.palette.grey[400],
    },
    bulletList: {
      listStyle: "outside",
      paddingLeft: "20px",
    },
    accountLink: {
      color: theme.palette.secondary.main,
      textDecoration: "none",
    },
  }),
);

const ConfirmationBodyBSM: React.FC<{
  topUpId?: string;
  gift: GiftType;
  pupilFirstName: string;
}> = ({ topUpId, gift, pupilFirstName }) => {
  const classes = useStyles() as any;
  const setupAccountLink = USE_AUTH_PORTAL
    ? window._env_.REACT_APP_AUTH_PORTAL_URL_BSM
    : `${getJourneyURL(BSM_BRAND_NAME)}${
        RETURNING_USER_JOURNEY_PATH_NAMES.LOGIN
      }`;

  const quickTopUpLink = topUpId
    ? `${getJourneyURL(BSM_BRAND_NAME)}${
        RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT
      }/${topUpId}`
    : undefined;

  return (
    <div className={classes.bodyWrapper}>
      <BodyText
        gift={gift}
        pupilFirstName={pupilFirstName}
        setupAccountLink={setupAccountLink}
        ListEl={props => <ul>{props.children}</ul>}
        ListItemEl={props => <li>{props.children}</li>}
        quickTopUpLink={quickTopUpLink}
        classes={classes}
      />
    </div>
  );
};

ConfirmationBodyBSM.propTypes = {
  topUpId: string,
};

export default ConfirmationBodyBSM;
