import React from "react";
import { func } from "prop-types";
import { Typography, makeStyles } from "@material-ui/core";

import ContinueButton from "../../common/ContinueButton/ContinueButton";

import { useBrand } from "../../common/BrandProvider/BrandProvider";
import ContentWrapper from "../../../components/common/ContentWrapper/ContentWrapper";
import newInstructorCheck from "../../../svg/new-instructor-check.svg";

const useStyles = makeStyles(() => ({
  text: {
    textAlign: "center",
    fontSize: "25px",
    fontWeight: "bold",
    marginBottom: "30px",
  },
}));

const NewInstructorRequestedBody = ({ onMyAccountClick }) => {
  const { getText } = useBrand();
  const classes = useStyles();

  return (
    <ContentWrapper>
      <Typography className={classes.text}>
        {getText("newInstructorRequest.title")}
      </Typography>
      <img
        style={{ maxHeight: 80, marginBottom: 30 }}
        src={newInstructorCheck}
        alt="New Instructor Check"
      />
      <Typography className={classes.text}>
        {getText("newInstructorRequest.subText")}
      </Typography>

      <ContinueButton
        text={getText("newInstructorRequest.buttonTitle")}
        onClick={onMyAccountClick}
        data-test="confirmation-continue-button"
      />
    </ContentWrapper>
  );
};

NewInstructorRequestedBody.propTypes = {
  onMyAccountClick: func,
};

export default NewInstructorRequestedBody;
