import { parseISO } from "date-fns";
import get from "lodash.get";
import { TODAY, LONG_DATE_FORMAT } from "../../../constants";

export const parseAvailableDates = (availableDates = [], todayDate = TODAY) =>
  availableDates.reduce((acc, availableDate) => {
    if (availableDate.timeSlots.length) {
      acc.push(parseISO(availableDate.date, LONG_DATE_FORMAT, todayDate));
    }
    return acc;
  }, []);

export const getInstructorName = (instructor, getText) =>
  instructor
    ? get(instructor, "name", "").split(" ")[1]
    : getText("timeslots.defaultInstructorName");
