const palette = {
  background: {
    default: "#fff",
    dark: "#F6F6F6",
  },
  primary: {
    main: "#FFCC00",
    dark: "#d2a800",
    light: "#FFFCF2",
    contrastText: "#2B2D32",
  },
  secondary: {
    main: "#07828D",
    contrastText: "#07828D",
  },
  error: {
    main: "#DA0076",
  },
  type: "light",
  text: {
    primary: "#2B2D32",
    secondary: "#07828D",
  },
  grey: {
    50: "#F8F7F9",
    100: "#F6F6F6",
    200: "#C3C4C4",
    300: "#EFEEF0",
    400: "#2B2D32",
    500: "#2E2E36",
  },
  common: {
    black: "#2B2D32",
    white: "#FFFFFF",
  },
};

export default palette;
