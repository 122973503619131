import React, { useRef, Fragment, useEffect, useState, FC } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { withRouter } from "react-router-dom";
import merge from "lodash.merge";

import Titlebar from "../../../common/Titlebar/Titlebar";
import ContentWrapper from "../../../common/ContentWrapper/ContentWrapper";
import ContinueButton from "../../../common/ContinueButton/ContinueButton";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import { useTealium } from "../../../common/TealiumProvider/TealiumProvider";
import {
  UPDATE_NUJ_HISTORY,
  useStateContext,
} from "../../../../context/stateContext";
import RadioList from "../../../common/RadioItemList/RadioList";
import LessonTypesOptionList from "./LessonTypesOptionList";
import {
  LESSON_TYPES,
  NEW_USER_JOURNEY_PATH_NAMES,
} from "../../../../constants";
import { changeLessonType } from "../Prices/helpers";
import { goBack } from "../../../../utils/navigation";
import { capitalizeEachWordOfPathname } from "../../../../utils/capitalisePathname";
import { ReactComponent as GiftIcon } from "../../../../svg/aa-gift-icon.svg";
import { LessonTypesProps } from "./types";

const useStyles = makeStyles(
  (theme: Theme & { journey: { lessonTypes: Record<string, any> } }) =>
    merge(theme.journey.lessonTypes, {
      root: {
        width: "100%",
        maxWidth: "384px",
        margin: "0 auto",
        padding: "0 0 20px 0",
        [theme.breakpoints.up("lg")]: {
          padding: "0 0 30px 0",
        },
        [theme.breakpoints.up("md")]: {
          width: "700px",
          margin: "auto",
        },
      },
      lessonTypesLabel: {
        color: theme.palette.grey[400],
        "font-size": "16px",
        "font-weight": "normal",
        margin: "0 0 12px 0",
      },
      radioGroup: {
        display: "flex",
        width: "100%",
      },
      radioCard: {
        paddingLeft: "16px",
        paddingRight: "16px",
        textAlign: "left",
        color: theme.palette.grey[500],
        justifyContent: "flex-start",
        margin: "0 0 10px 0",
        "&::last-child": {
          margin: "0",
        },
      },
      radioCardContentChecked: {
        color: theme.palette.grey[500],
      },
    }),
);

const NewUserJourneyStepsLessonTypes: FC<LessonTypesProps> = ({
  location: { pathname },
  history,
}) => {
  const classes = useStyles();
  const { trackEvent } = useTealium();
  const { getText } = useBrand();
  const buttonEl = useRef<HTMLButtonElement>(null);

  const { dispatch } = useStateContext();

  const [selectedLessonType, setSelectedLessonType] = useState(
    NEW_USER_JOURNEY_PATH_NAMES.PRICES,
  );

  useEffect(() => {
    if (buttonEl.current) {
      buttonEl.current.focus();
    }
  });

  const handleContinueButtonClick = () => {
    dispatch({
      type: UPDATE_NUJ_HISTORY,
      payload: {
        shortNoticeTestDateTime: null,
        shortNoticeTestCentre: null,
      },
    });

    if (selectedLessonType === NEW_USER_JOURNEY_PATH_NAMES.TEST_PASSED) {
      changeLessonType({
        dispatch,
        selectedLessonType: LESSON_TYPES.REFRESHER,
      });
    } else if (selectedLessonType === NEW_USER_JOURNEY_PATH_NAMES.PRICES) {
      changeLessonType({
        dispatch,
        selectedLessonType: LESSON_TYPES.LEARNER,
      });
    } else if (selectedLessonType === NEW_USER_JOURNEY_PATH_NAMES.TEST_BOOKED) {
      changeLessonType({
        dispatch,
        selectedLessonType: LESSON_TYPES.SHORT_NOTICE_TEST,
      });
    }
    trackEvent({
      eventCategory: "Lesson Types",
      eventAction: "Button Click",
      eventLabel: "Continue",
    });
  };

  const handleBackButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    goBack({ history, pathname });
  };

  return (
    <Fragment>
      <ContentWrapper>
        <Titlebar
          showBackButton
          onBackClick={handleBackButtonClick}
          headingProps={{ "data-test": "lesson-types-title-bar" }}
        >
          {getText("lesson-types.pageTitle")}
        </Titlebar>
      </ContentWrapper>

      <div className={classes.lessonTypes}>
        <ContentWrapper fullHeight>
          <RadioList label={getText("lesson-types.lessonTypesLabel")}>
            <LessonTypesOptionList
              selectedLessonType={selectedLessonType}
              onClick={setSelectedLessonType}
            />
          </RadioList>
          <Box
            display="flex"
            alignItems="flex-start"
            className={classes.giftInfo}
            data-test="gift-info-message"
          >
            <Box>
              <GiftIcon className={classes.giftIcon} />
            </Box>
            <Box>{getText("lesson-types.surpriseGift")}</Box>
          </Box>
          <ContinueButton
            navButton
            to={selectedLessonType}
            text={getText("lesson-types.continueButtonText")}
            data-test="continue-button"
            buttonRef={buttonEl}
            onClick={handleContinueButtonClick}
          />
        </ContentWrapper>
      </div>
    </Fragment>
  );
};

export default withRouter(NewUserJourneyStepsLessonTypes);
