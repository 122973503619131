import React from "react";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "../../ProgressBar/CircularProgress/CircularProgress";
import { useBrand } from "../../BrandProvider/BrandProvider";
import { getDesktopAppLogo } from "../../../../utils/logos";

const useStyles = makeStyles(({ breakpoints }) => ({
  sidebar: {
    background:
      "linear-gradient(0deg, #F9812A, #F9812A), linear-gradient(180deg, #5D62C0 0%, #3F4491 100%), linear-gradient(360deg, #FA6F2A 0%, #F9812A 100%), linear-gradient(0deg, #F9812A, #F9812A), linear-gradient(90deg, #F9812A 100%, #FDA147 0%);",
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    padding: "70px 30px 30px 30px",
    [breakpoints.up("md")]: {
      position: "fixed",
      width: "25%",
      height: "100%",
      paddingTop: "84px",
    },
    [breakpoints.up("lg")]: {
      padding: "84px 55px 30px 83px",
    },
  },
  sidebarTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  sidebarProgress: {
    marginTop: "50px",
  },
}));

type AppSidebarBSMProps = {
  stepsLength?: number;
  activeStep?: number;
  hideProgressBar?: boolean;
  onActionClick?: () => void;
  ActionButton?: React.FC<{
    onClick: () => void;
  }>;
};

const AppSidebarBSM: React.FC<AppSidebarBSMProps> = ({
  stepsLength = 0,
  activeStep = -1,
  hideProgressBar = false,
  onActionClick = () => {},
  ActionButton,
}) => {
  const classes = useStyles();
  const { brand } = useBrand();
  return (
    <div className={classes.sidebar} data-test="app-sidebar">
      <div className={classes.sidebarTop}>
        <img src={getDesktopAppLogo(brand)} alt="Logo" />
        <div></div>
        {ActionButton && (
          <React.Fragment>
            {/* @ts-ignore */}
            <ActionButton onClick={onActionClick} color="#ffffff" />
          </React.Fragment>
        )}
      </div>

      {!hideProgressBar && (
        <div className={classes.sidebarProgress}>
          <CircularProgress steps={stepsLength} activeStep={activeStep} />
        </div>
      )}
    </div>
  );
};

export default AppSidebarBSM;
