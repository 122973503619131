import React from "react";

const BsmWarning: React.FC = () => (
  <svg
    width="144"
    height="144"
    viewBox="0 0 144 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M144 72C144 105.2 121.5 133.2 90.9 141.5C84.9 143.1 78.5 144 72 144C64.8 144 57.9 143 51.3 141C21.6 132.1 0 104.6 0 72C0 32.2 32.2 0 72 0C111.8 0 144 32.2 144 72Z"
      fill="#E6604F"
    />
    <path
      d="M90.8997 141.5C84.8997 143.1 78.4997 144 71.9997 144C64.7997 144 57.8997 143 51.2997 141L51.1997 105.5C51.1997 94.6001 59.9997 85.7001 70.8997 85.7001C81.7997 85.7001 90.6997 94.5001 90.6997 105.4L90.8997 141.5Z"
      fill="#F5C2B8"
    />
    <path
      d="M53.0999 74.6001C49.9999 74.6001 47.4999 72.1001 47.4999 69.0001L47.3999 40.8001C47.3999 37.7001 49.8999 35.2001 52.9999 35.2001C56.0999 35.2001 58.5999 37.7001 58.5999 40.8001L58.6999 69.0001C58.5999 72.1001 56.1999 74.6001 53.0999 74.6001Z"
      fill="#F5C2B8"
    />
    <path
      d="M64.2 74.6C61.1 74.6 58.6 72.1 58.6 69L58.5 28.5C58.5 25.4 61 22.9 64.1 22.9C67.2 22.9 69.7 25.4 69.7 28.5L69.8 69C69.8 72.1 67.3 74.6 64.2 74.6Z"
      fill="#F5C2B8"
    />
    <path
      d="M75.3001 74.6C72.2001 74.6 69.7001 72.1 69.7001 69L69.6001 22C69.6001 18.9 72.1001 16.4 75.2001 16.4C78.3001 16.4 80.8001 18.9 80.8001 22L80.9001 69C80.9001 72.1 78.4001 74.6 75.3001 74.6Z"
      fill="#F5C2B8"
    />
    <path
      d="M86.6998 74.6H86.2998C83.2998 74.6 80.8998 72.2 80.8998 69.2L80.7998 32C80.7998 29 83.1998 26.6 86.1998 26.6H86.5998C89.5998 26.6 91.9998 29 91.9998 32V69.2C91.9998 72.1 89.5998 74.6 86.6998 74.6Z"
      fill="#F5C2B8"
    />
    <path
      d="M90.6997 88.0001C97.1997 81.8001 112.4 67.1001 112.4 67.1001C112.4 67.1001 109.9 56.3001 95.5997 66.7001C81.2997 77.1001 83.8997 86.1001 83.8997 86.1001C83.8997 86.1001 85.6997 93.0001 90.6997 88.0001Z"
      fill="#F5C2B8"
    />
    <path
      d="M95.1001 79.0001C95.1001 92.1001 84.5001 102.8 71.4001 102.8C58.3001 102.8 47.6001 92.2001 47.6001 79.1001C47.6001 72.5001 47.6001 69.6001 47.6001 63.5001C47.6001 58.8001 64.8001 59.6001 71.4001 59.6001C84.4001 59.6001 95.0001 65.9001 95.1001 79.0001Z"
      fill="#F5C2B8"
    />
  </svg>
);

export default BsmWarning;
