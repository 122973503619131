import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { RETURNING_USER_JOURNEY_PATH_NAMES } from "../../../constants";
import {
  useStateContext,
  UPDATE_RUJ_HISTORY,
} from "../../../context/stateContext";
import LessonCountry from "../../CommonRoutes/LessonCountry/LessonCountry";
import { usePostcodeCountryDetails } from "../../../hooks";

import { Country, StateContext, CountryDetails } from "@lessons/models";

const SelectCountryWithState = () => {
  const history = useHistory();

  const {
    dispatch,
    state: {
      rujHistory: { pupilPickupPostcode, selectedCountry },
    },
  }: StateContext = useStateContext();

  const [selected, setSelected] = useState<Country | undefined>(
    selectedCountry?.name,
  );

  const { countries } = usePostcodeCountryDetails({
    postcode: pupilPickupPostcode,
  });

  const handleChange = (country: Country) => setSelected(country);
  const handleSubmit = (selectedCountryDetails?: CountryDetails) => {
    dispatch({
      type: UPDATE_RUJ_HISTORY,
      payload: {
        selectedCountryDetails,
      },
    });

    if (selectedCountryDetails && selectedCountryDetails.isOpen) {
      return history.push(RETURNING_USER_JOURNEY_PATH_NAMES.TIMESLOTS);
    }

    if (
      selectedCountryDetails &&
      !selectedCountryDetails.isOpen &&
      selectedCountryDetails.isOpenToKeyWorkers
    ) {
      return history.push(RETURNING_USER_JOURNEY_PATH_NAMES.KEY_WORKER);
    }

    if (
      selectedCountryDetails &&
      !selectedCountryDetails.isOpen &&
      !selectedCountryDetails.isOpenToKeyWorkers
    ) {
      return history.push(
        RETURNING_USER_JOURNEY_PATH_NAMES.KEY_WORKER_TALK_TO_US,
      );
    }
  };

  return (
    <LessonCountry
      countries={countries}
      selected={selected}
      onChange={handleChange}
      onContinueClick={handleSubmit}
    />
  );
};

export default SelectCountryWithState;
