import React, { Fragment } from "react";
import { string, bool, arrayOf, shape, func, oneOfType } from "prop-types";

import { makeStyles, Typography, SvgIcon } from "@material-ui/core";
import QueryBuilder from "@material-ui/icons/QueryBuilder";

import { format } from "date-fns";
import mergeDeep from "lodash.merge";

import RadioCardGroup from "../RadioCardGroup/RadioCardGroup";
import RadioCard from "../RadioCard/RadioCard";
import { useBrand } from "../../BrandProvider/BrandProvider";
import { TIME_FORMAT } from "../../../../constants";
import { soapTimeToLocalTime } from "../../../../utils/soapTimeToLocalTime";
import { Brand } from "@graphql/types";

export const TimeSlotPropTypes = shape({
  startTime: string,
  endTime: string,
  selected: bool,
});

const useStyles = makeStyles(theme =>
  mergeDeep(theme.journey.timeSlotPicker, {
    icon: {
      height: "20px",
      width: "20px",
      color: theme.palette.grey.A200,
      margin: "0 18px 0 0",
      transform: "scale(-1,1)",
    },
    radio: {
      width: "100%",
      margin: "10px 0 0 0",
      padding: "13px",
      flex: "0 0 100%",
      justifyContent: "flex-start",
      fontWeight: theme.typography.fontWeightMedium,
      "&:first-child": {
        margin: "0",
      },
    },
  }),
);

/**
 * Component that displays a radio-button like list, allowing selection
 * @param {FormTimeSlotPickerProps} props Props that the component accepts
 */
const FormTimeSlotPicker = ({
  availableTimes = [],
  onChange = () => {},
  value = "",
  radioCardGroupProps = {},
}) => {
  const { brand, getText } = useBrand();
  const classes = useStyles();

  /**
   * Card content does not change when it is selected, so functionality is
   * abstracted out into a small helper function
   *
   * @param {TimeSlot} timeSlot Details of the timeslot to show
   */
  const CardContent = ({ startTime, endTime, selected = false }) => (
    <Fragment>
      {brand === Brand.Bsm && (
        <SvgIcon className={classes.icon}>
          <QueryBuilder />
        </SvgIcon>
      )}
      <Typography
        color="textPrimary"
        classes={{ root: selected && classes.selectedContent }}
      >
        {`${format(soapTimeToLocalTime(startTime), TIME_FORMAT)} —
        ${format(soapTimeToLocalTime(endTime), TIME_FORMAT)}`}
      </Typography>
    </Fragment>
  );

  return (
    <Fragment>
      {availableTimes.length === 0 ? (
        <Typography data-test="no-timeslots-text">
          {getText("common.timeslotPickerNoLessons")}
          <br />
          {getText("common.timeslotPickerPickerAnother")}
        </Typography>
      ) : (
        <RadioCardGroup
          name="radio-card-timeslot"
          value={typeof value === "string" ? value : JSON.stringify(value)}
          onChange={onChange}
          {...radioCardGroupProps}
        >
          {availableTimes.map(slot => (
            <RadioCard
              classes={{ root: classes.radio }}
              key={`radio-card-${slot.startTime}`}
              value={typeof slot === "string" ? slot : JSON.stringify(slot)}
              cardContent={<CardContent {...slot} />}
              cardContentChecked={<CardContent selected {...slot} />}
            />
          ))}
        </RadioCardGroup>
      )}
    </Fragment>
  );
};

FormTimeSlotPicker.propTypes = {
  availableTimes: arrayOf(TimeSlotPropTypes),
  onChange: func,
  value: oneOfType([
    shape({
      startTime: string,
      endTime: string,
    }),
    string,
  ]),
  brand: string,
  radioCardGroupProps: shape({}),
};

export default FormTimeSlotPicker;
