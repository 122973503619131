import React, { Fragment, useEffect, useState } from "react";
import { Router } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import CssBaseline from "@material-ui/core/CssBaseline";
import * as Sentry from "@sentry/react";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { v4 as uuid } from "uuid";

import Routes from "./Routes";
import AppMeta from "./AppMeta";
import ScrollToTop from "./components/common/ScrollToTop/ScrollToTop";
import { ApolloClientProvider } from "./client";
import { APP_VERSION } from "./constants";
import { useStateContext } from "./context/stateContext";
import CustomDateFnsUtils from "./utils/customDateFnsUtils";
import ThemeProviderWithBrand from "./theme/ThemeProviderWithBrand";
import BrandProvider from "./components/common/BrandProvider/BrandProvider";
import TealiumProvider from "./components/common/TealiumProvider/TealiumProvider";
import { SnackbarProvider } from "notistack";
import { createBrowserHistory } from "history";

const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });

Sentry.init({
  dsn: "https://1deb131344da47c5a1e7364259b9194e@sentry.io/1513607",
  environment: process.env.NODE_ENV,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  integrations: [
    new TracingIntegrations.BrowserTracing({
      // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 0.02,
  enabled: process.env.NODE_ENV !== "development",
});

/* eslint-disable no-console */
console.log("App version:", APP_VERSION);
console.log("Sentry release:", process.env.REACT_APP_SENTRY_RELEASE);

const notistackRef = React.createRef();

const App = ({ ldrive = false }) => {
  const [sessionId, setSessionId] = useState<string>("");

  useEffect(() => {
    const sessionId = sessionStorage.getItem("sessionId") || uuid();
    setSessionId(sessionId);
    sessionStorage.setItem("sessionId", sessionId);
  }, []);

  return (
    <Fragment>
      {sessionId && (
        <BrandProvider>
          <TealiumProvider ldrive={ldrive}>
            <ThemeProviderWithBrand>
              <MuiPickersUtilsProvider utils={CustomDateFnsUtils}>
                <useStateContext.Provider sessionId={sessionId} ldrive={ldrive}>
                  <Fragment>
                    <CssBaseline />
                    <SnackbarProvider maxSnack={8} ref={notistackRef}>
                      <Router history={history}>
                        <ApolloClientProvider sessionId={sessionId}>
                          <ScrollToTop>
                            <Routes setSessionId={setSessionId} />
                          </ScrollToTop>
                        </ApolloClientProvider>
                      </Router>
                    </SnackbarProvider>
                  </Fragment>
                </useStateContext.Provider>
              </MuiPickersUtilsProvider>
              <AppMeta />
            </ThemeProviderWithBrand>
          </TealiumProvider>
        </BrandProvider>
      )}
    </Fragment>
  );
};

export default App;
