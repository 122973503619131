import React from "react";
import { number } from "prop-types";
import {
  makeStyles,
  MobileStepper as MUIMobileStepper,
} from "@material-ui/core";
import mergeDeep from "lodash.merge";

export const createStyles = (theme, activeStep) => {
  const styles = mergeDeep(theme.journey.mobileStepper, {
    root: {
      background: "none",
    },
    dot: {
      background: theme.palette.grey[50],
      width: "20px",
      height: "6px",
      borderRadius: "0",
      margin: "0 1.5px",
    },
    dotActive: {
      background: theme.palette.primary.main,
    },
  });

  // use an nth-child selector to target all dots before the active step
  styles.dot[`&:nth-child(-n+${activeStep})`] = {
    background: theme.palette.primary.main,
  };

  return styles;
};

const useStyles = activeStep =>
  makeStyles(theme => createStyles(theme, activeStep));

const ProgressBarMobileStepper = ({ steps, activeStep, ...restProps }) => {
  const classes = useStyles(activeStep)();

  return (
    <MUIMobileStepper
      {...restProps}
      variant="dots"
      steps={steps}
      position="static"
      activeStep={activeStep}
      classes={classes}
      data-test="mobile-progress-bar"
    />
  );
};

ProgressBarMobileStepper.propTypes = {
  steps: number.isRequired,
  activeStep: number.isRequired,
};

export default ProgressBarMobileStepper;
