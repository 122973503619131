import React, { Fragment } from "react";
import { string, node, func, bool } from "prop-types";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import { Divider, ListItem, ListItemAvatar } from "@material-ui/core";
import ListItemActions from "./Actions";
import ListItemText from "./Text";

const useStyles = button =>
  makeStyles({
    container: {
      display: "flex",
      justifyContent: "space-between",
    },
    root: {
      width: "auto",
      display: "flex",
      "&:hover": {
        background: button ? "#F8F7F7" : "none",
      },
    },
  });

const Item = ({
  text,
  secondaryText,
  avatar,
  actions,
  icon,
  onClick = () => {},
  disabled = false,
  selected = false,
  button = true,
  ...restProps
}) => {
  const classes = useStyles(button)();
  return (
    <Fragment>
      <ListItem
        button={button}
        onClick={onClick}
        disabled={disabled}
        selected={selected}
        classes={classes}
        {...restProps}
      >
        {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
        {text && <ListItemText text={text} secondaryText={secondaryText} />}
        {actions && <ListItemActions>{actions}</ListItemActions>}
        {icon && <Fragment>{icon}</Fragment>}
      </ListItem>
      <Divider variant="middle" />
    </Fragment>
  );
};

Item.propTypes = {
  text: string.isRequired,
  secondaryText: node,
  avatar: node,
  actions: node,
  icon: node,
  onClick: func,
  disabled: bool,
  selected: bool,
};

export default withTheme(Item);
