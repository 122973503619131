import * as React from "react";
import { oneOfType, node, string, arrayOf, func, shape } from "prop-types";
import mergeDeep from "lodash.merge";
import {
  Tabs as MUITabs,
  Tab as MUITab,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useTealium } from "../TealiumProvider/TealiumProvider";

const useStyles = makeStyles(theme =>
  mergeDeep(theme.journey.tabs, {
    wrapper: {
      display: "flex",
      alignItems: "center",
    },
    tabs: {
      flex: "1",
    },
    tab: {
      textTransform: "none",
      fontSize: "1rem",
      color: theme.palette.grey[400],
      fontWeight: theme.typography.fontWeightRegular,
    },
    tabSelected: {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
    },
    tabsIndicator: {
      height: "3px",
    },
    labelWrap: {
      marginRight: "20px",
    },
  }),
);

const Tabs = ({
  label = "",
  tabItems = [],
  onChange = () => {},
  selected,
  disabled,
  ...rest
}) => {
  const { trackEvent } = useTealium();
  const activeTab = selected
    ? tabItems.findIndex(tabItem => tabItem.id === selected)
    : 0;
  const [value, setValue] = React.useState(activeTab);

  React.useEffect(() => {
    setValue(activeTab);
  }, [selected]);

  const handleChange = (event, newValue) => {
    if (newValue !== value && !disabled) {
      onChange(tabItems[newValue].id);
      setValue(newValue);
      trackEvent({
        eventCategory: "Prices",
        eventAction: "Interaction",
        eventLabel: `${tabItems[newValue].title} Tab`,
      });
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {label && (
        <Typography data-test="tabsLabel" className={classes.labelWrap}>
          {label}
        </Typography>
      )}
      <MUITabs
        classes={{ root: classes.tabs, indicator: classes.tabsIndicator }}
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        {...rest}
      >
        {tabItems.map(tabItem => (
          <MUITab
            key={tabItem.id}
            classes={{ root: classes.tab, selected: classes.tabSelected }}
            label={tabItem.title}
            data-test={`tab-item-${tabItem.id}`}
          />
        ))}
      </MUITabs>
    </div>
  );
};

Tabs.propTypes = {
  label: oneOfType([string, node]),
  tabItems: arrayOf(
    shape({
      id: string,
      title: string,
    }),
  ),
  onChange: func,
  selected: string,
};

export default Tabs;
