import React, { FC } from "react";

import CommonConfirmation from "../../../common/Confirmation/Confirmation";
import Body from "./Body";
import { ConfirmationProps } from "./types";

const Confirmation: FC<ConfirmationProps> = ({
  title,
  pickupPostcode,
  startTime,
  endTime,
  instructorName,
  topUpId,
  gift,
  pupilFirstName,
}) => (
  <CommonConfirmation
    title={title}
    pickupPostcode={pickupPostcode}
    startTime={startTime}
    endTime={endTime}
    instructorName={instructorName}
  >
    <Body topUpId={topUpId} gift={gift} pupilFirstName={pupilFirstName} />
  </CommonConfirmation>
);

export default Confirmation;
