import React, { Fragment, useState } from "react";
import { node, instanceOf, func, string, bool, oneOfType } from "prop-types";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  InputAdornment,
  Icon,
  makeStyles,
  InputLabel,
} from "@material-ui/core";
import { subYears } from "date-fns";

import { useBrand } from "../../BrandProvider/BrandProvider";

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: "14px",
    color: theme.palette.text.primary,
    opacity: 0.4,
    transform: "translateY(-1px)",
  },
}));

const FormDobPicker = ({
  value = null,
  name = "dateOfBirth",
  onChange,
  disabled = false,
  maxDate = subYears(new Date(), 17),
  minDate = subYears(new Date(), 110),
  minDateMessage,
  maxDateMessage,
  minimumAgeRequired = true,
  label,
  ...restProps
}) => {
  const { getText } = useBrand();
  const [selectedDate, setSelectedDate] = useState(value);
  const handleDateChange = newDate => {
    setSelectedDate(newDate);
    onChange(newDate);
  };
  const classes = useStyles();
  const dobPickerLabel = label || getText("common.dobPickerLabel");

  return (
    <Fragment>
      <InputLabel>{dobPickerLabel}</InputLabel>

      <KeyboardDatePicker
        value={selectedDate}
        initialFocusedDate={selectedDate}
        onChange={handleDateChange}
        disabled={disabled}
        maxDate={minimumAgeRequired ? maxDate : undefined}
        minDate={minDate}
        placeholder={getText("common.dobPickerPlaceholder")}
        format="dd/MM/yyyy"
        minDateMessage={minDateMessage || getText("common.dobPickerMinDate")}
        maxDateMessage={
          minimumAgeRequired
            ? maxDateMessage ?? getText("common.dobPickerMaxDate")
            : undefined
        }
        openTo="year"
        views={["year", "month", "date"]}
        InputLabelProps={{
          classes: { root: classes.label },
        }}
        KeyboardButtonProps={{
          "data-test": "dob-picker-button",
        }}
        InputProps={{
          name,
          classes: { input: classes.input },
          startAdornment: (
            <InputAdornment position="start">
              <Icon classes={{ root: classes.icon }}>calendar_today</Icon>
            </InputAdornment>
          ),
        }}
        inputProps={{
          inputMode: "decimal",
        }}
        disableFuture
        {...restProps}
      />
    </Fragment>
  );
};

FormDobPicker.propTypes = {
  value: oneOfType([instanceOf(Date), string]),
  name: string,
  onChange: func,
  disabled: bool,
  maxDate: instanceOf(Date),
  minDate: instanceOf(Date),
  minDateMessage: node,
  maxDateMessage: node,
  label: string,
  minimumAgeRequired: bool,
};

export default FormDobPicker;
