import React from "react";
import { string, oneOfType, number } from "prop-types";
import { Typography, Avatar, Box, withStyles } from "@material-ui/core";
import htmlParser from "html-react-parser";

const styles = theme => ({
  contentSection: {
    marginBottom: "27px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
  avatar: {
    float: "left",
    marginRight: "17px",
  },
  title: {
    lineHeight: "24px",
    color: theme.palette.grey[500],
    "& sup": {
      verticalAlign: "super",
      fontSize: "85%",
      lineHeight: "14px",
    },
  },
  subtitle: {
    fontSize: "12px",
    fontWeight: theme.typography.fontWeightRegular,
    opacity: 0.5,
  },
});

const LessonDetailSection = ({
  avatarColor,
  classes,
  avatarImage,
  subtitle,
  avatarText,
  title,
  ...restProps
}) => (
  <Box classes={{ root: classes.contentSection }} {...restProps}>
    <Avatar
      classes={{ root: classes.avatar }}
      className={avatarColor}
      src={avatarImage}
      data-test="avatar"
    >
      {avatarText}
    </Avatar>
    <Typography
      classes={{ root: classes.title }}
      variant="subtitle1"
      noWrap
      data-test="lesson-detail-title"
    >
      {htmlParser(title)}
    </Typography>
    <Typography classes={{ root: classes.subtitle }} variant="subtitle2" noWrap>
      {subtitle}
    </Typography>
  </Box>
);

LessonDetailSection.propTypes = {
  title: string.isRequired,
  subtitle: string,
  avatarImage: string,
  avatarText: oneOfType([string, number]),
  avatarColor: string,
};

export default withStyles(styles)(LessonDetailSection);
