import React from "react";

import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import mergeDeep from "lodash.merge";
import pick from "lodash.pick";

import { useBrand } from "../../../../common/BrandProvider/BrandProvider";
import Category from "../Category";
import { ReturningUserJourneyProgressProps } from "../../types";

type WithProgressDataProps = Pick<
  ReturningUserJourneyProgressProps,
  "categories" | "overallProgress"
>;

const useCircularProgressStyles = makeStyles(theme =>
  // @ts-ignore
  mergeDeep(theme.journey.circularProgressAlt, {
    root: {
      borderRadius: "50%",
      boxSizing: "border-box",
    },
    svg: {
      transform: "scale(1.075)",
    },
    wrapper: {
      display: "inline-block",
      position: "relative",
      fontFamily: theme.typography.fontFamily,
      height: "130px",
      width: "130px",
    },
    innerWrapper: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    },
    percentage: {
      opacity: "0.87",
      fontSize: "36px",
      fontWeight: "300",
      margin: "0",
      lineHeight: "1",
    },
  }),
);

const useStyles = makeStyles(
  // @ts-ignore
  theme => theme.journey.progressTracker,
);

const WithProgressData: React.FC<WithProgressDataProps> = ({
  overallProgress,
  categories = [],
}) => {
  const classes = useStyles();
  const { getText } = useBrand();
  const circularProgressClasses = useCircularProgressStyles();
  return (
    <React.Fragment>
      <div // @ts-ignore
        className={classes.progressMeterWrapper}
      >
        <div // @ts-ignore
          className={circularProgressClasses.wrapper}
          data-test="desktop-progress"
        >
          <div // @ts-ignore
            className={circularProgressClasses.innerWrapper}
          >
            <p // @ts-ignore
              className={circularProgressClasses.percentage}
              data-test="desktop-progress-percentage"
            >
              {`${overallProgress.toFixed(0)}%`}
            </p>
          </div>
          <CircularProgress
            variant="determinate"
            thickness={2.5}
            size={130}
            value={overallProgress}
            // @ts-ignore
            classes={pick(circularProgressClasses, ["root", "circle", "svg"])}
          />
        </div>
      </div>

      <div // @ts-ignore
        className={classes.categories}
      >
        {categories.map(category => (
          <Category key={category.categoryName} {...category} />
        ))}
      </div>

      <Typography // @ts-ignore
        className={classes.infoText}
        variant="h3"
      >
        {getText("returning/progress-tracker.infoTextTitle")}
      </Typography>
      <Typography // @ts-ignore
        className={classes.infoText}
        variant="body1"
      >
        {getText("returning/progress-tracker.infoText", {
          totalCategories: categories.length,
        })}
      </Typography>
    </React.Fragment>
  );
};

export default WithProgressData;
