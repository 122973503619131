import React, { ChangeEvent, useEffect, useState } from "react";
import { GraphQLError } from "graphql";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { useBrand } from "../../../../common/BrandProvider/BrandProvider";
import { useTealium } from "../../../../common/TealiumProvider/TealiumProvider";
import {
  GLOBAL_PATH_NAMES,
  MIN_PHONE_NUMBER_LENGTH,
  NUDGE_BACK_TYPES,
} from "../../../../../constants";
import { isValidPhoneNumber } from "../../../../../utils/validation";
import createNudgeBackMutation from "../../../../../graphql/createNudgeBack.graphql";
import { useStateContext } from "../../../../../context/stateContext";
import NudgeBack from "./NudgeBack";
import ReCAPTCHA from "react-google-recaptcha";
import { mapLessonTypeToLearnerLessonType } from "../helpers";

const PricesNudgeBack = () => {
  const { brand } = useBrand();
  const { trackEvent } = useTealium();

  const history = useHistory();

  const {
    state: {
      nujHistory: {
        pickupPostcode: postcode,
        selectedLessonType: lessonType,
        selectedTransmissionType: transmissionType,
        isRestrictedForKeyWorker,
      },
    },
  } = useStateContext();

  const isCovidNudgeBack = isRestrictedForKeyWorker;

  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");

  const [createNudgeBack, { loading: isSubmitting }] = useMutation(
    createNudgeBackMutation,
    {
      onCompleted: ({ createNudgeback }) => {
        if (createNudgeback.successful) {
          trackEvent({
            eventCategory: "Prices",
            eventAction: "Modal - Open",
            eventLabel: "Instructor Alert Created",
          });
          setShowSummaryModal(true);
        } else {
          history.push(GLOBAL_PATH_NAMES.CALL_TO_COMPLETE);
        }
      },
      onError: error => {
        throw new GraphQLError(error.message);
      },
    },
  );

  // const handleCreateNudgeBack = (event: React.FormEvent<HTMLFormElement>) => {
  const handleCreateNudgeBack = (
    event: React.FormEvent<HTMLFormElement> | string | null,
  ) => {
    // When called via Google ReCaptcha the event is not triggered by a form element and will contain a string
    if (event && typeof event !== "string") {
      event.preventDefault();
    }

    trackEvent({
      eventCategory: "Prices",
      eventAction: "Button Click",
      eventLabel: "Nudgeback",
    });

    createNudgeBack({
      variables: {
        brand,
        mobileNumber,
        lessonType: mapLessonTypeToLearnerLessonType(lessonType),
        postcode,
        transmissionType,
        type: isCovidNudgeBack
          ? NUDGE_BACK_TYPES.WEB_COVID
          : NUDGE_BACK_TYPES.WEB,
        timestamp: new Date().toString(),
      },
    });
  };

  const handleRecaptchaCheck = (
    event: React.FormEvent<HTMLFormElement>,
    recaptchaRef: React.RefObject<ReCAPTCHA>,
  ) => {
    if (event) {
      event.preventDefault();
    }
    recaptchaRef.current?.execute(); // Invisible Google ReCaptcha v2 Check
  };

  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsDirty(true);
    setMobileNumber(value);
  };

  const handleFocus = () => {
    trackEvent({
      eventCategory: "Prices",
      eventAction: "Interaction",
      eventLabel: "Mobile",
    });
  };

  const shouldValidate = mobileNumber.length >= MIN_PHONE_NUMBER_LENGTH;

  useEffect(() => {
    if (shouldValidate) {
      setIsValid(isValidPhoneNumber(mobileNumber));
    } else {
      setIsDirty(false);
      setIsValid(false);
    }
  }, [mobileNumber]);

  return (
    <NudgeBack
      handleRecaptchaCheck={handleRecaptchaCheck}
      handleCreateNudgeBack={handleCreateNudgeBack}
      handleChange={handleChange}
      handleFocus={handleFocus}
      isSubmitting={isSubmitting}
      isValid={isValid}
      isDirty={isDirty}
      postcode={postcode}
      transmissionType={transmissionType}
      mobileNumber={mobileNumber}
      showSummaryModal={showSummaryModal}
      isCovidNudgeBack={isCovidNudgeBack}
    />
  );
};

export default PricesNudgeBack;
