import React, { useMemo } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import get from "lodash.get";

import LoadingScreen from "../../common/LoadingScreen/LoadingScreen";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import topUpDetails from "../../../graphql/topUpDetails.graphql";
import learnerDetails from "../../../graphql/learnerDetails.graphql";
import requestNewInstructorMutation from "../../../graphql/requestNewInstructor.graphql";
import {
  useStateContext,
  UPDATE_QTU_HISTORY,
  UPDATE_RUJ_HISTORY,
} from "../../../context/stateContext";

import {
  getJourneyURL,
  GLOBAL_PATH_NAMES,
  JourneyType,
  RETURNING_USER_JOURNEY_PATH_NAMES,
  USE_AUTH_PORTAL,
} from "../../../constants";
import { StateContext } from "models/state";
import { History, Location } from "history";

import Account from "./Account";
import { Learner } from "@graphql/types";
import { redirectTarget } from "../../../client";
import { getJourneyType } from "@utils";

interface Props {
  location: Location;
  history: History;
  match: any;
}

const deleteCookie = (name: string) => {
  const rootDomain =
    location.hostname.split(".").reverse()[1] +
    "." +
    location.hostname.split(".").reverse()[0];

  document.cookie =
    name + `=; domain=${rootDomain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const AccountWithQuery: React.FC<Props> = ({
  match,
  history,
  location: { pathname },
}) => {
  const { brand } = useBrand();
  const topUpId = get(match, "params.topUpId");
  const { dispatch, state }: StateContext = useStateContext();
  const journeyType = useMemo(
    () => getJourneyType(history.location.pathname, state),
    [],
  );
  let customerType = localStorage.getItem("customerType") || "Learner";

  const [
    requestNewInstructor,
    { loading: requestInstructorClicked },
  ] = useMutation(requestNewInstructorMutation, {
    onCompleted: data => {
      if (!data.requestNewInstructor.successful) {
        history.push(GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG);
        return;
      }
      dispatch({
        type: UPDATE_RUJ_HISTORY,
        payload: {
          newInstructorRequested: true,
        },
      });
      history.push(
        RETURNING_USER_JOURNEY_PATH_NAMES.NEW_INSTRUCTOR_REQUEST_RECEIVED,
      );
    },
    onError: () => {
      history.push(GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG);
    },
  });

  if (!get(match, "params.topUpId") && state.rujHistory.topUpId) {
    history.replace(
      `${pathname}/${state.rujHistory.topUpId}${
        state?.sessionId ? `?${state?.sessionId}` : ""
      }`,
    );
  }

  const onQueryComplete = useMemo(() => {
    if (USE_AUTH_PORTAL && journeyType === JourneyType.RETURNING_USER_JOURNEY) {
      return (data: { thisLearner: Learner }) => {
        if (!data) {
          return;
        }

        // Fetch the allocation of the learner
        const allocation = data.thisLearner.allocations?.[0];

        // Compute hourly rate and discount
        const discountValue = allocation?.discounts?.[0]?.value || 0;
        const hourlyRate = allocation?.purchaseRate || 0;

        // Override customerType if necessary
        if (data.thisLearner.customerTypes) {
          customerType = "Learner";

          if (data.thisLearner?.customerTypes) {
            const isMotability = data.thisLearner?.customerTypes?.some(
              ct => ct.type === "Motability",
            );
            if (isMotability) {
              customerType = "Motability";
            }
          }
          localStorage.setItem("customerType", customerType);
        }

        // Assign state values from allocation
        const pupilFirstName = data.thisLearner.name.firstName;
        const learnerId = data.thisLearner.id;
        const pupilId = data.thisLearner.personId;
        const pupilPickupPostcode = data.thisLearner.pickupAddress?.postcode;
        const threshold = allocation?.discounts?.[0]?.hoursRangeStart || 0;
        const pupilBalance = allocation?.balance || 0;
        const selectedHoursCost = hourlyRate;
        const discountedHourlyRate = hourlyRate - discountValue;
        const instructorId = allocation?.instructor.id;
        const instructorName = allocation?.instructor.name.fullName;
        const transmissionType = allocation?.transmission;

        // Store values in redux state
        dispatch({
          type: UPDATE_QTU_HISTORY,
          payload: {
            personId: parseInt(pupilId),
            pupilId,
            learnerId,
            pupilBalance,
            pupilFirstName,
            hourlyRate,
            selectedHoursCost,
            discountedHourlyRate,
            threshold,
            instructorId,
            topUpId,
          },
        });
        dispatch({
          type: UPDATE_RUJ_HISTORY,
          payload: {
            personId: parseInt(pupilId),
            pupilId,
            learnerId,
            referralId: undefined,
            topUpId,
            pupilBalance,
            pupilFirstName,
            pupilPickupPostcode,
            instructorId,
            instructorName,
            transmissionType,
          },
        });
      };
    }

    return (data: { learnerTopUpDetails: Learner }) => {
      if (!data) {
        return;
      }

      // Fetch the allocation of the learner
      const allocation = data.learnerTopUpDetails.allocations?.[0];

      // Compute hourly rate and discount
      const discountValue = allocation?.discounts?.[0]?.value || 0;
      const hourlyRate = allocation?.purchaseRate || 0;

      // Override customerType if necessary
      if (data.learnerTopUpDetails.customerTypes) {
        customerType = "Learner";

        if (data.learnerTopUpDetails?.customerTypes) {
          const isMotability = data.learnerTopUpDetails?.customerTypes?.some(
            ct => ct.type === "Motability",
          );
          if (isMotability) {
            customerType = "Motability";
          }
        }
        localStorage.setItem("customerType", customerType);
      }

      // Assign state values from allocation
      const pupilFirstName = data.learnerTopUpDetails.name.firstName;
      const learnerId = data.learnerTopUpDetails.id;
      const pupilId = data.learnerTopUpDetails.personId;
      const pupilPickupPostcode =
        data.learnerTopUpDetails.pickupAddress?.postcode;
      const threshold = allocation?.discounts?.[0]?.hoursRangeStart || 0;
      const pupilBalance = allocation?.balance || 0;
      const selectedHoursCost = hourlyRate;
      const discountedHourlyRate = hourlyRate - discountValue;
      const instructorId = allocation?.instructor.id;
      const instructorName = allocation?.instructor.name.fullName;
      const transmissionType = allocation?.transmission;

      // Store values in redux state
      dispatch({
        type: UPDATE_QTU_HISTORY,
        payload: {
          personId: parseInt(pupilId),
          pupilId,
          learnerId,
          pupilBalance,
          pupilFirstName,
          hourlyRate,
          selectedHoursCost,
          discountedHourlyRate,
          threshold,
          instructorId,
          topUpId,
        },
      });
      dispatch({
        type: UPDATE_RUJ_HISTORY,
        payload: {
          personId: parseInt(pupilId),
          pupilId,
          learnerId,
          referralId: undefined,
          topUpId,
          pupilBalance,
          pupilFirstName,
          pupilPickupPostcode,
          instructorId,
          instructorName,
          transmissionType,
        },
      });
    };
  }, []);

  const doLogout = () => {
    if (USE_AUTH_PORTAL) {
      deleteCookie("authPortalToken");
    } else {
      localStorage.removeItem("learnerAuth");
    }

    sessionStorage.removeItem("appState");
    sessionStorage.removeItem("sessionId");

    // we have to use window.location.assign here to ensure that the Learner app sees the url change on Android
    // sadly a bug in react-native-webview causes Android to not notice history push state changes
    window.location.assign(
      USE_AUTH_PORTAL
        ? `${redirectTarget(brand, journeyType)}`
        : `${getJourneyURL(brand)}/login?doLogout=true`,
    );
  };

  const { loading, error, data } = useQuery(
    USE_AUTH_PORTAL && journeyType === JourneyType.RETURNING_USER_JOURNEY
      ? learnerDetails
      : topUpDetails,
    {
      variables:
        USE_AUTH_PORTAL && journeyType === JourneyType.RETURNING_USER_JOURNEY
          ? {}
          : {
              topUpId,
            },
      onCompleted: onQueryComplete as any,
      fetchPolicy: "no-cache",
      skip: !USE_AUTH_PORTAL && !topUpId,
    },
  );
  if (loading || requestInstructorClicked) {
    return (
      <LoadingScreen
        messageText={
          requestInstructorClicked
            ? "Processing your request"
            : "Your driving lessons"
        }
      />
    );
  }

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    return null;
  }

  const { name, hours, referralId, instructorId, learnerId } =
    USE_AUTH_PORTAL && journeyType === JourneyType.RETURNING_USER_JOURNEY
      ? {
          name: data.thisLearner.name.firstName,
          learnerId: data.thisLearner.id,
          hours: data.thisLearner.allocations?.[0]?.balance || 0,
          instructorId: data.thisLearner.allocations?.[0]?.instructor.id,
          referralId: undefined,
        }
      : {
          name: data.learnerTopUpDetails.name.firstName,
          learnerId: data.learnerTopUpDetails.id,
          hours: data.learnerTopUpDetails.allocations?.[0]?.balance || 0,
          instructorId:
            data.learnerTopUpDetails.allocations?.[0]?.instructor.id,
          referralId: undefined,
        };

  const handleRequestNewInstructor = () => {
    requestNewInstructor({
      variables: {
        input: {
          pupilId: learnerId,
        },
      },
    });
  };

  return (
    <Account
      customerType={customerType}
      pupilName={name}
      balance={hours}
      topUpRequired={hours < 2}
      instructorId={instructorId}
      referralId={referralId}
      onLogoutClick={doLogout}
      requestNewInstructor={handleRequestNewInstructor}
      newInstructorRequested={state.rujHistory.newInstructorRequested}
      requestInstructorClicked={requestInstructorClicked}
    />
  );
};

export default withRouter(AccountWithQuery);
