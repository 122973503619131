import React, { useState, useEffect } from "react";
import { number } from "prop-types";
import {
  makeStyles,
  CircularProgress as MUICircularProgress,
} from "@material-ui/core";
import pick from "lodash.pick";
import mergeDeep from "lodash.merge";

const useStyles = makeStyles(theme =>
  mergeDeep(theme.journey.circularProgress, {
    root: {
      borderRadius: "50%",
      boxSizing: "border-box",
    },
    svg: {
      transform: "scale(1.075)",
    },
    wrapper: {
      display: "inline-block",
      position: "relative",
      fontFamily: theme.typography.fontFamily,
    },
    innerWrapper: {
      position: "absolute",
      top: "56%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    },
    percentage: {
      opacity: "0.87",
      fontSize: "36px",
      fontWeight: "300",
      margin: "0",
      lineHeight: "1",
    },
    step: {
      opacity: "0.6",
      margin: "5px 0 0 0",
      fontSize: "14px",
    },
  }),
);

export const calculatePercentageValue = (activeStep, steps) =>
  (activeStep / (steps - 1)) * 100;

export const PercentageText = ({ percentageValue }) => {
  const classes = useStyles();
  return (
    <p className={classes.percentage} data-test="desktop-progress-percentage">
      {`${percentageValue.toFixed(0)}%`}
    </p>
  );
};

PercentageText.propTypes = {
  percentageValue: number.isRequired,
};

export const StepsText = ({ activeStep, steps }) => {
  const classes = useStyles();
  return (
    <p className={classes.step} data-test="desktop-progress-steps">
      {`${activeStep + 1}/${steps}`}
    </p>
  );
};

StepsText.propTypes = {
  activeStep: number.isRequired,
  steps: number.isRequired,
};

const ProgressBarCircularProgress = ({
  steps,
  activeStep,
  size = 130,
  thickness = 2.5,
  ...restProps
}) => {
  const classes = useStyles();

  const [percentageValue, setPercentageValue] = useState(
    calculatePercentageValue(activeStep, steps),
  );
  useEffect(() => {
    setPercentageValue(calculatePercentageValue(activeStep, steps));
  });

  return (
    <div className={classes.wrapper} data-test="desktop-progress">
      <div className={classes.innerWrapper}>
        <PercentageText percentageValue={percentageValue} />
        <StepsText activeStep={activeStep} steps={steps} />
      </div>
      <MUICircularProgress
        {...restProps}
        variant="determinate"
        value={percentageValue}
        classes={pick(classes, ["root", "circle", "svg"])}
        size={size}
        thickness={thickness}
      />
    </div>
  );
};

ProgressBarCircularProgress.propTypes = {
  steps: number,
  activeStep: number,
  size: number,
  thickness: number,
};

export default ProgressBarCircularProgress;
