import React from "react";
import { bool, string, node, func } from "prop-types";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  makeStyles,
} from "@material-ui/core";

const useLabelStyles = (value, indeterminate) =>
  makeStyles(theme => ({
    root: {
      boxShadow: () => (value || indeterminate) && theme.boxShadow,
      borderRadius: "6px",
      padding: "10px 22px 10px 0",
      color: theme.palette.grey[500],
      border: () =>
        value || indeterminate
          ? `2px solid ${theme.palette.primary.main}`
          : `1px solid ${theme.palette.grey[300]}`,
      margin: () =>
        value || indeterminate ? `14px -1px -1px -1px` : `15px 0 0 0`,
    },
  }));

const useCheckboxStyles = makeStyles(() => ({
  root: {
    paddingTop: "0",
    paddingBottom: "0",
  },
}));

const FormCheckbox = ({
  value,
  label,
  legend,
  helperText,
  onChange,
  indeterminate,
  disabled,
  ...restProps
}) => {
  const labelClasses = useLabelStyles(value, indeterminate)();
  const checkboxClasses = useCheckboxStyles();

  return (
    <FormControl fullWidth>
      {legend && <FormLabel component="legend">{legend}</FormLabel>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormControlLabel
        classes={labelClasses}
        label={label}
        control={
          <Checkbox
            onChange={onChange}
            classes={checkboxClasses}
            checked={value}
            value={value}
            indeterminate={indeterminate}
            disabled={disabled}
            {...restProps}
          />
        }
      />
    </FormControl>
  );
};

FormCheckbox.propTypes = {
  value: bool,
  label: string,
  legend: node,
  helperText: node,
  indeterminate: bool,
  onChange: func,
  disabled: bool,
};

FormCheckbox.defaultProps = {
  label: "",
  legend: "",
  helperText: "",
  value: false,
  indeterminate: false,
  onChange() {},
};

export default FormCheckbox;
