import DateFnsUtils from "@date-io/date-fns";
import { startOfYear, endOfYear, isAfter, subYears } from "date-fns";

export default class CustomDateFnsUtils extends DateFnsUtils {
  /**
   * Overrides the default `getYearRange` function to return the years in
   * descending order
   *
   * @param {Date} start Starting date, will be the last item in the returned
   * array
   * @param {*Date end Ending date, will be the first item in the returned
   * array
   *
   * @returns {Date[]} An array of dates, one for each year in the range between
   * the supplied start and end dates
   */
  // eslint-disable-next-line class-methods-use-this
  getYearRange(start, end) {
    const startDate = startOfYear(end);
    const endDate = endOfYear(start);
    const years = [];

    let current = startDate;
    while (isAfter(current, endDate)) {
      years.push(current);
      current = subYears(current, 1);
    }

    return years;
  }
}
