import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: "5px",
    padding: "20px 10px",
  },
  text: {
    marginTop: "10px",
  },
  icon: {
    textAlign: "center",
    "& img": {
      maxWidth: "100%",
    },
  },
  button: {
    padding: "10px",
  },
}));

const Banner = ({
  icon,
  title,
  text,
  buttonText,
  onButtonClick,
  buttonIsForChat,
  ...rest
}: {
  icon: JSX.Element;
  title: string;
  text: string;
  buttonText?: string;
  onButtonClick?: () => void;
  buttonIsForChat?: boolean;
  rest?: any;
}) => {
  const classes = useStyles();
  const hasButton = buttonText && onButtonClick;
  return (
    <Grid container spacing={1} classes={{ root: classes.container }} {...rest}>
      <Grid item xs={1} classes={{ root: classes.icon }}>
        {icon}
      </Grid>
      <Grid item xs={hasButton ? 8 : 11}>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="body1" classes={{ root: classes.text }}>
          {text}
        </Typography>
      </Grid>
      {hasButton && (
        <Grid item container xs={3} alignItems="center" justify="center">
          <Button
            onClick={onButtonClick}
            classes={{ root: classes.button }}
            variant="text"
            color="secondary"
            data-test="banner-button"
            data-chat={buttonIsForChat ?? false}
          >
            {buttonText}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Banner;
