import {
  RETURNING_USER_JOURNEY_PATH_NAMES,
  SESSION_ID_PARAM,
} from "../../../../constants";

/**
 * Get the booking link
 * @param brandName
 * @param topUpId
 * @param sessionId
 * @returns {string}
 */
export const getBookingUrl = (brandName, topUpId, sessionId) =>
  `${RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT}/${topUpId}${
    sessionId ? `?${SESSION_ID_PARAM}=${sessionId}` : ""
  }`;

export default {
  getBookingUrl,
};
