import React from "react";
import { useBrand } from "../../../../common/BrandProvider/BrandProvider";
import { useTealium } from "../../../../common/TealiumProvider/TealiumProvider";
import { useStateContext } from "../../../../../context/stateContext";
import ChangeInstructor from "./ChangeInstructor";
import { changeInstructor } from "./helpers";
import { Dispatch, State } from "models/state";

export const JourneyChangeInstructorWithState = ({
  history,
  location,
}: {
  history: History;
  location: Location;
}) => {
  const { brand } = useBrand();

  const state: { state: State; dispatch: Dispatch } = useStateContext();

  const {
    state: {
      nujHistory: { selectedInstructor, selectedPackage, availableInstructors },
    },
    dispatch,
  } = state;
  const { trackEvent } = useTealium();

  const onChange = (id: string) => {
    changeInstructor({
      id,
      history,
      location,
      availableInstructors: availableInstructors[brand],
      selectedPackage,
      dispatch,
      trackEvent,
    });
  };

  return (
    <ChangeInstructor
      onChange={onChange}
      instructors={availableInstructors[brand]}
      selectedInstructor={selectedInstructor}
      selectedPackageHours={selectedPackage?.hours || 0}
    />
  );
};

export default JourneyChangeInstructorWithState;
