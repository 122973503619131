import React from "react";
import SomethingsGoneWrong from "../SomethingsGoneWrong/SomethingsGoneWrong";
import ContinueButton from "../../common/ContinueButton/ContinueButton";
import { useBrand } from "../../common/BrandProvider/BrandProvider";

const NewUserJourneyStepsSomethingsGoneWrong500 = () => {
  const { getText } = useBrand();

  const renderActionButton = classes => (
    <ContinueButton
      href={getText("common.chatPage")}
      text={getText("somethings-gone-wrong-500.buttonText")}
      className={classes.actionButton}
      data-test="call-button"
    />
  );

  return (
    <SomethingsGoneWrong
      bodyTextKey="somethings-gone-wrong-500.body"
      renderActionButton={renderActionButton}
    />
  );
};

export default NewUserJourneyStepsSomethingsGoneWrong500;
