import React from "react";
import { withRouter } from "react-router-dom";
import CommonTimeslots from "../../../common/Timeslots";
import {
  useStateContext,
  UPDATE_NUJ_HISTORY,
} from "../../../../context/stateContext";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import { NEW_USER_JOURNEY_PATH_NAMES } from "../../../../constants";
import { RouterHistory } from "@sentry/react/dist/reactrouter";

const NewUserJourneyTimeslots = ({ history }: { history: RouterHistory }) => {
  const { getText } = useBrand();
  const {
    state: {
      nujHistory: {
        selectedLessonDate,
        selectedLessonTimeslot,
        selectedInstructor,
        selectedTransmissionType,
      },
    },
    dispatch,
  } = useStateContext();

  const instructor = selectedInstructor?.instructor;
  const instructorName = instructor?.name.split(" ")[1] || "";
  const handleUpdateContext = (payload: any) => {
    dispatch({
      type: UPDATE_NUJ_HISTORY,
      payload,
    });
    history.push(NEW_USER_JOURNEY_PATH_NAMES.PERSONAL_DETAILS);
  };
  return (
    <CommonTimeslots
      instructor={instructor}
      lessonDate={selectedLessonDate}
      lessonTimeslot={selectedLessonTimeslot}
      titleText={getText("timeslots.pageTitle", {
        instructorName,
      })}
      updateContext={handleUpdateContext}
      transmissionType={selectedTransmissionType}
    />
  );
};

export default withRouter(NewUserJourneyTimeslots);
