import React, { FC } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import get from "lodash.get";
import ContentWrapper from "../../../common/ContentWrapper/ContentWrapper";
import Titlebar from "../../../common/Titlebar/Titlebar";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";
import SummaryRow from "../../../common/Payment/SummaryRow/SummaryRow";
import PaymentForm from "../../../common/Payment/Form/Form";
import Summary from "../../../common/Payment/Summary/Summary";
import { goBack } from "../../../../utils/navigation";
import { useTealium } from "../../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../../utils/capitalisePathname";
import { PaymentProps } from "./types";

const useStyles = makeStyles(theme => ({
  paymentError: {
    marginTop: "2em",
  },
  loader: {
    marginTop: "30px",
  },
  total: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  discountText: {
    marginLeft: "-0.37em",
  },
}));

const NewUserJourneyStepsPayment: FC<PaymentProps> = ({
  history,
  location: { pathname },
  order,
  isPaymentDeclined = false,
  setIFrameForm = () => {},
  setIFrameLoading = () => {},
  isLoading = false,
}) => {
  const { getText } = useBrand();
  const classes = useStyles();
  const token = get(order, "payment.token");
  const endpoint = get(order, "payment.endpoint");
  const { trackEvent } = useTealium();

  const {
    orderCostSummary: { hours, packagePrice, discount, bookingFee, total },
  } = order;

  const handleBackButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    goBack({ history, pathname });
  };

  return (
    <ContentWrapper>
      <Titlebar
        // Need to review className typings perhaps?
        // @ts-expect-error
        className={classes.title}
        showBackButton
        onBackClick={handleBackButtonClick}
      >
        {getText("payment.pageTitle")}
      </Titlebar>

      <Summary>
        {packagePrice && (
          <SummaryRow
            left={`${getText("payment.lessons")} - ${hours} hrs`}
            right={`£${formatCurrency(packagePrice)}`}
          />
        )}
        {discount > 0 && (
          <SummaryRow
            left={getText("payment.discount")}
            right={
              <span className={classes.discountText}>
                -£{formatCurrency(discount)}
              </span>
            }
          />
        )}
        <SummaryRow
          left={getText("payment.bookingFee")}
          right={`£${formatCurrency(bookingFee)}`}
        />
        {total && (
          <SummaryRow
            left={getText("payment.total")}
            right={`£${formatCurrency(total)}`}
            className={classes.total}
          />
        )}
      </Summary>

      {isPaymentDeclined && (
        <Typography variant="h3" color="error" className={classes.paymentError}>
          {getText("payment.paymentDeclined")}
        </Typography>
      )}

      {token && endpoint && (
        <PaymentForm
          token={token}
          endpoint={endpoint}
          formRef={setIFrameForm}
          iFrameRef={setIFrameLoading}
          isLoading={isLoading}
          classes={classes}
        />
      )}
    </ContentWrapper>
  );
};

export default withRouter(NewUserJourneyStepsPayment);
