import React from "react";

const TheoryAppTermsAndConditions: React.FC = () => (
  <div style={{ padding: 50 }}>
    <h3 id="the-aa-driving-theory-app">The AA Driving Theory App</h3>
    <h1 id="terms-and-conditions">Terms and Conditions</h1>
    <p>from September 2023</p>
    <p>
      <strong>
        By using the AA Driving Theory App, you accept these terms
      </strong>
    </p>
    <p>
      Please read these terms carefully before you use the AA Driving Theory App
      (the “<strong>App</strong>”). By using the App, you agree to be bound by
      these terms and acknowledge that we process your personal data as set out
      in our{" "}
      <a href="https://www.theaa.com/driving-school/lessons/theory-app-privacy-notice">
        Privacy Notice
      </a>{" "}
      (which may change from time to time).
    </p>
    <p>We recommend that you print or save a copy of these terms.</p>
    <p>
      You are responsible for meeting any driving test requirements and we are
      not responsible if you don’t pass your test. You must not use the App
      whilst driving any vehicle.
    </p>
    <p>
      <strong>Third party terms apply</strong>
    </p>
    <p>
      Third party terms may apply to your purchase and use of the App – please
      refer to any terms provided to you in the Apple App Store or Google Play
      Store.
    </p>
    <p>
      <strong>The App contains DVSA material used with their permission</strong>
    </p>
    <p>
      The Driver and Vehicle Standards Agency (DVSA) has given permission for
      the reproduction of Crown copyright material. DVSA does not accept
      responsibility for the accuracy of the reproduction.
    </p>
    <p>
      This product includes the Driver and Vehicle Standards Agency (DVSA)
      revision question bank.
    </p>
    <p>
      The App may also include certain questions, case studies and other content
      which we have created and this content is not endorsed by the DVSA in any
      way.{" "}
    </p>
    <p>
      <strong>Who are we?</strong>
    </p>
    <p>
      We are <strong>Automobile Association Developments Limited</strong>{" "}
      (trading as “<strong>AA Driving School</strong>”) and we are part of the “
      <strong>AA Group</strong>” (which also includes any holding company or
      subsidiary company).
    </p>
    <p>
      <strong>What to do if you need help</strong>
    </p>
    <p>
      If you require help using the app or need to contact us with any issues,
      you can email us at drivingtheoryappsupport@theaa.com{" "}
    </p>
    <p>
      {" "}
      <strong>1.&nbsp;&nbsp;What can I use the App for?</strong>
    </p>
    <p>
      {" "}
      <strong>Using the App</strong>
    </p>
    <p>
      1.1&nbsp;&nbsp;You can use the App to practice for your driving theory
      test and full features are shown in the App.
    </p>
    <p>
      1.2&nbsp;&nbsp;We may add or remove App functionality from time to time –
      see Section 7
    </p>
    <p>
      <strong>Who can use the App?</strong>
    </p>
    <p>1.3&nbsp;&nbsp;&nbsp;To use the App, you must:</p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.3.1 have an eligible iOS or
      Android device with internet access and operating system which can run the
      App; and
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.3.2 have an Apple App Store or
      Google Play Store account.
    </p>
    <p>
      <strong>What does it cost?</strong>
    </p>
    <p>
      1.4&nbsp;&nbsp;The App requires a one-off purchase in the Apple App Store
      or Google Play Store.
    </p>
    <p>
      1.5&nbsp;&nbsp;Third party app store terms may apply – please refer to any
      terms provided to you in the Apple App Store or Google Play Store.
    </p>
    <p>
      1.6&nbsp;&nbsp;The App does not include any DVSA driving theory test fees
      or any other costs associated with your driving tuition.
    </p>
    <p>
      Your mobile data provider may charge you for using data on your device
      when you use the App.
    </p>
    <p>
      <strong>2. &nbsp;&nbsp;You should not rely on content in the App</strong>
    </p>
    <p>
      <strong>App content</strong>
    </p>
    <p>
      2.1&nbsp;&nbsp;App content (including training materials and questions) is
      provided for general information only and is not intended as advice on
      which you should rely.
    </p>
    <p>
      2.2&nbsp;&nbsp;Although we make reasonable efforts to update information
      in the App, we don’t make any guarantee that content in the App is
      accurate, complete or up to date.
    </p>
    <p>
      <strong>3.&nbsp;&nbsp;You must keep your account details safe</strong>
    </p>
    <p>
      <strong>Keeping your account details secure</strong>
    </p>
    <p>
      3.1&nbsp;&nbsp;When you register in the App, you can choose an email and
      may be required to choose a password or use a login email link.
    </p>
    <p>
      3.2&nbsp;&nbsp;Your login details are personal to you and can’t be
      transferred to someone else. You must not let anyone else use your
      account.{" "}
    </p>
    <p>
      3.3&nbsp;&nbsp;You must keep your login details confidential and must not
      disclose them to anyone else.
    </p>
    <p>
      3.4&nbsp;&nbsp;If you think anyone else may know your login details or can
      access your account, then you should let us know straight away.
    </p>
    <p>
      <strong>Responsibility for use</strong>
    </p>
    <p>3.5&nbsp;&nbsp;We use your login details to identify you.</p>
    <p>
      3.6&nbsp;&nbsp;You are responsible for any use of the App by anyone using
      your login details.
    </p>
    <p>
      <strong>4.&nbsp;&nbsp;How you may use materials in the App</strong>
    </p>
    <p>
      <strong>Your licence to use the App</strong>
    </p>
    <p>
      4.1&nbsp;&nbsp;We grant you a limited, personal, non-exclusive and
      non-transferrable right to access and browse the contents of the App on
      the following basis:
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.1.1&nbsp;&nbsp;you will not
      copy, modify, publish, transfer, sell, reproduce or in any way exploit the
      App (or any part of it) or any associated software;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.1.2&nbsp;&nbsp;you will not
      use any of our trademarks or trade names; and
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.1.3&nbsp;&nbsp;you will comply
      with our reasonable instructions relating to the App.
    </p>
    <p>
      <strong>Who owns materials, brands and logos</strong>
    </p>
    <p>
      4.2&nbsp;&nbsp;All materials presented in the App and any other materials
      we send you, unless specifically indicated otherwise, are owned by us or
      our licensors (including the DVSA).
    </p>
    <p>
      4.3&nbsp;&nbsp;Any materials in the App are protected by copyright and
      other laws and treaties around the world, and all rights in these
      materials are reserved.
    </p>
    <p>
      4.4&nbsp;&nbsp;You must not reproduce or redistribute any materials in the
      App without our prior written permission.{" "}
    </p>
    <p>
      4.5&nbsp;&nbsp;All AA trademarks, brands and logos used on the App or
      other materials we send you are the registered trademark of AA Brand
      Management Limited and are used under licence.
    </p>
    <p>
      4.6&nbsp;&nbsp;You have no right to use, replicate or produce any of the
      AA trademarks, brands or logos.
    </p>
    <p>
      4.7&nbsp;&nbsp;You must not use any part of the content in the App for
      commercial purposes without obtaining a licence to do so from us or our
      licensors.
    </p>
    <p>
      <strong>5.&nbsp;&nbsp;What is acceptable use of the App?</strong>
    </p>
    <p>
      <strong>Acceptable use of the App</strong>
    </p>
    <p>5.1&nbsp;&nbsp;When using the App, you must not:</p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.1.1&nbsp;&nbsp;knowingly post
      or transmit any technically harmful material (including viruses, corrupted
      data or other malicious software or data);{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.1.2&nbsp;&nbsp;misuse the App,
      including deliberately breaching security/authentication measures;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.1.3&nbsp;&nbsp;interfere with
      a service to any other user, host or network;{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.1.4&nbsp;&nbsp;attempt to
      modify or reverse engineer the App or related software; or
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.1.5&nbsp;&nbsp;transmit any
      material that is threatening, defamatory, obscene, offensive,
      pornographic, abusive, discriminatory, in breach of confidence or privacy,
      which may cause annoyance or which encourages any criminal offence.
    </p>
    <p>
      <strong>
        6.&nbsp;&nbsp;Availability may be affected by factors beyond our control
      </strong>
    </p>
    <p>
      <strong>You need to use the latest version of the App</strong>
    </p>
    <p>
      6.1&nbsp;&nbsp;From time to time, we may release new and updated versions
      of the App without notice which may change available functionality.
    </p>
    <p>
      6.2&nbsp;&nbsp;You should always use the latest App version to get the
      latest functionality and security updates and we aren’t responsible if you
      don’t use the latest version.
    </p>
    <p>
      <strong>Factors outside our control may affect availability</strong>
    </p>
    <p>
      6.3&nbsp;&nbsp;As the App requires an active internet connection
      (including to register or login), the availability of the App:
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.3.1&nbsp;&nbsp;depends on
      things outside our control (including mobile networks, the internet, app
      stores and other third party systems); and
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.3.2&nbsp;&nbsp;can be limited
      by poor mobile network coverage, including due to geographical and weather
      conditions, and physical obstacles like buildings;
    </p>
    <p>
      6.4&nbsp;&nbsp;As the App contains content with permission from third
      parties, certain content may not be available if those third parties stop
      giving us that permission.
    </p>
    <p>
      6.5&nbsp;&nbsp;We are not responsible for any losses you suffer if the App
      is not available due to these things which are outside of our control.{" "}
    </p>
    <p>
      <strong>We’re not responsible for delays outside our control</strong>
    </p>
    <p>
      6.6&nbsp;&nbsp;If our supply of any product (including the App) is delayed
      by an event outside our control, we’ll let you know as soon as possible
      and try to reduce the delay.
    </p>
    <p>
      6.7&nbsp;&nbsp;As long as we do this, we won’t compensate you for the
      delay.
    </p>
    <p>
      6.8&nbsp;&nbsp;Events outside our control may include delays caused by
      mobile networks, the internet, app stores and other third party systems.
    </p>
    <p>
      <strong>7.&nbsp;&nbsp;We can change products and these terms</strong>
    </p>
    <p>
      <strong>Changes we can always make</strong>
    </p>
    <p>
      7.1&nbsp;&nbsp;We can always change the App and any other products at any
      time:
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.1.1&nbsp;&nbsp;to reflect
      changes in laws and regulatory requirements (for example, changes to
      driving theory test requirements);
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.1.2&nbsp;&nbsp;to make minor
      technical adjustments and improvements (for example, to address a security
      threat); and
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.1.3&nbsp;&nbsp;to update
      digital content, provided that the digital content matches the description
      of it that we provided to you before you bought it. We might ask you to
      install these updates.
    </p>
    <p>
      <strong>Changes to these terms</strong>
    </p>
    <p>
      7.2&nbsp;&nbsp;We may change these terms at any time by publishing
      modified terms in the App or on our website or by providing you with a
      copy of updated terms.{" "}
    </p>
    <p>
      7.3&nbsp;&nbsp;Your use of the App after a change has been made available
      to you will be deemed to signify your acceptance of the modified terms.{" "}
    </p>
    <p>
      7.4&nbsp;&nbsp;We recommend you save a copy of these terms for your
      records. You can download them{" "}
      <a href="https://hazardperceptionvideos131402-dev.s3.amazonaws.com/general/terms.pdf">
        here
      </a>
    </p>
    <p>
      <strong>
        8.&nbsp;&nbsp;We can suspend supply (and you have rights if we do)
      </strong>
    </p>
    <p>
      <strong>We can suspend a product</strong>
    </p>
    <p>
      8.1&nbsp;&nbsp;We can suspend supply of any product (including the App and
      any content). We can do this to:
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.1.1&nbsp;&nbsp;deal with
      technical problems or make minor technical changes;
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.1.2&nbsp;&nbsp;update the
      product to reflect changes in relevant laws and regulatory requirements;
      or
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.1.3&nbsp;&nbsp;make changes to
      the App or these terms (see Section 7).
    </p>
    <p>
      <strong>Your rights if we suspend</strong>
    </p>
    <p>
      8.2&nbsp;&nbsp;We will contact you in advance to tell you that we’ll be
      suspending supply, unless the problem is urgent or an emergency.
    </p>
    <p>
      <strong>
        9.&nbsp;&nbsp;We can withdraw products and we can end our contract with
        you
      </strong>
    </p>
    <p>
      <strong>We can withdraw products</strong>
    </p>
    <p>9.1&nbsp;&nbsp;We can stop providing a product, such as the App.</p>
    <p>
      <strong>We can end our contract with you</strong>
    </p>
    <p>
      9.2&nbsp;&nbsp;We can end our contract with you the App if you are in
      material breach of these terms.
    </p>
    <p>
      <strong>
        10.&nbsp;&nbsp;We don’t compensate you for all losses caused by us or
        our products
      </strong>
    </p>
    <p>
      <strong>Losses we are not responsible for</strong>
    </p>
    <p>
      10.1&nbsp;&nbsp;We’re responsible for losses you suffer caused by us
      breaking these terms, unless the loss is:
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.1.1&nbsp;&nbsp;Unexpected. It
      was not obvious that it would happen (so, in the law, the loss was
      unforeseeable).
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.1.2&nbsp;&nbsp;Caused by a
      delaying event outside our control. As long as we have taken the steps set
      out in Section 6.6 (We’re not responsible for delays outside our control),
      we’ll not be responsible.{" "}
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.1.3&nbsp;&nbsp;Avoidable.
      Something you could have avoided by taking reasonable action. For example,
      damage to your digital content or device caused by digital content we
      supply and which you could have avoided by following our advice to apply a
      free update, following installation instructions or having minimum system
      requirements advised by us.
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.1.4&nbsp;&nbsp;A business
      loss. If you use the App for purposes of your trade, business, craft or
      profession. This includes (without limitation) loss of profit, loss of
      business, business interruption and loss of business opportunity.
    </p>
    <p>
      <strong>
        When we are responsible for losses caused by digital content we supply
      </strong>
    </p>
    <p>
      10.2&nbsp;&nbsp;If we supply digital content which causes damage to your
      device or other digital content (due to our failure to use reasonable care
      and skill), then we’ll decide whether to repair the device or compensate
      you, but the most we’ll pay you for these losses is £1,000.
    </p>
    <p>
      <strong>When are we responsible for other losses</strong>
    </p>
    <p>
      10.3&nbsp;&nbsp;Apart from the responsibility we accept under Section 10.2
      and under any terms implied by law, the most we’ll pay to you in relation
      to other losses you incur under these terms is limited to £30 or the total
      Fees paid (whichever is greater).
    </p>
    <p>
      <strong>Accuracy of content</strong>
    </p>
    <p>
      10.4&nbsp;&nbsp;We do not guarantee or promise that the App or any of its
      contents will be error free or uninterrupted. Other important limits on
      our liability
    </p>
    <p>
      10.5&nbsp;&nbsp;Responsibility for driving tests. You remain responsible
      for meeting all driving test and licensing requirements and we aren’t
      responsible if you don’t pass your driving theory test.
    </p>
    <p>
      10.6&nbsp;&nbsp;Errors and back-ups. The App may contain errors or bugs
      which may cause failures or loss of data from your device. You are
      strongly encouraged to back up all information on your device prior to
      installing or updating the App.
    </p>
    <p>
      <strong>
        11.&nbsp;&nbsp;We are not responsible for third-party websites
      </strong>
    </p>
    <p>
      <strong>Links to third party websites.</strong>
    </p>
    <p>
      11.1&nbsp;&nbsp;The App may contain links to third-party websites or
      display third-party websites within the app itself.{" "}
    </p>
    <p>
      11.2&nbsp;&nbsp;We have no control over third-party websites and have no
      responsibility for any content or material contained in them or goods and
      services offered via them.
    </p>
    <p>
      <strong>Use of third-party websites</strong>
    </p>
    <p>
      11.3&nbsp;&nbsp;Your use of any third-party website may be governed by the
      terms and conditions of that third-party website and you should read these
      carefully before using the third-party website or related services.
    </p>
    <p>
      <strong>12.&nbsp;&nbsp;Other important terms apply</strong>
    </p>
    <p>
      <strong>Please read these terms carefully</strong>
    </p>
    <p>
      12.1&nbsp;&nbsp;We can transfer all or part of our contract with you, so
      that a different organisation is responsible for supplying your product.
      We’ll contact you if this happens and we’ll take reasonable steps to
      ensure that this does not adversely affect your rights under this
      agreement.
    </p>
    <p>
      12.2&nbsp;&nbsp;You need our consent to transfer your rights under these
      terms to someone else.{" "}
    </p>
    <p>
      12.3&nbsp;&nbsp;Nobody else has any rights under this (except other AA
      Group companies). This contract is between you and us. Nobody else can
      enforce it (except other AA Group companies) and neither of us will need
      to ask anybody else to sign-off on ending or changing it.
    </p>
    <p>
      12.4&nbsp;&nbsp;If a court invalidates some of this contract, the rest of
      it will still apply. If a court or other authority decides that some of
      these terms are unlawful, the rest will continue to apply.
    </p>
    <p>
      12.5&nbsp;&nbsp;Effect of termination. If our agreement with you ends then
      this will not affect our right to receive any money which you owe to us
      under it.
    </p>
    <p>
      12.6&nbsp;&nbsp;Even if we delay in enforcing this contract, we can still
      enforce it later. We might not immediately chase you for not doing
      something (like paying) or for doing something you’re not allowed to, but
      that doesn’t mean we can’t do it later.
    </p>
    <p>
      12.7&nbsp;&nbsp;Governing law. These terms are governed by English law and
      wherever you live you can bring claims against us in the English courts.
      If you live in Wales, Scotland or Northern Ireland, you can also bring
      claims against us in the courts of the country you live in. We can claim
      against you in the courts of the country you live in.
    </p>
    <p>
      <strong>13.&nbsp;&nbsp;You can contact us about a complaint</strong>
    </p>
    <p>
      <strong>Resolving complaints</strong>
    </p>
    <p>
      13.1&nbsp;&nbsp;We aim to always provide you with a high level of service.
      However, there may be a time when you feel that our service has fallen
      below the standard you expect. If this is the case and you want to
      complain, we’ll do our best to try and resolve the situation.
    </p>
    <p>
      <strong>Contacting us</strong>
    </p>
    <p>
      13.2&nbsp;&nbsp;Please see the contact information at the start of these
      terms for how to contact us.
    </p>
  </div>
);

export default TheoryAppTermsAndConditions;
