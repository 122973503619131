import React, { Fragment } from "react";
import { string, arrayOf, func, bool } from "prop-types";
import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Modal from "../../../../common/Modal";
import Button from "../../../../common/Button/Button";
import { useBrand } from "../../../../common/BrandProvider/BrandProvider";

import { getJourneyExitURL } from "../../../../../constants";

const useStyles = makeStyles({
  actionButton: {
    margin: "0 auto",
  },
});

const NewUserJourneyStepsLessonTypeInfoModal = ({
  title,
  content,
  moreInfoLinkPath,
  moreInfoLinkText,
  actionButtonText,
  showModal,
  toggleModal,
  testId,
}) => {
  const classes = useStyles();
  const { brand } = useBrand();

  return (
    <Modal
      showModal={showModal}
      toggleModal={toggleModal}
      title={title}
      content={
        <Fragment>
          {content.map(text => (
            <Typography key={text} paragraph>
              {text}
            </Typography>
          ))}
          <Typography>
            <Link
              href={`${getJourneyExitURL(brand)}${moreInfoLinkPath}`}
              component="a"
              underline="always"
              target="_blank"
              rel="noopener"
            >
              {moreInfoLinkText}
            </Link>
          </Typography>
        </Fragment>
      }
      actions={
        <Button
          className={classes.actionButton}
          onClick={toggleModal}
          text={actionButtonText}
          secondary
          data-test={`${testId}-action-button`}
        />
      }
      testId={testId}
    />
  );
};

NewUserJourneyStepsLessonTypeInfoModal.propTypes = {
  title: string,
  content: arrayOf(string),
  moreInfoLinkPath: string,
  actionButtonText: string,
  showModal: bool,
  toggleModal: func,
  testId: string,
};

export default NewUserJourneyStepsLessonTypeInfoModal;
