import { format } from "date-fns";
import { indicator } from "ordinal";

// Utility function that formats a date to a human readable string
export const formatLessonDateTime = date =>
  `${format(date, "h:mmaaaaa'm'")} &#8212; ${format(date, "d")}<sup>${indicator(
    format(date, "d"),
  )}</sup> ${format(date, "MMMM")}`;

export default {
  formatLessonDateTime,
};
