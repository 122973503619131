/**
 * Copied & tweaked from https://gist.github.com/Billy-/d94b65998501736bfe6521eadc1ab538
 * @param {any} obj
 * @param {string[]} keys
 */
const omitDeep = (obj, keys) => {
  if (Array.isArray(obj)) {
    return obj.map(i => omitDeep(i, keys));
  }

  if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).reduce((newObject, k) => {
      if (keys.includes(k)) {
        return newObject;
      }

      return { [k]: omitDeep(obj[k], keys), ...newObject };
    }, {});
  }

  return obj;
};

export default omitDeep;
