import React, { useContext, createContext } from "react";
import get from "lodash.get";

import aaContent from "./aa";
import bsmContent from "./bsm";
import { Brand } from "@graphql/types";

export const allContent = { [Brand.Aads]: aaContent, [Brand.Bsm]: bsmContent };

type AppBrand = Brand.Aads | Brand.Bsm;

interface IBrandContext {
  brand: AppBrand;
  crossSellBrand: AppBrand;
  getText: (_contentKey?: string, _args?: any) => string;
}

// We have to keep these in a map, otherwise the tests can't switch based on brand
const getTextFn: {
  [brand: string]: () => (_contentKey?: string, _args?: any) => any;
} = {};

export const BrandContext = createContext<IBrandContext>({
  brand: Brand.Aads,
  crossSellBrand: Brand.Bsm,
  getText: (_contentKey?: string, _args?: any) => "",
});

export const getBrand = (props: { brand: AppBrand }): AppBrand =>
  get(props, "brand", process.env.REACT_APP_brand || Brand.Aads) as AppBrand;

export const getCrossSellBrand = (props: { brand: AppBrand }): AppBrand => {
  const brand = getBrand(props);
  if (brand === Brand.Aads) return Brand.Bsm;
  if (brand === Brand.Bsm) return Brand.Aads;
  return Brand.Aads;
};

export const setTextFn = (
  brand: string,
): ((_contentKey?: string, _args?: any) => any) => {
  if (!getTextFn[brand]) {
    getTextFn[brand] = (contentKey?: string, args?: any) => {
      const content = get(allContent, `${brand}.text.${contentKey}`);
      return typeof content === "function" ? content(args) : content;
    };
  }
  return getTextFn[brand];
};

const BrandProvider = (props: any) => {
  const brand = getBrand(props);
  const crossSellBrand = getCrossSellBrand(props);
  return (
    <BrandContext.Provider
      value={{
        brand,
        crossSellBrand,
        getText: setTextFn(brand),
      }}
    >
      {get(props, "children", [])}
    </BrandContext.Provider>
  );
};

export const useBrand = () => useContext(BrandContext);

export default BrandProvider;
