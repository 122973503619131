import React from "react";

import { Typography, makeStyles } from "@material-ui/core";
import RadioCardGroup from "../RadioCardGroup/RadioCardGroup";
import RadioCard from "../RadioCard/RadioCard";
import { TITLES } from "../../../../constants";
import { useBrand } from "../../BrandProvider/BrandProvider";

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    height: "56px",
  },
}));

type Props = {
  label: string;
  name?: string;
  value?: string;
  onChange: (_title: string) => void;
  disabled?: boolean;
};

const HonoraryTitlePicker: React.FC<Props> = ({
  label,
  name = "honoraryTitle",
  value = "",
  onChange,
  disabled = false,
}) => {
  const { getText } = useBrand();
  const classes = useStyles();
  const radioCardLabel = label || getText("common.titlePickerLabel");
  return (
    <RadioCardGroup
      label={radioCardLabel}
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {TITLES.map(title => (
        <RadioCard
          classes={classes}
          key={title}
          value={title}
          cardContent={<Typography color="textPrimary">{title}</Typography>}
          cardContentChecked={<Typography>{title}</Typography>}
        />
      ))}
    </RadioCardGroup>
  );
};

export default HonoraryTitlePicker;
