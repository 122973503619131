import { findIndex, get } from "lodash";
import { isSameDay, format, addDays, parseISO } from "date-fns";
import {
  LONG_DATE_FORMAT_WITH_ZEROED_TIMES,
  TIMESLOTS_MAX_DATE_OFFSET,
  TODAY,
} from "../constants";
import { soapTimeToLocalTime } from "./soapTimeToLocalTime";
import { AvailableDateNuj } from "@graphql/types";

export const getTimeSlots = (
  selectedDate: string | null,
  allDates: AvailableDateNuj[],
) => {
  if (!selectedDate || !allDates || !allDates.length) return [];
  const selectedDateAndTimes = allDates.find(d =>
    isSameDay(parseISO(d.date), soapTimeToLocalTime(selectedDate)),
  );
  return get(selectedDateAndTimes || [], "timeSlots", []);
};

export const getFormattedDate = (date: Date) =>
  format(date, LONG_DATE_FORMAT_WITH_ZEROED_TIMES);

export const getDisplayedDateIndex = (
  displayedDates: AvailableDateNuj[],
  date: string,
) =>
  findIndex(displayedDates, d =>
    isSameDay(
      new Date(d.date),
      typeof date === "string" ? new Date(date) : date,
    ),
  );

export const getTimeSlotsStartAndEndDates = (todayDate = TODAY) => ({
  startDate: getFormattedDate(todayDate),
  endDate: getFormattedDate(addDays(todayDate, TIMESLOTS_MAX_DATE_OFFSET)),
});
