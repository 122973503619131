import React from "react";
import { string, bool, shape } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import mergeDeep from "lodash.merge";
import { useBrand } from "../BrandProvider/BrandProvider";

const useStyles = makeStyles(
  ({ journey, breakpoints, palette: { common, primary } }) =>
    mergeDeep(journey.loadingScreen, {
      container: {
        opacity: 0,
        animation: `$fadeIn 1000ms linear forwards`,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        color: common.white,
        backgroundSize: "cover",
        [breakpoints.up("md")]: {
          height: "100vh",
        },
      },
      "@keyframes fadeIn": {
        "0%": {
          opacity: 0,
        },
        "100%": {
          opacity: 0.8,
        },
      },
      messageText: {
        marginBottom: "40px",
        fontWeight: 600,
        color: primary.contrastText,
      },
    }),
);

const LoadingScreen = ({
  messageText,
  showMessageText = true,
  showActivityIndicator = true,
  messageTextProps = {},
  activityIndicatorProps = {},
}) => {
  const { getText } = useBrand();
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      {showMessageText && (
        <Typography
          variant="h5"
          component="h2"
          className={classes.messageText}
          {...messageTextProps}
        >
          {messageText || getText("common.loading")}
        </Typography>
      )}
      {showActivityIndicator && (
        <CircularProgress
          className={classes.progress}
          color="inherit"
          size={70}
          {...activityIndicatorProps}
        />
      )}
    </Box>
  );
};

LoadingScreen.propTypes = {
  messageText: string,
  showMessageText: bool,
  showActivityIndicator: bool,
  messageTextProps: shape({}),
  activityIndicatorProps: shape({}),
};

export default LoadingScreen;
