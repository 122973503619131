import React from "react";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import mergeDeep from "lodash.merge";

import {
  AA_BRAND_NAME,
  getJourneyURL,
  RETURNING_USER_JOURNEY_PATH_NAMES,
  USE_AUTH_PORTAL,
} from "../../../../../constants";
import { BodyText } from "./BodyText";
import { GiftType } from "../../../../../models/state";

const useStyles = makeStyles(theme =>
  mergeDeep({ bodyWrapper: {} }, (theme as any).journey.nujConfirmation, {
    titleText: {
      color: theme.palette.grey[400],
      fontWeight: 300,
      fontSize: "1.6rem",
    },
    bulletList: {},
    accountLink: {
      color: theme.palette.secondary.main,
      textDecoration: "none",
    },
  }),
);

export const Bullet: React.FC = ({ children }) => (
  <ListItem>
    <ListItemIcon>
      <CheckCircle />
    </ListItemIcon>
    <ListItemText>{children}</ListItemText>
  </ListItem>
);

const ConfirmationBodyAAD: React.FC<{
  topUpId?: string;
  gift: GiftType;
  pupilFirstName: string;
}> = ({ topUpId, gift, pupilFirstName }) => {
  const classes = useStyles() as any;
  const setupAccountLink = USE_AUTH_PORTAL
    ? window._env_.REACT_APP_AUTH_PORTAL_URL_AA
    : `${getJourneyURL(AA_BRAND_NAME)}${
        RETURNING_USER_JOURNEY_PATH_NAMES.LOGIN
      }`;

  const quickTopUpLink = topUpId
    ? `${getJourneyURL(AA_BRAND_NAME)}${
        RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT
      }/${topUpId}`
    : undefined;

  return (
    <div className={classes.bodyWrapper}>
      <BodyText
        gift={gift}
        pupilFirstName={pupilFirstName}
        setupAccountLink={setupAccountLink}
        ListEl={List}
        ListItemEl={Bullet}
        quickTopUpLink={quickTopUpLink}
        classes={classes}
      />
    </div>
  );
};

export default ConfirmationBodyAAD;
