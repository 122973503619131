import * as React from "react";

import { makeStyles, Typography, Box, Theme } from "@material-ui/core";
import clsx from "clsx";

import aaNoProgressImage from "../../../../../svg/aa-no-progress.svg";
import bsmNoProgressImage from "../../../../../svg/bsm-no-progress.svg";
import { useBrand } from "../../../../common/BrandProvider/BrandProvider";
import { Brand } from "@graphql/types";

interface CustomTheme extends Theme {
  journey: {
    noProgress: Record<string, any>;
  };
}

const useStyles = makeStyles<CustomTheme>(theme => theme.journey.noProgress);

const NoProgress: React.FC = () => {
  const classes = useStyles();
  const { brand, getText } = useBrand();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <img
        className={classes.image}
        src={brand === Brand.Aads ? aaNoProgressImage : bsmNoProgressImage}
        alt={
          brand === Brand.Aads
            ? "The AA no progress illustration"
            : "BSM no progress illustration"
        }
      />
      <Typography className={classes.text}>
        {getText("returning/progress-tracker.noProgressPara1")}
      </Typography>
      <Typography className={clsx([classes.text, classes.para2])}>
        {getText("returning/progress-tracker.noProgressPara2")}
      </Typography>
    </Box>
  );
};

export default NoProgress;
