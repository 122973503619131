import React, { Fragment } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

import { string } from "prop-types";
import ShareLink from "../../../../common/ShareLink/ShareLink";
import { useBrand } from "../../../../common/BrandProvider/BrandProvider";

import {
  AA_BRAND_NAME,
  getJourneyURL,
  RETURNING_USER_JOURNEY_PATH_NAMES,
} from "../../../../../constants";
import Button from "../../../../common/Button/Button";

const useStyles = makeStyles(theme => theme.journey.sntConfirmation);

export const Bullet = ({ text }) => (
  <ListItem>
    <ListItemIcon>
      <CheckCircle />
    </ListItemIcon>
    <ListItemText>{text}</ListItemText>
  </ListItem>
);

const ConfirmationBodyAAD = ({ topUpId, handleOnClick }) => {
  const classes = useStyles();
  const { getText } = useBrand();
  return (
    <div className={classes.bodyWrapper}>
      <Typography
        component="h3"
        variant="h6"
        classes={{ root: classes.titleText }}
        gutterBottom
      >
        Great that’s booked. Now you need to book your assessment lesson.
      </Typography>

      <Typography variant="body1">
        This lesson needs to be 2hrs long and before your test lesson.
      </Typography>

      <div className={classes.buttonWrapper}>
        <Button
          text="Book more lessons"
          onClick={handleOnClick}
          data-test="snt-book-more-lessons"
        />
      </div>

      <Typography
        component="h3"
        variant="h6"
        classes={{ root: classes.titleText }}
        gutterBottom
      >
        So what happens next?
      </Typography>

      <Typography variant="body1">
        First off, good luck on your driving test!
      </Typography>

      <Typography variant="body1" gutterBottom>
        Your instructor may contact you before your lesson.
      </Typography>
      <br />

      <Typography variant="body1" gutterBottom>
        <strong>Your first lesson</strong>
      </Typography>

      <Typography
        variant="body1"
        component="div"
        gutterBottom
        classes={{ root: classes.bulletList }}
      >
        <List>
          <Bullet text="Don’t forget your provisional licence" />
          <Bullet text="If you need glasses, you must bring them. You will need them to drive" />
          <Bullet text="Wear comfy, flat soled shoes" />
          <Bullet text="Don’t forget your provisional licence" />
        </List>
      </Typography>
      <br />

      {topUpId && (
        <Fragment>
          <Typography
            variant="h6"
            component="h4"
            classes={{ root: classes.titleText }}
            gutterBottom
          >
            Crowdfund your lessons
          </Typography>

          <Typography variant="body1" gutterBottom>
            We’ve made it easy for your family and friends to top up your
            account and keep you learning.
          </Typography>

          <Typography variant="body1" gutterBottom>
            Share your unique link for anyone to add credit to your account:
          </Typography>

          <ShareLink
            link={`${getJourneyURL(AA_BRAND_NAME)}${
              RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT
            }/${topUpId}`}
            actionConfirmationText={getText("confirmation.topUpLinkCopied")}
            actionText={getText("confirmation.copyTopUpLink")}
          />
        </Fragment>
      )}

      <Typography
        classes={{
          root: classes.titleText,
          h6: classes.signOff,
        }}
        variant="h6"
        component="h4"
      >
        Enjoy your lesson!
      </Typography>
    </div>
  );
};

ConfirmationBodyAAD.propTypes = {
  topUpId: string,
  sessionId: string.isRequired,
};

export default ConfirmationBodyAAD;
