import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";

import LoadingScreen from "../../common/LoadingScreen/LoadingScreen";
import progressQuery from "../../../graphql/progress.graphql";
import { useStateContext } from "../../../context/stateContext";

import Progress from "./index";

const ProgressWithQuery = () => {
  const {
    state: {
      rujHistory: { personId },
    },
  } = useStateContext();

  const { loading, error, data } = useQuery(progressQuery, {
    variables: {
      personId,
    },
    skip: !personId,
  });

  const isLoading = loading || !data.learnerProgressByPersonId;

  if (isLoading) {
    return <LoadingScreen messageText="Loading your progress" />;
  }

  if (error) {
    throw new Error(error.message);
  }

  return <Progress {...data.learnerProgressByPersonId} />;
};

export default withRouter(ProgressWithQuery);
