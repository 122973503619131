import {
  UPDATE_NUJ_HISTORY,
  CLEAR_NUJ_SELECTED_INSTRUCTOR,
} from "../../../../context/stateContext";
import { NEW_USER_JOURNEY_PATH_NAMES } from "../../../../constants";

export const submitPickupLocationForm = ({
  event,
  isPostcodeInvalid,
  postcodeValue,
  dispatch,
  history,
}) => {
  event.preventDefault();
  if (!isPostcodeInvalid) {
    dispatch({
      type: UPDATE_NUJ_HISTORY,
      payload: {
        pickupPostcode: postcodeValue.trim(),
      },
    });

    dispatch({
      type: CLEAR_NUJ_SELECTED_INSTRUCTOR,
    });

    history.push(NEW_USER_JOURNEY_PATH_NAMES.FACE_COVERING);
  }
};

export default {
  submitPickupLocationForm,
};
