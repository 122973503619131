import { GraphQLError } from "graphql";
import { useMutation } from "@apollo/react-hooks";

import cancelBooking from "../graphql/cancelBooking.graphql";
import { ERROR_TYPES } from "../constants";

const useCancelBooking = () =>
  useMutation(cancelBooking, {
    onCompleted: ({ cancelLessonForRUJ: result }) => {
      if (result.errorType === ERROR_TYPES.LESSON_DOES_NOT_BELONG_TO_LEARNER) {
        throw new Error(ERROR_TYPES.LESSON_DOES_NOT_BELONG_TO_LEARNER);
      }
    },
    onError: error => {
      throw new GraphQLError(error);
    },
  });

export default useCancelBooking;
