import React from "react";
import { node } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  actionRoot: {
    top: "initial",
    right: "initial",
    position: "initial",
    transform: "initial",
    paddingBottom: "6px",
    flex: 2,
    textAlign: "centre",
    "@media(min-width: 600px)": {
      paddingBottom: "0",
    },
  },
}));

const Actions = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.actionRoot}>{children}</div>;
};

Actions.propTypes = {
  children: node.isRequired,
};

export default Actions;
