import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { FlagsProvider } from "flagged";

import ValidateRoute from "./components/common/ValidateRoute/ValidateRoute";
import getFeatureFlags from "./graphql/getDsGlobalConfig.graphql";

import {
  getJourneyExitURL,
  RETURNING_USER_JOURNEY_PATH_NAMES,
  USE_AUTH_PORTAL,
} from "./constants";

import { useBrand } from "./components/common/BrandProvider/BrandProvider";
import {
  NewUserNewUserJourneySteps,
  OtherNewUserJourneyRoutes,
  OtherPaths,
  QuickTopUpSteps,
  ReturningUserJourneySteps,
  TheoryAppPaths,
} from "./StepDefinitions";
import { BrowserTitles } from "BrowserTitles";

const Routes: React.FC<{ setSessionId: (sessionId: string) => void }> = ({
  setSessionId,
}) => {
  const { brand, getText } = useBrand();
  const { data } = useQuery(getFeatureFlags);
  const features = data?.getDsGlobalConfig;

  const getBrowserTitle = (path: string): string | undefined =>
    BrowserTitles[path] ? getText(BrowserTitles[path]) : undefined;

  const defaultPath = NewUserNewUserJourneySteps[0][0].path;

  return (
    <FlagsProvider features={features}>
      <Switch>
        <Redirect exact from="/" to={defaultPath} />
        {USE_AUTH_PORTAL && (
          <Route
            path={RETURNING_USER_JOURNEY_PATH_NAMES.LOGIN}
            component={() => {
              window.location.href = window._env_.REACT_APP_AUTH_PORTAL_URL_AA;
              return null;
            }}
          />
        )}
        {OtherPaths.map(props => (
          <ValidateRoute
            setSessionId={setSessionId}
            key={props.path}
            steps={[OtherPaths]}
            exact
            browserTitle={getBrowserTitle(props.path)}
            {...props}
          />
        ))}
        {NewUserNewUserJourneySteps.map(stepRoutes =>
          stepRoutes.map(props => (
            <ValidateRoute
              setSessionId={setSessionId}
              key={props.path}
              steps={NewUserNewUserJourneySteps}
              exact
              browserTitle={getBrowserTitle(props.path)}
              {...props}
            />
          )),
        )}
        {OtherNewUserJourneyRoutes.map(props => (
          <ValidateRoute
            setSessionId={setSessionId}
            key={props.path}
            exact
            browserTitle={getBrowserTitle(props.path)}
            {...props}
          />
        ))}
        {QuickTopUpSteps.map(stepRoutes =>
          stepRoutes.map(props => (
            <ValidateRoute
              setSessionId={setSessionId}
              key={props.path}
              steps={QuickTopUpSteps}
              exact
              browserTitle={getBrowserTitle(props.path)}
              {...props}
            />
          )),
        )}
        {ReturningUserJourneySteps.map(stepRoutes =>
          stepRoutes.map(props => (
            <ValidateRoute
              setSessionId={setSessionId}
              key={props.path}
              steps={ReturningUserJourneySteps}
              exact
              browserTitle={getBrowserTitle(props.path)}
              {...props}
            />
          )),
        )}
        {TheoryAppPaths.map(stepRoutes => (
          <ValidateRoute
            setSessionId={setSessionId}
            key={stepRoutes.path}
            steps={ReturningUserJourneySteps}
            exact
            browserTitle={getBrowserTitle(stepRoutes.path)}
            {...stepRoutes}
          />
        ))}
        <Route
          render={() => {
            window.location.href = `${getJourneyExitURL(brand)}/404`;
            return null;
          }}
        />
      </Switch>
    </FlagsProvider>
  );
};

export default Routes;
