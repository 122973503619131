import * as React from "react";
import { ReportProblem } from "@material-ui/icons";
import { BSM_BRAND_NAME } from "../../../../constants";
// @ts-ignore
import { ReactComponent as GiftSurpriseIconAA } from "../../../../svg/aa-gift-surprise-icon.svg";
// @ts-ignore
import { ReactComponent as GiftNoSurpriseIconAA } from "../../../../svg/aa-gift-no-surprise-icon.svg";

export const giftFields = [
  "giftGiversEmail",
  "giftGiversMobile",
  "shouldBeASurprise",
];

export const optionalFields = ["billingAddress", "isGift", ...giftFields];

export const validateForm = (values, { getText }) =>
  Object.entries(values).reduce((errors, [key, value]) => {
    const markAsRequired = () => ({
      ...errors,
      [key]: getText("personal-details.required"),
    });

    if (!value && !optionalFields.includes(key)) {
      return markAsRequired();
    }

    // If the key is one of the gift fields and isGift is set AND the current value is empty, return an error
    if (
      giftFields.includes(key) &&
      values["isGift"] &&
      values["shouldBeASurprise"] &&
      values[key] === ""
    ) {
      return markAsRequired();
    }

    return errors;
  }, {});

export const getSurpriseIcon = (brand, shouldBeASurprise, classes) => {
  const GiftSurpriseIcon =
    brand === BSM_BRAND_NAME ? (
      <ReportProblem classes={{ root: classes.infoPanelIcon }} />
    ) : (
      <GiftSurpriseIconAA />
    );
  const GiftNoSurpriseIcon =
    brand === BSM_BRAND_NAME ? (
      <ReportProblem classes={{ root: classes.infoPanelIcon }} />
    ) : (
      <GiftNoSurpriseIconAA />
    );
  return shouldBeASurprise ? GiftSurpriseIcon : GiftNoSurpriseIcon;
};
