import React from "react";
import mergeDeep from "lodash.merge";
import {
  Typography,
  Grid,
  Icon,
  Button as MUIButton,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { InfoPanelProps, RenderSvgProps, StylesProps } from "./types";
import clsx from "clsx";

const useStyles = ({ backgroundColor }: StylesProps) =>
  makeStyles((theme: Theme & { journey: { infoPanel: Record<string, any> } }) =>
    mergeDeep(theme.journey.infoPanel, {
      body1: {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: "16px",
        lineHeight: "24px",
        [`@media (min-width:${theme.breakpoints.values.md}px)`]: {
          fontSize: "18px",
          lineHeight: "24px",
        },
      },
      body1Variation: {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: "14px",
        lineHeight: "21px",
        [`@media (min-width:${theme.breakpoints.values.md}px)`]: {
          fontSize: "18px",
          lineHeight: "24px",
        },
      },
      body2: {
        fontSize: "14px",
        lineHeight: "21px",
        [`@media (min-width:${theme.breakpoints.values.md}px)`]: {
          fontSize: "16px",
          lineHeight: "24px",
        },
      },
      body2Variation: {
        fontSize: "14px",
        lineHeight: "21px",
      },
      root: {
        padding: "1em 0",
      },
      svg: {
        overflow: "visible",
      },
      wrapper: {
        backgroundColor: backgroundColor || "rgba(47, 42, 52, 0.05)",
        borderRadius: "8px",
        flexGrow: 1,
        paddingRight: "1em",
        paddingLeft: "1em",
      },
      textOuter: {
        zIndex: 0,
        padding: "1em 0 0.8em 1em",
      },
      textInner: {
        margin: "0",
      },
      svgContainer: {
        margin: "1.5em 0 0 1em",
      },
      icon: {
        margin: "1em 0 0 0.5em",
      },
      buttonColumn: {
        padding: "1em  0",
        display: "flex",
        alignItems: "center",
      },
      buttonRoot: {
        color: theme.palette.text.secondary,
      },
      buttonLabel: {
        fontSize: "1rem",
      },
      svgWrapper: {
        display: "flex",
      },
      grid: {
        flex: "1 1 auto",
      },
    }),
  );

const renderSvg: React.FC<RenderSvgProps> = ({
  classes,
  svgClasses,
  svgFlexAlignItems,
  svg,
}) => (
  <div className={classes.svgWrapper} style={{ alignItems: svgFlexAlignItems }}>
    {React.cloneElement(svg, {
      className: clsx(classes.svg, svgClasses),
      "data-test": "svg",
    })}
  </div>
);

/**
 * Component that renders an InfoButton, with icon and text. It will invoke
 * a callback on click, if supplied
 * @param {InfoPanelProps} props Props that the component accepts
 */
const InfoPanel: React.FC<InfoPanelProps> = ({
  title = "",
  variant = false,
  backgroundColor,
  svg,
  svgAlign = "start",
  svgFlexAlignItems = "initial",
  svgClasses,
  icon,
  iconProps = {},
  actionIcon,
  actionIconProps = {},
  actionText,
  actionCallback,
  children,
  rootClasses,
  ...wrapperProps
}) => {
  const classes = useStyles({ backgroundColor })();
  return (
    <div className={rootClasses || classes.root} {...wrapperProps}>
      <Grid container spacing={0} className={classes.wrapper}>
        {svg && svgAlign === "start" && (
          <Grid item className={classes.svgContainer}>
            {renderSvg({ classes, svgClasses, svgFlexAlignItems, svg })}
          </Grid>
        )}
        {icon && !svg && (
          <Grid item>
            <Icon className={classes.icon} {...iconProps} data-test="icon">
              {icon}
            </Icon>
          </Grid>
        )}
        {(title || children) && (
          <Grid item xs className={classes.textOuter}>
            <Grid
              item
              xs
              container
              className={classes.textInner}
              direction="column"
              spacing={2}
            >
              {title && (
                <Grid item xs className={classes.grid}>
                  <Typography
                    classes={{
                      body1: variant ? classes.body1Variation : classes.body1,
                    }}
                    data-test="title"
                    variant="body1"
                  >
                    {title}
                  </Typography>
                </Grid>
              )}
              {children && (
                <Grid item xs className={classes.grid}>
                  <Typography
                    classes={{
                      body1: classes.body2Variation,
                      body2: classes.body2,
                    }}
                    data-test="summary"
                    variant={variant ? "body1" : "body2"}
                    component="span"
                  >
                    {children}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {actionText && actionCallback && (
          <Grid item className={classes.buttonColumn}>
            <MUIButton
              classes={{
                root: classes.buttonRoot,
                label: classes.buttonLabel,
              }}
              data-test="button"
              startIcon={
                actionIcon && (
                  <Icon data-test="button-icon" {...actionIconProps}>
                    {actionIcon}
                  </Icon>
                )
              }
              onClick={actionCallback}
            >
              {actionText}
            </MUIButton>
          </Grid>
        )}
        {svg &&
          svgAlign === "end" &&
          renderSvg({ classes, svgClasses, svgFlexAlignItems, svg })}
      </Grid>
    </div>
  );
};

export default InfoPanel;
