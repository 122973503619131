import { string } from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import CommonConfirmation from "../../../common/Confirmation/Confirmation";
import Body from "./Body";
import { useBrand } from "../../../common/BrandProvider/BrandProvider";

import bsmSNTConfirmationImage from "../../../../svg/bsm-snt-success.svg";
import aaConfirmationBackground from "../../../../jpg/aad-snt-confirmation.jpg";
import { getBookingUrl } from "./helpers";

import { Brand } from "@graphql/types";

const SntConfirmation = ({
  title,
  pickupPostcode,
  startTime,
  endTime,
  instructorName,
  topUpId,
  sessionId,
}) => {
  const { brand } = useBrand();
  const history = useHistory();

  const confirmationProps =
    brand === Brand.Aads
      ? {
          aadBannerImage: aaConfirmationBackground,
          darkBannerImage: true,
          centerText: true,
        }
      : {
          bsmBannerImage: bsmSNTConfirmationImage,
        };

  const handleOnClick = () => {
    const url = getBookingUrl(brand, topUpId, sessionId);
    history.push(url);
  };

  return (
    <CommonConfirmation
      title={title}
      pickupPostcode={pickupPostcode}
      startTime={startTime}
      endTime={endTime}
      instructorName={instructorName}
      {...confirmationProps}
    >
      <Body
        topUpId={topUpId}
        sessionId={sessionId}
        handleOnClick={handleOnClick}
      />
    </CommonConfirmation>
  );
};

SntConfirmation.propTypes = {
  title: string.isRequired,
  pickupPostcode: string,
  startTime: string.isRequired,
  endTime: string.isRequired,
  instructorName: string.isRequired,
  topUpId: string.isRequired,
  sessionId: string.isRequired,
};

export default SntConfirmation;
