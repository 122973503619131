import React, { Fragment } from "react";
import { makeStyles, Typography, useTheme } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import mergeDeep from "lodash.merge";

import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import ContinueButton from "../../common/ContinueButton/ContinueButton";
import Titlebar from "../../common/Titlebar/Titlebar";
import { useBrand } from "../../common/BrandProvider/BrandProvider";

import { RETURNING_USER_JOURNEY_PATH_NAMES } from "../../../constants";
import { goBack } from "../../../utils/navigation";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";

const useStyles = makeStyles(theme =>
  mergeDeep(theme.journey.existingPupil, {
    bodyWrapper: {
      margin: "30px 0",
    },
    bodyText: {
      fontSize: "1.25rem",
      lineHeight: "30px",
    },
    footerWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      margin: "30px 0",
    },
    footerText: {
      fontSize: "1rem",
      lineHeight: "27px",

      [theme.breakpoints.down("xs")]: {
        flexShrink: "0",
      },
    },
  }),
);

const ExistingPupil = ({ history, location: { pathname } }) => {
  const { getText } = useBrand();
  const classes = useStyles();
  const theme = useTheme();
  const { trackEvent } = useTealium();

  const handleBackButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    goBack({ history, pathname });
  };

  return (
    <Fragment>
      <div className={classes.headerWrapper}>
        <ContentWrapper>
          <Titlebar
            showBackButton
            onBackClick={handleBackButtonClick}
            headingProps={{ "data-test": "existing-pupil-title-bar" }}
          >
            {getText("existing-pupil.pageTitle")}
          </Titlebar>
          {theme.journey.existingPupil.bannerImage && (
            <img
              data-happo-hide
              className={classes.headerImage}
              src={theme.journey.existingPupil.bannerImage}
              alt="Welcome Back"
            />
          )}
        </ContentWrapper>
      </div>

      <ContentWrapper fullHeight>
        <div className={classes.bodyWrapper}>
          <Typography component="p" className={classes.bodyText}>
            {getText("existing-pupil.bodyTitle")}
          </Typography>
          <Typography component="p" className={classes.bodyText}>
            {getText("existing-pupil.bodyText")}
          </Typography>
        </div>

        <ContinueButton
          text={getText("existing-pupil.loginButtonText")}
          className={classes.loginButton}
          onClick={e => {
            e.preventDefault();
            history.push(RETURNING_USER_JOURNEY_PATH_NAMES.LOGIN);
          }}
          data-test="login-button"
          disableVerticalMargin
        />

        <div className={classes.footerWrapper}>
          <Typography component="p" className={classes.footerText}>
            {getText("existing-pupil.footerText")}
          </Typography>

          <ContinueButton
            secondary
            className={classes.callUsButton}
            text={getText("existing-pupil.footerButtonText")}
            href={getText("common.chatPage")}
            disableVerticalMargin
            disableHorizontalMargin
          />
        </div>
      </ContentWrapper>
    </Fragment>
  );
};

export default withRouter(ExistingPupil);
