import React from "react";
import { node, string } from "prop-types";
import {
  Select as MuiSelect,
  MenuItem,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  formLabel: {
    marginBottom: "16px",
  },
});

const Select = ({ label, children, inputProps, ...restProps }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { SelectInput } = theme;
  return (
    <FormControl component="fieldset" fullWidth>
      {label && (
        <FormLabel classes={{ root: classes.formLabel }} component="legend">
          {label}
        </FormLabel>
      )}
      <MuiSelect {...restProps} input={<SelectInput {...inputProps} />}>
        <MenuItem value="">Please select</MenuItem>
        {children}
      </MuiSelect>
    </FormControl>
  );
};

Select.propTypes = {
  label: string,
  children: node,
};

export default Select;
