import React, { FC, Fragment } from "react";

import get from "lodash.get";
import { Theme, Typography, useMediaQuery } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/styles";

import { capitalizeEachWordOfPathname } from "../../../../../utils/capitalisePathname";
import { goBack } from "../../../../../utils/navigation";
import { useBrand } from "../../../../common/BrandProvider/BrandProvider";
import { useTealium } from "../../../../common/TealiumProvider/TealiumProvider";
import ContentWrapper from "../../../../common/ContentWrapper/ContentWrapper";
import InitialsAvatar from "../../../../common/Form/InstructorPicker/Avatar/Initials";
import InitialsAvatarSelected from "../../../../common/Form/InstructorPicker/Avatar/InitialsSelected";
import InstructorPicker from "../../../../common/Form/InstructorPicker/InstructorPicker";
import Titlebar from "../../../../common/Titlebar/Titlebar";
import { Instructor } from "models/state";

interface Props {
  instructors: Instructor[];
  onChange: (_id: string) => void | undefined;
  selectedInstructor: Instructor | null;
  selectedPackageHours: number | null;
}

export const JourneyChangeInstructor: FC<Props> = ({
  onChange = () => {},
  instructors = [],
  selectedInstructor = {},
  selectedPackageHours = 0,
}) => {
  const { getText } = useBrand();
  const history = useHistory();
  const { pathname } = useLocation();
  const theme = useTheme<Theme>();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const { trackEvent } = useTealium();

  const handleBackButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(
        pathname.replace("prices", ""),
      ),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    goBack({ history, pathname });
  };

  return (
    <Fragment>
      <ContentWrapper>
        <Titlebar
          showBackButton
          onBackClick={handleBackButtonClick}
          headingProps={{ "data-test": "prices-title-bar" }}
        >
          {getText("prices/change-instructor.selectInstructor")}
        </Titlebar>
      </ContentWrapper>
      <ContentWrapper fullHeight fullWidth={isMobile}>
        {instructors ? (
          <InstructorPicker
            value={get(selectedInstructor, "instructor.id")}
            instructors={instructors.map(instructor => ({
              ...get(instructor, "instructor"),
              hourlyRate: get(
                instructor.prices.find(
                  price => price.hours === selectedPackageHours,
                ),
                "standardHourlyPrice",
              ),
              avatar: (
                <InitialsAvatar name={get(instructor, "instructor.name")} />
              ),
              selectedAvatar: (
                <InitialsAvatarSelected
                  name={get(instructor, "instructor.name")}
                />
              ),
            }))}
            onChange={onChange}
            data-test="change-instructor-picker"
          />
        ) : (
          <Typography>
            {getText("prices/change-instructor.noInstructors")}
          </Typography>
        )}
      </ContentWrapper>
    </Fragment>
  );
};

export default JourneyChangeInstructor;
