import React, { useState } from "react";
import { Icon, makeStyles } from "@material-ui/core";
import { useBrand } from "../BrandProvider/BrandProvider";
import SecondaryText from "../Form/InstructorPicker/SecondaryText";
import TraineeModal from "../Form/InstructorPicker/TraineeModal";

const useStyles = makeStyles(({ palette: { grey } }) => ({
  secondaryTextIcon: {
    color: grey[400],
    fontSize: "16px",
    opacity: "0.9",
  },
  traineeMessage: {
    display: "flex",
    alignItems: "center",
    minWidth: "145px",
  },
}));

const TraineeMessage = () => {
  const { getText } = useBrand();
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = event => {
    if (event) {
      event.stopPropagation();
    }
    setShowModal(!showModal);
  };

  const handleSecondaryClick = event => {
    event.preventDefault();
    event.stopPropagation();
    toggleModal(event);
  };

  return (
    <span className={classes.traineeMessage}>
      <Icon className={classes.secondaryTextIcon} fontSize="small">
        notifications_none
      </Icon>
      <SecondaryText
        onClick={handleSecondaryClick}
        text={getText("common.traineeInstructor")}
      />
      <TraineeModal showModal={showModal} toggleModal={toggleModal} />
    </span>
  );
};

export default TraineeMessage;
