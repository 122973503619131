import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { useStateContext } from "@context";
import {
  GLOBAL_PATH_NAMES,
  RETURNING_USER_JOURNEY_PATH_NAMES,
} from "@constants";

import requestRefundMutation from "../../../graphql/requestRefundWithReason.graphql";
import getRefundBalance from "../../../graphql/getRefundBalance.graphql";
import { LearnerContributionAmount } from "@graphql/types";

import RefundRequest from "./RefundRequest";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import LoadingScreen from "../../common/LoadingScreen/LoadingScreen";

import { RefundableRateHours, RefundRequestStateProviderProps } from "./types";

const RefundRequestStateProvider: React.FC<RefundRequestStateProviderProps> = ({
  history,
}) => {
  const {
    state: {
      rujHistory: { learnerId, pupilFirstName },
    },
  } = useStateContext();

  const { getText } = useBrand();

  const [selectedReason, setSelectedReason] = useState("");

  const { data, loading, error } = useQuery(getRefundBalance, {
    variables: {
      learnerId,
    },
    // Force apollo to re-fetch the data from the server, as the hours should be updated
    fetchPolicy: "no-cache",
  });

  const [requestRefund, { loading: requestInProgress }] = useMutation(
    requestRefundMutation,
    {
      onCompleted: ({ requestRefundWithReason: { successful } }) => {
        if (successful) {
          history.push(
            RETURNING_USER_JOURNEY_PATH_NAMES.REFUND_REQUEST_CONFIRMATION,
          );
        } else {
          history.push(GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG);
        }
      },
      onError: () => {
        history.push(GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG);
      },
    },
  );

  const handleSubmit = () =>
    requestRefund({
      variables: {
        input: {
          pupilId: learnerId,
          reason: selectedReason,
        },
      },
    });

  if (error) {
    throw error;
  }

  const refundableBalanceData: LearnerContributionAmount[] =
    data?.learner?.refundableBalance || [];

  const calcRefundableRateHours = (): RefundableRateHours[] =>
    refundableBalanceData.map(balance => ({
      hours: balance.hours,
      rate: balance.learnerContribution,
    }));

  const calcRefundableTotal = () =>
    refundableBalanceData.reduce(
      (total, balance) => total + balance.hours * balance.learnerContribution,
      0,
    );

  const calcRefundableBalance = () =>
    refundableBalanceData.reduce((hours, balance) => hours + balance.hours, 0);

  const refundableRateHours = calcRefundableRateHours();
  const refundableTotal = calcRefundableTotal();
  const refundableBalance = calcRefundableBalance();

  if (requestInProgress) {
    return (
      <LoadingScreen
        messageText={getText("requestRefund/request.processing")}
      />
    );
  }

  return (
    <RefundRequest
      name={pupilFirstName}
      onSubmit={handleSubmit}
      refundableRateHours={refundableRateHours}
      refundableBalance={refundableBalance}
      refundableTotal={refundableTotal}
      loading={loading}
      submitButtonDisabled={!selectedReason || refundableTotal === 0}
      reason={selectedReason}
      onReasonChange={setSelectedReason}
    />
  );
};

export default withRouter(RefundRequestStateProvider);
