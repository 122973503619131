import React, { useState, useEffect, Children, cloneElement } from "react";
import { string, func, node, bool } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import get from "lodash.get";
import { RadioGroup, FormControl, FormLabel } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0 -4px",
  },
  formLabel: {
    marginBottom: "16px",
  },
});

const RadioCardGroup = ({
  children,
  label,
  name,
  value,
  onChange,
  onBlur = () => {},
  disabled = false,
  ...restProps
}) => {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleClick = (event, val) => {
    setSelected(val);
    onChange(val);
  };

  const classes = useStyles();

  return (
    <FormControl component="fieldset" fullWidth>
      {label && (
        <FormLabel classes={{ root: classes.formLabel }} component="legend">
          {label}
        </FormLabel>
      )}
      <RadioGroup
        name={name}
        value={selected}
        onChange={handleClick}
        onBlur={onBlur}
        classes={{ root: classes.root }}
        {...restProps}
      >
        {Children.map(children, child =>
          cloneElement(child, {
            checked: get(child, "props.value") === selected,
            disabled: child.props.disabled ? true : disabled,
          }),
        )}
      </RadioGroup>
    </FormControl>
  );
};

RadioCardGroup.propTypes = {
  label: string,
  name: string,
  value: node,
  onChange: func,
  onBlur: func,
  disabled: bool,
};

export default RadioCardGroup;
