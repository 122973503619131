import React, { Fragment } from "react";

import { useBrand } from "../BrandProvider/BrandProvider";
import { BSM_BRAND_NAME } from "../../../constants";

import BsmWarning from "./assets/svg/BsmWarning";
import AaWarning from "./assets/svg/AaWarning";

const WarningImage = () => {
  const { brand } = useBrand();
  return (
    <Fragment>
      {brand === BSM_BRAND_NAME ? <BsmWarning /> : <AaWarning />}
    </Fragment>
  );
};

export default WarningImage;
