import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useStateContext } from "../../../context/stateContext";
import FaceCovering from "../../CommonRoutes/FaceCovering/FaceCovering";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";
import { goBack } from "../../../utils/navigation";
import { usePostcodeCountryDetails } from "../../../hooks";
import { RETURNING_USER_JOURNEY_PATH_NAMES } from "../../../constants";
const RUJFaceCoveringWithState = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { trackEvent } = useTealium();

  const {
    state: {
      rujHistory: { pupilPickupPostcode },
    },
  } = useStateContext();

  const countryDetails = usePostcodeCountryDetails({
    postcode: pupilPickupPostcode,
  });

  const handleBackButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    goBack({ history, pathname });
  };

  const handleSubmit = () => {
    history.push(RETURNING_USER_JOURNEY_PATH_NAMES.TIMESLOTS);
  };

  return (
    <FaceCovering
      handleSubmit={handleSubmit}
      handleBackButtonClick={handleBackButtonClick}
      loading={countryDetails.loading}
    />
  );
};

export default RUJFaceCoveringWithState;
