export const getGAClientId = () => {
  try {
    if (typeof document.cookie === "undefined") {
      return null;
    }

    const name = "_ga";
    const matches = document.cookie.match(
      new RegExp(
        `(?:^|; )${name.replace(/([.$?*|{}([\]\\/+^])/g, "\\$1")}=([^;]*)`,
      ),
    );

    return matches ? decodeURIComponent(matches[1]) : null;
  } catch (e) {
    return null;
  }
};

export default { getGAClientId };
