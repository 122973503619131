import * as React from "react";
import {
  Button as MUIButton,
  Icon,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";

// The usage of React.forwardRef will no longer be required for react-router-dom v6.
// see https://github.com/ReactTraining/react-router/issues/6056
const AdapterLink = React.forwardRef(
  (props: { to: string }, ref: React.Ref<HTMLAnchorElement>) => (
    <Link innerRef={ref} {...props} />
  ),
);

const useStyles = makeStyles({
  icon: {
    marginRight: "10px",
  },
});

type ButtonProps = {
  secondary?: boolean;
  text: string;
  icon?: React.ReactNode;
  iconProps?: Record<string, any>;
  navButton?: boolean;
  loading?: boolean;
  [key: string]: any; // restProps
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      secondary,
      text,
      icon,
      iconProps,
      loading = false,
      navButton = false,
      ...restProps
    },
    ref,
  ) => {
    const classes = useStyles();

    return (
      <MUIButton
        color={secondary ? "secondary" : "primary"}
        variant={secondary ? "text" : "contained"}
        component={navButton ? AdapterLink : "button"}
        autoFocus
        ref={ref}
        {...restProps}
      >
        {icon && (
          <Icon classes={{ root: classes.icon }} {...iconProps}>
            {icon}
          </Icon>
        )}
        {loading ? <CircularProgress size={14} color="inherit" /> : text}
      </MUIButton>
    );
  },
);

export default Button;
