import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import Titlebar from "../../common/Titlebar/Titlebar";
import CancelLessonSelect from "./CancelLessonSelect";
import TimeSlot from "./TimeSlot";
import ContinueButton from "../../common/ContinueButton/ContinueButton";
import RadioCardGroup from "../../common/Form/RadioCardGroup/RadioCardGroup";
import NoBookedLessons from "../../common/NoBookedLessons";
import { RETURNING_USER_JOURNEY_PATH_NAMES } from "../../../constants";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";
import { CancelProps } from "./types";

const useStyles = makeStyles({
  cancelLessonSelect: {
    padding: "30px 0",
  },
});

type LessonInfo = {
  bookingId: string;
  lessonDate: Date;
};

type CancelLessonProps = {
  lessons: LessonInfo[];
  onCancelBooking: (input: CancelProps) => void;
};

const CancelLesson: React.FC<CancelLessonProps> = ({
  lessons,
  onCancelBooking,
}) => {
  const classes = useStyles();
  const [selectedBooking, setSelectedBooking] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const { getText } = useBrand();
  const { brand } = useBrand();
  const { trackEvent, updateDataLayer } = useTealium();
  const { pathname } = useLocation();
  const [fieldTouched, setFieldTouched] = useState(false);

  const canCancelLesson = selectedBooking && selectedReason;

  const handleCancelLesson = (event: React.FormEvent<HTMLFormElement>) => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Button Click",
      eventLabel: "Cancel Lesson",
    });
    updateDataLayer({
      cancelled_lesson_sent: "true",
    });
    event.preventDefault();
    onCancelBooking({
      brand,
      bookingId: selectedBooking,
      reason: selectedReason,
    });
  };

  const handleCancelLessonReason = (value: string) => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Interaction",
      eventLabel: value,
    });
    updateDataLayer({
      cancel_lesson_reason: value,
    });
    setSelectedReason(value);
  };

  return (
    <Fragment>
      <ContentWrapper>
        <Titlebar headingProps={{ "data-test": "cancel-lesson-title-bar" }}>
          {getText("returning/cancel-lesson.title")}
        </Titlebar>
        {!lessons.length && (
          <NoBookedLessons
            text={getText("returning/booked-lessons.noLessons")}
            buttonURL={RETURNING_USER_JOURNEY_PATH_NAMES.TIMESLOTS}
          />
        )}
      </ContentWrapper>
      {lessons.length > 0 && (
        <Fragment>
          <ContentWrapper fullHeight>
            <RadioCardGroup
              onChange={setSelectedBooking}
              value={selectedBooking}
              label={getText("returning/cancel-lesson.lessonSlotLabel")}
              name="cancel-lesson-radio"
              data-test="cancel-lesson-lesson-slot"
              onClick={() => {
                if (!fieldTouched) {
                  trackEvent({
                    eventCategory: capitalizeEachWordOfPathname(pathname),
                    eventAction: "Interaction",
                    eventLabel: "Choose A Lesson",
                  });
                  setFieldTouched(true);
                }
              }}
            >
              {lessons.map(({ bookingId, lessonDate }) => (
                <TimeSlot
                  key={bookingId}
                  bookingId={bookingId}
                  lessonDate={lessonDate}
                />
              ))}
            </RadioCardGroup>
          </ContentWrapper>
          <ContentWrapper fullHeight>
            <div className={classes.cancelLessonSelect}>
              <CancelLessonSelect
                label={getText("returning/cancel-lesson.reasonLabel")}
                selected={selectedReason}
                onChange={handleCancelLessonReason}
              />
            </div>
          </ContentWrapper>
          <ContentWrapper fullHeight>
            <Typography>
              <strong>{getText("returning/cancel-lesson.noteEmphasis")}</strong>
              {getText("returning/cancel-lesson.note")}
            </Typography>
          </ContentWrapper>
          <ContentWrapper fullHeight>
            <ContinueButton
              text={getText("returning/cancel-lesson.button")}
              data-test="cancel-lesson-button"
              onClick={handleCancelLesson}
              disabled={!canCancelLesson}
            />
          </ContentWrapper>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CancelLesson;
