import React from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Success from "../../common/Success/Success";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import WarningImage from "../../common/Success/WarningImage";
import { RETURNING_USER_JOURNEY_PATH_NAMES } from "../../../constants";

const useStyles = makeStyles({
  text: {
    marginTop: "30px",
  },
});

const CancelLessonChat = () => {
  const { getText } = useBrand();
  const history = useHistory();
  const classes = useStyles();

  return (
    <Success
      testId="cancel-lesson-chat"
      title={getText("returning/cancel-lesson/chat.title")}
      buttonText={getText("returning/cancel-lesson/chat.buttonText")}
      buttonIsForChat={true}
      secondaryButtonText={getText(
        "returning/cancel-lesson/chat.secondaryButtonText",
      )}
      handleSecondaryButtonClick={() =>
        history.push(RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT)
      }
      Image={WarningImage}
    >
      {getText("returning/cancel-lesson/chat.summary")}
      <Typography component="p" className={classes.text}>
        {getText("returning/cancel-lesson/chat.secondarySummary")}
      </Typography>
    </Success>
  );
};

export default CancelLessonChat;
