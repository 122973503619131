import React, { Fragment } from "react";
import InfoPanel from "../InfoPanel/InfoPanel";
import { useBrand } from "../BrandProvider/BrandProvider";
import { ReactComponent as ShieldIcon } from "../../../svg/icon-money-shield.svg";
import { ReactComponent as MoneyHeartIcon } from "../../../svg/icon-money-heart.svg";
import { BSM_BRAND_NAME } from "../../../constants";

const RefundGuarantee = () => {
  const { getText, brand } = useBrand();

  return (
    <InfoPanel
      svg={brand === BSM_BRAND_NAME ? <MoneyHeartIcon /> : <ShieldIcon />}
      svgFlexAlignItems="flex-start"
      title={getText("common.refundGuaranteeTitle")}
      variant
    >
      <Fragment>
        {`${getText("common.refundGuarantee")} `}
        Our{" "}
        <a
          href={getText("personal-details.tsandcsLink")}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms
        </a>{" "}
        may apply.
      </Fragment>
    </InfoPanel>
  );
};

export default RefundGuarantee;
