import get from "lodash.get";
import set from "lodash.set";
import cloneDeep from "lodash.clonedeep";

/**
 * Given a `firstName` and ` lastName`, returns a string comprised of the
 * capitalized first letter of each name
 *
 * @param {string} firstName
 * @param {string} lastName
 *
 * @example
 * getInitials("john", "dorian") // "JD"
 * getInitials("John", "Dorian") // "JD"
 */
export const getInitials = (firstName, lastName) =>
  `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;

/**
 * Given `name`, returns the first name and last name
 *
 * @param {string} name
 *
 * @example
 * getFirstAndLastName("john dorian") // ["john", "dorian"]
 */
export const getFirstAndLastName = name => name.split(" ");

/**
 * Given `name`, returns a string comprised of the capitalized first letter of each name
 *
 * @param {string} name
 *
 * @example
 * getInitialsFromName("john dorian") // "JD"
 * getInitialsFromName("John Dorian") // "JD"
 */
export const getInitialsFromName = name => {
  const [firstName, lastName] = getFirstAndLastName(name);
  return getInitials(firstName, lastName);
};

/**
 * Splits the supplied string by whitespace, capitalizes the first letter of
 * each word, and returns them as a string
 *
 * @param {string} text
 *
 * @example
 * capitalize("hello") // "Hello"
 * capitalize("Hello") // "Hello"
 * capitalize("hello world") // "Hello World"
 * capitalize("hello       world") // "Hello World"
 */
export const capitalize = text =>
  text
    .replace(/\s\s+/g, " ")
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

/**
 * Given a valid postcode, uppercases all text and inserts space before the
 * last 3 characters
 *
 * @param {string} postcode
 *
 * @example
 * formatPostcode("ab123cd") // "AB12 3CD"
 * formatPostcode("ab12 3cd") // "AB12 3CD"
 * formatPostcode("a b 1 2 3 c d") // "AB12 3CD"
 */
export const formatPostcode = postcode => {
  const uppercased = postcode.replace(/\s/g, "").toUpperCase();
  const sliceIndex = uppercased.length - 3;
  const firstPart = uppercased.slice(0, sliceIndex);
  const secondPart = uppercased.slice(sliceIndex);
  return `${firstPart} ${secondPart}`;
};

/**
 * Trims whitespace from values at given object paths, if they are strings.
 *
 * NB. This only does a shallow pass and does not recurs deeply into
 * an object.
 *
 * @param {Object} obj
 * @param {Array} paths
 * @return {Object} A new object with string values at the given paths trimmed of whitespace
 */
export const trimObjectValues = (obj, paths = []) => {
  const trimmedValues = cloneDeep(obj);
  paths.forEach(path => {
    const value = get(trimmedValues, path);
    if (typeof value === "string") {
      set(trimmedValues, path, value.trim());
    }
  });
  return trimmedValues;
};

export const maskLastChars = (postcode = "", maskLength = 2, mask = "*") => {
  const poChars = postcode.split("");
  const poLength = poChars.length;
  const unmasked = poLength - maskLength;
  return poChars.fill(mask, unmasked, poLength).join("");
};

export default {
  getInitials,
  getInitialsFromName,
  getFirstAndLastName,
  capitalize,
  formatPostcode,
};

export * from "./formatInstructors";
export * from "./getGAClientId";
export * from "./formatBrandForGQLResponse";
export * from "./useQueryParams";
export * from "./getJourneyType";
