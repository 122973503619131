import { GraphQLError } from "graphql";
import { useLazyQuery } from "@apollo/react-hooks";

import getTopupDetailsQuery from "../graphql/topUpDetails.graphql";

const useFetchTopUpDetails = ({ onCompleted }) =>
  useLazyQuery(getTopupDetailsQuery, {
    onCompleted,
    onError: error => {
      throw new GraphQLError(error);
    },
  });

export default useFetchTopUpDetails;
