import React, { Fragment } from "react";
import { node } from "prop-types";
import { Grid } from "@material-ui/core";

const PaymentSummaryRow = ({
  left,
  right,
  className,
}: {
  left: string | number | React.ReactNode;
  right: string | number | React.ReactNode;
  className?: string;
}) => (
  <Fragment>
    <Grid item xs={6} className={className}>
      {left}
    </Grid>
    <Grid item xs={6} className={className}>
      {right}
    </Grid>
  </Fragment>
);

PaymentSummaryRow.propTypes = {
  left: node,
  right: node,
};

export default PaymentSummaryRow;
