import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import FaceCovering from "../../../CommonRoutes/FaceCovering/FaceCovering";
import { useTealium } from "../../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../../utils/capitalisePathname";

import { goBack } from "../../../../utils/navigation";
import { NEW_USER_JOURNEY_PATH_NAMES } from "../../../../constants";

const NUJFaceCoveringWithState = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { trackEvent } = useTealium();

  const handleBackButtonClick = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Link Click",
      eventLabel: "Back",
    });
    goBack({ history, pathname });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    history.push(NEW_USER_JOURNEY_PATH_NAMES.LESSON_TYPES);
  };

  return (
    <FaceCovering
      handleSubmit={handleSubmit}
      handleBackButtonClick={handleBackButtonClick}
    />
  );
};

export default NUJFaceCoveringWithState;
